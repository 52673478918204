import * as React from "react";

const WhatsApp = (props) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={32} height={32} rx={16} fill="#4CAF50" />
    <path
      d="M22.341 9.65A8.343 8.343 0 0 0 16.43 7.2c-4.624 0-8.37 3.733-8.37 8.34 0 1.46.41 2.918 1.112 4.143L8 23.999l4.449-1.166a8.56 8.56 0 0 0 3.98.991c4.625 0 8.371-3.733 8.371-8.341-.059-2.159-.878-4.259-2.459-5.834Zm-1.873 8.866c-.175.467-.995.933-1.405.992-.35.058-.82.058-1.287-.059-.293-.116-.703-.233-1.171-.466-2.107-.875-3.454-2.975-3.57-3.15-.118-.117-.879-1.109-.879-2.159 0-1.05.527-1.516.702-1.75.176-.233.41-.233.586-.233h.41c.117 0 .292-.058.468.35.176.408.585 1.458.644 1.517a.37.37 0 0 1 0 .35c-.059.116-.117.233-.234.35-.117.116-.234.291-.293.35-.117.116-.234.233-.117.408.117.233.527.875 1.17 1.458.82.7 1.464.934 1.698 1.05.234.117.351.059.468-.058.118-.117.527-.583.644-.817.117-.233.293-.175.469-.116.175.058 1.229.583 1.405.7.234.116.35.175.41.233.058.175.058.583-.118 1.05Z"
      fill="#fff"
    />
  </svg>
);

export default WhatsApp;
