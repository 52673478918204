import { createSlice } from "@reduxjs/toolkit";

const normalizeData = (data) =>
  data.map(
    ({
      id,
      item,
      itemAmount,
      message,
      hasAccess,
      createdAt,
      companyName,
      firstName,
      lastName,
      country,
      email,
      phone,
      buyerId,
      status,
      unreadMessagesCount,
      companyViewed
    }) => ({
      key: id,
      unreadMessagesCount,
      product: item ?? "",
      productAmount: itemAmount ?? "",
      message: message ?? "",
      hasAccess: hasAccess ?? "",
      requestTime: createdAt ?? "",
      company: companyName ?? "",
      fullName: `${firstName ?? ""} ${lastName ?? ""}`.trim(),
      country: country?.localizedName ?? "",
      workMail: email ?? "",
      phoneNumber: phone ?? "",
      toDialog: buyerId ?? "",
      isUserDeactivated: status?.id === 4,
      companyViewed
    })
  );

const initialState = {
  data: [],
  total: 10
};

const incomingRequestsSellerSlice = createSlice({
  name: "incomingRequestsSeller",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = incomingRequestsSellerSlice.actions;

export default incomingRequestsSellerSlice.reducer;
