import { validateCaptcha } from "react-simple-captcha";

export const nameValidation = [
  { required: true, message: "Введите имя" },
  {
    min: 2,
    message: "Не менее двух символов"
  },
  {
    max: 20,
    message: "Не более двадцати символов"
  },
  {
    validator: (_, value) => {
      if (value && value.trim().length === 0) {
        return Promise.reject("Введите имя");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const companyValidation = [
  { required: true, message: "Введите название вашей компании" },
  {
    min: 5,
    message: "Не менее пяти символов"
  },
  {
    max: 100,
    message: "Не более ста  символов"
  },
  {
    validator: (_, value) => {
      if (value && value.trim().length === 0) {
        return Promise.reject("Введите название вашей компании");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const countryValidation = [
  { required: true, message: "Введите вашу страну" },
  {
    min: 2,
    message: "Не менее двух символов"
  },
  {
    max: 100,
    message: "Не более ста символов"
  },
  {
    validator: (_, value) => {
      if (value && value.trim().length === 0) {
        return Promise.reject("Введите вашу страну");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const mobilePhoneValidation = [
  { required: true, message: "" },
  {
    message: "Введите корректный номер телефона",
    validator: (_, value) => {
      if (!value || value.indexOf("_") !== -1) {
        return Promise.reject("Введите корректный номер телефона");
      } else {
        return Promise.resolve();
      }
    }
  }
];
export const checkboxRequiredValidation = [
  {
    required: true,
    validator: (_, value) => {
      if (!value) {
        return Promise.reject("");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const captchaValidation = [
  {
    required: true,
    message: ""
  },
  {
    message: "Неверная капча!",
    validator: (_, value) => {
      if (validateCaptcha(value) === false) {
        return Promise.reject("Неверная капча!");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const loginPasswordValidation = [
  { required: true, message: "Введите пароль" },
  {
    validator: (_, value) => {
      if (value && value.trim().length === 0) {
        return Promise.reject("Введите пароль");
      } else {
        return Promise.resolve();
      }
    }
  }
];

export const emailValidation = [
  { required: true, type: "email", message: "Введите валидный email!" }
];
export const customKeyWordsValidator = (form, t, ) => (
  {
    validator: (_, value) => {
      const normalizedValue = value
        ? value
          .trim()
          .split(",")
          .filter((el) => el.trim() !== "")
          .map((el) => el.trim())
        : value;
      if (!normalizedValue || normalizedValue.length === 0) {
        return Promise.reject(
          t("fields.keywords.errors.required", { ns: "FS10-AddNewProduct" })
        );
      } else if (normalizedValue.length > 5) {
        return Promise.reject(
          t("fields.keywords.errors.notMoreThanFive", {
            ns: "FS10-AddNewProduct"
          })
        );
      } else if (normalizedValue.find((el) => el.trim().split(" ").length > 2)) {
        return Promise.reject(t("maxTwoWordsInCollocation", { ns: "utils" }));
      } else {
        form.setFieldsValue({ keywords: normalizedValue.join(", ") });
        return Promise.resolve();
      }
    }
  }
)
