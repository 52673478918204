import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/promocodes/`
});

const GET = {
  getPromocodes: (config) => instance.get("", config),
  searchPromocodes: (name) => instance.get(`search/${name}`)
};

export const promocodesApi = { ...GET };
