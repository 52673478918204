/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import s from "./PageSizePicker.module.less";

const pageSizePickerOptions = [
  {
    label: 10,
    value: 10
  },
  {
    label: 25,
    value: 25
  },
  {
    label: 50,
    value: 50
  },
  {
    label: 100,
    value: 100
  }
];

const defaultPageSize = 10;

const PageSizePicker = ({ setPageSize, style, employee = false, node }) => {
  const { t } = useTranslation(["FS16-IncomingRequestsSeller", "FA34-EmployeesList"]);
  const [openState, setOpenState] = useState(false);

  return (
    <Space align="center" size={12} style={style}>
      <span className={s.pageSizePickerLabel}>{t("show")}</span>
      <Select
        className={s.pageSizePicker}
        getPopupContainer={() => node ? node.current : document.getElementById('root')}
        size="large"
        style={{ width: 73 }}
        open={openState}
        suffixIcon={
          <DropdownIcon
            onClick={() => {
              setOpenState((prevState) => !prevState);
            }}
          />
        }
        onDropdownVisibleChange={setOpenState}
        onChange={setPageSize}
        optionLabelProp={"label"}
        options={pageSizePickerOptions}
        defaultValue={defaultPageSize}
      />
      <span className={s.pageSizePickerLabel}>{employee ? t("users", { ns: "FA34-EmployeesList" }) : t("requests")}</span>
    </Space>
  );
};

export default PageSizePicker;
