/* eslint-disable react/prop-types */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space } from 'antd';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import YouTube from 'react-youtube';

import { draftsAPI, mediaUrl, serviceAPI, uploadsAPI } from '../../api/api';
import Close from '../../assets/svg/Close';
import Img from '../../assets/svg/Img';
import plusSvg from '../../assets/svg/plus.svg';
import Trash from '../../assets/svg/trash';
import AddAndCropLogo from '../../components/AddAndCropLogo/AddAndCropLogo';
import CategoriesSelect from '../../components/CategoriesSelect/CategoriesSelect';
import DropdownIcon from '../../components/DropdownIcon/DropdownIcon';
import Loader from '../../components/Loader/Loader';
import { convertStringToLinks } from '../../helpers/convertStringToLinks';
import { createImgState, drawImgInputs } from '../../helpers/formElements';
import { customKeyWordsValidator } from '../../helpers/validation-rules';
import useChangeFormAlert from '../../hooks/useChangeFormAlert';
import useOnlyLatinLetterInputHandlerAndModal from '../../hooks/useOnlyLatinLetterInputHandlerAndModal';
import useProductServiceInfo from '../../hooks/useProductServiceInfo';
import { setIsLoading } from '../../store/authSlice';
import { getServiceCategories, getServiceRegions } from '../../store/resourcesSlice';
import { initialCurrentService, setCurrentService } from '../../store/servicesSlice';
import s2 from '../FK2.1-productPage/ProductPage.module.less';
import s from '../FS10-AddNewProduct/AddNewProduct.module.less';


const MIN_IMG_WIDTH = 600;
const MIN_IMG_HEIGHT = 600;
const AddNewService = React.forwardRef(function (
  {
    editMode = null,
    setEditMode = null,
    editId = 0,
    admin,
    customCompanyId,
    showStatistics = true,
    showButtonsAndStatus = true,
    formDisabled = false
  },
  ref
) {
  const autoSaveTimeoutRef = useRef(0);
  const dispatch = useDispatch();
  const { serviceCategories, serviceRegions } = useSelector((state) => state.resources);
  const imgInputRef = React.createRef();
  const imgInputRef2 = React.createRef();
  const imgInputRef3 = useRef(null);
  const { user, isLoading, isAdmin } = useSelector((state) => state.auth);
  const { currentService } = useSelector((state) => state.services);
  const [showPreview, setShowPreview] = useState(false);
  const [showSendForModerationInPreview, setShowSendForModerationInPreview] = useState(false);
  const [videoIsUploading, setVideoIsUploading] = useState(false);
  const [companyId, setCompanyId] = useState(admin ? customCompanyId : user?.company?.id);
  const [serviceStatus, setServiceStatus] = useState({ text: "", className: "green" });
  const [displayAlert, setDisplayAlert, EditFormAlert] = useChangeFormAlert();
  const [mainPreviewImg, setMainPreviewImg] = useState("");
  const [previewImages, setPreviewImages] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  let redirectTo = useRef(null);
  const requestError = useRef(false);
  const navigate = useNavigate();
  const fetchDraft = () => {
    if (user.company?.type === 1 || user.company?.type === 4) {
      dispatch(setCurrentService(initialCurrentService));
    } else {
      draftsAPI.getDraft({ companyId: user?.company?.id, entityType: "service" }).then((res) => {
        if (res.data) {
          dispatch(setCurrentService(res.data));
          setServiceStatus({
            text: "draft",
            className: "darkRed"
          });
        } else {
          dispatch(setCurrentService(initialCurrentService));
        }
      });
    }
  };
  const autoSave = (data) => {
    clearTimeout(autoSaveTimeoutRef.current);
    if (!displayAlert && !showPreview) {
      autoSaveTimeoutRef.current = setTimeout(() => {
        onFinish(data, false, false);
      }, 1000);
    }
  };
  useEffect(() => {
    !admin && setCompanyId(user?.company?.id);
    if (user && !editMode) {
      fetchDraft();
    }
  }, [user]);
  useEffect(() => {
    if (!serviceCategories.length) {
      dispatch(getServiceCategories());
    }
    if (!serviceRegions.length) {
      dispatch(getServiceRegions());
    }
  }, [serviceCategories, serviceRegions]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [showExitWithoutSavingModal, setShowExitWithoutSavingModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [closeAfterEdit, setCloseAfterEdit] = useState(false);
  const [currentSub, setCurrentSub] = useState([]);
  const [form] = Form.useForm();

  const [video, setVideo] = useState(true);
  const [videoFileName, setVideoFileName] = useState("");
  const [videoFileStatus, setVideoFileStatus] = useState("");
  const [videoFileStatusText, setVideoFileStatusText] = useState("");
  const [responseVideoFileName, setResponseVideoFileName] = useState("");

  const [imgFileName, setImgFileName] = useState("");
  const [imgFileStatus, setImgFileStatus] = useState("");
  const [imgFileStatusText, setImgFileStatusText] = useState("");
  const [responseImgFileName, setResponseImgFileName] = useState("");
  const [localImgUrl, setLocalImgUrl] = useState("");
  const [imgError, setImgError] = useState(false);
  const [subcategoriesError, setSubcategoriesError] = useState(false);
  const [showImgErrorModal, setShowImgErrorModal] = useState(false);
  const previewImagesRef = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    if (subcategories.length) {
      setSubcategoriesError(false);
    }
  }, [subcategories.length]);

  const checkSubcategoriesError = () => {
    if (!subcategories.length) {
      setSubcategoriesError(true);
    } else setSubcategoriesError(false);
  };

  const resetForm = () => {
    setImgFileName("");
    setImgFileStatus("");
    setLocalImgUrl("");
    setResponseImgFileName("");
    setAdditionalImgs({
      addImg: {
        localImgUrl: "",
        fileName: "",
        fileStatus: "",
        statusText: "",
        responseFileName: ""
      }
    });
    setVideoFileStatus("");
    setVideoFileStatusText("");
    setResponseVideoFileName("");
    form.resetFields();
    getInfo();
  };
  const [additionalImgs, setAdditionalImgs] = useState({
    addImg: {
      localImgUrl: "",
      fileName: "",
      fileStatus: "",
      statusText: "",
      responseFileName: ""
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setPreviewImages(() => {
      let newPreviewImages = Object.values(additionalImgs)
        .filter((item) => !!item?.localImgUrl)
        .map((item) => item.localImgUrl);
      newPreviewImages.push(localImgUrl);
      return newPreviewImages;
    });
  }, [additionalImgs, localImgUrl]);
  useEffect(() => {
    setMainPreviewImg(localImgUrl);
  }, [localImgUrl]);

  useEffect(() => {
    if (editMode) {
      if (
        currentService.moderationStatus.name === "ACCEPTED" ||
        currentService.moderationStatus.name === "PROCESSED"
      ) {
        setDisplayAlert(true);
      } else {
        setDisplayAlert(false);
      }
      if (currentService.moderationStatus.name === "PROCESSED") {
        setServiceStatus({
          text: "onModeration",
          className: "red"
        });
      } else if (currentService.moderationStatus.name === "EDITED") {
        setServiceStatus({
          text: "changedWithoutModeration",
          className: "darkRed"
        });
      } else if (currentService.moderationStatus.name === "ACCEPTED") {
        setServiceStatus({
          text: "moderated",
          className: "green"
        });
      } else if (currentService.moderationStatus.name === "REJECTED") {
        setServiceStatus({
          text: "rejected",
          className: "red"
        });
      }
    }

    const dataSrc = currentService.moderations ? currentService.moderations : currentService;
    if (dataSrc?.video?.indexOf(".mp4") !== -1) {
      setVideo(false);
      setVideoFileName(dataSrc?.video);
      setResponseVideoFileName(dataSrc?.video);
    } else {
      setVideo(true);
    }
    if (dataSrc.image) {
      setImgFileName(dataSrc.image);
      setResponseImgFileName(dataSrc.image);
      setLocalImgUrl(`${mediaUrl}${dataSrc.image}`);
    } else {
      setImgFileName("");
      setResponseImgFileName("");
      setLocalImgUrl("");
      setMainPreviewImg("");
    }
    if (dataSrc.subcategories.some((obj) => obj.id === undefined)) {
      setSubcategories([]);
    } else {
      setSubcategories(dataSrc.subcategories.map((obj) => obj.id));
    }
    createImgState(dataSrc, "servicePhotos", setAdditionalImgs);
    form.setFieldsValue({
      serviceName: dataSrc.serviceName,
      keywords: dataSrc.serviceKeywords.map((k) => k.keyword).join(", "),
      description: dataSrc.description,
      subcategories:
        dataSrc.subcategories && dataSrc.subcategories.length
          ? [...Object.values(dataSrc.subcategories).map((el) => el.id)]
          : [undefined],
      youtubeLink: dataSrc?.video?.indexOf(".mp4") === -1 ? dataSrc?.video : "",
      advantage1: dataSrc.advantage1,
      advantage2: dataSrc.advantage2,
      advantage3: dataSrc.advantage3,
      availableCertificates: dataSrc.availableCertificates,
      rangeId: dataSrc.serviceRange?.id || undefined,
      visible: dataSrc.visible,
      video: dataSrc?.video?.indexOf(".mp4") !== -1 ? "" : dataSrc?.video
    });
    // console.log(dataSrc);
  }, [form, currentService]);
  const { t } = useTranslation([
    "FS11-AddNewService",
    "ServiceRegions",
    "ServiceCategories",
    "FS10-AddNewProduct",
    "FS8-changeCompanyProfile",
    "FK2.8-servicePage",
    "utils"
  ]);
  const context = useOutletContext();
  const setTitle = context?.setTitle || null;
  const initialState = {
    category0: false,
    category1: false,
    rangeId: false
  };
  const [openState, setOpenState] = useState(initialState);

  useEffect(() => {
    if (setTitle) {
      if (editMode) setTitle(t("editService", { ns: "FS12-EditProductOrService" }));
      else setTitle(t("title"));
    }
  }, [t, setTitle]);

  const checkImgError = () => {
    if (!responseImgFileName) {
      setImgError(true);
    } else {
      setImgError(false);
    }
  };
  const { getInfo, InfoVisualisation } = useProductServiceInfo(admin, customCompanyId);
  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  useEffect(() => {
    autoSave(form.getFieldsValue());
    return () => {
      clearTimeout(autoSaveTimeoutRef.current);
    };
  }, [
    responseImgFileName,
    additionalImgs,
    responseVideoFileName,
    video,
    displayAlert,
    subcategories
  ]);
  const onFinish = (
    result,
    sendModeration = false,
    showResultModal = true,
    redirectToEditPage = true
  ) => {
    clearTimeout(autoSaveTimeoutRef.current);
    if (videoIsUploading) {
      setModalMessage(t("responses.waitForVideoToUpload"));
      if (showResultModal) setModalIsVisible(true);
      return;
    }

    if (!result.video && result.video !== "") {
      result.video = responseVideoFileName;
    }
    result.keywords = result.keywords?.split(",").map((el) => el.trim()) || [];
    // console.log(result);
    const image = responseImgFileName;
    const images = Object.values(additionalImgs)
      .filter((el) => el && el.responseFileName !== "")
      .map((el) => el.responseFileName);
    const {
      serviceName,
      description,
      keywords,
      visible,
      advantage1,
      advantage2,
      advantage3,
      video,
      availableCertificates,
      rangeId
    } = result;
    if (editMode === null) {
      dispatch(setIsLoading(true));
      serviceAPI
        .createService(
          { sendModeration },
          serviceName,
          description,
          subcategories,
          visible,
          advantage1,
          advantage2,
          advantage3,
          image,
          video,
          availableCertificates,
          rangeId,
          keywords,
          images,
          companyId
        )
        .then((res) => {
          requestError.current = false;
          if (sendModeration) {
            if (!isAdmin) {
              setModalMessage(t("responses.success"));
            } else {
              setModalMessage(t("responses.saved"));
            }
            if (redirectToEditPage)
              redirectTo.current = `${!isAdmin ? "/account/edit-product-or-service" : "/admin-panel"
                }`;
          } else {
            setModalMessage(t("responses.saved"));
          }
          if (showResultModal) setModalIsVisible(true);
        })
        .catch((e) => {
          requestError.current = true;
          if (e.response.data.message === "PRODUCTS_SERVICES_ADD_LIMIT") {
            setModalMessage(t("responses.PRODUCTS_SERVICES_ADD_LIMIT"));
          } else {
            setModalMessage(t("responses.error"));
          }
          if (showResultModal) setModalIsVisible(true);
          // console.log(e.response);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
      // imgInputRef.current.value = "";
      // imgInputRef2.current.value = "";
    } else {
      dispatch(setIsLoading(true));
      serviceAPI
        .updateService(
          { sendModeration },
          serviceName,
          description,
          subcategories,
          visible,
          advantage1,
          advantage2,
          advantage3,
          image,
          video,
          availableCertificates,
          rangeId,
          keywords,
          images,
          editId
        )
        .then(() => {
          requestError.current = false;
          if (sendModeration) {
            if (!isAdmin) {
              setModalMessage(t("responses.success"));
            } else {
              setModalMessage(t("responses.saved"));
            }
            setCloseAfterEdit(true);
            redirectTo.current = `${!isAdmin ? "/account/edit-product-or-service" : "/admin-panel"
              }`;
            setServiceStatus({
              text: "onModeration",
              className: "red"
            });
            setModalIsVisible(true);
            setDisplayAlert(true);
          } else {
            setModalMessage(t("responses.saved"));
            setServiceStatus({
              text: "changedWithoutModeration",
              className: "orange"
            });
          }
          if (!displayAlert) {
            if (showResultModal) setModalIsVisible(true);
          }
        })
        .catch(() => {
          requestError.current = true;
          setModalMessage(t("responses.error"));
          setModalIsVisible(true);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  };
  const onFinishFailed = (result) => {
    // console.log("fail");
    // console.log(responseVideoFileName);
    const images = Object.values(additionalImgs)
      .filter((el) => el && el.responseFileName !== "")
      .map((el) => el.responseFileName);
    // console.log(images);
    result.values.keywords = result.values.keywords?.split(",") || [];
    // console.log(result.values);
    checkImgError();
    checkSubcategoriesError();
  };
  const [visibleCropLogo, setVisibleCropLogo] = useState(false);
  const [visibleAdditionalCropLogo, setVisibleAdditionalCropLogo] = useState(false);
  const [img, setImg] = useState({});
  const [ind, setInd] = useState({});

  const addFoto = (file) => {
    setImgError(false);
    setResponseImgFileName("");
    setImgFileStatusText("");
    setImgFileStatus("");
    setImgFileName(file?.name || "");
    setLocalImgUrl("");
    if (file) {
      setLocalImgUrl(URL.createObjectURL(file));
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        setImgFileStatus("loading");
        setImgFileStatusText(t("fields.File.status.loading"));

        const formData = new FormData();
        formData.append("file", file);
        formData.append("originalname", file.name);
        uploadsAPI
          .uploadImage(formData)
          .then((res) => {
            // console.log(res);
            setImgFileStatus("success");
            setImgFileStatusText(t("fields.File.status.succesfullyLoaded"));
            setResponseImgFileName(res.data.filename);
          })
          .catch((e) => {
            // console.log(e);
            setImgFileStatus("failed");
            setImgFileStatusText(t("fields.File.status.uploadFailed"));
          });
      };
    }
  };

  // console.log(additionalImgs);
  const addAdditionalPhoto = (e) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(e);
    img.onload = function () {
      setAdditionalImgs((prev) => ({
        ...prev,
        ["addImg" + ind]: {
          localImgUrl: URL.createObjectURL(e),
          fileName: e?.name,
          fileStatus: "loading",
          statusText: t("fields.File.status.loading"),
          responseFileName: ""
        }
      }));
      const formData = new FormData();
      formData.append("file", e);
      formData.append("originalname", e.name);
      uploadsAPI
        .uploadImage(formData)
        .then((res) => {
          setAdditionalImgs((prev) => ({
            ...prev,
            ["addImg" + ind]: {
              localImgUrl: URL.createObjectURL(e),
              fileName: e?.name,
              fileStatus: "success",
              statusText: t("fields.File.status.succesfullyLoaded"),
              responseFileName: res.data.filename
            }
          }));
          if (Object.keys(additionalImgs).length >= 1) {
            setAdditionalImgs((prev) => ({
              ...prev,
              ["addImg" + Object.keys(prev).length]: {
                localImgUrl: "",
                fileName: "",
                fileStatus: "",
                statusText: "",
                responseFileName: ""
              }
            }));
          }
        })
        .catch((e) => {
          setAdditionalImgs((prev) => ({
            ...prev,
            addImg: {
              localImgUrl: URL.createObjectURL(e),
              fileName: e?.name,
              fileStatus: "failed",
              statusText: t("fields.File.status.uploadFailed"),
              responseFileName: ""
            }
          }));
        })
        .finally(() => {
          setInd("");
        });
    };
  };

  const onSelectFile = (e, type, ind) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 50000000) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImg({ base64: reader.result?.toString() || "", name: e.target.files[0].name })
      );
      reader.readAsDataURL(e.target.files[0]);
      if (type === "cropLogo") setVisibleCropLogo(true);
      else if (type === "cropAdditionalImg") {
        setVisibleAdditionalCropLogo(true);
        setInd(ind);
      }
    } else if (e.target.files[0].size > 50000000) {
      setShowImgErrorModal(true);
    }
  };

  const handlePickImgInputRef3 = () => {
    imgInputRef3.current.click();
  };

  const handleSwitchMainImg = (img, index) => {
    setMainPreviewImg(img);
  };

  const handlerScrollRight = () => {
    previewImagesRef.current.scrollBy(130, 0);
  };
  const handlerScrollLeft = () => {
    previewImagesRef.current.scrollBy(-130, 0);
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        validateAndSave() {
          return validateAndSave();
        }
      };
    },
    [videoIsUploading, responseImgFileName, subcategories, video]
  );

  return (
    <>
      <AddAndCropLogo
        visible={visibleCropLogo}
        setVisible={setVisibleCropLogo}
        saveCropLogo={addFoto}
        img={img}
        setImg={setImg}
        inputId={"mainServiceImg"}
      />
      <AddAndCropLogo
        visible={visibleAdditionalCropLogo}
        setVisible={setVisibleAdditionalCropLogo}
        saveCropLogo={addAdditionalPhoto}
        img={img}
        setImg={setImg}
        inputId={"additionalPhoto"}
      />
      {OnlyLatinLettersModal}
      <Modal
        visible={showImgErrorModal}
        onOk={() => setShowImgErrorModal("")}
        onCancel={() => setShowImgErrorModal("")}
        footer={[
          <Button
            key="ok"
            className="btn btn_v2"
            onClick={() => {
              setShowImgErrorModal("");
            }}
          >
            OK
          </Button>
        ]}
      >
        <p>{t("logoModalError", { ns: "FS8-changeCompanyProfile" })}</p>
      </Modal>
      {showExitWithoutSavingModal && (
        <Modal
          visible={showExitWithoutSavingModal}
          onOk={() => setEditMode(null)}
          onCancel={() => setShowExitWithoutSavingModal(false)}
          width={"max-content"}
          style={{ maxWidth: "1000px" }}
          footer={[
            <Space
              key={1}
              size={30}
              align="center"
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "flex-end",
                zIndex: 2,
                position: "relative"
              }}
            >
              <Button
                className={`btn btn_v2  ml-auto`}
                size="large"
                onClick={() => setEditMode(null)}
              >
                {t("buttons.yes", { ns: "utils" })}
              </Button>
              <Button
                className={`btn btn_v8  ml-auto`}
                size="large"
                onClick={() => setShowExitWithoutSavingModal(false)}
              >
                {t("buttons.no", { ns: "utils" })}
              </Button>
            </Space>
          ]}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            {t("confirmLeaveWithoutSaving", { ns: "FS10-AddNewProduct" })}
          </p>
        </Modal>
      )}
      {showPreview && (
        <Modal
          visible={showPreview}
          onOk={() => {
            setShowPreview(false);
          }}
          onCancel={() => setShowPreview(false)}
          width={"max-content"}
          style={{ maxWidth: "1000px" }}
          footer={[
            <Space
              key={1}
              size={30}
              align="center"
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "flex-end",
                zIndex: 2,
                position: "relative"
              }}
            >
              <Button
                className={`btn btn_v8  ml-auto`}
                size="large"
                onClick={() => {
                  setShowPreview(false);
                }}
              >
                {t("buttons.backToEdit", { ns: "FS10-AddNewProduct" })}
              </Button>
              {showSendForModerationInPreview && (
                <Button
                  className={`btn btn_v2 ml-auto`}
                  type="default"
                  size="large"
                  onClick={(e) => {
                    checkImgError();
                    checkSubcategoriesError;
                    form
                      .validateFields()
                      .then((res) => {
                        if (videoIsUploading) {
                          setModalMessage(t("responses.waitForVideoToUpload"));
                          setModalIsVisible(true);
                          return;
                        }
                        if (!responseImgFileName) {
                          document.getElementById("mainServiceImg").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                          return;
                        }
                        if (subcategoriesError) {
                          document.getElementById("test").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                          return;
                        }
                        onFinish(form.getFieldsValue(), true);
                        setShowPreview(false);
                      })
                      .catch((e) => {
                        if (!responseImgFileName) {
                          document.getElementById("mainServiceImg").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                        } else {
                          const errorFieldId =
                            e.errorFields[0].name[0] === "subcategories"
                              ? `#addService_${e.errorFields[0].name[0]}_0`
                              : `#addService_${e.errorFields[0].name[0]}`;
                          document.querySelector(`${errorFieldId}`).scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start"
                          });
                        }
                      });
                  }}
                >
                  {t(`${isAdmin ? "buttons.save" : "buttons.sendForModeration"}`, {
                    ns: "FS10-AddNewProduct"
                  })}
                </Button>
              )}
            </Space>
          ]}
        >
          <div className={s2.main} style={{ fontWeight: 400 }}>
            <div style={{ display: "flex" }}>
              <div>
                <div className={s2.imgWrapper}>
                  <img className={s2.image} src={`${mainPreviewImg}`} alt="" />
                </div>
                <div style={{ position: "relative" }}>
                  <div className={s2.smallImgWrapper} ref={previewImagesRef}>
                    {previewImages.map((img, index) => {
                      return (
                        <img
                          key={index}
                          className={s2.smallImage}
                          src={`${img}`}
                          alt="photo"
                          onClick={() => handleSwitchMainImg(img)}
                        />
                      );
                    })}
                  </div>
                  {previewImages.length > 3 && (
                    <div className={s2.arrowLeft} onClick={handlerScrollLeft}>
                      <LeftOutlined />
                    </div>
                  )}
                  {previewImages.length > 3 && (
                    <div className={s2.arrowRight} onClick={handlerScrollRight}>
                      <RightOutlined />
                    </div>
                  )}
                </div>
              </div>
              <div style={{ margin: "0 32px 32px 32px" }}>
                <div className={s2.title}>{form.getFieldValue("serviceName")}</div>

                <div className={s2.subTitle}>{t("certificate", { ns: "FK2.8-servicePage" })}</div>
                <div className={s2.subValue}>
                  {convertStringToLinks(form.getFieldValue("availableCertificates"))}
                </div>
                <div className={s2.subTitle}>{t("region", { ns: "FK2.8-servicePage" })}</div>
                <div className={s2.subValue}>
                  {t(
                    `${serviceRegions.find((el) => el.id === form.getFieldValue("rangeId")).range}`,
                    { ns: "ServiceRegions" }
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "50px" }}>
              <div className={s2.title}>{t("description", { ns: "FK2.8-servicePage" })}</div>
              <div className={s2.text}>{form.getFieldValue("description")}</div>
              {form.getFieldValue("advantage1") ||
                form.getFieldValue("advantage2") ||
                form.getFieldValue("advantage3") ? (
                <>
                  <div className={s2.title} style={{ marginTop: "32px" }}>
                    {t("advantages", { ns: "FK2.8-servicePage" })}
                  </div>
                  <ul>
                    {form.getFieldValue("advantage1") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage1")}</li>
                    )}
                    {form.getFieldValue("advantage2") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage2")}</li>
                    )}
                    {form.getFieldValue("advantage3") && (
                      <li className={s2.advantage}>{form.getFieldValue("advantage3")}</li>
                    )}
                  </ul>
                </>
              ) : null}
            </div>
            {((video && form.getFieldValue("video")) ||
              (videoFileStatus !== "error" && responseVideoFileName)) && (
                <div style={{ marginTop: "50px", position: "relative", zIndex: 2 }}>
                  <div className={s2.title}>{t("video", { ns: "FK2.8-servicePage" })}</div>
                  {!video && videoFileStatus !== "error" && responseVideoFileName && (
                    <video
                      src={`${mediaUrl}${responseVideoFileName}`}
                      title="video"
                      controls="controls"
                      preload={false}
                      style={{
                        width: "max-content",
                        height: "max-content",
                        maxHeight: "260px",
                        maxWidth: "390px"
                      }}
                    />
                  )}
                  {video && form.getFieldValue("video") && (
                    <>
                      <YouTube
                        videoId={
                          form
                            .getFieldValue("video")
                            .match(
                              /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                            )[7]
                        }
                        style={{ width: "390px", height: "260px" }}
                      />
                    </>
                  )}
                </div>
              )}
          </div>
        </Modal>
      )}
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onOk={() => {
            setModalIsVisible(false);
            setShowPreview(false);
            if (!editMode && !requestError.current) {
              resetForm();
              fetchDraft();
            }
            if (redirectTo.current) {
              navigate(redirectTo.current, { state: { tab: "service" } });
            }
          }}
          onCancel={() => {
            setModalIsVisible(false);
            setShowPreview(false);
            if (!editMode && !requestError.current) {
              resetForm();
              fetchDraft();
            }
          }}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                // console.log(requestError.current);
                setModalIsVisible(false);
                setShowPreview(false);
                if (!editMode && !requestError.current) {
                  resetForm();
                  fetchDraft();
                }
                if (redirectTo.current) {
                  navigate(redirectTo.current, { state: { tab: "service" } });
                }
                if (closeAfterEdit) {
                  setEditMode(null);
                }
              }}
            >
              ОК
            </Button>
          ]}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            <Trans components={{ h3: <h3 style={{ marginBottom: 10 }} /> }}>{modalMessage}</Trans>
          </p>
        </Modal>
      ) : null}
      <Row ref={containerRef}>
        <Col xxl={{ span: 24 }} className={`regular_block`}>
          {editMode !== null && (
            <div className={s.close} onClick={() => setEditMode(null)}>
              <Close />
            </div>
          )}
          {isLoading && <Loader />}
          <Form
            disabled={formDisabled}
            form={form}
            style={{ width: "100%", position: "relative" }}
            colon={false}
            name="addService"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelWrap
            requiredMark={false}
            validateTrigger="onSubmit"
            scrollToFirstError={true}
          // onFieldsChange={(e) => {
          //     clearTimeout(autoSaveTimeoutRef.current)
          //     autoSave(form.getFieldsValue())
          // }}
          >
            {editMode &&
              (currentService.moderationStatus.name === "ACCEPTED" ||
                currentService.moderationStatus.name === "PROCESSED") && (
                <EditFormAlert saveAsDraft={() => onFinish(form.getFieldsValue())} />
              )}
            {showStatistics && (
              <>
                <Row gutter={{ xl: 100 }}>
                  <Col span={12} className="mb-18">
                    <p style={{ fontSize: 18, fontWeight: 500 }}>{t("formatDesc")}</p>
                  </Col>
                  {InfoVisualisation()}
                </Row>
                <div style={{ borderBottom: "1px solid #C9D4DB", marginBottom: 25 }}></div>
              </>
            )}
            <Row gutter={{ xl: 100 }}>
              <Col span={12}>
                <p className="fw-500 mb-30 orange">{t("p1")}</p>
                <Form.Item
                  label={
                    <div className={`${`label`} ${`req`}`}>{t("fields.serviceName.label")}</div>
                  }
                  name="serviceName"
                  className={s.no_margin}
                  rules={[
                    {
                      required: true,
                      message: t("fields.serviceName.errors.required")
                    },
                    {
                      min: 2,
                      message: t("fields.serviceName.errors.min")
                    },
                    {
                      max: 50,
                      message: t("fields.serviceName.errors.max")
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject(t("fields.serviceName.errors.required"));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={50}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "serviceName",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.keywords.label")}</div>}
                  name="keywords"
                  rules={[
                    {
                      min: 2,
                      message: t("fields.keywords.errors.min", { ns: "FS10-AddNewProduct" })
                    },
                    {
                      max: 150,
                      message: t("fields.keywords.errors.max", { ns: "FS10-AddNewProduct" })
                    },
                    customKeyWordsValidator(form, t)
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={100}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "keywords",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <p className="fz-12 grey fw300 mb-18">{t("p2")}</p>
                <Form.Item
                  label={
                    <div className={`${`label`} ${`req`}`}>{t("fields.description.label")}</div>
                  }
                  name="description"
                  className={s.margin}
                  rules={[
                    {
                      required: true,
                      message: t("fields.description.errors.required")
                    },
                    {
                      min: 30,
                      message: t("fields.description.errors.min")
                    },
                    {
                      max: 700,
                      message: t("fields.description.errors.max")
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject(t("fields.description.errors.required"));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    maxLength={700}
                    showCount
                    autoSize={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "description",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <p className="fz-12 grey fw300 mb-18">{t("descriptionHint", { ns: "utils" })}</p>
                <Form.Item
                  className={s.formItem}
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.category.label")}</div>}
                  name={"subcategories"}
                >
                  <CategoriesSelect
                    isValidate={subcategoriesError}
                    translateTitle={""}
                    categories={serviceCategories}
                    translate={"ServiceCategories"}
                    node={containerRef}
                    currentSubId={subcategories}
                    setCurrentSubId={setSubcategories}
                  />
                </Form.Item>

                <p className="fz-12 grey fw300 mb-80">{t("p9", { ns: "FS10-AddNewProduct" })}</p>

                <Row>
                  <Col span={20}>
                    <div className="border-grey">
                      <h5>{t("h5")}</h5>
                      <p className="fw-300 mb-12">{t("p3")}</p>

                      <Radio.Group
                        value={video}
                        onChange={(e) => {
                          setVideo(e.target.value);
                        }}
                      >
                        <Space direction="vertical">
                          <Radio
                            // disabled={videoFileName || form.getFieldValue("video")}
                            value={true}
                          >
                            {t("radio.youtubeLink")}
                          </Radio>
                          <Radio
                            // disabled={videoFileName || form.getFieldValue("video")}
                            className="mt-10"
                            value={false}
                          >
                            {t("radio.uploadVideo")}
                          </Radio>
                        </Space>
                      </Radio.Group>

                      {video ? (
                        <Form.Item
                          className="mt-12"
                          name="video"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (
                                  value &&
                                  value.trim().length !== 0 &&
                                  // eslint-disable-next-line no-useless-escape
                                  !/^(https?\:\/\/)?((www\.)?(youtube\.com|youtu\.be))\/.+$/.test(
                                    value
                                  )
                                ) {
                                  return Promise.reject(t("fields.youtubeLink.errors.correctLink"));
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]}
                        >
                          <Input placeholder={t("fields.youtubeLink.label")} size="large" />
                        </Form.Item>
                      ) : (
                        <>
                          <br />
                          <div style={{ display: "flex" }}>
                            <label
                              htmlFor="videoInput"
                              className="word-break mt-12 btn btn_v5"
                              style={{ width: "max-content", cursor: "pointer", minHeight: "62px" }}
                            >
                              {!videoFileName && <img src={plusSvg} className="mr-20" />}
                              {videoFileName || t("buttons.addVideoFile")}
                            </label>
                            <div className={s.trash}>
                              {videoFileName && (
                                <Trash
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setVideoFileName("");
                                    setVideoFileStatus("");
                                    document.getElementById("videoInput").value = "";
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <input
                            accept=".mp4"
                            id="videoInput"
                            type="file"
                            className="d-none"
                            onChange={(e) => {
                              setVideoFileStatusText("");
                              setVideoFileStatus("");
                              setVideoFileName(e.target.files[0]?.name || "");
                              if (e.target.files[0] && e.target.files[0].size > 1073741824) {
                                setVideoFileStatus("error");
                                setVideoFileStatusText(t("fields.File.status.tooBig"));
                              } else if (e.target.files[0]) {
                                setVideoIsUploading(true);
                                setVideoFileStatus("loading");
                                setVideoFileStatusText(t("fields.File.status.loading"));

                                const formData = new FormData();
                                formData.append("file", e.target.files[0]);
                                formData.append("originalname", e.target.files[0].name);
                                uploadsAPI
                                  .uploadVideo(formData)
                                  .then((res) => {
                                    // console.log(res);
                                    setVideoFileStatus("success");
                                    setVideoFileStatusText(
                                      t("fields.File.status.succesfullyLoaded")
                                    );
                                    setResponseVideoFileName(res.data.filename);
                                  })
                                  .catch((e) => {
                                    // console.log(e);
                                    setVideoFileStatus("failed");
                                    setVideoFileStatusText(t("fields.File.status.uploadFailed"));
                                  })
                                  .finally(() => {
                                    setVideoIsUploading(false);
                                  });
                              }
                            }}
                            disabled={videoFileName}
                          />

                          {videoFileStatus && (
                            <div
                              className={`${videoFileStatus === "error"
                                  ? "red"
                                  : videoFileStatus === "loading"
                                    ? "orange"
                                    : "green"
                                }`}
                            >
                              {videoFileStatusText}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <p className="fw-500 mb-30 orange">{t("p4")}</p>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage1.label")}</div>}
                  name="advantage1"
                  className={s.no_margin}
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage1",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage2.label")}</div>}
                  name="advantage2"
                  className={s.no_margin}
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage2",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`}`}>{t("fields.advantage3.label")}</div>}
                  name="advantage3"
                  className={s.no_margin}
                  style={{ marginBottom: 36 }}
                >
                  <Input.TextArea
                    autoSize={true}
                    size="large"
                    maxLength={100}
                    showCount={true}
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "advantage3",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <div className={`imgWrapper ${imgError ? "border-red" : ""}`}>
                  {localImgUrl ? (
                    <img
                      style={{ height: "80px", width: "80px", objectFit: "contain" }}
                      src={localImgUrl}
                    />
                  ) : (
                    <Img id={"2"} />
                  )}
                  {responseImgFileName || imgFileStatus === "error" ? (
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!formDisabled) {
                          setImgError(false);
                          setImgFileName("");
                          setImgFileStatus("");
                          setLocalImgUrl("");
                          setResponseImgFileName("");
                          imgInputRef.current.value = "";
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <label>
                    <p className="link-heading">{t("fields.photo1.heading") + "*"}</p>
                    <p className={`link`}>{imgFileName || t("fields.photo1.label")}</p>
                    <input
                      disabled={formDisabled}
                      id="mainServiceImg"
                      ref={imgInputRef}
                      className={`file`}
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => onSelectFile(e, "cropLogo", "")}
                    />
                  </label>
                  {imgFileStatus && (
                    <div
                      className={`ml-15 ${imgFileStatus === "error"
                          ? "red"
                          : imgFileStatus === "loading"
                            ? "orange"
                            : "green"
                        }`}
                    >
                      {imgFileStatusText}
                    </div>
                  )}
                </div>
                {imgError && <div className={"red"}>{t("fields.img.errors.required")}</div>}

                <p className="fz-12 grey fw300 mb-45">
                  {t("p5_1")}
                  <br />
                  {t("p5_2")}
                </p>
                <p className="fw-500 mb-20">{t("p6")}</p>
                <div className={`imgWrapper`}>
                  {additionalImgs.addImg.localImgUrl ? (
                    <img
                      style={{ height: "80px", width: "80px", objectFit: "contain" }}
                      src={additionalImgs.addImg.localImgUrl}
                    />
                  ) : (
                    ""
                  )}
                  {additionalImgs.addImg.responseFileName ||
                    additionalImgs.addImg.fileStatus === "error" ? (
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!formDisabled) {
                          imgInputRef2.current.value = null;
                          let activeCount = 0;
                          Object.values(additionalImgs).forEach((el) => {
                            if (el) ++activeCount;
                          });

                          if (activeCount === 2) {
                            setAdditionalImgs({
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            });
                          } else {
                            setAdditionalImgs((prev) => ({
                              ...prev,
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            }));
                          }
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {additionalImgs.addImg.localImgUrl ? (
                    <label>
                      <p className="link-heading">{t("fields.photo2.heading")}</p>
                      <p className={`link`}>
                        {additionalImgs.addImg.fileName || t("fields.photo1.label")}
                      </p>
                      <input
                        disabled={formDisabled}
                        ref={imgInputRef2}
                        className={`file`}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => {
                          setAdditionalImgs((prev) => ({
                            ...prev,
                            addImg: {
                              localImgUrl: "",
                              fileName: "",
                              fileStatus: "",
                              statusText: "",
                              responseFileName: ""
                            }
                          }));

                          if (e.target.files[0]) {
                            setAdditionalImgs((prev) => ({
                              ...prev,
                              addImg: {
                                localImgUrl: URL.createObjectURL(e.target.files[0]),
                                fileName: e.target.files[0]?.name,
                                fileStatus: "loading",
                                statusText: t("fields.File.status.loading"),
                                responseFileName: ""
                              }
                            }));
                            let img = new Image();
                            img.src = window.URL.createObjectURL(e.target.files[0]);
                            img.onload = function () {
                              const formData = new FormData();
                              formData.append("file", e.target.files[0]);
                              formData.append("originalname", e.target.files[0].name);
                              uploadsAPI
                                .uploadImage(formData)
                                .then((res) => {
                                  // console.log(res);
                                  setAdditionalImgs((prev) => ({
                                    ...prev,
                                    addImg: {
                                      localImgUrl: URL.createObjectURL(e.target.files[0]),
                                      fileName: e.target.files[0]?.name,
                                      fileStatus: "success",
                                      statusText: t("fields.File.status.succesfullyLoaded"),
                                      responseFileName: res.data.filename
                                    }
                                  }));
                                  console.log(previewImages, `${mediaUrl}${res.data.filename}`);
                                  if (Object.keys(additionalImgs).length === 1) {
                                    setAdditionalImgs((prev) => ({
                                      ...prev,
                                      ["addImg" + Object.keys(prev).length]: {
                                        localImgUrl: "",
                                        fileName: "",
                                        fileStatus: "",
                                        statusText: "",
                                        responseFileName: ""
                                      }
                                    }));
                                  }
                                })
                                .catch((e) => {
                                  // console.log(e);

                                  setAdditionalImgs((prev) => ({
                                    ...prev,
                                    addImg: {
                                      localImgUrl: URL.createObjectURL(e.target.files[0]),
                                      fileName: e.target.files[0]?.name,
                                      fileStatus: "failed",
                                      statusText: t("fields.File.status.uploadFailed"),
                                      responseFileName: ""
                                    }
                                  }));
                                });
                            };
                          } else {
                            setShowImgErrorModal(true);
                            let activeCount = 0;
                            Object.values(additionalImgs).forEach((el) => {
                              if (el) ++activeCount;
                            });

                            if (activeCount === 2) {
                              setAdditionalImgs({
                                addImg: {
                                  localImgUrl: "",
                                  fileName: "",
                                  fileStatus: "",
                                  statusText: "",
                                  responseFileName: ""
                                }
                              });
                            }
                          }
                        }}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                  {additionalImgs.addImg.fileStatus && (
                    <div
                      className={`ml-15 ${additionalImgs.addImg.fileStatus === "error"
                          ? "red"
                          : additionalImgs.addImg.fileStatus === "loading"
                            ? "orange"
                            : "green"
                        }`}
                    >
                      {additionalImgs.addImg.statusText}
                    </div>
                  )}
                </div>
                {drawImgInputs(
                  additionalImgs,
                  setAdditionalImgs,
                  t,
                  "2",
                  setShowImgErrorModal,
                  onSelectFile
                )}
                {Object.keys(additionalImgs).length === 1 &&
                  additionalImgs?.addImg?.statusText === "" &&
                  !!!Object.values(additionalImgs).find((f) => f !== null && f.responseFileName) ? (
                  <div onClick={handlePickImgInputRef3} className={`imgWrapper`}>
                    <Img id={2} />
                    <label>
                      <p className="link-heading">{t("fields.photo2.heading")}</p>
                      <p className={`link`}>{t("fields.photo1.label")}</p>
                    </label>
                    <input
                      ref={imgInputRef3}
                      accept=".jpg,.jpeg,.png"
                      disabled={formDisabled}
                      className={`file`}
                      type="file"
                      onChange={(e) => {
                        setAdditionalImgs((prev) => ({
                          ...prev,
                          addImg: {
                            localImgUrl: "",
                            fileName: "",
                            fileStatus: "",
                            statusText: "",
                            responseFileName: ""
                          }
                        }));

                        if (e.target.files[0].size < 50000000) {
                          onSelectFile(e, "cropAdditionalImg", "");
                        } else if (e.target.files[0].size > 50000000) {
                          setShowImgErrorModal(true);
                          let activeCount = 0;
                          Object.values(additionalImgs).forEach((el) => {
                            if (el) ++activeCount;
                          });
                          if (activeCount === 2) {
                            setAdditionalImgs({
                              addImg: {
                                localImgUrl: "",
                                fileName: "",
                                fileStatus: "",
                                statusText: "",
                                responseFileName: ""
                              }
                            });
                          }
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <p className="fz-12 grey fw300 mb-95">{t("p7")}</p>
              </Col>
              <Col span={24}>
                <h2 className="mt-50 mb-24">{t("h2")}</h2>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <div className={`${`label`} `}>{t("fields.availableCertificates.label")}</div>
                  }
                  name="availableCertificates"
                  rules={[
                    {
                      max: 150,
                      message: t("fields.availableCertificates.errors.max")
                    }
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    autoSize={true}
                    maxLength={150}
                    showCount
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "availableCertificates",
                        form,
                        true,
                        null,
                        null,
                        true,
                        () => autoSave(form.getFieldsValue())
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={<div className={`${`label`} ${`req`}`}>{t("fields.rangeId.label")}</div>}
                  name="rangeId"
                  rules={[
                    {
                      required: true,
                      message: t("fields.rangeId.errors.required")
                    }
                  ]}
                  validateTrigger={"onChange"}
                >
                  <Select
                    onDropdownVisibleChange={(state) => {
                      setOpenState({ ...initialState, rangeId: state });
                    }}
                    open={openState.rangeId}
                    placeholder={t("fields.select.placeholder")}
                    size="large"
                    suffixIcon={
                      <DropdownIcon
                        onClick={() => {
                          setOpenState((prevState) => ({
                            ...initialState,
                            rangeId: !prevState.rangeId
                          }));
                        }}
                      />
                    }
                  >
                    {serviceRegions.length &&
                      serviceRegions.map((cat) => (
                        <Select.Option
                          key={cat.id}
                          label={t(`serviceRegions.${cat.range}`, {
                            ns: "ServiceRegions"
                          })}
                          value={cat.id}
                        >
                          {t(`${cat.range}`, { ns: "ServiceRegions" })}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {showButtonsAndStatus && (
              <Row>
                <Col span={24}>
                  <div
                    style={
                      !isAdmin
                        ? {
                          marginLeft: "auto",
                          marginBottom: "15px",
                          display: "flex",
                          justifyContent: "flex-end"
                        }
                        : {
                          display: "flex",
                          justifyContent: "center"
                        }
                    }
                  >
                    {editMode && (
                      <Button
                        className={`submit ml-auto`}
                        type="default"
                        size="large"
                        style={{ flex: "1 1 0", width: 0, position: "relative", zIndex: 3 }}
                        onClick={() => {
                          // setEditMode(null);
                          // console.log(currentService)
                          // console.log("");
                          // console.log(form.getFieldsValue())
                          // // console.log(JSON.stringify(currentService) === JSON.stringify(form.getFieldsValue()))
                          checkIfFormWasChanged();
                        }}
                      >
                        {t("buttons.leaveWithoutSaving", { ns: "FS10-AddNewProduct" })}
                      </Button>
                    )}
                    {!admin && (
                      <Button
                        style={{
                          flex: "1 1 0",
                          width: 0,
                          marginLeft: 15,
                          position: "relative",
                          zIndex: 3
                        }}
                        className={`submit ml-auto`}
                        type="default"
                        size="large"
                        onClick={(e) => {
                          checkImgError();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              setShowSendForModerationInPreview(false);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addService_${e.errorFields[0].name[0]}_0`
                                    : `#addService_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        }}
                      >
                        {t("buttons.preview", { ns: "FS10-AddNewProduct" })}
                      </Button>
                    )}
                    <Button
                      className={`submit ml-auto`}
                      type="default"
                      size="large"
                      style={
                        !isAdmin
                          ? { flex: "1 1 0", width: 0, marginLeft: 15, flexWrap: "wrap" }
                          : { width: "maxContent", marginLeft: 15, flexWrap: "wrap" }
                      }
                      onClick={() => {
                        if (isAdmin) {
                          checkImgError();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              setShowSendForModerationInPreview(true);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addService_${e.errorFields[0].name[0]}_0`
                                    : `#addService_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        } else {
                          onFinish(form.getFieldsValue());
                        }
                      }}
                    >
                      {t("buttons.save")}
                    </Button>
                    {!isAdmin && (
                      <Button
                        style={{ flex: "1 1 0", width: 0, marginLeft: 15, flexWrap: "wrap" }}
                        className={`submit ml-auto`}
                        type="default"
                        size="large"
                        // onClick={(e) => {
                        //   checkImgError();
                        //   form
                        //     .validateFields()
                        //     .then((res) => {
                        //       if (videoIsUploading) {
                        //         setModalMessage(t("responses.waitForVideoToUpload"));
                        //         setModalIsVisible(true);
                        //         return;
                        //       }
                        //       if (!responseImgFileName) {
                        //         document.getElementById("mainServiceImg").scrollIntoView({
                        //           behavior: "smooth",
                        //           block: "center",
                        //           inline: "start"
                        //         });
                        //         return;
                        //       }
                        //       onFinish(form.getFieldsValue(), true);
                        //     })
                        //     .catch((e) => {
                        //       if (!responseImgFileName) {
                        //         document.getElementById("mainServiceImg").scrollIntoView({
                        //           behavior: "smooth",
                        //           block: "center",
                        //           inline: "start"
                        //         });
                        //       } else {
                        //         const errorFieldId =
                        //           e.errorFields[0].name[0] === "subcategories"
                        //             ? `#addService_${e.errorFields[0].name[0]}_0`
                        //             : `#addService_${e.errorFields[0].name[0]}`;
                        //         document.querySelector(`${errorFieldId}`).scrollIntoView({
                        //           behavior: "smooth",
                        //           block: "center",
                        //           inline: "start"
                        //         });
                        //       }
                        //     });
                        // }}
                        onClick={(e) => {
                          checkImgError();
                          checkSubcategoriesError();
                          form
                            .validateFields()
                            .then((res) => {
                              if (videoIsUploading) {
                                setModalMessage(t("responses.waitForVideoToUpload"));
                                setModalIsVisible(true);
                                return;
                              }
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              if (!subcategories.length) {
                                document.getElementById("test").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                                return;
                              }
                              setShowSendForModerationInPreview(true);
                              setShowPreview(true);
                            })
                            .catch((e) => {
                              if (!responseImgFileName) {
                                document.getElementById("mainServiceImg").scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              } else {
                                const errorFieldId =
                                  e.errorFields[0].name[0] === "subcategories"
                                    ? `#addService_${e.errorFields[0].name[0]}_0`
                                    : `#addService_${e.errorFields[0].name[0]}`;
                                document.querySelector(`${errorFieldId}`).scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start"
                                });
                              }
                            });
                        }}
                      >
                        {t("buttons.sendForModeration", { ns: "FS10-AddNewProduct" })}
                      </Button>
                    )}
                  </div>
                </Col>
                {serviceStatus.text && (
                  <>
                    <Col span={10}></Col>
                    <Col span={14}>
                      <p className={serviceStatus.className} style={{ textAlign: "right" }}>
                        {t(serviceStatus.text, {
                          ns: "FS11-AddNewService"
                        })}
                      </p>
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );

  function checkIfFormWasChanged() {
    const currentFormState = form.getFieldsValue();
    const lastStateOfItem = currentService.moderations || currentService;
    // console.log(lastStateOfItem);
    // console.log(currentFormState);
    // console.log(additionalImgs);
    // console.log(lastStateOfItem.serviceKeywords.length)
    // console.log(currentFormState.keywords.split(",").map((el) => el.trim()).filter(el => el.length > 0).length)
    if (
      lastStateOfItem.serviceName === currentFormState.serviceName &&
      lastStateOfItem.serviceKeywords.length ===
      currentFormState.keywords
        .split(",")
        .map((el) => el.trim())
        .filter((el) => el.length > 0).length &&
      lastStateOfItem.serviceKeywords.map((k) => k.keyword).join("") ===
      currentFormState.keywords
        .split(",")
        .map((el) => el.trim())
        .join("") &&
      lastStateOfItem.description === currentFormState.description &&
      lastStateOfItem.subcategories.length === currentFormState.subcategories.length &&
      lastStateOfItem.subcategories
        .map((el) => el.id)
        .sort()
        .join("") === currentFormState.subcategories.sort().join("") &&
      (lastStateOfItem.video === currentFormState.video ||
        (!video && lastStateOfItem.video === videoFileName)) &&
      lastStateOfItem.advantage1 === currentFormState.advantage1 &&
      lastStateOfItem.advantage2 === currentFormState.advantage2 &&
      lastStateOfItem.advantage3 === currentFormState.advantage3 &&
      lastStateOfItem.image === imgFileName &&
      Object.values(lastStateOfItem.servicePhotos)
        .map((i) => i.photo)
        .join("") ===
      Object.values(additionalImgs)
        .map((i) => i.fileName)
        .join("") &&
      lastStateOfItem.availableCertificates === currentFormState.availableCertificates &&
      lastStateOfItem.rangeId === currentFormState.rangeId
    ) {
      setEditMode(null);
    } else {
      setShowExitWithoutSavingModal(true);
    }
  }
  function validateAndSave() {
    checkImgError();
    checkSubcategoriesError();
    return form
      .validateFields()
      .then((res) => {
        if (videoIsUploading) {
          setModalMessage(t("responses.waitForVideoToUpload"));
          setModalIsVisible(true);
          return Promise.reject();
        }
        if (!responseImgFileName) {
          document.getElementById("mainServiceImg").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
          return Promise.reject();
        }
        if (!subcategories.length) {
          document.getElementById("test").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
          return Promise.reject();
        }
        onFinish(form.getFieldsValue(), true, false, false);
      })
      .catch((e) => {
        if (!responseImgFileName) {
          document.getElementById("mainServiceImg").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        } else {
          const errorFieldId =
            e.errorFields[0].name[0] === "subcategories"
              ? `#addService_${e.errorFields[0].name[0]}_0`
              : `#addService_${e.errorFields[0].name[0]}`;
          document.querySelector(`${errorFieldId}`).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }
        return Promise.reject();
      });
  }
});

export default AddNewService;
