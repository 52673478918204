import { createSlice } from "@reduxjs/toolkit";
import { notificationsFeedAPI } from "../api/api";

const initialState = {
  count: {
    unreadAll: 0,
    unreadBuyer: 0,
    unreadSeller: 0
  },
  buyerNotifications: { items: [], total: 0, loading: false },
  sellerNotifications: { items: [], total: 0, loading: false }
};
const notificationsFeedSlice = createSlice({
  name: "notificationsFeed",
  initialState,
  reducers: {
    setUnreadCount(state, action) {
      state.count = action.payload;
    },
    setBuyerNotifications(state, action) {
      state.buyerNotifications = {
        total: action.payload.total,
        items: [...state.buyerNotifications.items, ...action.payload.items]
      };
    },
    setSellerNotifications(state, action) {
      state.sellerNotifications = {
        total: action.payload.total,
        items: [...state.sellerNotifications.items, ...action.payload.items]
      };
    },
    addNewNotification(state, action) {
      if (action.payload.side === "BUYER") {
        state.buyerNotifications.items = [action.payload.item, ...state.buyerNotifications.items];
        state.buyerNotifications.total += 1;
      } else if (action.payload.side === "SELLER") {
        state.sellerNotifications.items = [action.payload.item, ...state.sellerNotifications.items];
        state.sellerNotifications.total += 1;
      }
    },
    setLoading(state, action) {
      if (action.payload.side === "BUYER") {
        state.buyerNotifications.loading = action.payload.loading;
      } else if (action.payload.side === "SELLER") {
        state.sellerNotifications.loading = action.payload.loading;
      }
    },
    markNotificationsAsRead(state, action) {
      state.buyerNotifications.items = state.buyerNotifications.items.map((el) => {
        if (action.payload.includes(el.id)) {
          return { ...el, isRead: 1 };
        } else {
          return el;
        }
      });
      state.sellerNotifications.items = state.sellerNotifications.items.map((el) => {
        if (action.payload.includes(el.id)) {
          return { ...el, isRead: 1 };
        } else {
          return el;
        }
      });
    },
    dropNotifications() {
      return initialState;
    }
  }
});
export const {
  setUnreadCount,
  setBuyerNotifications,
  setSellerNotifications,
  addNewNotification,
  setLoading,
  markNotificationsAsRead,
  dropNotifications
} = notificationsFeedSlice.actions;

export const getUnreadNotificationsCount = () => (dispatch) => {
  notificationsFeedAPI.getUnreadNotificationsCount().then((res) => {
    dispatch(setUnreadCount(res.data.count));
  });
};
export const getNotificationsFeed = (side, offset, limit) => (dispatch) => {
  if (side === "BUYER") {
    dispatch(setLoading({ side: "BUYER", loading: true }));
  } else if (side === "SELLER") {
    dispatch(setLoading({ side: "SELLER", loading: true }));
  }
  notificationsFeedAPI
    .getNotifications(side, offset, limit)
    .then((res) => {
      if (side === "BUYER") {
        dispatch(setBuyerNotifications(res.data));
      } else if (side === "SELLER") {
        dispatch(setSellerNotifications(res.data));
      }
    })
    .finally(() => {
      if (side === "BUYER") {
        dispatch(setLoading({ side: "BUYER", loading: false }));
      } else if (side === "SELLER") {
        dispatch(setLoading({ side: "SELLER", loading: false }));
      }
    });
};
export const newNotificationHandler = (n) => (dispatch) => {
  dispatch(addNewNotification({ side: n?.notification?.side, item: n }));
};
export const sendMarkNotificationsAsRead = (idsArr) => (dispatch) => {
  notificationsFeedAPI
    .markNotificationsAsRead(idsArr)
    .then(() => {
      dispatch(markNotificationsAsRead(idsArr));
    })
    .then(() => {
      dispatch(getUnreadNotificationsCount());
    });
};
export const clearNotifications = () => (dispatch) => {
  dispatch(dropNotifications());
};

export default notificationsFeedSlice.reducer;
