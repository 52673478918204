import { Col, Row, Table } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import styles from "../Policy/policy.module.less";

const CookiesPolicy = () => {
  const { t } = useTranslation("Footer");
  const { text_block, title } = styles;
  useEffect(() => {
    setDocumentTitle(t("cookies_policy"));
  }, [t]);

  const columns = [
    {
      title: "Cookie Source",
      dataIndex: "Cookie Source",
      key: "Cookie Source"
    },
    {
      title: "Cookies",
      dataIndex: "Cookies",
      key: "Cookies"
    },
    {
      title: "Placement",
      dataIndex: "Placement",
      key: "Placement"
    },
    {
      title: "Lifespan",
      dataIndex: "Lifespan",
      key: "Lifespan"
    }
  ];

  const analitycalDataSource = [
    {
      key: "1",
      "Cookie Source": "Source and Link	",
      Cookies: "Visits count",
      Placement: "Google",
      Lifespan: "Persistent"
    }
  ];

  const functionalDataSource = [
    {
      key: "1",
      "Cookie Source": "Source and Link	",
      Cookies: "User Preferencest",
      Placement: "First Party",
      Lifespan: "Persistent"
    },
    {
      key: "2",
      "Cookie Source": "",
      Cookies: "Cookie consent",
      Placement: "Osano",
      Lifespan: "Session"
    }
  ];

  const essentialDataSource = [
    {
      key: "1",
      "Cookie Source": "Source and Link",
      Cookies: "Payment cookies",
      Placement: "Stripe",
      Lifespan: "Session"
    },
    {
      key: "2",
      "Cookie Source": "",
      Cookies: "Login services",
      Placement: "First party",
      Lifespan: "Persistent"
    },
    {
      key: "3",
      "Cookie Source": "",
      Cookies: "Language preferences",
      Placement: "First party",
      Lifespan: "Persistent"
    }
  ];

  return (
    <>
      <Header h1={t("cookies_policy")} />
      <Row justify="center">
        <Col xl={{ span: 16 }}>
          <div className="regular_block">
            <h3 className={title}>Introduction</h3>
            <p className={text_block}>
              B2B One Event Management LLC (<strong>“B2B”</strong> or <strong>“B2B”</strong> /{" "}
              <strong>“B2B”</strong>) is a limited liability company registered in Dubai, United
              Arab Emirates. Its services are provided within the United Arab Emirates and / or
              abroad. Its activity, policies and regulatory compliance requirements are subject to
              the Laws of the Emirate of Dubai and the Federal Laws of the United Arab Emirates
              (herein referred to as the <strong>“Applicable Law”</strong>).
            </p>
            <p className={text_block}>
              B2B is the owner of{" "}
              <Link to={"/"} target={"_blank"}>
                www.b2b-one.com
              </Link>{" "}
              (“Website”) and is the user of certain branded pages on various online platforms (such
              as Facebook, Instagram, LinkedIn etc.). B2B may develop other websites and online
              presence in the future. In so far as B2B decided to use a web app, then this app shall
              also be referred to as part of the Website and shall be subject to this Policy.{" "}
            </p>
            <p className={text_block}>
              B2B Cookies Policy applies to the Website(s) and branded pages operated by or on
              behalf of B2B. For the provision of its services, B2B uses cookies or similar
              technologies (such as web beacons, pixel tabs, etc).
            </p>
            <p className={text_block}>
              This Policy applies to all Users and Members on the Website, as defined below:{" "}
              <li>
                A. <strong>User</strong> means any person (natural or juridical) that accesses and /
                or browses our Website, with our without creating an account on the Website,
                accessible with username and password;
              </li>
              <li>
                B. <strong>Member</strong> means any person (natural or juridical) that creates an
                account on our Website, accessible with username and password.
              </li>
            </p>
            <strong style={{ fontSize: 15 }}>
              What is a Cookie (and/ or a similar technology)?
            </strong>
            <p className={text_block}>
              Cookies are small text files that are stored on your device (computer, tablet, mobile
              phone) and which let us make your experience better and more efficient. They allow us
              to recognize your device and browser, and store information which we deem of
              importance for the improvement of our services.
            </p>
            <p className={text_block}>
              The usage of cookies is common in the online world, to enhance the User’s browser &
              website experience, track website activities, store and exchange information, for
              marketing purposes and, finally, to boost the functionality and responsiveness of a
              website.
            </p>
            <p className={text_block}>
              Whereas cookies are being sent usually to the server that set them or to a server in
              the same internet domain with the one that created them, the newer internet protocols
              allow cookies to be sent to third party websites (“Third Party Cookies”). For
              instance, an advertisement photo on a social media platform page may transmit cookies
              information to the browser from where the media platform is accessed (Google Chrome,
              Mozilla, Safari etc.)
            </p>
            <p className={text_block}>
              You may disable usage of cookies from your browser; however, this would impact your
              browsing experience or even prevent your access to various areas of websites or online
              platforms. For example, a User would not be able to enter all transactions on the
              Website if cookies are disabled.
            </p>
            <strong className={text_block} style={{ textDecoration: "underline" }}>
              Why B2B uses cookies?
            </strong>
            <p className={text_block}>
              B2B uses cookies in a fully transparent and compliant manner, for:
              <div style={{ marginLeft: 20 }}>
                <p>- Enhancement of B2B Website(s) and branded pages activity </p>
                <p>
                  - Remembering a User as visitor of the Website and determining the User’s location
                  and/ or preferences, for future marketing and advertisement purposes{" "}
                </p>
                <p>
                  - Measuring and analyzing the User’s activity on the Website and allowing users to
                  access various areas of B2B Website{" "}
                </p>
                <p>
                  - Allowing the User to log in and remembering the User’s ID and log in credentials
                  for future visits.{" "}
                </p>
              </div>
              Although some cookies store certain personal information (as described below), such
              storage is intended for advertising and marketing purposes, in agreement with B2B{" "}
              <Link to={"/privacy_policy"} target="_blank">
                <strong>Privacy Policy</strong>
              </Link>
              .
            </p>
            <strong className={text_block}>Types of cookies we use:</strong>
            <ul>
              <li>
                Session Cookies and Persistent Cookies
                <ul>
                  <li>
                    Session Cookies are stored temporarily on the memory of your devices while you
                    are visiting the website and are erased once you close your browser.
                  </li>
                  <li>
                    <li>
                      Persistent Cookies are stored for a predetermined amount of time, which varies
                      from cookie to cookie. It is longer than the Session Cookies; however, they
                      also expire after some months. They aid us to understand whether you have
                      previously visited our website or similar sites in the past.
                    </li>
                  </li>
                </ul>
              </li>
            </ul>
            <p className={text_block} style={{ marginTop: 18 }}>
              Both types of cookies can be used by B2B or by Third Party Websites and may store
              personal information in the following ways:
            </p>
            <ol>
              <li>
                Cookies that allow Users to explore various areas of B2B Website and make the B2B
                Website or branded pages work correctly – these cookies provide anonymous
                information about the User, such as location and areas of the website the User has
                explored, but not the identity of the User;{" "}
              </li>
              <li>
                Cookies that allow Users to interact with the B2B Website and transmit information
                on how much time Users spend and what they look for in various sections of the B2B
                website or branded pages- as well, these cookies provide anonymous data about the
                User;{" "}
              </li>
              <li>
                Cookies that allow the B2B Website to remember usernames, preferences, profile
                pictures- such cookies contain personally identifiable information, such as username
                or profile with description and attached documents, if any (note that if you are
                only a User of the Website, no pictures or other personal information will be
                needed);
              </li>
              <li>
                Cookies that allow B2B to personalize the User’s experience and provide website
                responses according to the User’s preferences and previous searches- they usually
                transmit personally identifiable information, such as IP.{" "}
              </li>
            </ol>
            <strong className={text_block}>What other types of cookies do we use?</strong>
            <strong style={{ fontSize: 15 }}>Essential Cookies</strong>
            <p>
              We may store cookies on your device if they are necessary to enable us to offer and
              provide our services. They are also required for you to navigate through the Website
              and benefit from all the available functions. These cookies cannot be switched off and
              are set for very basic options on the Website, such as logging in, forms and set
              preferences such as time zone or languages. Note that Essential Cookies are generally
              used and required for Members of the Website.{" "}
            </p>
            <strong style={{ fontSize: 15, marginTop: 25 }}>Analytical Cookies</strong>
            <p>
              These cookies are placed to aid us in understanding how you interact with the
              available options on the website, which parts you use the most, and how you engage
              with our services. This helps us create statistics of usage and the amount of time you
              spend on the Website. These statistics will ultimately help us to improve our website
              and any services we offer on it.
            </p>
            <strong style={{ fontSize: 15, marginTop: 25 }}>Functional Cookies</strong>
            <p>
              These cookies are placed by us to help us to provide you with better functionality of
              the Website and our services and to make them more personalized to you. They can also
              be placed by third-party providers you specifically consent to when you accept this
              Cookie Policy. You may block these cookies, but if you do, it may be that parts of the
              Website do not function the way we intended.
            </p>
            <strong style={{ fontSize: 15, marginTop: 25 }}>
              Can cookies be disabled or managed?
            </strong>
            <p className={text_block}>
              Whereas most internet browsers are initially configured to accept cookies, their
              settings can be changed in the sense that cookies may be completely disabled or, the
              User may allow only specific types of cookies; else, the User may opt to get notified
              when a new cookie is to be stored on his device. Each internet browser has its
              specific settings and configurations and those fall outside the responsibility of B2B.
            </p>
            <p className={text_block}>
              Please see the links below for guidance on how to modify your web browser’s settings
              on the most popular browsers:
            </p>
            <ul style={{ marginBottom: 20, textDecoration: "underline", paddingLeft: 35 }}>
              <li>Microsoft Edge</li> <li>Internet Explorer</li> <li>Mozilla Firefox</li>
              <li> Google Chrome</li> <li>Apple Safari</li> <li>Opera</li> <li>Netscape</li>
            </ul>
            <p className={text_block}>
              Once you correctly block cookies on your browser, parts of the website may become
              unavailable to you or may otherwise not function properly.{" "}
            </p>
            <strong className={text_block}>How to opt-out of Third-Party Cookies</strong>
            <p>
              To opt out of tracking analytics placed by Google, you can opt out by using{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                this link
              </a>
              . This works for all websites that use Google’s services to track and gather analytics
              data. To learn more, please visit{" "}
              <a
                href="https://youradchoices.com/"
                style={{ textDecoration: "underline" }}
                target="_blank"
              >
                https://youradchoices.com/
              </a>
              .
            </p>
            <strong className={text_block}>Cookies List</strong>
            <ul style={{ paddingLeft: 40 }}>
              <li style={{ marginBottom: 30 }}>ESSENTIAL COOKIES</li>
            </ul>
            <p className={text_block}>
              Essential Cookies and scripts are essential for our website to function. They allow
              visitors to move around our website and use its basic features, such as accessing
              secure areas of the website, opening navigation, and displaying content.
            </p>
            <Table dataSource={essentialDataSource} columns={columns} pagination={false} />
            <ul style={{ paddingLeft: 40, marginTop: 40 }}>
              <li style={{ marginBottom: 30 }}>ANALYTICAL COOKIES</li>
            </ul>
            <p className={text_block}>
              Analytics allow us to count visits and traffic sources to the website, so that we can
              measure and improve the performance of our site. Analytics let us know which pages are
              the most and least popular and see how visitors move around the site. All of the
              information collected from Analytical Cookies is aggregated so it is anonymous.
            </p>
            <Table dataSource={analitycalDataSource} columns={columns} pagination={false} />
            <ul style={{ paddingLeft: 40, marginTop: 40 }}>
              <li style={{ marginBottom: 30 }}>FUNCTIONAL COOKIES</li>
            </ul>
            <p className={text_block}>
              Functional Cookies help us to personalize your browsing experience. Unlike necessary
              cookies, these cookies support additional functionality that enhances our site. These
              cookies are anonymous and don’t track browsing activity across other websites.
            </p>
            <Table dataSource={functionalDataSource} columns={columns} pagination={false} />
            <p className={text_block} style={{ marginTop: 18 }}>
              For all the cookies presented here, please note that we have compiled this data based
              on our best ability, research and knowledge. Cookies, especially coming from Third
              Parties, may change in functionality and in the form they are provided, since they are
              not created or controlled by us in any way. For this reason, we assume no liability
              and cannot be held liable for any Third-Party Cookies or on the information provided
              in this Policy on any Third-Party Cookies.{" "}
            </p>
            <strong className={text_block}>
              Third Party Cookies from other Sites & Advertisements{" "}
            </strong>
            <p className={text_block}>
              B2B Website may contain links to other websites, as well as different advertisements
              from third parties or links to social media platforms such as Facebook, LinkedIn etc.
              Please note that these websites are external to B2B, not in the control of B2B and may
              use cookies and / or User’s personal information. Details on usage of cookies by third
              parties websites may be found on the respective websites’ terms and conditions. In all
              cases, usage of Third Party Cookies fall outside the responsibility of B2B.
            </p>
            <strong className={text_block}>
              Usage of Google AdSense, Google Analytics and similar technologies{" "}
            </strong>
            <p className={text_block}>
              Google Analytics and similar technologies are services offered by Google Inc. and
              produce statistics about websites traffic, sources of traffic, approximate geolocation
              and determine the conversion of such traffic into sales and turnover. All such
              technologies use cookies and their regime is determined and controlled solely and
              exclusively by Google Inc. and the processing of personal data obtained as such is
              subject to the regulatory requirements of Google Inc.
            </p>
            <p className={text_block}>
              In case B2B understands to use any of the technologies described in the present
              section, then the purpose of such usage will be to integrate and optimize web pages
              and to measure traffic into B2B Website in order to improve the B2B product and boost
              sales.{" "}
            </p>
            <p className={text_block}>
              By using the B2B Website and / or branded pages, as appropriate, you consent to the
              processing data requirements of B2B and of the Third-Party providers, such as Google
              Inc. In all cases, B2B is unable to control and is at no liability for the activity of
              such technologies employed by Third Parties.
            </p>
            <strong className={text_block}>Regulatory and Compliance </strong>
            <p className={text_block}>
              The present Cookies Policy is governed by the applicable laws of the Emirate of Dubai
              & the Federal Laws of the United Arab Emirates.
            </p>
            <p className={text_block}>
              B2B may update their Cookies Policy from time to time. Any such amendment or update
              becomes immediately applicable.
            </p>
            <p className={text_block}>
              Users are advised to check regularly the B2B Cookies Policy at{" "}
              <a href="https://b2b-one.com/cookies_policy" target="_blank">
                www.b2b-one.com/cookies_policy
              </a>
            </p>
            <p className={text_block}>
              For any questions related to this Cookies Policy please feel free to contact us.
            </p>
            <p className={text_block}>B2B ONE EVENT MANAGEMENT L.L.C.</p>
            <a className={text_block} href="mailto:support@b2b-one.com">
              support@b2b-one.com
            </a>
            <p className={text_block}>B2B ONE EVENT MANAGEMENT L.L.C. UAE, Dubai, PO Box 452785</p>
            <p className={text_block}>
              EU Representative: Osano International Compliance Services Limited, ATTN: KJMU, 3
              Dublin Landings, North Wall Quay, Dublin, 1 D01C4E0 <br /> UK Representative: Osano UK
              Compliance LTD, ATTN: KJMU, 42-46 Fountain Street, Belfast, Antrim.
            </p>
          </div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default CookiesPolicy;
