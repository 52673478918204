import React, {useState, useRef, useEffect} from 'react';
import ReactCrop from 'react-image-crop';
import {useDebounceEffect} from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal} from 'antd';
import {useTranslation} from 'react-i18next';

const AddAndCropLogo = ({
                          visible,
                          setVisible,
                          img,
                          setImg, saveCropLogo,
                          inputId = 'fileInput'
                        }) => {
  const {t} = useTranslation('FK3-сompanyProfile');
  const previewCanvasRef = useRef(null);
  const ref = useRef(null);

  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [loader, setLoader] = useState(true)

  const convertBase64ToFile = () => {
    setLoader(true)
    const canvas = previewCanvasRef.current;
    canvas.toBlob((blob) => {
      saveCropLogo(new File([blob], img.name, {type: blob.type}));
      setVisible(false);
      setImg({});
      setCrop({});
      setCompletedCrop(null);
      setLoader(false)
    }, 'image/webp', 0.5);
  };

  const fullImage = () => {
    const {clientWidth, clientHeight} = ref.current.mediaRef.current;
    const t = setTimeout(() => {
      setCompletedCrop({unit: 'px', x: 0, y: 0, width: clientWidth, height: clientHeight});
      setCrop({unit: '%', x: 0, y: 0, width: 100, height: 100});
      setLoader(false)
    }, 700);
    return () => {
      clearTimeout(t);
    };
  };
  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current
      ) {
        await setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );


  function setCanvasImage(image, canvas, crop) {
    if (!crop || !canvas || !image) {
      return;
    }
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }

  const cancel = () => {
    if (!loader) {
      setVisible(false);
      setImg({});
      setCrop({});
      setCompletedCrop(null);
      document.getElementById(inputId).value = '';
    }
  };

  return (
    <div>
      <Modal
        title={t('title')}
        width={'max-content'}
        style={{maxWidth: '80vw', top: '30px', cursor: 'default'}}
        bodyStyle={{display: 'flex', maxHeight: '85vh', gap: '10px'}}
        visible={visible}
        onCancel={cancel}
        maskClosable={false}
        footer={[
          <div style={{display: 'flex', marginBottom: '10px', marginTop: '-10px'}}>
            <Button loading={loader} key="ok" className="btn btn_v3" onClick={cancel}>
              {t('btn2')}
            </Button>
            <Button loading={loader}  key="ok2" className="btn btn_v2" onClick={() => {
              convertBase64ToFile();
            }}>
              OK
            </Button>
          </div>,
        ]}
      >
        {!!img && (
          <ReactCrop
            keepSelection={crop?.width > 3 && crop?.height > 3}
            ref={ref}
            style={{
              cursor: completedCrop ? 'default' : 'pointer',
              height: 'min-content',
            }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            minHeight={100}
            minWidth={100}
          >
            <img
              style={{
                maxHeight: 'calc(100vh - 305px)',
                maxWidth: 'calc(100vw - 305px)',
                width: '100%',
                height: '100%',
              }}
              ref={imgRef}
              alt="Loading..."
              src={img.base64}
              onLoad={fullImage}
            />
          </ReactCrop>
        )}
        <div style={{width: '295px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <div style={{minHeight: '280px'}}>
            {!!completedCrop && crop?.width > 3 && crop?.height > 3 && (
              <>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    marginRight: '15px',
                    width: '280px',
                    height: '280px',
                  }}
                />
              </>
            )}
          </div>
          <div style={{fontWeight: 500, fontSize: '16px'}}>{t('desc')}</div>
        </div>


      </Modal>
    </div>
  );
};
export default AddAndCropLogo;
