import PropTypes from "prop-types";

const Edit = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ cursor: "pointer" }}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16536 3.16762H2.9987C2.55667 3.16762 2.13275 3.34321 1.82019 3.65577C1.50763 3.96833 1.33203 4.39225 1.33203 4.83428V14.0009C1.33203 14.443 1.50763 14.8669 1.82019 15.1795C2.13275 15.492 2.55667 15.6676 2.9987 15.6676H12.1654C12.6074 15.6676 13.0313 15.492 13.3439 15.1795C13.6564 14.8669 13.832 14.443 13.832 14.0009V9.83428M12.6537 1.98928C12.8074 1.8301 12.9914 1.70313 13.1947 1.61578C13.398 1.52843 13.6167 1.48245 13.838 1.48053C14.0593 1.47861 14.2788 1.52078 14.4836 1.60458C14.6884 1.68838 14.8745 1.81214 15.031 1.96862C15.1875 2.12511 15.3113 2.3112 15.3951 2.51603C15.4789 2.72085 15.521 2.94032 15.5191 3.16162C15.5172 3.38292 15.4712 3.60162 15.3839 3.80496C15.2965 4.0083 15.1695 4.1922 15.0104 4.34595L7.85536 11.5009H5.4987V9.14428L12.6537 1.98928Z"
        stroke="#343543"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Edit.propTypes = {
  onClick: PropTypes.func
};
export default Edit;
