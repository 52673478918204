import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authAPI, companyAPI, profileAPI } from "../../api/api";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";

export const CompleteCompanyNameModal = () => {
  const { user } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation([
    "ConfirmPageChangeModal",
    "CompleteCompanyNameModal",
    "FS10-AddNewProduct",
    "FS8-changeCompanyProfile",
    "FA40-SendEmailToUser",
    "AdminLogin"
  ]);
  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  const [form] = Form.useForm();

  const handleModalSubmit = async () => {
    const data = form.getFieldsValue();
    await companyAPI
      .updateCompanyNameOauth(data.companyName)
      .then(async () => {
        setModalOpen(false);
        await authAPI.whoAmI();
      })
      .catch(() =>
        form.setFields([
          {
            name: "companyName",
            errors: [t("modalMessages.error", { ns: "AdminLogin" })]
          }
        ])
      );
  };

  useEffect(() => {
    if (user?.company?.companyName === "n/a") {
      setModalOpen(true);
    }
  }, [user, user?.company?.companyName]);

  return (
    <>
      {OnlyLatinLettersModal}
      <Modal
        width={520}
        visible={modalOpen}
        onOk={handleModalSubmit}
        footer={[
          <Button
            key={0}
            className={`btn btn_v2`}
            size="large"
            onClick={() => {
              console.log(form.getFieldsError());
              form.getFieldsError();
              form.validateFields().then(() => {
                handleModalSubmit();
              });
            }}
          >
            {t("Buttons.Submit", { ns: "FA40-SendEmailToUser" })}
          </Button>
        ]}
        closable={false}
      >
        <Form
          style={{ width: "100%" }}
          colon={false}
          name="changeProfile"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          labelAlign="left"
          labelWrap
          requiredMark={false}
          validateTrigger="onSubmit"
          form={form}
        >
          <p>{t("title", { ns: "CompleteCompanyNameModal" })}</p>
          <Form.Item
            className="label-auto-height"
            name="companyName"
            rules={[
              {
                required: true,
                message: t("companyNameError", { ns: "FS8-changeCompanyProfile" })
              }
            ]}
          >
            <Input
              onChange={(e) => {
                OnlyLatinLettersTextInput(
                  e.target.value,
                  "companyName",
                  form,
                  true,
                  null,
                  null,
                  true
                );
              }}
              size="large"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
