import * as React from "react";

const PremiumMember = (props) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.5 0a.5.5 0 0 0-.429.243l-3 5a.5.5 0 0 0 .045.577l7.494 8.993a.5.5 0 0 0 .856-.132L11.842 6h3.892l.15-.18a.5.5 0 0 0 .045-.577l-3-5A.5.5 0 0 0 12.5 0h-9Zm7.27 6L8 13.12 5.231 6H10.77ZM1.382 5l2.4-4h1.979l-1.6 4H1.382Zm2.775 1 2.267 5.829L1.568 6h2.59-.001Zm1.08-1 1.6-4H9.16l1.6 4H5.237Zm6.6 0-1.6-4h1.979l2.4 4h-2.779Zm1.662 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5c2.5 0 3.5-1.255 3.5-2.5a1.5 1.5 0 0 0-1.5-1.5h-4a1.5 1.5 0 0 0-1.5 1.5c0 1.25 1 2.5 3.5 2.5Z"
      fill="#EA5B0C"
    />
  </svg>
);

export default PremiumMember;
