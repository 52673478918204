const Edit2 = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6009_15387)">
        <path d="M8.70703 1.58594H7.1237C3.16536 1.58594 1.58203 3.16927 1.58203 7.1276V11.8776C1.58203 15.8359 3.16536 17.4193 7.1237 17.4193H11.8737C15.832 17.4193 17.4154 15.8359 17.4154 11.8776V10.2943" stroke="#96A6B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.6999 2.3892L6.46161 8.62754C6.22411 8.86504 5.98661 9.33212 5.93911 9.67254L5.5987 12.0555C5.47203 12.9184 6.08161 13.52 6.94453 13.4013L9.32745 13.0609C9.65995 13.0134 10.127 12.7759 10.3724 12.5384L16.6108 6.30004C17.6874 5.22337 18.1941 3.97254 16.6108 2.3892C15.0274 0.805869 13.7766 1.31254 12.6999 2.3892Z" stroke="#96A6B0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.8047 3.28906C12.0672 4.22122 12.5647 5.07036 13.2494 5.75514C13.9342 6.43992 14.7834 6.93739 15.7155 7.1999" stroke="#96A6B0" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6009_15387">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Edit2;
