/* eslint-disable react/prop-types */

import { Form, Input } from "antd";
import { domain, mediaUrl } from "../../api/api";
import { useEffect, useState, useRef } from "react";

import Img from "Assets/svg/Img";
import TextArea from "antd/lib/input/TextArea";
import Trash from "../../assets/svg/trash";
import axios from "axios";
import s from "./AddUser.module.less";
import { setLogo } from "Store/addUserSlice";
import style from "../../pages/FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AddAndCropLogo from "../../components/AddAndCropLogo/AddAndCropLogo";
import { customKeyWordsValidator } from "../../helpers/validation-rules";

const leftInputStyles = { width: 290, backgroundColor: "#F5F7F8" };
const rightInputStyles = { width: 360, backgroundColor: "#F5F7F8" };

const CompanyInfo = ({ hasError, logo }) => {
  const { t } = useTranslation(["FA2.1-AddUser", "FS8-changeCompanyProfile", "FS10-AddNewProduct"]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(null);
  const [fileName, setFileName] = useState("");
  const [form] = Form.useForm();
  const imageRef = useRef(null)

  console.log(imageRef)

  useEffect(() => {
    if (!logo) {
      setLoader(null);
      setFileName("");
    }
  }, [logo]);

  const [img, setImg] = useState({});
  const [visibleCropLogo, setVisibleCropLogo] = useState(false);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImg({ base64: reader.result?.toString() || "", name: e.target.files[0].name })
      );
      reader.readAsDataURL(e.target.files[0]);
      setVisibleCropLogo(true);
    }
  };

  const handleLogoSelect = async (file) => {
    form.setFields([{ name: "logo", errors: [] }]);
    setLoader(t("loading", { ns: "FS8-changeCompanyProfile" }));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("originalname", file?.name);
    try {
      const res = await axios({
        method: "post",
        url: `${domain}api/upload/image`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });
      if (res.status === 200) {
        dispatch(setLogo(res.data?.filename));
        setLoader(t("loaded", { ns: "FS8-changeCompanyProfile" }));
        setFileName(file?.name);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // console.log(error.response);
      }
    }
  };

  // console.log(imageRef.current.value)

  return (
    <section>
      <h2 className={s.title}>{t("companyInfo")}</h2>
      <div className={s.wrapper}>
        <div>
          <div
            className={`${s.imgWrapper} ${logo ? s.imgWrapperWithCompanyPhoto : ""}`}
            style={hasError ? { border: "1px solid #ff4d4f" } : {}}
          >
            {logo ? (
              <img
                src={`${mediaUrl}${logo}`}
                style={{ height: "80px", width: "80px", objectFit: "contain" }}
              />
            ) : (
              <Img />
            )}
            <div style={{ padding: "0 20px" }}>
              <label className={style.link}>
                <div className={style.logoTitle}>
                  {t("logoTitle", { ns: "FS8-changeCompanyProfile" })}*
                </div>
                {loader !== t("loading", { ns: "FS8-changeCompanyProfile" }) && (
                  <div
                    className={style.link}
                    style={{ textDecoration: "underline", fontWeight: 300 }}
                  >
                    {t("upload", { ns: "FS8-changeCompanyProfile" })}
                  </div>
                )}
                <div className={style.keywordsDescription} style={{ margin: "0 0 0 30px" }}>
                  {t("format", { ns: "FS8-changeCompanyProfile" })}
                </div>
                <Form.Item
                  name="logo"
                  rules={[
                    { required: true, message: "" },
                    { validator: hasError, message: "" }
                  ]}
                >
                  <input
                    className={style.file}
                    type="file"
                    ref={imageRef}
                    onChange={onSelectFile}
                    accept=".png, .jpg, .jpeg"
                  />
                </Form.Item>
              </label>

              {logo && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setLogo(""));
                    imageRef.current.form.reset();
                  }}
                >
                  <Trash style={{ position: "absolute", top: "10px", right: "20px" }} />
                </div>
              )}
              <div
                className={style.loader}
                style={
                  loader === t("loading", { ns: "FS8-changeCompanyProfile" })
                    ? { color: "red" }
                    : { color: "green" }
                }
              >
                {loader}
              </div>
            </div>
          </div>
          <div style={{ color: "#ff4d4f", marginBottom: "20px" }}>
            {hasError ? t("logoError", { ns: "FS8-changeCompanyProfile" }) : null}
          </div>
          <Form.Item
            name={["company", "companyName"]}
            rules={[{ required: true, message: t("errors.companyName") }]}
            label={<span className={s.label_3}>{t("companyName")}*</span>}
          >
            <Input size="large" style={leftInputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "businessCompanyName"]}
            rules={[{ required: true, message: t("errors.businessCompanyName") }]}
            label={<span className={s.label_3}>{t("businessCompanyName")}*</span>}
          >
            <Input size="large" style={leftInputStyles} />
          </Form.Item>

          {/* <Form.Item
            name={["company", "companyType"]}
            rules={[{ required: true, message: t("errors.companyType") }]}
            label={<span className={s.label_3}>{t("companyType")}*</span>}
          >
            <Input size="large" style={leftInputStyles} />
          </Form.Item> */}

          <Form.Item
            name={["company", "registrationNumber"]}
            label={<span className={s.label_3}>{t("registrationNumber")}</span>}
          >
            <Input size="large" style={leftInputStyles} />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={["company", "website"]}
            rules={[
              { required: true, message: t("errors.website") },
              { type: "url", message: t("errors.correctWebsite") }
            ]}
            label={<span className={s.label_4}>{t("website")}*</span>}
          >
            <Input size="large" style={rightInputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "description"]}
            // rules={[{ required: true, message: t("errors.description") }]}
            label={<span className={s.label_4}>{t("companyDesc")}*</span>}
            rules={[
              {
                required: true,
                message: t("fields.description.errors.required", { ns: "FS10-AddNewProduct" })
              },
              {
                min: 30,
                message: t("fields.description.errors.min", { ns: "FS10-AddNewProduct" })
              },
              {
                max: 700,
                message: t("fields.description.errors.max", { ns: "FS10-AddNewProduct" })
              }
            ]}
          >
            <TextArea autoSize allowClear showCount maxLength={700} className={s.TextArea} />
          </Form.Item>

          <Form.Item
            name={["company", "keywords"]}
            rules={[
              {
                required: true,
                message: '',
              },
              customKeyWordsValidator(form, t),
            ]}
            label={<span className={s.label_4}>{t("keywords")}*</span>}
          >
            <Input size="large" style={rightInputStyles} />
          </Form.Item>
          <p className={s.help}>{t("keywordsLimit")}</p>
        </div>
      </div>
      <AddAndCropLogo
        visible={visibleCropLogo}
        setVisible={setVisibleCropLogo}
        saveCropLogo={handleLogoSelect}
        img={img}
        setImg={setImg}
      />
    </section>
  );
};

export default CompanyInfo;

// const onAddLogo = (e) => {
//   const formData = new FormData();
//   formData.append("file", e.target.files[0]);
//   axios({
//     method: "post",
//     url: `${backendUrl}/upload/image`,
//     data: formData,
//     headers: { "Content-Type": "multipart/form-data" }
//   }).then((res) => {
//     if (res.status === 200) dispatch(setLogo(res.data?.filename));
//   });
// };
{
  /*<div className={s.imgContainer} style={{ marginBottom: 20, border: hasError && "1px solid #ff4d4f" }}>*/
}
{
  /*  {logo ? (*/
}
{
  /*    <img className={s.uploads} src={`${mediaUrl}${logo}`} alt="logo" />*/
}
{
  /*  ) : (*/
}
{
  /*    <Img />*/
}
{
  /*  )}*/
}
{
  /*  <div style={{ paddingRight: 25 }}>*/
}
{
  /*    <p style={{ fontWeight: 500 }}>{t("companyLogo")}*</p>*/
}
{
  /*    <label className={s.uploader}>*/
}
{
  /*      {t("addImage")}*/
}
{
  /*      <input*/
}
{
  /*        style={{ display: "none" }}*/
}
{
  /*        type="file"*/
}
{
  /*        accept=".jpg,.jpeg,.png"*/
}
{
  /*        onChange={onAddLogo}*/
}
{
  /*      />*/
}
{
  /*    </label>*/
}
{
  /*    {hasError && <p style={{ color: "#ff4d4f" }}>{t("errors.companyLogo")}</p>}*/
}
{
  /*  </div>*/
}
{
  /*</div>*/
}
