import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  callback: null,
  condition: false
};

const leavePageModalSlice = createSlice({
  name: "showLeavePageModalSlice",
  initialState,
  reducers: {
    setLeavePageModalState(state, action) {
      state.show = action.payload.show;
      state.callback = action.payload.callback;
      state.condition = action.payload.condition;
    }
  }
});

export const { setLeavePageModalState } = leavePageModalSlice.actions;

export default leavePageModalSlice.reducer;
