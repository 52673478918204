/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */

import {
  Agent,
  BusinessService,
  DistributorWholesaler,
  GovernmentMinistryBureau,
  Manufacturer,
  Office,
  Other,
  PurchasingOffice,
  Retailer
} from "../../../../assets/svg/BusinessType";

import React from "react";
import s from "./BusinessTypeItem.module.less";

const BusinessTypeItem = ({ title, t, id, preview }) => {
  const type = [
    <Manufacturer />,
    <PurchasingOffice />,
    <Office />,
    <Agent />,
    <DistributorWholesaler style={{ height: "100px" }} />,
    <GovernmentMinistryBureau />,
    <BusinessService />,
    <Retailer />,
    <Other />
  ];

  const name = [
    "INDUSTRY",
    "TRADE_COMPANY",
    "PURCHASING_DEPARTMENT",
    "AGENT",
    "STOCK",
    "GOVERNMENT",
    "SERVICE_COMPANY",
    "RETAILER",
    "OTHER"
  ];

  return (
    <div className={s.wrapper}>
      {type[id - 1]}
      <div className={s.title}>
        {preview ? t(`businessType.${name[id - 1]}`) : t(`businessType.${title}`)}
      </div>
    </div>
  );
};

export default BusinessTypeItem;
