/* eslint-disable react/prop-types */
import React from "react";
import {useTranslation} from "react-i18next";
import s from "./ShowcaseSelection.module.less";
import SubCategories from "../FK0-mainCatalog/Categories/SubCategories";

const ShowcaseSelectionItem = ({
                                 item,
                                 idCategories,
                                 setIdCategories,
                                 orange,
                                 company,
                                 setIdSubCategories,
                                 idSubCategories,
                                 service
                               }) => {
  const {t} = useTranslation(["ProductCategories", "ServiceCategories"]);

  const title = service ? item.type : (item?.subcategories[0]?.productsCount ? `productCategories.${item.type}` : `${item.type}`)

  const parentItem = item
  return (
    <div>
      <div
        className={s.link}
        style={orange && {color: "#ea5b0c"}}
        onClick={() => {
          if (item.id === idCategories && !item.type.includes('SERVICES')) {
            setIdCategories(null);
          } else if (item.id + 10000 === idCategories && item.type.includes('SERVICES')) {
            setIdCategories(null);
          } else {
            if (item.type.includes('SERVICES')) {
              setIdCategories(item.id + 10000);
            } else {
              setIdCategories(item.id);
            }
          }
        }}
      >
        {service ? t(`${title}.label`, {ns: "ServiceCategories"}) : (item?.subcategories[0]?.productsCount ? t(`${title}.label`) : t(`${title}.label`, {ns: "ServiceCategories"}))}{" "}
        ({item?.subcategories[0]?.productsCount ? item?.subcategories?.map((f) => f.productsCount).reduce((p, c) => p + c) : item?.subcategories?.map((f) => f.servicesCount).reduce((p, c) => p + c)})
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{width: "250px"}}
            className={(item.type.includes('SERVICES') ? idCategories === item.id + 10000 : idCategories === item.id) ? `${s.active} ${s.accordion}` : s.accordion}
          >
            {item.subcategories.map((item, index) => (
              <SubCategories
                parentItem={parentItem}
                idCategories={idCategories}
                company={company}
                orange={orange}
                key={index}
                t={t}
                item={item}
                length={service ? item.servicesCount : item.productsCount}
                title={title}
                setIdSubCategories={setIdSubCategories}
                idSubCategories={idSubCategories}
                service={service}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowcaseSelectionItem;
