import { createSlice } from "@reduxjs/toolkit";

const normalizeData = (data) =>
  data.map(({ id, item, companyIdFk, itemAmount, message, createdAt, status, companyId, unreadMessagesCount }) => ({
    key: id,
    product: item ?? "",
    company: companyIdFk?.companyName ? {
      id: companyIdFk?.id,
      name: companyIdFk?.companyName
    } : "",
    country: companyIdFk?.country?.localizedName ?? "",
    productAmount: itemAmount ?? "",
    message: message ?? "",
    requestTime: createdAt ?? "",
    requestStatus: status ?? "",
    toDialog: companyId ?? "",
    isUserDeactivated: status?.id === 4,
    unreadMessagesCount
  }));

const initialState = {
  data: [],
  total: 10
};

const outgoingRequestsBuyerSlice = createSlice({
  name: "outgoingRequestsBuyer",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = outgoingRequestsBuyerSlice.actions;

export default outgoingRequestsBuyerSlice.reducer;
