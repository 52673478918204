import { createSlice } from "@reduxjs/toolkit";
import { techIssuesAPI } from "../api/api";

const initialState = {
  allTechIssue: [],
  techIssueById: []
};

const techIssueSlice = createSlice({
  name: "techIssues",
  initialState,
  reducers: {
    setAllTechIssues(state, action) {
      state.allTechIssue = action.payload;
    },
    setAllTechIssuesById(state, action) {
      state.techIssueById = action.payload;
    }
  }
});

export const { setAllTechIssues } = techIssueSlice.actions;
export const getAllTechIssues = (limit, page) => (dispatch) => {
  techIssuesAPI.getAllTechIssue(limit, page).then((res) => {
    dispatch(setAllTechIssues(res.data));
  });
};

export const { setAllTechIssuesById } = techIssueSlice.actions;
export const getTechIssueById = (id) => (dispatch) => {
  techIssuesAPI.getTechIssueById(id).then((res) => {
    dispatch(setAllTechIssuesById(res.data));
  });
};

export default techIssueSlice.reducer;
