import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/company/`
});

const GET = {
  getAllSellerRequests: (config) => instance.get("all-request-seller", config),
  getAllBuyerRequests: (config) => instance.get("all-request-buyer", config),
  searchSellerRequests: (name) => (value) =>
    instance.get(`all-request-seller/search?${name}=${value}`),
  searchBuyerRequests: (name) => (value) =>
    instance.get(`all-request-buyer/search?${name}=${value}`),
  searchCompaniesByName: (name) => instance.get(`name/${name}?showBlocked=true`),
  viewSellerRequest: (config) =>
    axios.put(`${backendUrl}/viewed`, { type: "orders.seller.incoming", ...config })
};

export const companyApi = { ...GET };
