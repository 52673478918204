import React from "react";

export const tariffDescriptionJSONtoHTML = (tariffName, t) => {
  return (
    <>
      <p className="mb-12">
        {t(`${tariffName}.desc`, { ns: "tariffDescription" })}
      </p>
      <ul className="mb-12">
        {t(`${tariffName}.list`, { ns: "tariffDescription" }).map((el, i) => (
          <li key={i}>{el}</li>
        ))}
      </ul>
      {t(`${tariffName}.paragraphs`, { ns: "tariffDescription" }).map((el, i) => (
        <p className="fz-12 mb-12" key={i}>
          {el}
        </p>
      ))}
    </>
  );
};
