import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import s from "./Paginator.module.less";

const Paginator = ({ pagination, setCurrentPage, total, style, showPrevNextJumpers }) => {
  const { t } = useTranslation("FS19-Dialogs");

  const itemRender = useCallback(
    (_, type, originalElement) => {
      if (type === "prev" || type === "next") {
        return <a>{t(type)}</a>;
      }
      return originalElement;
    },
    [t]
  );

  return (
    <Pagination
      {...pagination}
      className={s.pagination}
      style={style}
      onChange={setCurrentPage}
      total={total}
      hideOnSinglePage={true}
      showSizeChanger={false}
      itemRender={itemRender}
      showPrevNextJumpers={showPrevNextJumpers}
    />
  );
};

Paginator.propTypes = {
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
  total: PropTypes.number,
  style: PropTypes.object,
  showPrevNextJumpers: PropTypes.bool
};
export default Paginator;
