import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select as AntdSelect } from "antd";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";

const Select = (props) => {
  const { t } = useTranslation("Search");
  const [openState, setOpenState] = useState(false);

  return (
    <AntdSelect
      style={{ height: 32 }}
      showArrow
      suffixIcon={
        <DropdownIcon
          onClick={() => {
            setOpenState((prevState) => !prevState);
          }}
        />
      }
      maxTagTextLength={4}
      maxTagCount="responsive"
      onDropdownVisibleChange={setOpenState}
      open={openState}
      placeholder={t("all")}
      {...props}
    />
  );
};

export default Select;
