import { Button, Form, Input, Upload } from 'antd';
import Clip from 'Assets/svg/clip.svg';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { backendUrl, communicationHistoryAPI, dialogsAPI } from '../../api/api';
import { socket } from '../../App';
import SendMessageBtn from '../../assets/svg/SendMessageBtn';
import Loader from '../Loader/Loader';
import s from './DialogsWindow.module.less';
import Message from './Message/Message';

const DialogsWindow = ({
  connectToId,
  entityId = null,
  entityType = null,
  side = null,
  roomId = null
}) => {
  const [form] = Form.useForm();
  const ref = useRef(null);
  const inputRef = useRef(null);
  const { user, isAdmin } = useSelector((state) => state.auth);
  const [fetching, setFetching] = useState(true);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [lastEl, setLastEl] = useState(null);
  const [newMessage, setNewMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchOffset, setFetchOffset] = useState(0);
  const [messageWasSent, setMessageWasSent] = useState("");
  const [fileList, setFileList] = useState([]);

  const [wsRoom, setWsRoom] = useState({ roomId });
  useEffect(() => {
    // console.log(wsRoom);
    if (wsRoom.roomId) {
      socket.emit("joinRoom", {
        username: user.email,
        roomId: wsRoom.roomId
      });
    } else {
      dialogsAPI.getWSRoomId({ companyId: connectToId }).then((res) => {
        setWsRoom({ ...res.data, roomId: res.data.id });
      });
    }
  }, [wsRoom]);
  useEffect(() => {
    const dialogsWindowRef = ref.current;
    socket.on("roomUsers", ({ roomId, users }) => {
      // console.log("Room joined");
      // console.log(roomId, users);
    });
    socket.on("message", (message) => {
      if (
        dialogsWindowRef.scrollTop + dialogsWindowRef.clientHeight ===
        dialogsWindowRef.scrollHeight ||
        dialogsWindowRef.scrollHeight -
        (dialogsWindowRef.scrollTop + dialogsWindowRef.clientHeight) <=
        50
      ) {
        setScrollToBottom(true);
      }
      setLastEl(null);
      setMessages((prev) => [
        ...prev,
        {
          ...message,
          isRead: false
        }
      ]);
    });
    socket.on("setMessageIsRead", (message) => {
      setMessages((prev) => {
        return prev.map((el) => (el.id === message.id ? { ...el, isRead: true } : el));
      });
    });
    socket.on("serviceMessage", (message) => {
      // console.log(message);
    });
    return () => {
      // console.log(wsRoom);
      // console.log("leaving the room");
      socket.emit("leaveRoom", {
        roomId: wsRoom.roomId
      });
      ["roomUsers", "setMessageIsRead", "message", "serviceMessage"].forEach((event) =>
        socket.removeAllListeners(event)
      );
    };
  }, []);

  useEffect(() => {
    // console.log(messages.length);
    const dialogsWindowRef = ref.current;
    // window.dialogsWindowRef = dialogsWindowRef
    // window.lastEl = lastEl
    if (messages.length && scrollToBottom) {
      // console.log("scroll to bottom");
      dialogsWindowRef.scrollTop = dialogsWindowRef.scrollHeight;
      setScrollToBottom(false);
    } else if (lastEl) {
      // console.log("scroll Height: " + dialogsWindowRef.scrollHeight);
      // console.log("lastEl offsetTop: " + lastEl.offsetTop);
      // console.log(lastEl);
      dialogsWindowRef.scrollTop = lastEl.offsetTop - 10;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((ent) => {
          if (ent.isIntersecting) {
            socket.emit("readMessage", {
              messageId: ent.target.getAttribute("data-id"),
              roomId: wsRoom.roomId
            });
            observer.unobserve(ent.target);
          }
        });
      },
      { threshold: 1, root: dialogsWindowRef, rootMargin: "24px" }
    );
    dialogsWindowRef.querySelectorAll("[data-my='false'][data-isread='false']").forEach((el) => {
      observer.observe(el);
    });
    return () => {
      observer.disconnect();
    };
  }, [scrollToBottom, messages]);

  const dialogsScroll = (e) => {
    // console.log("scrollTop: " + e.target.scrollTop);
    if (e.target.scrollTop === 0 && messages.length < totalCount) {
      setLastEl(e.target.querySelector("div"));
      setFetching(true);
    }
  };
  useEffect(() => {
    const dialogsWindowRef = ref.current;
    dialogsWindowRef.addEventListener("scroll", dialogsScroll);
    setFetchOffset(messages.length);
    return () => {
      dialogsWindowRef.removeEventListener("scroll", dialogsScroll);
    };
  }, [messages]);

  useEffect(() => {
    if (fetching && wsRoom.roomId) {
      // console.log("get messages");
      dialogsAPI
        .getMessages(wsRoom.roomId, 20, fetchOffset)
        .then((res) => {
          setMessages((prev) => [...res.data.items, ...prev]);
          setTotalCount(res.data.total);
        })
        .finally(() => setFetching(false));
    }
  }, [fetching, wsRoom]);

  const { t } = useTranslation(["DialogsWindow"]);
  const sendMessageSubmit = (val) => {
    const { message } = val;
    if (message && message.trim().length !== 0) {
      const newChatMessage = {
        username: user.email,
        roomId: wsRoom.roomId,
        text: message
      };
      // console.log(connectToId)
      try {
        if (isAdmin && !messageWasSent) {
          setMessageWasSent(true);
          communicationHistoryAPI.addCommentToHistory(
            entityId,
            entityType,
            user.email,
            "MESSAGE_SENT",
            side,
            false
          );
        }
        socket.emit("chatMessage", newChatMessage);
        // console.log("send");
        setNewMessage((prev) => (prev ? false : true));
      } catch (e) {
        // console.log(e);
      }
      form.setFieldsValue({ message: undefined });
      inputRef.current.focus({ cursor: "start" });
    }
    if (fileList) {
      // console.log("fileList", fileList);
      let files = fileList.map(
        (file) =>
          file.status === "done" &&
          `EXTF:${file.response.filename}:${file.originFileObj.name}:${file.type.includes("image") ? ":IMG" : file.type.includes("video") ? ":VID" : ""
          }`
      );
      files.map((file) => {
        if (file) {
          const newChatMessage = {
            username: user.email,
            roomId: wsRoom.roomId,
            text: file
          };
          socket.emit("chatMessage", newChatMessage);
        }
      });
      form.setFieldsValue({ message: undefined });
      setFileList([]);
      inputRef.current.focus({ cursor: "start" });
    }
  };

  // console.log(fileList);

  const submitOnEnter = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      form.submit();
    }
  };

  const handleCustomRequest = ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }
    // console.log("fileName", file.name);
    formData.append(filename, file);
    formData.append("originalname", file.name);
    headers["Content-Type"] = "text/html; charset=utf-8 multipart/form-data";
    headers["Accept"] = "application/json; charset=utf-8";
    axios
      .post(action, formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        }
      })
      .then(({ data: response }) => {
        onSuccess(response, file);
      })
      .catch(({ data: response }) => {
        onError(response, "error");
      })
      .catch(onError);

    return {
      abort() {
        // console.log("upload progress is aborted.");
      }
    };
  };

  const handleChange = (info) => {
    // console.log(info);
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        // console.log(file);
        // Component will show file.url as link
        file.url = file.originFileObj.name;
      }
      return file;
    });

    // console.log(newFileList);

    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    // console.log(file);
    setFileList((prev) => prev.filter((prevFile) => prevFile.uid !== file.uid));
  };

  return (
    <>
      <div className={s.mainDialogWindow} ref={ref}>
        {fetching && <Loader />}
        {!!messages.length && messages.map((el) => <Message key={el.id} item={el} />)}
      </div>
      <div>
        <Form
          form={form}
          name="sendMessage"
          validateTrigger="onSubmit"
          onFinishFailed={(data) => {
            // console.log(data);
          }}
          className={`mt-24 ${s.form}`}
          onFinish={sendMessageSubmit}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div className={s.customInput}>
              <Form.Item noStyle name="message" style={{ flexGrow: 1 }}>
                <Input.TextArea
                  size="small"
                  placeholder={t("message", { ns: "DialogsWindow" })}
                  style={{ padding: "13px 20px", maxHeight: 300, minHeight: 55 }}
                  rows={1}
                  onPressEnter={submitOnEnter}
                  ref={inputRef}
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  className={s.messageInput}
                />
              </Form.Item>
              <Upload
                action={`${backendUrl}/upload/file`}
                customRequest={handleCustomRequest}
                onChange={handleChange}
                onRemove={handleRemove}
                multiple={true}
                maxCount={5}
                fileList={fileList}
                showUploadList={false}
              >
                <Button className={s.uploadButton} icon={<img src={Clip} />}></Button>
              </Upload>
            </div>
            <div style={{ display: "flex" }}>
              <Form.Item className="ml-15">
                <Button className="btn btn_v9" htmlType="submit">
                  <SendMessageBtn />
                </Button>
              </Form.Item>
            </div>
          </div>
          <Upload maxCount={5} onRemove={handleRemove} multiple={true} fileList={fileList}></Upload>
        </Form>
      </div>
    </>
  );
};
DialogsWindow.propTypes = {
  connectToId: PropTypes.number,
  entityId: PropTypes.number,
  entityType: PropTypes.string,
  side: PropTypes.string,
  roomId: PropTypes.number || null
};
export default DialogsWindow;
