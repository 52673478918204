import * as React from "react";

export const Manufacturer = (props) => (
  <svg width={100} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M92.971 20.472a2.778 2.778 0 0 0-2.777.167L63.888 38.25V22.945a2.778 2.778 0 0 0-4.555-2.14L37.443 38.89h-9.666V12.833a2.777 2.777 0 0 0-2.167-2.777l-11.11-2.5a2.778 2.778 0 0 0-3.14 1.56c-.178.39-.264.816-.25 1.245V38.89H8.332a2.778 2.778 0 0 0-2.777 2.778V86.11a2.778 2.778 0 0 0 2.777 2.778h83.334a2.778 2.778 0 0 0 2.778-2.778V22.945a2.778 2.778 0 0 0-1.473-2.473ZM16.666 13.89l5.555 1.222V38.89h-5.555v-25Zm72.222 69.445H11.11v-38.89h27.306a2.778 2.778 0 0 0 1.778-.638l18.139-15v14.666a2.778 2.778 0 0 0 4.333 2.306l26.222-17.611v55.166Z"
      fill="#343543"
    />
    <path
      d="M16.664 49.973h22.222v5.555H16.664v-5.555Zm0 11.11h22.222v5.556H16.664v-5.555Zm0 11.112h22.222v5.555H16.664v-5.555ZM52.775 52.75h5.556v8.334h-5.556V52.75Zm0 16.667h5.556v8.333h-5.556v-8.333ZM63.886 52.75h5.556v8.334h-5.556V52.75Zm0 16.667h5.556v8.333h-5.556v-8.333ZM74.997 52.75h5.556v8.334h-5.556V52.75Zm0 16.667h5.556v8.333h-5.556v-8.333Z"
      fill="#343543"
    />
  </svg>
);
export const Office = (props) => (
  <svg width={101} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M79.967 12.501v75h-25V72.918h-8.333v14.583h-25v-75h58.333ZM63.301 29.168h8.333v-8.333h-8.333v8.333Zm-16.667 0h8.333v-8.333h-8.333v8.333Zm-16.667 0h8.334v-8.333h-8.334v8.333Zm33.334 16.667h8.333V37.5h-8.333v8.334Zm-16.667 0h8.333V37.5h-8.333v8.334Zm-16.667 0h8.334V37.5h-8.334v8.334ZM63.301 62.5h8.333v-8.333h-8.333v8.333Zm-16.667 0h8.333v-8.333h-8.333v8.333Zm-16.667 0h8.334v-8.333h-8.334v8.333Zm33.334 16.667h8.333v-8.333h-8.333v8.333Zm-33.334 0h8.334v-8.333h-8.334v8.333Zm58.334-75h-75v91.667h75V4.168Z"
      fill="#343543"
    />
  </svg>
);
export const PurchasingOffice = (props) => (
  <svg width={101} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.65 87.499h87.5v6.25H6.65v-6.25Zm78.125-53.125a3.125 3.125 0 0 0 3.125-3.125v-9.375a3.125 3.125 0 0 0-2.062-2.938l-34.375-12.5a3.125 3.125 0 0 0-2.125 0l-34.375 12.5a3.125 3.125 0 0 0-2.063 2.938v9.375a3.125 3.125 0 0 0 3.125 3.125h3.125v40.625H12.9v6.25h75v-6.25h-6.25V34.374h3.125ZM19.15 24.06 50.4 12.686l31.25 11.375v4.063h-62.5V24.06ZM56.65 75h-12.5V34.374h12.5v40.625ZM25.4 34.374h12.5v40.625H25.4V34.374Zm50 40.625H62.9V34.374h12.5v40.625Z"
      fill="#343543"
    />
  </svg>
);

export const Agent = (props) => (
  <svg width={101} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M87.702 50.917c0-22.875-17.75-38.417-37.5-38.417-19.542 0-37.5 15.208-37.5 38.667a8.199 8.199 0 0 0-4.167 7.166v8.334c0 4.583 3.75 8.333 8.334 8.333a4.179 4.179 0 0 0 4.166-4.167V50.792c0-15.959 12.292-29.917 28.25-30.375A29.196 29.196 0 0 1 79.37 49.583v29.584H50.201a4.179 4.179 0 0 0-4.167 4.166 4.179 4.179 0 0 0 4.167 4.167h29.166c4.584 0 8.334-3.75 8.334-8.333v-5.084c2.458-1.291 4.166-3.833 4.166-6.833v-9.583c0-2.917-1.708-5.459-4.166-6.75Z"
      fill="#343543"
    />
    <path
      d="M37.702 58.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333ZM62.702 58.333a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333Z"
      fill="#343543"
    />
    <path
      d="M75.2 45.958A25.167 25.167 0 0 0 50.408 25C37.783 25 24.2 35.458 25.283 51.875a33.646 33.646 0 0 0 20.25-24.542C50.992 38.292 62.2 45.833 75.2 45.958Z"
      fill="#343543"
    />
  </svg>
);

export const DistributorWholesaler = (props) => (
  <svg width={82} height={81} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M67.6 28.181v35.944h-6.75v-27h-40.5v27H13.6V28.181l27-10.8 27 10.8Zm6.75 42.694v-47.25l-33.75-13.5-33.75 13.5v47.25H27.1v-27h27v27h20.25Zm-37.125-6.75h-6.75v6.75h6.75v-6.75ZM43.975 54h-6.75v6.75h6.75V54Zm6.75 10.125h-6.75v6.75h6.75v-6.75Z"
      fill="#343543"
    />
  </svg>
);

export const GovernmentMinistryBureau = (props) => (
  <svg width={82} height={81} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.25 74.25h67.5"
      stroke="#343543"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 12.273S25.813 6.75 41 6.75c15.188 0 27 5.523 27 5.523v9.665H14v-9.665Z"
      stroke="#343543"
      strokeWidth={6}
      strokeLinejoin="round"
    />
    <path
      d="M17.375 21.938v42.187m11.813-42.188v42.188M41 21.937v42.188m11.813-42.188v42.188m11.812-42.188v42.188"
      stroke="#343543"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.313 64.125h57.374V74.25H12.313V64.125Z"
      stroke="#343543"
      strokeWidth={6}
      strokeLinejoin="round"
    />
  </svg>
);

export const BusinessService = (props) => (
  <svg width={100} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="m99.103 52.228-9.374 9.375a3.203 3.203 0 0 1-4.454 0L75.9 52.228a3.164 3.164 0 0 1 4.453-4.453l4.024 4.062v-1.836a34.376 34.376 0 0 0-63.242-18.633 3.126 3.126 0 0 1-4.336.899 3.086 3.086 0 0 1-.938-4.297A40.625 40.625 0 0 1 90.627 50v1.836l4.023-4.062a3.164 3.164 0 0 1 4.453 4.453Zm-14.96 19.804a40.625 40.625 0 0 1-74.766-22.03v-1.837l-4.023 4.063a3.164 3.164 0 0 1-4.454 0 3.164 3.164 0 0 1 0-4.453l9.375-9.375a3.164 3.164 0 0 1 4.454 0l9.375 9.375a3.164 3.164 0 0 1 0 4.453 3.203 3.203 0 0 1-4.454 0l-4.023-4.063v1.836a34.219 34.219 0 0 0 8.672 22.813A30.938 30.938 0 0 1 38.4 61.603a18.75 18.75 0 1 1 23.203 0 31.017 31.017 0 0 1 14.102 11.25 33.936 33.936 0 0 0 3.164-4.219 3.125 3.125 0 0 1 4.336-.898 3.087 3.087 0 0 1 .938 4.296ZM50.002 59.376a12.5 12.5 0 1 0-12.5-12.5 12.539 12.539 0 0 0 12.5 12.5Zm0 25a34.414 34.414 0 0 0 21.094-7.187 25 25 0 0 0-42.149-.04 34.14 34.14 0 0 0 21.055 7.227Z"
        fill="#343543"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const Retailer = (props) => (
  <svg width={101} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M56.75 59.375a3.125 3.125 0 0 1 3.125-3.125h12.5a3.125 3.125 0 0 1 3.125 3.125v12.5A3.125 3.125 0 0 1 72.375 75h-12.5a3.125 3.125 0 0 1-3.125-3.125v-12.5ZM63 68.75h6.25V62.5H63v6.25ZM26.675 6.937l-15.625 12.5c-1.038.825-1.175 2.25-1.175 3.5v11.438A15.55 15.55 0 0 0 13 43.75v46.875a3.125 3.125 0 0 0 3.125 3.125h68.75A3.125 3.125 0 0 0 88 90.625V43.75a15.55 15.55 0 0 0 3.125-9.375V22.913c0-1.25-.138-2.65-1.175-3.476l-15.625-12.5a3.125 3.125 0 0 0-1.95-.687h-43.75a3.125 3.125 0 0 0-1.95.688ZM34.875 25v9.375a9.375 9.375 0 1 1-18.75 0V25h18.75Zm25 0v9.375a9.375 9.375 0 1 1-18.75 0V25h18.75Zm25 0v9.375a9.375 9.375 0 1 1-18.75 0V25h18.75ZM38.181 12.5l-2.343 6.25H21.905l7.813-6.25h8.468-.006Zm4.332 6.25 2.343-6.25h11.206l2.082 6.25H42.513ZM62.65 12.5h8.625l7.813 6.25H64.73L62.65 12.5Zm19.1 36.2v38.8H50.5V59.375a3.125 3.125 0 0 0-3.125-3.125h-18.75a3.125 3.125 0 0 0-3.125 3.125V87.5h-6.25V48.7A15.6 15.6 0 0 0 38 43.75C40.85 47.544 45.388 50 50.5 50s9.65-2.456 12.5-6.25a15.6 15.6 0 0 0 18.75 4.95Zm-37.5 38.8h-12.5v-25h12.5v25Z"
      fill="#343543"
    />
  </svg>
);

export const Other = (props) => (
  <svg width={100} height={100} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M50.003 91.665c23.011 0 41.666-18.654 41.666-41.666 0-23.012-18.654-41.667-41.666-41.667-23.012 0-41.667 18.655-41.667 41.667S26.99 91.665 50.003 91.665Z"
      stroke="#343543"
      strokeWidth={6}
    />
    <path
      d="M41.672 35.347c2.083-4.125 4.167-6.183 8.333-6.183 5.192 0 8.334 4.12 8.334 8.242 0 4.12-2.084 6.179-8.334 10.304v6.454m0 14.583v2.084"
      stroke="#343543"
      strokeWidth={6}
      strokeLinecap="round"
    />
  </svg>
);
