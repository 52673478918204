import * as React from 'react';

const Save = (props) => (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 333.488 333.488" >
        <g>
            <path d="M268.796,0.003H38.561C17.295,0.003,0,17.298,0,38.564v256.36c0,21.266,17.295,38.561,38.561,38.561h21.523h213.692h21.151
		c21.266,0,38.561-17.295,38.561-38.561V64.689L268.796,0.003z M77.591,19.284h28.085v66.511c0,2.269,0.463,4.409,1.183,6.427
		H84.018c-3.548,0-6.427-2.886-6.427-6.427C77.591,85.795,77.591,19.284,77.591,19.284z M241.996,29.412v52.674
		c0,4.351-3.515,7.879-7.873,7.879h-18.702c-4.357,0-7.886-3.528-7.886-7.879V29.412c0-4.351,3.528-7.879,7.886-7.879h18.702
		C238.481,21.533,241.996,25.061,241.996,29.412z M72.938,320.625V187.069c0-7.095,5.765-12.854,12.854-12.854h162.278
		c7.082,0,12.854,5.758,12.854,12.854v133.556H72.938z M320.635,294.918c0,14.184-11.536,25.707-25.707,25.707h-21.151V187.069
		c0-14.184-11.536-25.707-25.707-25.707H85.792c-14.178,0-25.707,11.523-25.707,25.707v133.556H38.561
		c-14.178,0-25.707-11.523-25.707-25.707V38.564c0-14.178,11.53-25.707,25.707-25.707h26.176v72.938
		c0,10.643,8.638,19.281,19.281,19.281h40.939h121.306c10.643,0,19.28-8.638,19.28-19.281V14.926l55.091,55.084
		C320.635,70.011,320.635,294.918,320.635,294.918z M94.764,198.265h143.942v17.558H94.764V198.265z M94.764,238.644h143.942v17.545
		H94.764V238.644z M94.764,279.018h106.499v17.558H94.764V279.018z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

);

export default Save;
