import React, {useMemo} from 'react';
import s from '../../FK3-сompanyProfile/About/ProductItem/ProductItem.module.less';
import {mediaUrl} from '../../../api/api';
import {Tooltip} from 'antd';

const PreviewItem = ({item, t, setDetailItem, type}) => {
  const visible = useMemo(() => Boolean(item?.visible && !item?.onModeration), [item]);
  const title = useMemo(() => item?.productName ? item.productName : item.serviceName, [item]);
  const incotermsMap = item?.incoterms ? item?.incoterms.map((m, index) => <span key={index}>{m.name}</span>) : [];

  return (
    <div onClick={() => setDetailItem(item)} className={s.wrapper} style={{margin: 0}}>
      <div className={s.img}>
        <img src={`${mediaUrl}${item?.image}`} className={s.img} alt={'product image'}/>
      </div>
      <div className={s.flex}>
        {title && (
          <div className={s.title} style={type === 'service' ? {maxWidth: '100%'} : {}}>
            {title}
          </div>
        )}
        <div>
          {item?.unitPrice && item?.unitPrice / 100} {item?.currency && item?.currency.name}{' '}
          {item?.incoterms && item?.incoterms.length ? item?.incoterms[0].name : null}
          {item?.incoterms && item?.incoterms.length > 1 && (
            <Tooltip placement="topLeft" title={incotermsMap} arrowPointAtCenter>
              ...
            </Tooltip>
          )}
        </div>
      </div>
      {visible !== null && <div className={s.visible}
                                style={visible ? {background: '#249F5D'} : {background: '#D62D30'}}>{visible ? t('visible') : t('invisible')}</div>}
    </div>
  );
};

export default PreviewItem;
