/* eslint-disable react/prop-types */
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Map = ({ bool, companyId, profile }) => {
  const { countries } = useSelector((state) => state.changeCompanyProfile.resources);

  let [countriesArr, setCountriesArr] = useState([]);

  useEffect(() => {
    if (profile) {
      if (profile.mainMarkets !== null && typeof profile.mainMarkets[0] === "object") {
        setCountriesArr(profile.mainMarkets.map((m) => m.name && m.name));
      } else if (profile.mainMarkets !== null && typeof profile.mainMarkets[0] === "number") {
        setCountriesArr(profile.mainMarkets.map((m) => countries.find(f => f.id === m && f).name));
      }
    }
  }, []);

  const mapData = {};
  companyId
    ? companyId.mainMarkets.forEach((f) => (mapData[f.name] = "0"))
    : countriesArr.forEach((f) => (mapData[f] = "0"));

  return (
    <div style={{ height: "440px" }}>
      <VectorMap
        map={worldMill}
        backgroundColor="#F5F7F8"
        labels={{
          markers: []
        }}
        regionLabelStyle={{
          initial: {
            fontSize: "8px",
            backgroundColor: "#fff"
          }
        }}
        regionStyle={{
          initial: {
            fill: "#9193AA",
            cursor: "default"
          }
        }}
        series={{
          regions: [
            {
              values: mapData, //this is your data
              scale: ["#EA5B0C"] //your color game's here
              // normalizeFunction: "polynomial"
            }
          ]
        }}
      />
    </div>
  );
};
export default Map;
