import { createSlice } from "@reduxjs/toolkit";

const statuses = {
  1: "Не подтверждена-NOT_CONFIRMED",
  2: "Подтверждена-CONFIRMED",
  3: "Отменена-CANCELED",
  4: "Проведена-COMPLETED"
};

const normalizeData = (data) =>
  data.map(
    ({
      id,
      language,
      createdAt,
      dateFrom,
      companyId,
      order,
      status,
      companyIdMeetingFk,
      countryIdFk,
      zoomLink,
      languageId,
      buyerId,
      unreadMessagesCount
    }) => {
      const date = new Date(dateFrom);
      date.setHours(date.getHours() + 2);

      return {
        key: id,
        buyerId,
        unreadMessagesCount,
        countryId: countryIdFk?.name ?? "",
        product: order?.item ?? "",
        company: companyIdMeetingFk?.companyName ? {
          id: companyIdMeetingFk?.id,
          name: companyIdMeetingFk?.companyName
        } : "",
        country: countryIdFk?.localizedName ?? "",
        productAmount: order?.itemAmount ?? "",
        message: order?.message ?? "",
        languageId: languageId ?? "",
        usedLanguage: language ?? "",
        requestTime: createdAt ?? "",
        meetingTime: dateFrom ?? "",
        meetingDuration: date.toISOString(),
        link: zoomLink ?? "",
        toDialog: companyId ?? "",
        status
      };
    }
  );

const initialState = {
  data: [],
  total: 10
};

const incomingRequestsMeetingModerBuyerSlice = createSlice({
  name: "incomingRequestsMeetingModerBuyer",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = incomingRequestsMeetingModerBuyerSlice.actions;

export default incomingRequestsMeetingModerBuyerSlice.reducer;
