import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import { Select } from "Components/Search";
import {
  fetchBusinessType,
  getAnnualTurnoverTypes,
  getNumberEmployeesTypes,
  getPercentageTurnoverTypes
} from "Store/resourcesSlice";
import s from "./AddUser.module.less";

const selectStyles = { width: 290, fontSize: 14 };
const inputStyles = { width: 290, backgroundColor: "#F5F7F8" };

const CompanyInfoDetails = () => {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation(["FA2.1-AddUser", "FK3-сompanyProfile"]);
  const dispatch = useDispatch();
  const { businessType, annualTurnoverTypes, numberEmployeesTypes, percentageTurnoverTypes } =
    useSelector((state) => state.resources);

  useEffect(() => {
    if (!businessType.length) dispatch(fetchBusinessType());
    if (!annualTurnoverTypes.length) dispatch(getAnnualTurnoverTypes());
    if (!numberEmployeesTypes.length) dispatch(getNumberEmployeesTypes());
    if (!percentageTurnoverTypes.length) dispatch(getPercentageTurnoverTypes());
  }, []);

  const checkboxOptions = useMemo(
    () =>
      businessType.reduce((arr, { id, name }) => {
        if (name !== "PURCHASING_DEPARTMENT") {
          arr.push({
            value: id,
            label: t(`businessType.${name}`, { ns: "FK3-сompanyProfile" })
          });
        }
        return arr;
      }, []),
    [t, businessType]
  );

  const annualTurnOverOptions = useMemo(
    () => annualTurnoverTypes.map(({ id: value, name: label }) => ({ value, label })),
    [annualTurnoverTypes]
  );

  const employeesNumberOptions = useMemo(
    () => numberEmployeesTypes.map(({ id: value, name: label }) => ({ value, label })),
    [numberEmployeesTypes]
  );

  const shareOptions = useMemo(
    () => percentageTurnoverTypes.map(({ id: value, name: label }) => ({ value, label })),
    [percentageTurnoverTypes]
  );

  return (
    <section>
      <h2 className={s.title}>{t("companyInfoDetails")}</h2>

      <h3 className={s.subtitle}>{t("businessType")}*</h3>
      <p style={{ fontWeight: 300, marginBottom: 32, color: hasError ? "#ff4d4f" : "" }}>
        {t("choose")}
      </p>

      <Form.Item
        name={["company", "businessTypes"]}
        style={{ marginBottom: 32 }}
        rules={[
          { required: true, message: "" },
          {
            validator(_, value) {
              if (!value || !value.length) return Promise.reject(setHasError(true));
              return Promise.resolve(setHasError(false));
            }
          }
        ]}
      >
        <Checkbox.Group
          options={checkboxOptions}
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px 80px"
          }}
        />
      </Form.Item>

      <div className={s.wrapper}>
        <div>
          <Form.Item
            name={["company", "annualTurnoverId"]}
            label={<span className={s.label_3}>{t("annualTurnOver")}</span>}
          >
            <Select
              options={annualTurnOverOptions}
              size="large"
              style={selectStyles}
              placeholder={t("unset")}
            />
          </Form.Item>

          <Form.Item
            name={["company", "numberEmployeesId"]}
            label={<span className={s.label_3}>{t("employeesNumber")}</span>}
          >
            <Select
              options={employeesNumberOptions}
              size="large"
              style={selectStyles}
              placeholder={t("unset")}
            />
          </Form.Item>

          <Form.Item
            name={["company", "yearFoundation"]}
            label={<span className={s.label_3}>{t("foundationYear")}</span>}
          >
            <MaskedInput
              mask={Number}
              maskOptions={{ min: 0, max: 2050 }}
              style={inputStyles}
              size="large"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={["company", "percentageImportsTurnoverId"]}
            label={<span className={s.label_3}>{t("importShare")}</span>}
          >
            <Select
              options={shareOptions}
              size="large"
              style={selectStyles}
              placeholder={t("unset")}
            />
          </Form.Item>

          <Form.Item
            name={["company", "percentageExportsTurnoverId"]}
            label={<span className={s.label_3}>{t("exportShare")}</span>}
          >
            <Select
              options={shareOptions}
              size="large"
              style={selectStyles}
              placeholder={t("unset")}
            />
          </Form.Item>

          <Form.Item
            name={["company", "membershipAssociations"]}
            label={<span className={s.label_3}>{t("membership")}</span>}
          >
            <Input style={inputStyles} size="large" />
          </Form.Item>
        </div>
      </div>
    </section>
  );
};

export default CompanyInfoDetails;
