import * as React from "react";

const Twitter = (props) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={32} height={32} rx={16} fill="#03A9F4" />
    <path
      d="M24.477 11.918c-.56.24-1.2.4-1.84.48.64-.4 1.2-1.04 1.44-1.76-.64.4-1.28.64-2.08.8a3.148 3.148 0 0 0-2.32-1.04 3.21 3.21 0 0 0-3.2 3.2c0 .24 0 .48.08.72a9.43 9.43 0 0 1-6.64-3.36c-.24.48-.4 1.04-.4 1.6 0 1.12.56 2.08 1.44 2.64-.56 0-1.04-.16-1.44-.4 0 1.52 1.12 2.88 2.56 3.12-.24.08-.56.08-.88.08-.24 0-.4 0-.64-.08.4 1.28 1.6 2.24 3.04 2.24-1.12.88-2.48 1.36-4 1.36-.24 0-.48 0-.8-.08 1.44.88 3.12 1.44 4.96 1.44 5.92 0 9.12-4.88 9.12-9.12v-.4c.64-.24 1.2-.8 1.6-1.44Z"
      fill="#fff"
    />
  </svg>
);

export default Twitter;
