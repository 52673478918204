import * as React from "react"

const Camera = (props) => (
  <svg
    width={20}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.562 5.5h1.875m-4.213 8.75h6.55c1.725 0 2.413-1.056 2.494-2.344l.325-5.162a2.348 2.348 0 0 0-2.344-2.494c-.381 0-.731-.219-.906-.556l-.45-.906c-.288-.57-1.038-1.038-1.675-1.038H6.787c-.644 0-1.394.469-1.682 1.038l-.45.906a1.027 1.027 0 0 1-.906.556 2.346 2.346 0 0 0-2.344 2.494l.325 5.162c.075 1.288.77 2.344 2.494 2.344Z"
      stroke="#222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 11.75a2.035 2.035 0 0 0 2.031-2.031A2.035 2.035 0 0 0 7.5 7.688a2.035 2.035 0 0 0-2.031 2.03c0 1.12.912 2.032 2.031 2.032Z"
      stroke="#222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Camera
