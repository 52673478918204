import React from "react";

export const convertStringToLinks = (string = "") => {
  return string.split(",").map(el => {
    if(el.includes("http")){
      return <a href={el} target="_blank">{el}</a>
    }else{
      return el
    }
  }).reduce((prev, next) =>[prev, <br/>, next])
}
