import { createSlice } from "@reduxjs/toolkit";

const normalizeData = ({ data, userId }) =>
  data.map((item) => {
    const rendezVous = item.company1Id === userId ? "company2" : "company1";

    return {
      key: item.id,
      company: item.admin ? "" : item[`${rendezVous}Room`].companyName,
      messageTime: item.messages?.length ? item.messages[0]?.createdAt : item.createdAt,
      allMessages: item.totalMessagesCount,
      newMessages: item.unreadMessagesCount,
      pinned: Boolean(item.pinned?.length),
      isAdmin: item.admin,
      toDialog: item.admin ? "" : item[`${rendezVous}Id`]
    };
  });

const initialState = {
  data: [],
  total: 10
};

const dialogsSlice = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    setData(state, action) {
      const { total, items, userId } = action.payload;
      state.total = total;
      state.data = normalizeData({ data: items, userId });
    }
  }
});

export const { setData } = dialogsSlice.actions;

export default dialogsSlice.reducer;
