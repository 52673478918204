/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { fetchCompanyCategories, fetchServiceCategoriesByCompany } from "../../store/catalogSlice";
import { productsAPI, serviceAPI } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";

import Plug from "../../assets/svg/Plug";
import ProductItem from "../FK3-сompanyProfile/About/ProductItem/ProductItem";
import s from "../FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import { Tooltip } from "antd";
import TooltipShowcase from "../FS8-changeCompanyProfile/TooltipShowcase";

const PlugItem = ({ setVisible, id, full, companyId }) => {
  const dispatch = useDispatch();
  const { showcase } = useSelector((state) => state.changeCompanyProfile.customProfile);

  const [data, setData] = useState({
    currency: { id: 1, name: "" },
    id: 3,
    image: "",
    incoterms: [{ id: 1, name: "" }],
    productName: "",
    unitPrice: 1,
    serviceName: ""
  });

  useEffect(() => {
    if (showcase[id]) {
      if (showcase[id].entityType === 'product') {
        productsAPI
          .getProductId(showcase[id].entityId)
          .then((res) => {
            setData(res.data);
          })
          .catch((e) => {
            // console.log(e.response);
          });
      } else if (showcase[id].entityType === 'service') {
        serviceAPI.getServiceById(showcase[id].entityId).then((res) => {
          setData(res.data);
        }).catch((e) => {
          // console.log(e.response);
        });
      }
    }
  }, [id, showcase]);

  const dataHandler = () => {
    dispatch(fetchCompanyCategories(companyId, setVisible));
    dispatch(fetchServiceCategoriesByCompany(companyId));
  }

  return (
    <div className={full? null : s.wid}>
      {showcase[id] ? (
        <div
          onClick={dataHandler}
          style={{ padding: "0" }}
          className={full ? s.selectFull : s.select}
        >
          {
            full ? (
              data.productName ?
                <ProductItem
                  plug
                  item={data}
                  price={data.unitPrice}
                  img={data.image}
                  currency={data.currency.name}
                  title={data.productName}
                  customMode={{
                    enabled: true,
                  }}
                  wrapStyle={{ height: '90%' }}
                /> :
                <ProductItem
                  plug
                  item={data}
                  img={data.image}
                  title={data.serviceName}
                  customMode={{
                    enabled: true,
                  }}
                  wrapStyle={{ height: '90%' }}
                />
            ) : data.productName ?
              <ProductItem
                imgStyle={{ width: "200px", height: "200px" }}
                plug
                item={data}
                price={data.unitPrice}
                currency={data.currency.name}
                title={data.productName}
                descStyle={{ flexDirection: "column" }}
                wrapStyle={{
                  width: "220px",
                  height: "100%",
                  border: 'none',
                  margin: "0",
                  padding: "10px"
                }}
                img={data.image}
              /> :
              <ProductItem
                imgStyle={{ width: "200px", height: "200px" }}
                plugService
                title={data.serviceName}
                descStyle={{ flexDirection: "column" }}
                wrapStyle={{
                  width: "220px",
                  height: "100%",
                  border: 'none',
                  margin: "0",
                  padding: "10px"
                }}
                img={data.image}
              />
          }
        </div>
      ) : (
        // <Tooltip overlayStyle={{ maxWidth: "400px" }} title={<TooltipShowcase/>}>
          <div
            onClick={dataHandler}
            className={s.select}
          >
            <Plug />
          </div>
        // </Tooltip>
      )}
    </div>
  );
};

export default PlugItem;
