import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/inquiries/`
});

const GET = {
  getInquiriesByCompanyId: (id) => (config) => instance.get(`company/${id}`, config)
};

export const inquiriesApi = { ...GET };
