import { Button, Form, Input, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { getAllEmployees, getAllEmployeesTable } from "../../store/employeesListSlice";

import dayjs from "dayjs";
import { employeesAPI } from "../../api/api";
import { makeColumnItem } from "../../helpers/tableHelpers";
import s from "./FA34EmployeesTable.module.less";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const FA34EMployeesListTable = ({ tableData, pageSize, current, setQParams }) => {
  const { t } = useTranslation([
    "FA34-EmployeesList",
    "FS7-userProfile",
    "Roles",
    "FA28-ComplaintProcess",
    "FA2-UsersList",
    "utils"
  ]);
  const { lang } = useSelector((state) => state.auth);
  const [PasswordModalIsVisible, setPasswordModalIsVisible] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [adminId, setAdminId] = useState(0);
  const [password, setPassword] = useState("");
  const [passwordRep, setPasswordRep] = useState("");
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinishPassword = () => {
    employeesAPI.changePassword(adminId, password).then((res) => {
      if (res.status === 200) {
        setPasswordModalIsVisible(false);
        setModalIsVisible(true);
        setModalMessage(t("modal.success", { ns: "FA34-EmployeesList" }));
        form.resetFields();
      } else {
        setModalIsVisible(true);
        setModalMessage(t("something_went_wrong", { ns: "FA28-ComplaintProcess" }));
        form.resetFields();
      }
    });
  };

  const handleOpenPasswordModal = (id) => {
    setPasswordModalIsVisible(true);
    setAdminId(id);
  };

  const handleDelete = (id) => {
    employeesAPI
      .deleteUser(id)
      .then((res) => {
        if (res.status === 200) {
          setUserDeleteModal(false);
          setModalMessage(t("modal.deleteSuccess", { ns: "FA34-EmployeesList" }));
          setModalIsVisible(true);
          dispatch(getAllEmployeesTable());
          dispatch(getAllEmployees());
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setModalMessage(t("modal.superAdmin", { ns: "FA34-EmployeesList" }));
          setModalIsVisible(true);
          setUserDeleteModal(false);
        } else if (e.response.status === 403) {
          setModalIsVisible(true);
          setUserDeleteModal(false);
          setModalMessage(t("something_went_wrong", { ns: "FA28-ComplaintProcess" }));
        }
      });
  };

  const deleteIdCheck = (id) => {
    setUserId(id);
    setUserDeleteModal(true);
  };

  const handleRestore = (id) => {
    employeesAPI
      .restoreUser(id)
      .then(() => {
        setModalMessage(t("RESTORE_SUCCESS"));
        setModalIsVisible(true);
        dispatch(getAllEmployeesTable());
        dispatch(getAllEmployees());
      })
      .catch((error) => {
        if (error.response.data.message === "ADMIN_ALREADY_RESTORED") {
          setModalMessage(t("ADMIN_ALREADY_RESTORED"));
          setModalIsVisible(true);
        } else if (error.response.data.message === "ADMIN_NOT_FOUND") {
          setModalMessage(t("ADMIN_NOT_FOUND"));
          setModalIsVisible(true);
        }
      });
  };

  const filterTableData = tableData?.length
    ? [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...tableData?.map((item, i) => ({ ...item, key: `${item.id}_${i}` }))
      ]
    : [];

  const columns = [
    {
      ...makeColumnItem(t, "role"),
      sorter: true,
      dataIndex: "roleId",
      sortDirections: ["ascend", "descend"],
      render: (_, { role }) => <span>{t(role.name, { ns: "Roles" })}</span>
    },
    {
      ...makeColumnItem(t, "FIO", 200),
      sorter: true,
      dataIndex: "firstName",
      key: "id",
      sortDirections: ["ascend", "descend"],
      render(_, { firstName, lastName }) {
        return (
          <span>
            {firstName} {lastName}
          </span>
        );
      }
    },
    {
      ...makeColumnItem(t, "email"),
      dataIndex: "email",
      key: "id",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      render: (_, { email }) => <span>{email}</span>
    },
    {
      ...makeColumnItem(t, "password"),
      dataIndex: "id",
      key: "id",
      render: (_, { id }) => (
        <p
          style={{
            width: "145px",
            textDecoration: "underline",
            cursor: "pointer",
            color: "#EA5B0C",
            fontWeight: 500
          }}
          onClick={() => handleOpenPasswordModal(id)}
        >
          {t("columns.changePassword", { ns: "FA34-EmployeesList" })}
        </p>
      )
    },
    {
      ...makeColumnItem(t, "createdAt", 200, "date"),
      render: (_, { createdAt }) => <span>{dayjs(createdAt).format("DD.MM.YYYY HH:mm")}</span>
    },
    {
      ...makeColumnItem(t, "lastLoginDate", 200, "date"),
      render: (_, { lastLoginDate }) => (
        <p>
          {lastLoginDate
            ? dayjs(lastLoginDate).format("DD.MM.YYYY HH:mm")
            : t("noLogin", { ns: "FA34-EmployeesList" })}
        </p>
      )
    },
    {
      ...makeColumnItem(t, "lastAction"),
      render: (_, { lastAction }) => (
        <p style={{ textAlign: !lastAction && "center" }}>{lastAction?.[lang] || "- - -"}</p>
      )
    },
    {
      ...makeColumnItem(t, "", 150),
      render: (_, { id, deletedAt }) =>
        deletedAt !== null ? (
          <button
            style={{
              background: "none",
              color: "#EA5B0C",
              cursor: "pointer",
              borderBottom: "1px solid #EA5B0C",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none"
            }}
            onClick={() => handleRestore(id)}
          >
            {t("reestablish", { ns: "FA34-EmployeesList" })}
          </button>
        ) : (
          <button
            style={{
              background: "none",
              color: "#EA5B0C",
              cursor: "pointer",
              borderBottom: "1px solid #EA5B0C",
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none"
            }}
            onClick={() => deleteIdCheck(id)}
          >
            {t("columns.delete", { ns: "FA34-EmployeesList" })}
          </button>
        )
    }
  ];

  const setPasswordFC = (num, value) => {
    const reg = /[а-я,А-Я]/g;
    if (num === 1) {
      setPassword(value.replace(reg, ""));
      form.setFieldsValue({ password: value.replace(reg, "") });
    }
    if (num === 2) {
      setPasswordRep(value.replace(reg, ""));
      form.setFieldsValue({ repeatPassword: value.replace(reg, "") });
    }
  };

  const tableChange = (sorter) => {
    setQParams((prev) => ({
      ...prev,
      sortDirection: sorter.order ? (sorter.order === "ascend" ? "ASC" : "DESC") : null,
      sortColumn: sorter.order ? sorter.field : null
    }));
  };

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") setModalIsVisible(false);
    });
  }, [document]);

  return (
    <div className={s.sectionOver}>
      {userDeleteModal && (
        <Modal
          visible={userDeleteModal}
          onCancel={() => setUserDeleteModal(false)}
          footer={[
            <React.Fragment key={1}>
              <Button
                key="ok"
                className="btn btn_v12"
                style={{ minHeight: 40 }}
                onClick={() => handleDelete(userId)}
              >
                {t("yes", { ns: "FA2-UsersList" })}
              </Button>
              <Button key="no" className="btn btn_v2" onClick={() => setUserDeleteModal(false)}>
                {t("no", { ns: "FA2-UsersList" })}
              </Button>
            </React.Fragment>
          ]}
        >
          <p key={"p"} style={{ marginTop: "15px" }}>
            {t("areUSureDelete", { ns: "FA34-EmployeesList" })}
          </p>
        </Modal>
      )}
      {modalIsVisible && (
        <Modal
          visible={modalIsVisible}
          onOk={() => setModalIsVisible(false)}
          onCancel={() => setModalIsVisible(false)}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalIsVisible(false);
              }}
            >
              {t("modal.buttonOk", { ns: "FA34-EmployeesList" })}
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      )}
      {PasswordModalIsVisible ? (
        <div className={s.modal}>
          <Modal
            visible={PasswordModalIsVisible}
            footer={[<div key="password_modal" style={{ marginBottom: "20px" }}></div>]}
            onCancel={() => {
              setPasswordModalIsVisible(false);
              form.resetFields();
            }}
          >
            <Form
              form={form}
              style={{ width: "100%", height: "100%", marginTop: "25px" }}
              colon={false}
              name="changePassword"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinishPassword}
              autoComplete="off"
              labelAlign="left"
              labelWrap
              requiredMark={false}
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={
                  <span className={s.label}>{t("password.label", { ns: "FS7-userProfile" })}*</span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("password.errors.required", { ns: "FS7-userProfile" })
                  },
                  {
                    min: 6,
                    message: t("password.errors.min", { ns: "FS7-userProfile" })
                  },
                  {
                    message: "",
                    validator: (_, value) => {
                      if (value !== passwordRep) {
                        return Promise.reject(t("passwordNotSame", { ns: "FA34-EmployeesList" }));
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
                className={s.passwordInput}
              >
                <Input.Password
                  onChange={(e) => setPasswordFC(1, e.target.value)}
                  size="large"
                  style={{ backgroundColor: "#F5F7F8" }}
                  value={password}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className={s.label}>
                    {t("passwordRepeat.label", { ns: "FS7-userProfile" })}*
                  </span>
                }
                className={s.passwordInput}
                name="repeatPassword"
                rules={[
                  {
                    required: true,
                    message: t("passwordRepeat.errors.required", { ns: "FS7-userProfile" })
                  },
                  {
                    min: 6,
                    message: t("passwordRepeat.errors.min", { ns: "FS7-userProfile" })
                  },
                  {
                    message: t("passwordRepeat.errors.match", { ns: "FS7-userProfile" }),
                    validator: (_, value) => {
                      if (value !== password) {
                        return Promise.reject(
                          t("passwordRepeat.errors.match", { ns: "FS7-userProfile" })
                        );
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
              >
                <Input.Password
                  onChange={(e) => setPasswordFC(2, e.target.value)}
                  size="large"
                  style={{ backgroundColor: "#F5F7F8" }}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  className="btn btn_v2"
                  type="default"
                  htmlType="submit"
                  size="large"
                  style={{ padding: "14px 40px", float: "right" }}
                >
                  {t("btn2")}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      ) : null}
      <div className={s.sectionOver}>
        <Table
          locale={{
            triggerDesc: t("triggerDesc", { ns: "utils" }),
            triggerAsc: t("triggerAsc", { ns: "utils" }),
            cancelSort: t("cancelSort", { ns: "utils" })
          }}
          pagination={{ pageSize, current }}
          dataSource={filterTableData}
          columns={columns}
          onChange={(newPagination, filters, sorter) => {
            tableChange(sorter);
          }}
        />
      </div>
    </div>
  );
};

FA34EMployeesListTable.propTypes = {
  tableData: PropTypes.array,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  setQParams: PropTypes.func
};
