import React, {useEffect, useMemo} from 'react';
import {createPortal} from "react-dom";

const ShowModal = ({visible, children}) => {
  const modalRooElement = document.querySelector("#modal")
  const element = useMemo(() => document.createElement("div"), [])

  useEffect(() => {
    if (visible) {
      modalRooElement.appendChild(element)
      document.body.style.overflow = 'hidden';
      return () => {
        modalRooElement.removeChild(element)
        document.body.style.overflow = 'auto';
      }
    }
  }, [visible])
  if (visible){
    return createPortal(children, element);
  }
  return null
};

export default ShowModal;