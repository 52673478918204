import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const TooltipWithLink = ({ isVerified }) => {
  const { t } = useTranslation("isVerified");
  const { lang } = useSelector((state) => state.auth);
  return (
    <>
      {isVerified ? (
        <div>
          <p>{t("verified.first")}</p>
          <br />
          <p>{t("verified.second")}</p>
          <p>{t("verified.ul")}</p>
          <p>{t("verified.li1")}</p>
          <p>{t("verified.li2")}</p>
          <br />
          <p>{t("verified.third")}</p>
          <p>
            {t("addtional")} &laquo;
            <Link style={{ color: "#EA5B0C", textDecoration: "none" }} to={"/safety-instruction"}>
              {t("link")}
            </Link>
            &raquo;
            {' '}
            {lang === "en" ? t("page") : ""}.
          </p>
        </div>
      ) : (
        <div>
          <p>{t("isNotVerified")}</p>
          <br />
          <p>{t("second")}</p>
          <br />
          <p>
            {t("addtional")} &laquo;
            <Link style={{ color: "#EA5B0C", textDecoration: "none" }} to={"/safety-instruction"}>
              {t("link")}
            </Link>
            &raquo;
            {lang === "en" ? " " + t("page") : ""}.
          </p>
        </div>
      )}
    </>
  );
};

TooltipWithLink.propTypes = {
  isVerified: PropTypes.bool
};
