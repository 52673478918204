import { createSlice } from "@reduxjs/toolkit";
import { employeesAPI } from "../api/api";


const normalizeData = (items) =>
  Object.keys(items).map((item) => ({
    name: item,
    amount: items[item],
    roleId: roleIDCheck(item)
  }));

const roleIDCheck = (role) => {
  if (role === "salesManagers") return 2;
  else if (role === "buyerManagers") return 3;
  else if (role === "headSalesManagers") return 4;
  else if (role === "headBuyersManagers") return 5;
  else if (role === "headTechSupport") return 6;
  else if (role === "techSupport") return 7;
  else if (role === "meetingModerators") return 8;
  else if (role === "translators") return 9;
  else if (role === "thisMonth") return "Month";
  else if (role === "lastDay") return "lastDay";
  else if (role === "deleted") return "deleted";
  else if (role === "total") return "total";
  else {
    return null;
  }
};

const initialState = {
  allEmployees: [],
  allEmployeesTable: []
};

const employeesSLice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setAllEmployees(state, action) {
      state.allEmployees = normalizeData(action.payload);
    },
    setAllEmployeesTable(state, action) {
      state.allEmployeesTable = action.payload;
    }
  }
});

export const { setAllEmployees } = employeesSLice.actions;
export const { setAllEmployeesTable } = employeesSLice.actions;

export const getAllEmployees = () => (dispatch) => {
  employeesAPI.getEmployees().then((res) => {
    dispatch(setAllEmployees(res.data));
  });
};

export const searchEmployee = (name) => (dispatch) => {
  if (name) {
    employeesAPI.search(name).then((res) => {
      dispatch(setAllEmployeesTable(res.data));
    });
  } else {
    dispatch(getAllEmployeesTable());
  }
};

export const getAllEmployeesTable =
  (qParams = {}) =>
    (dispatch) => {
      employeesAPI.getEmployeesTable(qParams).then((res) => {
        dispatch(setAllEmployeesTable(res.data));
      });
    };

export default employeesSLice.reducer;
