import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/dialogue/`
});

const GET = {
  getAllDialogues: (config) => instance.get("rooms", config),
  getNewDialogues: (config) => instance.get("rooms?unreadOnly=true", config),
  getTechSupport: (config) => instance.get("rooms?admin=true", config),
  searchCompanies: (value) => instance.get(`search?companyName=${value}`)
};

const POST = {
  pinDialogues: (roomId) => instance.post(`room/pin/${roomId}`),
  unpinDialogues: (roomId) => instance.post(`room/unpin/${roomId}`)
};

export const dialogueApi = { ...GET, ...POST };
