/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from "react";
import s from "./ShowcaseSelection.module.less";
import { useDispatch, useSelector } from "react-redux";
import ShowcaseSelectionItem from "./ShowcaseSelectionItem";
import ProductItem from "../FK3-сompanyProfile/About/ProductItem/ProductItem";
import {
  closeWindowShowSelection,
  fetchServiceSubcategoryId,
  fetchSubcategoryId,
  setShowcase
} from "../../store/changeCompanyProfileSlice";
import Pagination from "../../components/Pagination/Pagination";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";
import ExampleItem from "./ExampleItem";
import {companyAPI} from '../../api/api';

const ShowcaseSelection = ({ setVisible, visible, t, plug }) => {
  const { products, productTotal, pageCount, subcategoryId, companyId } = useSelector(
    (state) => state.changeCompanyProfile
  );
  const dispatch = useDispatch();
  const [idCategories, setIdCategories] = useState(null);
  const [idSubCategories, setIdSubCategories] = useState(null);
  const [init, setInit] = useState([]);
  const [current, setCurrent] = useState(pageCount);
  const { showcase } = useSelector((state) => state.changeCompanyProfile.customProfile);
  const { companyCategories, serviceCategories } = useSelector((state) => state.catalog);

  const catalogArr = useMemo(() => [...serviceCategories, ...companyCategories],[serviceCategories, companyCategories])
  const {user} = useSelector((state) => state.auth);

  const saveShowcase = () => {
    companyAPI.putShowcase(showcase, user?.company?.id).then((res) => {
      console.log(res);
    }).catch((e) => {
      console.log(e.response);
    }).finally(() => {
      setVisible(false)
    })
  }

  useEffect(() => {
    setInit(showcase);
  }, []);

  const back = () => {
    dispatch(setShowcase(init));
    setVisible(false);
  };


  useEffect(() => {
    if (catalogArr[0]?.subcategories[0]?.productsCount) {
      dispatch(fetchSubcategoryId(catalogArr[0]?.subcategories[0]?.id, user.company.id, 8, 1));
      setIdCategories(catalogArr[0]?.id)
      setIdSubCategories(catalogArr[0]?.subcategories[0]?.id);
    } else {
      dispatch(fetchServiceSubcategoryId(catalogArr[0]?.subcategories[0]?.id, user.company.id, 8, 1));
      setIdCategories(catalogArr[0]?.id + 10000)
      setIdSubCategories(catalogArr[0]?.subcategories[0]?.id + 10000);
    }
  }, [catalogArr])

  useEffect(() => {
    return () => dispatch(closeWindowShowSelection())
  }, [])
  if (!catalogArr.length) return (
    <Modal
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="ok"
          className="btn btn_v2"
          onClick={() => {
            setVisible(false);
          }}
        >
          OK
        </Button>,
      ]}
    >
      <h3>
        <span>{t("plugModalText")}<NavLink target={'_blank'} style={{color: 'rgb(234, 91, 12)'}} to={'/account/add-product'}>{t("tooltipLink1")}</NavLink></span>{" "}
        <span>{t("tooltip2")}<NavLink target={'_blank'} style={{color: 'rgb(234, 91, 12)'}} to={'/account/add-service'}>{t("tooltipLink2")}</NavLink> {t("tooltipLink3")}</span><br/>
        <div style={{paddingTop: '10px'}}>{t("tooltip3")}<NavLink target={'_blank'} style={{color: 'rgb(234, 91, 12)'}} to={'/account/edit-product-or-service'}>{t("tooltipLink4")}</NavLink> {t("tooltip4")}</div>{" "}
      </h3>
    </Modal>
  )
  else return (
    <div className={s.wrapperModal} onClick={() => setVisible(false)}>
      <div className={s.modal} onClick={(e) => e.stopPropagation()}>
        <div className={s.menu}>
          <div className={s.title}>{t("modal.title")}</div>
          <div>
            {catalogArr.map((item, index) => (
              <ShowcaseSelectionItem
                key={index}
                item={item}
                setIdCategories={setIdCategories}
                idCategories={idCategories}
                setIdSubCategories={setIdSubCategories}
                idSubCategories={idSubCategories}
                showItemCatDouble
              />
            ))}
          </div>
        </div>
        <div className={s.main}>
          <div className={s.topLine}>
            <div className={s.hint}>
              {t("windowHint")}
            </div>
            <div style={{display: 'flex'}}>
              <div className={s.btn1} onClick={saveShowcase}>
                {t("modal.save")}
              </div>
              <div className={s.btn2} onClick={back}>
                {t("modal.cancel")}
              </div>
            </div>

          </div>
          <div className={s.exampleWrapper}>
            {plug.map(m => <ExampleItem key={m} id={m}/>)}
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", padding: '0 20px', gap: '22px',  marginTop: '30px'}}>
            {products.map((item) => {
              if (item.currency) {
                return <ProductItem
                  check={true}
                  wrapStyle={{ width: "221px", minHeight: "260px", padding: "10px", cursor: 'pointer', position: 'relative', margin: 0 }}
                  img={item.image}
                  imgStyle={{ width: "180px", height: "180px" }}
                  price={item.unitPrice}
                  item={item}
                  title={item.productName}
                  key={item.id}
                  currency={item.currency.name}
                  product
                  descStyle={{marginBottom: '20px'}}
                />
              } else {
                return <ProductItem
                  check={true}
                  wrapStyle={{ width: "221px", minHeight: "260px", padding: "10px", cursor: 'pointer', position: 'relative', margin: 0 }}
                  img={item.image}
                  imgStyle={{ width: "180px", height: "180px" }}
                  price={item.unitPrice}
                  item={item}
                  title={item.serviceName}
                  key={item.id}
                  length={18}
                  descStyle={{marginBottom: '20px'}}
                />
              }
            })}
          </div>
          <Pagination
            pageSize={8}
            current={current}
            total={productTotal}
            setCurrent={setCurrent}
            style={{ justifyContent: "center", marginTop: "30px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowcaseSelection;
