import { useState } from "react";

export const useSelectCropImage = () => {
  const [img, setImg] = useState({});
  const [visibleImage, setVisibleImage] = useState(false);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImg({ base64: reader.result?.toString() || "", name: e.target.files[0].name })
      );
      reader.readAsDataURL(e.target.files[0]);
      setVisibleImage(true);
    }
  };
  return { img, visibleImage, setImg, setVisibleImage, onSelectFile };
};
