import { Pagination as AntdPagination } from "antd";
import PropTypes from "prop-types";
import s from "./Pagination.module.less";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const Pagination = ({
  current,
  setCurrent,
  setPagination,
  pageSize,
  total,
  style,
  showPrevNextJumpers,
  showLessItems
}) => {
  const { t } = useTranslation("FS19-Dialogs");

  const itemRender = useCallback(
    (_, type, originalElement) => {
      if (type === "prev" || type === "next") {
        return <a>{t(type)}</a>;
      }
      return originalElement;
    },
    [t]
  );

  return (
    <AntdPagination
      className={s.pagination}
      style={style}
      current={current}
      pageSize={pageSize}
      onChange={(current) => {
        if (setCurrent) setCurrent(current);
        if (setPagination) setPagination((prev) => ({ ...prev, current }));
      }}
      total={total}
      hideOnSinglePage={true}
      showSizeChanger={false}
      itemRender={itemRender}
      showPrevNextJumpers={showPrevNextJumpers}
      showLessItems={showLessItems}
    />
  );
};

Pagination.propTypes = {
  current: PropTypes.number,
  setCurrent: PropTypes.func,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  style: PropTypes.object
};
export default Pagination;
