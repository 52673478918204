import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/profile/`
});

const GET = {
  getAllUsers: (config) => instance.get("users", config)
};

export const profileApi = { ...GET };
