import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyByIdInSubcategory,
  fetchServiceSubcategoryById
} from "../../store/catalogSlice";
import Pagination from "../../components/Pagination/Pagination";
import ProductItem from "../FK3-сompanyProfile/About/ProductItem/ProductItem";
import { useTranslation } from "react-i18next";
import TopCompanyItem from "../FK0-mainCatalog/TopCompanyItem";
import ArrowLeft from "../../assets/svg/ArrowLeft";
import Loader from "../../components/Loader/Loader";
import s from '../FK0-mainCatalog/MainCatalog.module.less'

const CatalogCategories = () => {
  const { t } = useTranslation("FK1.1-CatalogCategories");
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pages, setPages] = useState(1);
  const [pagesCompany, setPagesCompany] = useState(1);
  const pageSize = 20;
  const {loader} = useSelector(state => state.catalog)
  const {
    serviceSubcategory,
    serviceSubcategoryTotal,
    companyByIdInSubcategory,
    companyByIdInSubcategoryTotal
  } = useSelector((state) => state.catalog);

  useEffect(() => {
    setPages(1)
  }, [id]);

  useEffect(() => {
    dispatch(fetchServiceSubcategoryById(id, pageSize, pages));
  }, [id, pages, dispatch]);

  useEffect(() => {
    dispatch(fetchCompanyByIdInSubcategory(id, 4, pagesCompany, 'services'));
  }, [id, pagesCompany, dispatch]);

  const min = pagesCompany <= 1;
  const max = pagesCompany >= Math.ceil(companyByIdInSubcategoryTotal / 4);

  const handler = (num) => {
    if (num === 1) {
      if (!min) {
        setPagesCompany(pagesCompany - 1);
      }
    } else if (num === 2) {
      if (!max) {
        setPagesCompany(pagesCompany + 1);
      }
    }
  };
  if (loader) return (<Loader style={{alignItems: 'flex-start', paddingTop: '400px'}}/>)
  else {
  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {serviceSubcategory.map((item, index) => (
          <ProductItem
            item={item}
            key={index}
            title={item.serviceName}
            price={item.unitPrice}
            currency={item.currency && item.currency.name}
            wrapStyle={{ margin: "0", padding: "16px" }}
            navLink={`/company-profile/${item.companyId}/service/${item.id}`}
            img={item.image}
            length={25}
          />
        ))}
      </div>
      <Pagination
        style={{ justifyContent: "center", marginTop: "32px" }}
        current={pages}
        pageSize={pageSize}
        total={serviceSubcategoryTotal}
        setCurrent={setPages}
      />
      <div>
        {companyByIdInSubcategory.length ? (
          <div
            style={{
              marginTop: "52px",
              marginBottom: "24px",
              fontWeight: "500",
              fontSize: "24px",
              color: "#222222"
            }}
          >
            {t("companyTitle")}
          </div>
        ) : null}

        <div className={s.companyItem}>
          {companyByIdInSubcategory.map((item, index) => (
            <TopCompanyItem
              key={index}
              name={item.companyName}
              id={item.id}
              city={item.city}
              logo={item.logo}
              link={`/company-profile/${id}`}
            />
          ))}
        </div>

        {companyByIdInSubcategoryTotal > 4 && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: '20px' }}>
            <div
              onClick={() => handler(1)}
              style={{
                border: "1px solid #E8EDF0",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: !min ? "pointer" : "not-allowed"
              }}
            >
              <ArrowLeft />
            </div>
            <div
              onClick={() => handler(2)}
              style={{
                border: "1px solid #E8EDF0",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: !max ? "pointer" : "not-allowed"
              }}
            >
              <ArrowLeft style={{ transform: "rotate(180deg)" }} />
            </div>
          </div>
        )}
      </div>
    </div>
  )}
};

export default CatalogCategories;
