/* eslint-disable react/prop-types */
import React from "react";
import s from "../Categories/Categories.module.less";
import style from "../../FS9-showcaseSelection/ShowcaseSelection.module.less";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServiceSubcategoryId,
  fetchSubcategoryId
} from "../../../store/changeCompanyProfileSlice";
import { NavLink, useLocation, useParams } from "react-router-dom";
import subCategories from "../../FK4-SearchProducts/NotFound/Categories/SubCategories";
import { fetchAllCompanyById } from "../../../store/catalogSlice";

const SubCategories = ({
  item,
  length,
  parentItem,
  t,
  title,
  orange,
  company,
  product,
  setIdSubCategories,
  idSubCategories,
  service,
  customMode = { enabled: false }
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const productHandler = () => {
    let companyId = user.company.id;
    if (title) {
      if (item?.productsCount) {
        dispatch(fetchSubcategoryId(item.id, companyId, 8, 1));
        setIdSubCategories(item.id);
      } else {
        dispatch(fetchServiceSubcategoryId(item.id, companyId, 8, 1));
        setIdSubCategories(item.id + 10000);
      }
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
    // dispatch(fetchAllCompanyById(parentItem, 15, 1, customMode.editMode));
  };
  let link = useLocation().pathname.indexOf("product");
  let link2 = window.location.pathname.split("/service")[1];
  let link3 = window.location.pathname.split("/product")[1];
  const { id } = useParams();

  const option = () => {
    if (orange && !service) {
      return (
        <NavLink
          className={s.link}
          style={
            (Number(id) === item.id || Number(idSubCategories) === item.id) && link3
              ? {
                  margin: "4px 10px 4px 20px",
                  color: "#000"
                }
              : {
                  margin: "4px 10px 4px 20px"
                }
          }
          to={`/company-profile/${company.id}/product-categories/${item.id}#${parentItem.id}`}
        >
          <div onClick={() => setIdSubCategories(item.id)}>
            {t(title + "." + item.type)} ({length})
          </div>
        </NavLink>
      );
    } else if (orange && service) {
      return (
        <NavLink
          className={s.link}
          style={
            (Number(id) === item.id || Number(idSubCategories) === item.id) && link2
              ? {
                  margin: "4px 10px 4px 20px",
                  color: "#000"
                }
              : { margin: "4px 10px 4px 20px" }
          }
          to={`/company-profile/${company.id}/service-categories/${item.id}#${
            parentItem.id + 10000
          }`}
        >
          <div onClick={() => setIdSubCategories(item.id)}>
            {t(title + "." + item.type, { ns: "ServiceCategories" })} ({length})
          </div>
        </NavLink>
      );
    } else if (title) {
      return (
        <div
          className={style.link}
          style={
            (
              item?.productsCount
                ? idSubCategories === item.id
                : idSubCategories === item.id + 10000
            )
              ? {
                  margin: "4px 10px 4px 20px",
                  color: "#EA5B0C"
                }
              : { margin: "4px 10px 4px 20px" }
          }
          onClick={productHandler}
        >
          <div>
            {item?.productsCount
              ? t(title + "." + item.type)
              : t(title + "." + item.type, { ns: "ServiceCategories" })}{" "}
            ({item?.productsCount ? item?.productsCount : item?.servicesCount})
          </div>
        </div>
      );
    } else if (
      customMode.enabled &&
      !product &&
      customMode.editMode &&
      customMode.editMode !== "service"
    ) {
      return (
        <div
          className={`${s.link} ${customMode?.chosenCat === item.id ? s.active : ""}`}
          style={{ margin: "4px 10px 4px 20px" }}
          onClick={() => {
            if (customMode.setChosenCat) {
              customMode.setChosenCat(item.id);
            }
            customMode.setSubcategoryId(item.id);
          }}
        >
          <div>
            {" "}
            {customMode.editMode === "product"
              ? t(item.type, { ns: "ProductSubCategories" })
              : t(item.type, { ns: "ServiceSubCategories" })}{" "}
            ({length})
          </div>
        </div>
      );
    } else if (product) {
      if (length === 0) return null;
      else {
        return (
          <NavLink
            className={s.link}
            style={
              Number(id) === item.id && link > 0
                ? {
                    margin: "4px 10px 4px 20px",
                    color: "#000"
                  }
                : { margin: "4px 10px 4px 20px" }
            }
            to={`/main-catalog/product-categories/${item.id}#${parentItem}`}
          >
            <div onClick={goToTop}>
              {item.type}
              <span style={{ whiteSpace: "nowrap" }}> ({length})</span>
            </div>
          </NavLink>
        );
      }
    } else if (customMode.enabled && customMode.editMode === "service") {
      if (length === 0) return null;
      else {
        return (
          <NavLink
            className={s.link}
            style={
              Number(id) === item.id && link < 0
                ? {
                    margin: "4px 10px 4px 20px",
                    color: "#000"
                  }
                : { margin: "4px 10px 4px 20px" }
            }
            to={`/main-catalog/categories/${item.id}#${parentItem + 10000}`}
          >
            <div onClick={goToTop}>
              {item.type}
              <span style={{ whiteSpace: "nowrap" }}> ({length})</span>
            </div>
          </NavLink>
        );
      }
    }
  };

  return <>{option()}</>;
};

export default SubCategories;
