import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/feedback/general`
});

const GET = {
  searchGeneralFio: (fio) => instance.get(`/search?fio=${fio}`)
};

export const generalFeedbackApi = { ...GET };
