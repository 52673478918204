/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import s from "../Categories/Categories.module.less";
const SubCategories = ({ item, length, isService }) => {
  const productT = useTranslation(["ProductSubCategories"]);
  const { t } = useTranslation([
    "ServiceCategories",
    "ProductCategories",
  ]);

    if(!item.servicesCount && !item.productsCount){
        return <></>
    }

  return (
    <>
      <Link
        to={`/main-catalog/${
          item.servicesCount ? `categories/${item.id}` : `product-categories/${item.id}`
        }`}
        className={s.link}
        style={{ margin: "4px 10px 4px 20px" }}
      >
        {isService ? t(item.category + '.' + item.type, {ns:"ServiceCategories"}) : t(`productCategories.${item.category}.${item.type}`, {ns:"ProductCategories"})} (
        {item.servicesCount ? item.servicesCount : item.productsCount})
      </Link>
      {/* {JSON.stringify(item)} */}
      {/* <div >
        {t(item.type)} ({length})
      </div> */}
    </>
  );
};

export default SubCategories;
