import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";

const VoluntaryVerification = () => {
  const { t } = useTranslation("FS0-Home");
  useEffect(() => {
    setDocumentTitle(t("voluntary_verification"));
  }, [t]);
  return (
    <>
      <Header h1={t("voluntary_verification")} />
      <Row justify="center">
        <Col xl={{ span: 16 }}>
          <div className="regular_block">{t("voluntary_verification")}</div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};
export default VoluntaryVerification;
