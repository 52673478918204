import * as React from "react";

const CheckboxSVG = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h24v24H0V0Z"
      fill="#FF7326"
    />
    <path
      d="M6.844 12.734 9.79 15.68l7.369-7.368"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxSVG;
