import * as React from "react";

const Facebook = (props) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={32} height={32} rx={16} fill="#1976D2" />
    <path
      d="M17.329 16.29h2.977l.468-3.043h-3.446v-1.663c0-1.265.41-2.386 1.586-2.386h1.89V6.542c-.333-.045-1.035-.144-2.36-.144-2.77 0-4.394 1.472-4.394 4.824v2.025h-2.847v3.043h2.847v8.365c.564.086 1.135.143 1.721.143.53 0 1.047-.048 1.558-.118v-8.39Z"
      fill="#fff"
    />
  </svg>
);

export default Facebook;
