import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const normalizeData = (data) =>
  data.map(
    ({ id, name, discount, dateStart, dateEnd, isActive, possibleUses, usesLeft, inquiries }) => ({
      key: id,
      promocode: name,
      amount: discount,
      dateFrom: dayjs(dateStart).format("DD.MM.YYYY"),
      dateTo: dayjs(dateEnd).format("DD.MM.YYYY"),
      status: isActive,
      quantity: possibleUses,
      remain: usesLeft,
      usedBy: inquiries,
      validity: dayjs(dateEnd).diff(dayjs(dateStart), "day")
    })
  );

const initialState = {
  data: [],
  total: 10
};

const promocodesSlice = createSlice({
  name: "promocodes",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = promocodesSlice.actions;

export default promocodesSlice.reducer;
