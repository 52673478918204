const QueryParamsParser = (queryBaseStr, qParams = {}) => {
  let qParamsArr = [];
  for (let key in qParams) {
    if ((qParams[key] !== null && qParams[key] !== "" && qParams[key] !== undefined) || qParams[key] === false) {
      qParamsArr.push(`${key}=${qParams[key]}`);
    }
  }
  if (qParamsArr.length) {
    qParamsArr.forEach((p, i) => {
      if (i === 0) {
        queryBaseStr += `?${p}`;
      } else {
        queryBaseStr += `&${p}`;
      }
    });
  }
  return queryBaseStr;
};

export default QueryParamsParser;
