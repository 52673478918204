import { backendUrl, mediaUrl, uploadsAPI } from "../api/api";

import Img from "../assets/svg/Img";
import Trash from "../assets/svg/trash";
import plusSvg from "../assets/svg/plus.svg";

const MIN_IMG_WIDTH = 600;
const MIN_IMG_HEIGHT = 600;
export const drawImgInputs = (items, setItems, t, id = null, setShowImgErrorModal, onSelectFile) => {
  return Object.keys(items).length > 1
    ? Object.values(items).map((img, ind) => {
      if (ind > 0 && img) {
        return (
          <>
            <div key={ind} className={`imgWrapper`}>
              {items["addImg" + ind].localImgUrl ? (
                <img
                  style={{height: "80px", width: "80px", objectFit: 'contain'}}
                  src={items["addImg" + ind].localImgUrl}
                />
              ) : (
                <Img id={id} />
              )}
              {/*<Img/>*/}
              {items["addImg" + ind].responseFileName ||
                items["addImg" + ind].fileStatus === "error" ? (
                <Trash
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setItems((prev) => ({
                      ...prev,
                      ["addImg" + ind]: null
                    }));
                  }}
                />
              ) : (
                ""
              )}
              <label>
                <p className="link-heading">{t("fields.photo2.heading")}</p>
                <p className={`link`}>
                  {items["addImg" + ind]?.fileName || t("fields.photo1.label")}
                </p>
                <input
                  className={`file`}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => {
                    setItems((prev) => ({
                      ...prev,
                      ["addImg" + ind]: {
                        localImgUrl: "",
                        fileName: "",
                        fileStatus: "",
                        statusText: "",
                        responseFileName: ""
                      }
                    }));
                    if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 50000000) {
                      onSelectFile(e, 'cropAdditionalImg', ind)
                    } else {
                      setShowImgErrorModal(true)
                    }
                  }}
                />
              </label>
              {items["addImg" + ind].fileStatus && (
                <div
                  className={`ml-15 ${items["addImg" + ind].fileStatus === "error"
                    ? "red"
                    : items["addImg" + ind].fileStatus === "loading"
                      ? "orange"
                      : "green"
                    }`}
                >
                  {items["addImg" + ind].statusText}
                </div>
              )}
            </div>
          </>
        );
      }
    })
    : "";
};

export const createImgState = (curItem, photoStorage, imgInputSetter) => {
  if (curItem[photoStorage].length) {
    const addImages = {
      addImg: {
        localImgUrl: `${mediaUrl}${curItem[photoStorage][0].photo}`,
        fileName: curItem[photoStorage][0].photo,
        fileStatus: "",
        statusText: "success",
        responseFileName: curItem[photoStorage][0].photo
      }
    };
    curItem[photoStorage].forEach((p, i) => {
      if (i !== 0) {
        addImages[`addImg${i}`] = {
          localImgUrl: `${mediaUrl}${curItem[photoStorage][i].photo}`,
          fileName: curItem[photoStorage][i].photo,
          fileStatus: "",
          statusText: "",
          responseFileName: curItem[photoStorage][i].photo
        };
      }
    });
    addImages[`addImg${curItem[photoStorage].length}`] = {
      localImgUrl: "",
      fileName: "",
      fileStatus: "",
      statusText: "",
      responseFileName: ""
    };
    imgInputSetter(addImages);
  } else {
    imgInputSetter({
      addImg: {
        localImgUrl: "",
        fileName: "",
        fileStatus: "",
        statusText: "",
        responseFileName: ""
      }
    });
  }
};

export const createFileState = () => {
  return [
    {
      key: "file0",
      fileName: "",
      fileStatus: "",
      statusText: "",
      responseFileName: ""
    }
  ];
};
let fileIndex = 1;
export const drawFileInputs = (files, setFiles, maxSize, t) => {
  return files.map((f, i) => (
    <div key={f.key}>
      <label
        htmlFor={`file${f.key}`}
        className="word-break mt-12 btn btn_v5"
        style={{ width: "max-content", cursor: "pointer" }}
      >
        <img className="mr-20" src={plusSvg} alt="add file" />
        {f.fileName || t("addFile", { ns: "utils" })}
      </label>
      <input
        id={`file${f.key}`}
        type="file"
        className="d-none"
        accept="video/*,image/*"
        onChange={(e) => {
          const isImageOrVideo =
            e.target.files[0].type.includes("image") || e.target.files[0].type.includes("video");
          if (!isImageOrVideo) {
            setFiles((prev) =>
              prev.map((el) =>
                el.key === f.key
                  ? {
                    ...el,
                    fileStatus: "failed",
                    statusText: t("fileTypeError", { ns: "utils" })
                  }
                  : el
              )
            );
            return false;
          }
          setFiles((prev) =>
            prev.map((el) =>
              el.key === f.key
                ? {
                  ...el,
                  fileStatus: "",
                  statusText: "",
                  fileName: e.target.files[0]?.name || "",
                  responseFileName: ""
                }
                : el
            )
          );
          if (!e.target.files[0] && i !== 0) {
            setFiles((prev) => prev.filter((el) => el.key !== f.key));
          } else if (!e.target.files[0] && i === 0) {
            setFiles((prev) => {
              return prev.filter((el, i) => i !== 0 || el.responseFileName);
            });
          } else if (e.target.files[0] && e.target.files[0].size > maxSize) {
            setFiles((prev) =>
              prev.map((el) =>
                el.key === f.key
                  ? {
                    ...el,
                    fileStatus: "error",
                    statusText: t("tooBig", { ns: "utils" }),
                    responseFileName: ""
                  }
                  : el
              )
            );
          } else if (e.target.files[0]) {
            setFiles((prev) =>
              prev.map((el) =>
                el.key === f.key
                  ? {
                    ...el,
                    fileStatus: "loading",
                    statusText: t("loading", { ns: "utils" })
                  }
                  : el
              )
            );
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("originalname", e.target.files[0].name);
            uploadsAPI
              .uploadFile(formData)
              .then((res) => {
                setFiles((prev) =>
                  prev.map((el) =>
                    el.key === f.key
                      ? {
                        ...el,
                        fileStatus: "success",
                        statusText: t("succesfullyLoaded", { ns: "utils" }),
                        responseFileName: res.data.filename
                      }
                      : el
                  )
                );
                setFiles((prev) => {
                  if (prev[prev.length - 1].responseFileName) {
                    return [
                      ...prev,
                      {
                        key: `file${++fileIndex}`,
                        fileName: "",
                        fileStatus: "",
                        statusText: "",
                        responseFileName: ""
                      }
                    ];
                  } else {
                    return [...prev];
                  }
                });
              })
              .catch(() => {
                setFiles((prev) =>
                  prev.map((el) =>
                    el.key === f.key
                      ? {
                        ...el,
                        fileStatus: "failed",
                        statusText: t("uploadFailed", { ns: "utils" })
                      }
                      : el
                  )
                );
              });
          }
        }}
      />
      {f.fileStatus && (
        <div
          className={`${f.fileStatus === "error" || f.fileStatus === "failed"
            ? "red"
            : f.fileStatus === "loading"
              ? "orange"
              : "green"
            }`}
        >
          {f.statusText}
        </div>
      )}
    </div>
  ));
};
