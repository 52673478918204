import { createSlice } from "@reduxjs/toolkit";

const statuses = {
  1: "Заявка не рассмотрена",
  2: "Запрошен счет на оплату",
  3: "Запрошена ссылка на оплату",
  4: "Счет на оплату выставлен, ожидает выполнения",
  5: "Ссылка на оплату отправлена, ожидает выполнения",
  6: "Произведена оплата банковской картой",
  7: "Произведена оплата по выставленному счету",
  8: "Произведена оплата по ссылке",
  9: "Заявка отменена",
  10: "Запрошен возврат",
  11: "Произведен возврат",
  12: "Начата оплата банковской картой"
};

const normalizeData = (data) =>
  data.map(({ id, name, cost, createdAt, promoCode, status, fileUrl, statusId, amountCredits }) => ({
    key: id,
    service: name ?? "",
    price: cost ?? "",
    sentTime: createdAt ?? "",
    usedPromocode: promoCode?.name ?? "",
    status: status?.name ?? "",
    forPayment: fileUrl ?? "",
    canCancel: [1, 2, 3, 4, 5].includes(statusId),
    amountCredits: amountCredits
  }));

const initialState = {
  data: [],
  total: 10
};

const paymentApplicationsSlice = createSlice({
  name: "paymentApplications",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = paymentApplicationsSlice.actions;

export default paymentApplicationsSlice.reducer;
