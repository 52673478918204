/* eslint-disable react/prop-types */
import React from "react";
import s from "./KeyIndicators.module.less";

const KeyIndicators = ({ svg, title, value }) => {
  return (
    <div className={value === null || !value ? s.wrapper_none : s.wrapper}>
      <div>{svg}</div>
      <div className={s.title}>{title}</div>
      <div className={s.value}>{value}</div>
    </div>
  );
};

export default KeyIndicators;
