import * as React from "react"

const Message = (props) => (
  <svg
    width={17}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.735 8.143v2.833c0 .184-.007.361-.028.531-.163 1.913-1.29 2.862-3.365 2.862H9.06a.57.57 0 0 0-.453.227l-.85 1.133c-.376.503-.985.503-1.36 0l-.85-1.133a.656.656 0 0 0-.454-.227H4.81c-2.26 0-3.393-.56-3.393-3.393V8.143c0-2.076.956-3.202 2.862-3.365.17-.02.347-.028.53-.028h4.534c2.26 0 3.393 1.133 3.393 3.393Z"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.57 5.307V8.14c0 2.083-.956 3.202-2.861 3.365.02-.17.028-.347.028-.531V8.14c0-2.26-1.133-3.393-3.393-3.393H4.81c-.185 0-.362.007-.532.029.163-1.906 1.29-2.862 3.365-2.862h4.533c2.26 0 3.393 1.133 3.393 3.393Z"
      stroke="#fff"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.56 9.883h.007m-2.486 0h.007m-2.486 0h.007"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Message