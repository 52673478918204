import { companyAPI } from "../api/api";
import { createSlice } from "@reduxjs/toolkit";

const companyId = {
  annualTurnover: { id: "", name: "" },
  businessCompanyName: "",
  city: "",
  companyBusinessTypes: [],
  companyKeywords: [],
  companyName: "",
  companyType: "",
  country: "",
  description: "",
  facebook: null,
  houseNumber: "",
  id: '',
  index: "",
  isVerified: null,
  keywords: "",
  linkedin: null,
  logo: "",
  mainImage: "",
  mainMarkets: [],
  membershipAssociations: "",
  moderationCredits: null,
  numberEmployees: {},
  percentageExportsTurnover: {},
  percentageImportsTurnover: {},
  phone: "",
  phone2: null,
  products: [],
  region: "",
  registrationNumber: "",
  status: "",
  street: "",
  tariffId: 1,
  twitter: null,
  type: 1,
  updatedAt: "",
  userId: 1,
  video: "",
  website: "",
  yearFoundation: "",
  youtube: null,
  companyPhotos: [],
  companyFiles: [],
  services: [],
  user: {selfBlockedAt: null, adminBlockedAt: null}
}

const initialState = {
  companyId,
  save: false
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
    setSave(state, action) {
      state.save = action.payload;
    },
    setCompanyInit(state) {
      state.companyId = companyId;
    },
  }
});

export const { setCompanyId, setSave, setCompanyInit } = companySlice.actions;

export default companySlice.reducer;

export const fetchCompanyId = (id, moderations) => (dispatch) => {
  companyAPI
    .getCompanyId(id)
    .then((res) => {
      if (res.data.moderations && moderations) {
        dispatch(setCompanyId(Object.assign(res.data, res.data.moderations)));
      } else {
        dispatch(setCompanyId(res.data));
      }
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
