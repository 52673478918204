import i18n from "i18next";
import Backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        mode: "no-cors"
      }
    },
    ns: [],
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    returnObjects: true
  });

export default i18n;
