import * as React from "react";

const BlackArrow = (props) => (
  <svg width={9} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.992.501.995 7.494H8.99L4.992.5Z"
      fill={props.color ? props.color : "#222"}
    />
  </svg>
);

export default BlackArrow;
