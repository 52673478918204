/* eslint-disable react/prop-types */

import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { setFile, setFileName } from "../../store/changeCompanyProfileSlice";
import { useDispatch, useSelector } from "react-redux";

import TextArea from "antd/es/input/TextArea";
import Trash from "../../assets/svg/trash";
import axios from "axios";
import { domain } from "../../api/api";
import plusSvg from "../../assets/svg/plus.svg";
import s from "./ChangeCompanyProfile.module.less";

const AddFiles = ({ t, id, validateInput, OnlyLatinLettersTextInput, form, OnlyLatinLettersSuccessCallback = null }) => {
  const { files, filesName } = useSelector((state) => state.changeCompanyProfile.customProfile);
  const dispatch = useDispatch();

  const changeState = (num, payLoad) => {
    if (num === 1) {
      const newFile = files.map((item, index) =>
        index === id
          ? {
              filename: payLoad,
              description: item.description
            }
          : item
      );
      dispatch(setFile(newFile));
    } else if (num === 2) {
        form.setFields([
            {
                name: `descriptionFile${id}`,
                errors: [],
            },
        ]);
      const newFile = files.map((item, index) =>
        index === id ? { filename: item.filename, description: payLoad } : item
      );
      dispatch(setFile(newFile));
    } else if (num === 3) {
      const newFile = filesName.map((item, index) =>
        index === id
          ? {
              id: id,
              name: payLoad.file,
              loader: payLoad.loader
            }
          : item
      );
      dispatch(setFileName(newFile));
    } else if (num === 4) {
      const newFile = files.filter((item, index) => index !== id && item);
      const newFileName = filesName.filter((item, index) => index !== id && item);
      document.getElementById(`fileInput${id}`).value = ''
      dispatch(setFile(newFile));
      dispatch(setFileName(newFileName));
    }
  };

  const handleFileSelect = async (e) => {
    changeState(3, { loader: t("loading") });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("originalname", e.target.files[0].name);
    try {
      const res = await axios({
        method: "post",
        url: `${domain}api/upload/file`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });
      if (res.status === 200) {
          console.log(res.data.filename,  e.target.files[0].name)
        let payload = { file: e.target.files[0].name, loader: t("loaded") };
        changeState(1, res.data.filename + "|" + e.target.files[0].name);
        changeState(3, payload);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // console.log(error.response);
      }
    }
  };

  useEffect(() => {
    if (files[files.length - 1].filename) {
      dispatch(setFile([...files, { filename: "", description: "" }]));
      dispatch(setFileName([...filesName, { name: "", id: "" }]));
    }
  }, [dispatch, files]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <label
          htmlFor={`fileInput${id}`}
          className="mt-12 btn btn_v5"
          style={{ width: "max-content", cursor: "pointer", minHeight: '64px' }}
        >
          {!files[id].filename && <img src={plusSvg} className="mr-20"/>}
          {files[id].filename
            ? filesName[id].name &&
              filesName[id].name.slice(0, 12) +
                "..." +
                filesName[id].name.slice(filesName[id].name.length - 4)
            : t("addFile")}
          <input type="file" id={`fileInput${id}`} className="d-none" onChange={handleFileSelect} disabled={files[id].filename}/>
        </label>
        {files[id].filename && (
          <div className={s.trash}>
            <Trash style={{ cursor: "pointer" }} onClick={() => changeState(4)} />
          </div>
        )}
      </div>
      <div style={filesName[id].loader === t("loading") ? { color: "red" } : { color: "green" }}>
        {filesName[id].loader}
      </div>

      <div className={s.descriptionFile}>{t("descriptionFile")}{files[id].filename && '*'}</div>
      <Form.Item
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        name={`descriptionFile${id}`}
        className={s.textArea}
        initialValue={files[id].description}
        // onChange={(e) => {
        //   changeState(2, validateInput(e.target.value))
        //   OnlyLatinLettersTextInput(e.target.value, `descriptionFile${id}`, form, true)
        // }}
        rules={[
          {
            validator: (_, value) => {
              if (_.field === `descriptionFile${files.length - 1}` && !value) {
                return Promise.resolve();
              } else if (_.field !== `descriptionFile${files.length - 1}` && !value) {
                return Promise.reject(t("fileError"));
              } else if (value.trim().length < 1) {
                  return Promise.reject(t("fileError"));
              } else {
                return Promise.resolve();
              }
            }
          }
        ]}
      >
        <TextArea
          row={4}
          showCount
          autoSize={true}
          maxLength={255}
          size="large"
          style={{ maxWidth: "360px" }}
          onChange={(e) => {
            changeState(2, validateInput(e.target.value.replace(/\s+/g, ' ')));
            OnlyLatinLettersTextInput(e.target.value, `descriptionFile${id}`, form, true, null, null, false, OnlyLatinLettersSuccessCallback);
          }}
        />
      </Form.Item>
    </div>
  );
};

export default AddFiles;
