import { showWorkTime } from "./timeDate";
import showSVG from "Assets/svg/show_table.svg";
import hideSVG from "Assets/svg/hide_table.svg";
import { Tooltip } from "antd";

export const requestAmount = [
  {
    label: 10,
    value: 10
  },
  {
    label: 25,
    value: 25
  },
  {
    label: 50,
    value: 50
  },
  {
    label: 100,
    value: 100
  }
];

const compareTypes = {
  str: (prop) => (a, b) => a[prop].localeCompare(b[prop]),
  num: (prop) => (a, b) => a[prop] - b[prop],
  date: (prop) => (a, b) => Date.parse(a[prop]) - Date.parse(b[prop]),
  bool: (prop) => (a, b) => a[prop] > b[prop]
};

const compareTypesObj = {
  str: (prop1, prop2) => (a, b) => a[prop1][prop2].localeCompare(b[prop1][prop2]),
  num: (prop1, prop2) => (a, b) => a[prop1][prop2] - b[prop1][prop2],
  date: (prop1, prop2) => (a, b) => Date.parse(a[prop1][prop2]) - Date.parse(b[prop1][prop2]),
  bool: (prop1, prop2) => (a, b) => a[prop1][prop2] > b[prop1][prop2]
};

export const makeColumn = (t, type, width, sortColumn) => ({
  title: typeof t === "function" ? t(type) : null,
  dataIndex: type,
  key: type,
  ...[""].reduce((obj) => {
    if (width) obj.width = width;
    if (sortColumn) {
      obj.sorter = true;
      obj.sortColumn = sortColumn;
      obj.sortDirections = ["ascend", "descend", "ascend"];
    }
    return obj;
  }, {})
});

export const makeColumnItem = (t, type, width, sorter, defaultSortOrder) => ({
  title: t(type),
  dataIndex: type,
  key: type,
  defaultSortOrder: defaultSortOrder,
  ...[""].reduce((obj) => {
    if (width) obj.width = width;
    if (sorter) {
      obj.sortDirections = ["ascend", "descend", "ascend"];
      obj.sorter = compareTypes[sorter](type);
    }
    return obj;
  }, {})
});

export const columnItem = ({ t, type, width, sorter, sorterString, sorterStringSub }) => ({
  title: t(type),
  dataIndex: type,
  key: type,
  ...[""].reduce((obj) => {
    if (width) obj.width = width;
    if (sorter) {
      obj.sortDirections = ["ascend", "descend", "ascend"];
      if (sorterStringSub) {
        obj.sorter = compareTypesObj[sorter](sorterString, sorterStringSub);
      } else {
        obj.sorter = compareTypes[sorter](sorterString ? sorterString : type);
      }
    }
    return obj;
  }, {})
});

export const hideText = (data) => data || "*****";

export const showTime = (date, tz) => (
  <span style={{ whiteSpace: "break-spaces" }}>{showWorkTime(date, tz)}</span>
);

export const sortHandler =
  (setSorting) =>
  (a, b, { column, order }) => {
    setSorting({
      sortColumn: column.sortColumn,
      sortDirection: order === "ascend" ? "ASC" : "DESC"
    });
  };

export const changeColumnVisibility = (setState, prop, value) => {
  setState((prev) => ({ ...prev, [prop]: value }));
};

export const titleWithHide = ({ condition, title, showFunc, hideFunc }) => {
  return condition ? (
    <>
      {" "}
      {title} <img src={hideSVG} style={{ cursor: "pointer" }} onClick={hideFunc} />{" "}
    </>
  ) : (
    <Tooltip placement="top" title={title}>
      <img src={showSVG} style={{ cursor: "pointer" }} onClick={showFunc} />
    </Tooltip>
  );
};
