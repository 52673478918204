import * as React from "react";

const NotVerified = (props) => (
  <svg width={16} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m5.5 6.5 5 5m-5 0 5-5-5 5Z"
      stroke="#D62D30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M.5 2.5A2.5 2.5 0 0 1 3 0h10a2.5 2.5 0 0 1 2.5 2.5v16.875a.625.625 0 0 1-.971.52L8 16.376l-6.529 3.519a.625.625 0 0 1-.971-.52V2.5ZM3 1.25A1.25 1.25 0 0 0 1.75 2.5v15.707l5.904-3.102a.625.625 0 0 1 .692 0l5.904 3.102V2.5A1.25 1.25 0 0 0 13 1.25H3Z"
      fill="#D62D30"
    />
  </svg>
);

export default NotVerified;
