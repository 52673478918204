import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useOutletContext } from "react-router-dom";

import { companyAPI, mediaUrl, notesAPi, profileAPI } from "../../api/api";
import { Note } from "../../components/Dialog/Note";
import { NoteInput } from "../../components/Dialog/NoteInput";
import DialogsWindow from "../../components/DialogsWindow/DialogsWindow";
import { GoBackHeader } from "../../components/GoBackHeader/GoBackHeader";
import Pagination from "../../components/Pagination/Pagination";
import { convertDate } from "../../helpers/timeFormat";
import styles from "./Dialog.module.less";

const Dialog = () => {
  const { t } = useTranslation(["FS20-Dialog", "FA2-UsersList"]);
  const tCountries = useTranslation("Countries");
  const FS19 = useTranslation("FS19-Dialogs");
  const [companyData, setCompanyData] = useState({});
  const { setTitle } = useOutletContext();
  const [companyId, setCompanyId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const location = useLocation();
  const targetCompanyId = location.pathname.split("/")[3];
  const [showMoreDesc, setShowMoreDesc] = useState(false);
  const { isAdmin } = useSelector((state) => state.auth);
  const { state } = useLocation();
  const [noteText, setNoteText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const getNotes = async () => {
    if (companyId && !state?.chatWithAdmin) {
      const notesData = await notesAPi
        .getNotes(companyId, targetCompanyId, currentPage)
        .then((res) => res.data);
      setCompanyData((prev) => ({ ...prev, notes: notesData.items }));
      setTotalPages(notesData.total);
    }
  };

  useEffect(() => {
    setTitle(FS19.t("title"));
  }, [t, setTitle]);

  useEffect(() => {
    if (state?.chatWithAdmin || isAdmin) return;
    const getAllData = async () => {
      await companyAPI.getCompanyId(targetCompanyId).then((res) => setCompanyData(res.data));
      await profileAPI.getCompany().then((res) => setCompanyId(res.data.company.id));
    };
    getAllData();
  }, []);

  useEffect(() => {
    getNotes();
  }, [companyId, currentPage]);

  const handleNoteChange = (text) => {
    setNoteText(text);
  };

  const handleNoteDelete = async () => {
    await notesAPi.deleteNote(openModal);
    toggleDeletionModel();
    getNotes();
  };

  const toggleDeletionModel = (id) => {
    setOpenModal((prev) => (prev ? false : id));
  };

  const handleNoteEdit = async (id, text) => {
    if (!text) return setEditMode(false);
    await notesAPi.updateNote(id, text);
    setEditMode(false);
    getNotes();
  };

  const handleNoteMode = (id) => {
    setEditMode((prev) => (prev ? false : id));
  };

  const handleSubmitNote = async () => {
    if (!noteText) return;
    try {
      await notesAPi.createNote(targetCompanyId, noteText);
      setNoteText("");
      getNotes();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className={styles.wrapper}>
      <GoBackHeader companyId={companyId} text={t("GoBackTitle")} />
      {openModal && (
        <Modal
          visible={openModal}
          onCancel={() => setOpenModal(false)}
          footer={[
            <div className={styles.modalButtons} key={1}>
              <Button key="ok" className="btn btn_v8" onClick={() => handleNoteDelete()}>
                {t("yes", { ns: "FA2-UsersList" })}
              </Button>
              <Button key="no" className="btn btn_v2" onClick={() => setOpenModal(false)}>
                {t("no", { ns: "FA2-UsersList" })}
              </Button>
            </div>
          ]}
        >
          <p key={"p"} style={{ marginTop: "15px" }}>
            {t("areUSureDelete")}
          </p>
        </Modal>
      )}
      <div className={styles.sections}>
        <section className={styles.section}>
          <div className={styles.companyDetails}>
            <h2>
              {state?.chatWithAdmin ? (
                `${t("techSupport")} `
              ) : (
                <>
                  {`${t("company")} `}
                  {(companyData?.type === 0 || companyData?.type === 2) && (
                    <Link to={`/company-profile/${companyData.id}`}>
                      <span className={"orange"}>{companyData?.companyName}</span>
                    </Link>
                  )}

                  {companyData?.type === 1 && <span className="orange">{companyData?.companyName}</span>}
                </>
              )}
            </h2>
            {state?.chatWithAdmin ? (
              <img
                src={`https://b2b-digital-platform.sector.show/images/logo.png`}
                alt="tech support logo"
                className={styles.companyLogo}
              />
            ) : (
              companyData?.logo && (
                <img
                  src={`${mediaUrl}${companyData?.logo}`}
                  alt="company logo"
                  className={styles.companyLogo}
                />
              )
            )}
            {!state?.chatWithAdmin && (
              <div className={styles.details}>
                <div className={styles.detail}>
                  <p style={{ fontWeight: 500 }}>{t("country")}</p>
                  <p>{tCountries.t(companyData.country?.name)}</p>
                </div>
                <div className={styles.detail}>
                  <p style={{ fontWeight: 500 }}>{t("numberOfEmployees")}</p>
                  <p>{companyData.numberEmployees?.name}</p>
                </div>
                <div className={styles.detail}>
                  <p style={{ fontWeight: 500 }}>{t("description")}</p>
                  <p>
                    {companyData?.description === undefined
                      ? ""
                      : companyData?.description.length < 141
                      ? companyData?.description
                      : !showMoreDesc
                      ? `${companyData?.description.slice(0, 140)}`
                      : companyData?.description}
                    {companyData?.description?.length >= 141 && !showMoreDesc && (
                      <span style={{ cursor: "pointer" }} onClick={() => setShowMoreDesc(true)}>
                        ...
                      </span>
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
          <hr className={styles.divider} />
          {companyId && !state?.chatWithAdmin && (
            <div className={styles.companyNotes}>
              <h2>{t("notes")}</h2>
              <NoteInput
                t={t}
                noteText={noteText}
                handleNoteChange={handleNoteChange}
                handleSubmitNote={handleSubmitNote}
              />
              <div className={styles.notes}>
                {companyData.notes?.map((note, index) => (
                  <Note
                    editMode={editMode}
                    handleNoteEdit={handleNoteEdit}
                    handleNoteMode={handleNoteMode}
                    handleNoteDelete={toggleDeletionModel}
                    key={`note+${index}`}
                    text={note.text}
                    id={note.id}
                    date={convertDate(note.createdAt)}
                  />
                ))}
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  setCurrent={setCurrentPage}
                  total={totalPages}
                  style={{ justifyContent: "center" }}
                />
              </div>
            </div>
          )}
        </section>
        <section className={styles.sectionWindow}>
          <h2>{t("dialog")}</h2>
          <DialogsWindow
            connectToId={+targetCompanyId}
            roomId={state?.roomId || null}
            side={state?.side || null}
            entityId={state?.entityId || null}
            entityType={state?.entityType || null}
          />
        </section>
      </div>
    </section>
  );
};

export default Dialog;
