
import { createSlice } from "@reduxjs/toolkit";
import { settingsAPI } from "../api/api";
import { setIsLoading } from "./authSlice";


const initialState = {
  settings: {
    request: false,
    meeting: false,
    changeMeeting: false,
    message: false,
    m10: false,
    m30: false,
    h1: false,
    h2: false,
    h5: false,
    h10: false,
    d1: false,
    d3: false
  }
};

const settingSlice = createSlice({

  name: "settings",
  initialState,
  reducers: {
    setSettings(state, action) {
      state.settings = action.payload;
    }
  }
});

export const { setSettings } = settingSlice.actions;
export default settingSlice.reducer;

export const fetchSettings = () => (dispatch) => {
  dispatch(setIsLoading(true));
  settingsAPI
    .getSettings()
    .then((res) => {
      // console.log(res.data.settings);
      dispatch(setSettings(res.data.settings));
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const updateSettings =
  (
    request,
    meeting,
    changeMeeting,
    message,
    m10,
    m30,
    h1,
    h2,
    h5,
    h10,
    d1,
    d3,
    setModalIsVisible,
    setSaveError
  ) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    settingsAPI
      .changeSettings(request, meeting, changeMeeting, message, m10, m30, h1, h2, h5, h10, d1, d3)
      .then((res) => {
        // console.log(res);
        dispatch(
          setSettings({
            request,
            meeting,
            changeMeeting,
            message,
            m10,
            m30,
            h1,
            h2,
            h5,
            h10,
            d1,
            d3
          })
        );
      })
      .catch((e) => {
        setSaveError(true);
        console.log(e);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
        setModalIsVisible(true);
      });
  };

