import { catalogAPI, companyAPI, productsAPI, promotionAPI, serviceAPI } from "../api/api";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topTenCategories: [],
  categories: [],
  sevenDaysTop: [],
  allCompany: [],
  topCompany: [],
  topCompanyTotal: 0,
  subcategory: [],
  companyByIdInSubcategory: [],
  companyCategories: [],
  promotionItems: [],
  total: "",
  companyByIdInSubcategoryTotal: "",
  promotionTotal: "",
  serviceSubcategory: [],
  serviceSubcategoryTotal: "",
  serviceCategories: [],
  servicePromotionTotal: "",
  servicePromotionItems: [],
  searchingProductType: 0,
  totalAllCompany: 0,
  loader: false
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    addTopTen(state, action) {
      state.topTenCategories = action.payload;
    },
    addCategories(state, action) {
      state.categories = action.payload;
    },
    setSevenDaysTop(state, action) {
      state.sevenDaysTop = action.payload;
    },
    setAllCompany(state, action) {
      state.allCompany = action.payload;
    },
    setTopCompany(state, action) {
      state.topCompany = action.payload;
    },
    setTopCompanyTotal(state, action) {
      state.topCompanyTotal = action.payload;
    },
    setSubcategoryById(state, action) {
      state.subcategory = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setCompanyByIdInSubcategory(state, action) {
      state.companyByIdInSubcategory = action.payload;
    },
    setCompanyByIdInSubcategoryTotal(state, action) {
      state.companyByIdInSubcategoryTotal = action.payload;
    },
    setCompanyCategories(state, action) {
      state.companyCategories = action.payload;
    },
    setPromotionItems(state, action) {
      state.promotionItems = action.payload;
    },
    setPromotionTotal(state, action) {
      state.promotionTotal = action.payload;
    },
    setServiceSubcategoryById(state, action) {
      state.serviceSubcategory = action.payload;
    },
    setServiceTotal(state, action) {
      state.serviceSubcategoryTotal = action.payload;
    },
    setServiceCategories(state, action) {
      state.serviceCategories = action.payload;
    },
    setServicePromotionTotal(state, action) {
      state.servicePromotionTotal = action.payload;
    },
    setServicePromotionItems(state, action) {
      state.servicePromotionItems = action.payload;
    },
    setSearchingProductType(state, action) {
      state.searchingProductType = action.payload;
    },
    setTotalAllCompany(state, action) {
      state.totalAllCompany = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
  }
});

export const {
  addTopTen,
  setCompanyCategories,
  addCategories,
  setSevenDaysTop,
  setAllCompany,
  setTopCompany,
  setTopCompanyTotal,
  setSubcategoryById,
  setTotal,
  setCompanyByIdInSubcategory,
  setCompanyByIdInSubcategoryTotal,
  setPromotionTotal,
  setPromotionItems,
  setServiceSubcategoryById,
  setServiceTotal,
  setServiceCategories,
  setServicePromotionTotal,
  setServicePromotionItems,
  setSearchingProductType,
  setTotalAllCompany,
  setLoader
} = catalogSlice.actions;

export default catalogSlice.reducer;

export const fetchTopTen = () => (dispatch) => {
  catalogAPI
    .getTopTenCategory()
    .then((res) => {
      dispatch(addTopTen(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const fetchCategories = () => (dispatch) => {
  catalogAPI
    .getCategories()
    .then((res) => {
      dispatch(addCategories(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const fetchSevenDaysTop = () => (dispatch) => {
  catalogAPI
    .getSevenDaysTop()
    .then((res) => {
      dispatch(setSevenDaysTop(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const fetchAllCompany = (limit, page) => (dispatch) => {
  catalogAPI
    .getAllCompany(limit, page)
    .then((res) => {
      dispatch(setAllCompany(res.data.items));
      dispatch(setTotalAllCompany(res.data.total));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
export const fetchAllCompanyById = (categoryId, limit, page, entityType) => (dispatch) => {
  catalogAPI
    .getAllCompanyById(categoryId, limit, page, entityType)
    .then((res) => {
      dispatch(setAllCompany(res.data.items));
      dispatch(setTotalAllCompany(res.data.total));
      // console.log('1123', res.data)
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const fetchTopCompany = (pageSize, page) => (dispatch) => {
  catalogAPI
    .getTopCompany(pageSize, page)
    .then((res) => {
      dispatch(setTopCompany(res.data.items));
      dispatch(setTopCompanyTotal(res.data.total))
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
export const fetchSubcategoryById = (subcategoryId, limit, pages) => (dispatch) => {
  dispatch(setLoader(true))
  productsAPI
    .getSubcategoryById(subcategoryId, limit, pages)
    .then((res) => {
      dispatch(setSubcategoryById(res.data.items));
      dispatch(setTotal(res.data.total));
    })
    .catch((e) => {
      // console.log(e.response);
    }).finally(() => dispatch(setLoader(false)))
};
export const fetchServiceSubcategoryById = (subcategoryId, limit, pages) => (dispatch) => {
  dispatch(setLoader(true))
  serviceAPI
    .getServiceSubcategoryById(subcategoryId, limit, pages)
    .then((res) => {
      dispatch(setServiceSubcategoryById(res.data.items));
      dispatch(setServiceTotal(res.data.total));
    })
    .catch((e) => {
      // console.log(e.response);
    }).finally(() => dispatch(setLoader(false)))
};

export const fetchCompanyByIdInSubcategory = (id, limit, pages, entityType) => (dispatch) => {
  companyAPI
    .getCompanyByIdInSubcategory(id, limit, pages, entityType)
    .then((res) => {
      dispatch(setCompanyByIdInSubcategory(res.data.items));
      dispatch(setCompanyByIdInSubcategoryTotal(res.data.total));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
export const fetchCompanyCategories = (companyId, setVisible, subcategoryIds) => (dispatch) => {
  productsAPI
    .getCompanyCategories(companyId, subcategoryIds)
    .then((res) => {
      dispatch(setCompanyCategories(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    }).finally(() => {
      if (setVisible) {
        setVisible(true);
      }
    })

};
export const fetchServiceCategoriesByCompany = (companyId, subcategoryIds) => (dispatch) => {
  serviceAPI
    .getServiceCategoriesByCompany(companyId, subcategoryIds)
    .then((res) => {
      dispatch(setServiceCategories(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};
export const fetchPromotionProduct = (id, page, limit) => (dispatch) => {
  promotionAPI
    .getPromotionProduct(id, page, limit)
    .then((res) => {
      dispatch(setPromotionTotal(res.data.total));
      dispatch(setPromotionItems(res.data.items));
    })
    .catch((e) => {
      // console.log(e);
    });
};
export const fetchServicePromotion = (id, page, limit) => (dispatch) => {
  promotionAPI
    .getServicePromotion(id, page, limit)
    .then((res) => {
      dispatch(setServicePromotionTotal(res.data.total));
      dispatch(setServicePromotionItems(res.data.items));
    })
    .catch((e) => {
      // console.log(e);
    });
};
