import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { authAPI } from "../../api/api";
import { setIsLoading } from "../../store/authSlice";
import { useTranslation } from "react-i18next";
import styles from "./email-confirmation.module.less";

export const EmailConfirmationPage = () => {
  const { t } = useTranslation(["EmailConfirmation", "CancelMeeting"]);
  const { token } = useParams();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    authAPI
      .confirmEmail(token)
      .then((res) => {
        if (res.status === 200 && res.data.isSendPassword) {
          setModalMessage(t("responses.successWithInfo"));
          return setModalIsVisible(true);
        } else if (res.status === 200) {
          setModalMessage("success");
          setModalIsVisible(true);
        }
      })
      .catch((e) => {
        // console.log(e.response);
        if (e.response.data.message === "AUTH_CONFIRM_EMAIL_NOT_VALID_TOKEN") {
          setModalMessage(t("responses.invalidToken"));
        } else if (e.response.data.message === "AUTH_CONFIRM_EMAIL_USER_IS_NOT_FOUND") {
          setModalMessage(t("responses.userNotFound"));
        } else if (e.response.data.message === "AUTH_CONFIRM_EMAIL_ALREADY_CONFIRMED") {
          setModalMessage(t("responses.emailIsConfirmed"));
        } else if (e.response.data.message === "FEEDBACK_GENERAL_ALREADY_CONFIRMED") {
          setModalMessage(t("responses.feedbackConfirmed"));
        } else if (e.response.data.message === "MATCHMAKING_ALREADY_CONFIRMED") {
          setModalMessage(t("responses.matchmakingConfirmed"));
        } else if (e.response.data.message === "FEEDBACK_MAIN_ALREADY_CONFIRMED") {
          setModalMessage(t("responses.emailIsConfirmed"));
        } else {
          setModalMessage(t("responses.error"));
        }
        setModalIsVisible(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onOk={() => {
            setModalIsVisible(false);
            navigate("/login");
          }}
          onCancel={() => {
            setModalIsVisible(false);
            navigate("/login");
          }}
          footer={[
            <Button
              key="ok"
              className="btn btn_v1"
              onClick={() => {
                setModalIsVisible(false);
                navigate("/login");
              }}
            >
              {t("modal.button")}
            </Button>
          ]}
        >
          {modalMessage === "success" && (
            <div className={styles.success_modal}>
              <h5>{t("dearUser", { ns: "CancelMeeting" })}</h5>
              <p>{t("modal.email_confirmed")}</p>
              <p>{t("modal.thank")}</p>
            </div>
          )}
          {modalMessage !== "success" && <p>{modalMessage}</p>}
        </Modal>
      ) : null}
    </>
  );
};
