import { Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { AllTechIssuesTable } from "../../components/AllTechIssuesTable/AllTechIssuesTable";
import s from "../../pages/FS38-List-of-complaints/ListOfComplaints.module.less";

export const AllTechIssue = () => {
  const { t } = useTranslation("FS40-all-tech-issues");
  const { setTitle } = useOutletContext();

  useEffect(() => {
    setTitle(t("title"));
  }, [setTitle, t]);
  return (
    <section className={s.wrapper}>
      <Typography.Paragraph style={{ fontSize: 22, fontWeight: 500 }}>
        {t("about_page")}
      </Typography.Paragraph>
      <div className={s.complaint_table_container}>
        <AllTechIssuesTable />
      </div>
    </section>
  );
};
