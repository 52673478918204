import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { Footer } from "Components/footer/Footer";
import { Header } from "Components/header/Header";
import Loader from "Components/Loader/Loader";
import Sider from "Components/Sider/Sider";
import { setDocumentTitle } from "Helpers/setDocumentTitle";

const { Content } = Layout;

const Account = () => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);

  return (
    <Layout>
      <Header h1={title}/>
      <Layout style={{ flexDirection: "row" }}>
        <Sider />
        <Suspense fallback={<Loader style={{ width: "100%", height: "auto" }} />}>
          <Content style={{ padding: "32px" }} id={'main'}>
            <Outlet context={{ setTitle }} />
          </Content>
        </Suspense>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default Account;
