import s from "./CheckboxV1.module.less";
import PropTypes from "prop-types";
const CheckboxV1 = ({ style, visible, onClick }) => {
  return (
    <span style={{ ...style }} onClick={onClick} className={s.checkbox}>
      <svg
        className={`${s.flag} ${visible ? s.checked : ""}`}
        width="7"
        height="5"
        viewBox="0 0 7 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.53906L3.50042 4.0324L6.00083 1.53906"
          stroke="#FF7326"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
CheckboxV1.propTypes = {
  style: PropTypes.object,
  visible: PropTypes.bool,
  onClick: PropTypes.func
};
export default CheckboxV1;
