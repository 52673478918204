const SentMsg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.97065 4.96809C9.11151 4.83398 9.29897 4.75984 9.49346 4.76131C9.68796 4.76278 9.87427 4.83975 10.0131 4.97598C10.1519 5.1122 10.2324 5.29703 10.2375 5.49146C10.2427 5.68589 10.1721 5.87472 10.0406 6.01809L6.05065 11.0081C5.98204 11.082 5.89923 11.1413 5.80718 11.1825C5.71513 11.2236 5.61572 11.2458 5.5149 11.2477C5.41408 11.2495 5.31392 11.2311 5.2204 11.1933C5.12689 11.1556 5.04194 11.0994 4.97065 11.0281L2.32465 8.38209C2.25096 8.31343 2.19186 8.23063 2.15086 8.13863C2.10987 8.04663 2.08783 7.94732 2.08605 7.84661C2.08428 7.74591 2.1028 7.64588 2.14052 7.55249C2.17824 7.4591 2.23439 7.37427 2.30561 7.30305C2.37683 7.23183 2.46166 7.17569 2.55505 7.13797C2.64844 7.10025 2.74847 7.08172 2.84917 7.0835C2.94987 7.08527 3.04918 7.10732 3.14118 7.14831C3.23318 7.1893 3.31598 7.2484 3.38465 7.32209L5.47865 9.41509L8.95065 4.99009C8.95687 4.98237 8.96355 4.97502 8.97065 4.96809ZM8.05065 10.1081L8.97065 11.0281C9.04193 11.0992 9.12681 11.1553 9.22023 11.1929C9.31365 11.2306 9.41369 11.249 9.51439 11.2471C9.61509 11.2453 9.71438 11.2232 9.80634 11.1821C9.8983 11.141 9.98105 11.0818 10.0496 11.0081L14.0416 6.01809C14.1134 5.94729 14.1701 5.86277 14.2084 5.76957C14.2467 5.67636 14.2658 5.5764 14.2647 5.47563C14.2635 5.37487 14.242 5.27537 14.2015 5.18309C14.1611 5.0908 14.1024 5.00763 14.0291 4.93852C13.9557 4.86941 13.8692 4.81579 13.7747 4.78087C13.6801 4.74594 13.5796 4.73042 13.4789 4.73523C13.3782 4.74004 13.2796 4.76509 13.1888 4.80888C13.0981 4.85267 13.0171 4.9143 12.9506 4.99009L9.47765 9.41509L8.99265 8.92909L8.04965 10.1081H8.05065Z"
        fill="#B0AEB4"
      />
    </svg>
  );
};

export default SentMsg;
