import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { complaintsAPI, profileAPI } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";

import CommentsWindow from "../../components/CommentsWindow/CommentsWindow";
import DropdownIcon from "../../components/DropdownIcon/DropdownIcon";
import PropTypes from "prop-types";
import SendEmailToUser from "../FA40-SendEmailToUser/SendEmailToUser";
import { complaintById } from "../../store/complaintsSlice";
import { getAllComplaintStatuses } from "../../store/resourcesSlice";
import s from "./ComplaintProcess.module.less";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertDate, convertTime } from "../../helpers/timeFormat";

export const ComplaintProcess = () => {
  const initialState = {
    statuses: false
  };
  const { t } = useTranslation([
    "FA28-ComplaintProcess",
    "Statuses",
    "ComplaintTopics",
    "FS3-MatchMaking",
    "FA36-TechnicalProblems",
    "FA27-Complaint",
    "FA2-UsersList"
  ]);
  const { setTitle } = useOutletContext();
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.complaints.complaintById);
  const statuses = useSelector((state) => state.resources.complaintStatuses);
  const [openState, setOpenState] = useState(initialState);
  const [complaintId, setComplaintId] = useState(searched?.status?.id);
  const { id2 } = useParams();
  const [adminAnswer, setAdminAnswer] = useState("");

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [sendEmailVisible, setSendEmailVisible] = useState(null);
  const [deactiveModalOpen, setDeactiveModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    setTitle(t("title"));
    dispatch(getAllComplaintStatuses());
  }, [setTitle, t]);

  useEffect(() => {
    dispatch(complaintById(id2));
  }, [id2]);

  useEffect(() => {
    setComplaintId(searched?.status?.id);
  }, [searched]);

  useEffect(() => {
    form.setFieldsValue({
      adminAnswer: searched.answer
    });
  }, [searched]);

  const profileDeactive = (id) => {
    profileAPI
      .profileDeactive(id)
      .then(() => {
        setModalIsVisible(true);
        setModalMessage(t("deactived"));
      })
      .catch((error) => {
        if (error.response.data.message === "USER_IS_ALREADY_BLOCKED") {
          setModalIsVisible(true);
          setModalMessage(t("deactived_error"));
        } else {
          setModalIsVisible(true);
          setModalMessage(t("something_went_wrong"));
        }
      });
  };

  const handleCloseSendEmail = () => {
    setSendEmailVisible(null);
  };

  const handleAdminAnswer = () => {
    const idCheck =
      complaintId === 3 ||
      searched?.status?.id === 3 ||
      complaintId === 1 ||
      searched?.status?.id === 1;
    if (idCheck) {
      complaintsAPI
        .updateComplaintById(id2, complaintId || searched?.status?.id, adminAnswer)
        .then(() => {
          form.resetFields();
          setModalIsVisible(true);
          setModalMessage(t("adminSuccess"));
        });
    } else {
      setModalIsVisible(true);
      setModalMessage(t("complaintStatusError"));
    }
  };

  const handleUserDeactive = (side) => {
    if (side === "from") {
      setUserId(searched?.fromCompany?.id);
      setDeactiveModalOpen(true);
    } else {
      setUserId(searched?.toCompany?.id);
      setDeactiveModalOpen(true);
    }
  };

  const userDeactive = () => {
    profileDeactive(userId);
    setDeactiveModalOpen(false);
  };

  const statusCheck =
    searched.status?.name === "NOT_PROCESSED"
      ? s.not_process_select
      : searched.status?.name === "PROCESSED"
      ? s.process_select
      : searched.status?.name === "VIEWED"
      ? s.viewed_select
      : "";

  const handleRedirect = () => {
    setModalIsVisible(false);
    if (modalMessage === t("adminSuccess")) {
      navigate("/admin-panel/complaints");
    }
  };
  return (
    <>
      {deactiveModalOpen ? (
        <Modal
          visible={deactiveModalOpen}
          onCancel={() => setDeactiveModalOpen(false)}
          onOk={() => profileDeactive(userId)}
          footer={[
            <>
              <Button key="ok" className="btn btn_v2" onClick={userDeactive}>
                {t("yes", { ns: "FA2-UsersList" })}
              </Button>
              <Button key="no" className="btn btn_v2" onClick={() => setDeactiveModalOpen(false)}>
                {t("no", { ns: "FA2-UsersList" })}
              </Button>
            </>
          ]}
        >
          <p>{t("areUSureToDeactive")}</p>
        </Modal>
      ) : null}
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onCancel={handleRedirect}
          onOk={handleRedirect}
          footer={[
            <Button key="ok" className="btn btn_v2" onClick={handleRedirect}>
              {t("button", { ns: "FS3-MatchMaking" })}
            </Button>
          ]}
        >
          <p style={{ marginTop: "10px" }}>{modalMessage}</p>
        </Modal>
      ) : null}
      {sendEmailVisible !== null && (
        <SendEmailToUser
          SendEmailVisible={sendEmailVisible}
          handleCloseSendEmail={handleCloseSendEmail}
          type="complaint"
          typeId={id2}
        />
      )}
      <section className="regular_block">
        <section className={s.processComplaint}>
          <div className={s.info}>
            <h3>{t("proccessComplaint.complaint-ID")} </h3>
            <p>{searched.code}</p>
          </div>
          <div className={s.info}>
            <h3>{t("proccessComplaint.complaintDate")}</h3>
            <p>
              {convertDate(searched?.createdAt)} {convertTime(searched?.createdAt)}
            </p>
          </div>
          <br />
          <div className={s.complaintInfo}>
            <div className={s.complaintFrom}>
              <h3>{t("proccessComplaint.complaintFrom")}</h3>
              <p style={{ fontSize: "18px" }}>
                {searched.fromCompany?.companyName}, {t("proccessComplaint.from")}{" "}
                {searched.fromCount} {t("proccessComplaint.complaint")}
              </p>
            </div>
            <div className={s.complaintInfo_btns}>
              <Button className="btn btn_v2">{t("btn.call")}</Button>
              <Button
                className="btn btn_v8"
                onClick={() =>
                  setSendEmailVisible({
                    companyId: searched?.fromCompany?.id,
                    type: "CO",
                    typeId: id2,
                    entityType: "SD"
                  })
                }
              >
                {t("btn.sendEmail")}
              </Button>
              {/* <Link to={`/admin-panel/dialogs/${searched?.fromCompany?.id}`}>
                <Button className="btn btn_v8">{t("btn.openDialog")}</Button>
              </Link> */}
              <Button className="btn btn_v4" onClick={() => handleUserDeactive("from")}>
                {t("btn.deactive")}
              </Button>
            </div>
          </div>
          <br />
          <div className={s.complaintInfo}>
            <div className={s.complaintFrom}>
              <h3>{t("proccessComplaint.complaintTo")}</h3>
              <p style={{ fontSize: "18px" }}>
                {searched.toCompany?.companyName}, {t("proccessComplaint.to")} {searched.toCount}{" "}
                {t("proccessComplaint.complaint")}
              </p>
            </div>
            <div className={s.complaintInfo_btns}>
              <Button className="btn btn_v2">{t("btn.call")}</Button>
              <Button
                className="btn btn_v8"
                onClick={() =>
                  setSendEmailVisible({
                    companyId: searched?.toCompany?.id,
                    type: "CO",
                    typeId: id2,
                    entityType: "OB"
                  })
                }
              >
                {t("btn.sendEmail")}
              </Button>
              {/* <Link to={`/admin-panel/dialogs/${searched?.toCompany?.id}`}>
                <Button className="btn btn_v8">{t("btn.openDialog")}</Button>
              </Link> */}
              <Button className="btn btn_v4" onClick={() => handleUserDeactive("to")}>
                {t("btn.deactive")}
              </Button>
            </div>
          </div>
          <br />
          <div className={s.info}>
            <h3>{t("proccessComplaint.status")}</h3>
            <div className={statusCheck}>
              <Select
                style={{ width: "200px" }}
                onDropdownVisibleChange={(state) => {
                  setOpenState({ ...initialState, statuses: state });
                }}
                onChange={(val) => {
                  setComplaintId(val);
                }}
                open={openState.statuses}
                size="large"
                value={complaintId}
                suffixIcon={
                  <DropdownIcon
                    onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        statuses: !prevState.statuses
                      }));
                    }}
                  />
                }
                placeholder={t(searched.status?.name, { ns: "Statuses" })}
              >
                {statuses.length &&
                  statuses.map((status) => (
                    <Select.Option key={status.id} value={status.id}>
                      <button
                        style={{ background: "none", border: "none", cursor: "pointer" }}
                        className={
                          status.name === "NOT_PROCESSED"
                            ? s.not_processed
                            : status?.name === "PROCESSED"
                            ? s.processed
                            : status?.name === "VIEWED"
                            ? s.viewed
                            : ""
                        }
                      >
                        {t(status.name, { ns: "Statuses" })}
                      </button>
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className={s.info}>
            <h3>{t("proccessComplaint.productLink")}</h3>
            <Link
              className={s.link}
              to={`/company-profile/${searched.toCompany?.id}/${searched?.entityType}/${searched.entity?.id}`}
            >
              {searched.entity?.name}
            </Link>
          </div>
          <div className={s.info}>
            <h3>{t("proccessComplaint.complaint-theme")} </h3>
            <p>{t(searched.topicComplaint?.name, { ns: "ComplaintTopics" })}</p>
          </div>
          <div className={s.info}>
            <h3>{t("proccessComplaint.complaint-object")}</h3>
            <p>{searched?.message}</p>
          </div>
          <Form
            form={form}
            autoComplete="off"
            requiredMark={false}
            validateTrigger="onSubmit"
            onFinish={handleAdminAnswer}
            labelAlign="left"
            initialValues={{
              adminAnswer: searched.answer
            }}
          >
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 40 }}
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                height: "auto"
              }}
              name="adminAnswer"
              label={
                <h3 style={{ fontSize: 19.89, marginBottom: "10px" }} className={`label req`}>
                  {t("addResponse", { ns: "FA27-Complaint" })}
                </h3>
              }
              rules={[
                {
                  required: true,
                  message: t("addResponse", { ns: "FA27-Complaint" })
                },
                {
                  validator: (_, value) => {
                    if (
                      (value && value?.length) ||
                      complaintId === 1 ||
                      searched?.status?.id === 1
                    ) {
                      return Promise.resolve(handleAdminAnswer());
                    } else if (complaintId === 2 && searched?.status?.id === 2) {
                      return Promise.resolve(
                        setModalIsVisible(true),
                        setModalMessage(t("complaintStatusError", { ns: "FA28-ComplaintProcess" }))
                      );
                    } else if (
                      value?.length === 0 ||
                      complaintId === 3 ||
                      searched?.status?.id === 3
                    ) {
                      return Promise.reject();
                    }
                  }
                }
              ]}
            >
              <Input.TextArea
                style={{
                  backgroundColor: "#F5F7F8",
                  padding: "20px",
                  width: "50%",
                  minHeight: "120px"
                }}
                onChange={(e) => {
                  setAdminAnswer(e.target.value);
                  form.setFields([{ name: "adminAnswer", errors: [] }]);
                }}
                rows={5}
                allowClear
                autoSize
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                className="btn btn_v6"
                style={{
                  padding: "0 40px",
                  fontWeight: "300",
                  lineHeight: "16px",
                  fontSize: "14px",
                  color: "#fff",
                  marginTop: "14px",
                  width: "207px"
                }}
                type="default"
                htmlType="submit"
                size="large"
              >
                {t("addAnswer", { ns: "FA36-TechnicalProblems" })}
              </Button>
            </Form.Item>
          </Form>
        </section>
        <Row gutter={[32]} style={{ display: "flex", justifyContent: "center" }}>
          <Col span={12}>
            {searched?.fromCompany && (
              <CommentsWindow
                id={Number(id2)}
                entityType={"complaint"}
                side={"sender"}
                userType={"Sender"}
              />
            )}
          </Col>
          <Col span={12}>
            {searched?.toCompany && (
              <CommentsWindow
                id={Number(id2)}
                entityType={"complaint"}
                side={"object"}
                userType={"ProblemObject"}
              />
            )}
          </Col>
        </Row>
      </section>
    </>
  );
};

ComplaintProcess.propTypes = {
  id: PropTypes.number
};
