import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locales, mailTemplateAPI, PRODUCTION_URL, TINY_MCE_API_KEY } from "../../api/api";
import {
  BUYER_AND_SELLER_FEEDBACK_MAIL_TEMPLATE,
  getBuyerAndSellerFeedbackMailTemplate,
  setMailTemplate,
  setMailTemplateSubject
} from "../../store/mailTemplatesSlice";
import { Button, Col, Form, Input, Modal, Row, Tabs } from "antd";
import Loader from "../../components/Loader/Loader";
import { tabsChange, updateTemplate } from "../../helpers/mailTemplatesHelpers";
import { Editor } from "@tinymce/tinymce-react";
const { TabPane } = Tabs;
const BuyerAndSellerFeedbackMailTemplate = () => {
  const dispatch = useDispatch();
  const { setTitle } = useOutletContext();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { isLoading } = useSelector((state) => state.auth);
  const { buyerAndSellerFeedbackMailTemplate } = useSelector((state) => state.mailTemplates);
  const { t } = useTranslation([
    "FA5-ExporterIncomingRequestMailTemplate",
    "FA48-BuyerAndSellerFeedbackMailTemplate"
  ]);
  useEffect(() => {
    if (!buyerAndSellerFeedbackMailTemplate[locales[0]]) {
      dispatch(getBuyerAndSellerFeedbackMailTemplate([locales[0]]));
    }
  }, []);

  useEffect(() => {
    setTitle(t("title", { ns: "FA48-BuyerAndSellerFeedbackMailTemplate" }));
  }, [t, setTitle]);

  return (
    <>
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onCancel={() => {
            setModalIsVisible(false);
          }}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalIsVisible(false);
              }}
            >
              ОК
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      ) : null}
      <Row>
        <Col sm={{ span: 24 }} xxl={{ span: 20 }} className={`regular_block`}>
          {isLoading && <Loader />}
          <div>
            <h5>{t("availableVariables")}</h5>
            <p>#FIRSTNAME - {t("FIRSTNAME", { ns: "FA5-ExporterIncomingRequestMailTemplate" })}</p>
            <p>#LASTNAME - {t("LASTNAME", { ns: "FA5-ExporterIncomingRequestMailTemplate" })}</p>
            <p>
              #COMPANY_NAME - {t("COMPANY_NAME", { ns: "FA5-ExporterIncomingRequestMailTemplate" })}
            </p>
            <p>{t("BUTTON_EXAMPLE", { ns: "FA5-ExporterIncomingRequestMailTemplate" })}</p>
          </div>
          <Tabs
            defaultActiveKey={0}
            onChange={(key) => {
              tabsChange(
                key,
                buyerAndSellerFeedbackMailTemplate,
                dispatch,
                getBuyerAndSellerFeedbackMailTemplate
              );
            }}
          >
            {locales.map((el, i) => (
              <TabPane tab={el} key={i}>
                <Col span={12}>
                  <p className="mb-12">
                    {t("mailSubject", { ns: "FA5-ExporterIncomingRequestMailTemplate" })}
                  </p>
                  <Form>
                    <Form.Item>
                      <Input
                        value={buyerAndSellerFeedbackMailTemplate[locales[i]]?.subject}
                        onChange={(e) => {
                          dispatch(
                            setMailTemplateSubject({
                              mailTemplate: BUYER_AND_SELLER_FEEDBACK_MAIL_TEMPLATE,
                              lang: el,
                              subject: e.target.value
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Editor
                  inline_styles={true}
                  apiKey={TINY_MCE_API_KEY}
                  plugins={["code"]}
                  value={buyerAndSellerFeedbackMailTemplate[locales[i]]?.template}
                  init={{
                    relative_urls: false,
                    remove_script_host: false,
                    document_base_url: PRODUCTION_URL
                  }}
                  onEditorChange={(content) => {
                    dispatch(
                      setMailTemplate({
                        mailTemplateName: BUYER_AND_SELLER_FEEDBACK_MAIL_TEMPLATE,
                        mailTemplateContent: content,
                        lang: locales[i]
                      })
                    );
                  }}
                />
                <div>
                  <h5 className="mt-50 mb-24">{t("mailTemplate")}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: buyerAndSellerFeedbackMailTemplate[locales[i]]?.template
                        ?.replace("#FIRSTNAME", t("FIRSTNAMEex"))
                        ?.replace("#LASTNAME", t("LASTNAMEex"))
                        ?.replace("#COMPANY_NAME", t("COMPANY_NAMEex"))
                        ?.replace(
                          "#BUTTON_START#",
                          "<a style='padding: 14px 40px; background-color: #343543; color: #fff; display: flex; width: max-content;' href='#LINK'>"
                        )
                        ?.replace("#BUTTON_END#", "</a>")
                    }}
                  ></div>
                </div>
                <Button
                  onClick={() =>
                    updateTemplate(
                      locales[i],
                      buyerAndSellerFeedbackMailTemplate,
                      setModalMessage,
                      setModalIsVisible,
                      mailTemplateAPI.updateBuyerAndSellerFeedbackMailTemplate,
                      t,
                      dispatch
                    )
                  }
                  className="btn btn_v2 ml-auto mt-24"
                >
                  {t("buttons.save")}
                </Button>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default BuyerAndSellerFeedbackMailTemplate;
