import React, {useEffect, useState} from 'react';
import { domain, mediaUrl } from "../../api/api";
import {
  setFile,
  setImages,
  setImagesName,
} from "../../store/changeCompanyProfileSlice";
import { useDispatch, useSelector } from "react-redux";

import Img from "../../assets/svg/Img";
import Trash from "../../assets/svg/trash";
import axios from "axios";
import s from "./ChangeCompanyProfile.module.less";
import AddAndCropLogo from '../../components/AddAndCropLogo/AddAndCropLogo';

const AddPhoto = ({ id, t, setModalMessage }) => {
  const { images, imagesName } = useSelector((state) => state.changeCompanyProfile.customProfile);
  const [visibleCropLogo, setVisibleCropLogo] = useState(false);
  const [img, setImg] = useState({});
  const dispatch = useDispatch();

  const changeState = (num, payLoad) => {
    if (num === 1) {
      const newFile = images.map((item, index) => (index === id ? payLoad : item));
      dispatch(setImages(newFile));
    } else if (num === 3) {
      const newFile = imagesName.map((item, index) =>
        index === id ? { fileName: payLoad.fileName, loader: payLoad.loader } : item
      );
      dispatch(setImagesName(newFile));
    } else if (num === 4) {
      if (images.length === 2) {
        const newFileName = imagesName.filter((item, index) => index !== id && item);
        dispatch(setImages([""]));
        dispatch(setImagesName(newFileName));
      } else {
        const newFile = images.filter((item, index) => index !== id && item);
        const newFileName = imagesName.filter((item, index) => index !== id && item);
        dispatch(setImages(newFile));
        dispatch(setImagesName(newFileName));
      }
    }
  };

  const handleFileSelect = async (file) => {
    if (file && file.size < 50000000){
    changeState(3, { loader: t("loading") });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("originalname", file?.name);
    try {
      const res = await axios({
        method: "post",
        url: `${domain}api/upload/image`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      });
      if (res.status === 200) {
        let payload = { fileName: file?.name, loader: t("loaded") };
        changeState(1, res.data.filename);
        changeState(3, payload);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // console.log(error.response);
      }
    }} else setModalMessage(t("logoModalError"))
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 50000000) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImg({base64: reader.result?.toString() || '', name: e.target.files[0].name}),
      );
      reader.readAsDataURL(e.target.files[0]);
      setVisibleCropLogo(true);
    } else if (e.target.files[0].size > 50000000) {
      document.getElementById('inpt').value = '';
      setModalMessage(t('logoModalError'));
    }
  };


  useEffect(() => {
    if (images.length < 4) {
      if (images[images.length - 1] !== "") {
        dispatch(setImages([...images, ""]));
        dispatch(setImagesName([...imagesName, { fileName: "", loader: "" }]));
      }
    }
    if (!images.length) {
      dispatch(setFile([""]));
    }
  }, [dispatch, images, imagesName]);

  return (
    <div className={`${s.imgWrapper} ${s.child}`} style={{ position: "relative" }}>
      {images[id] ? (
        <img
          src={`${mediaUrl}min-${images[id]}`}
          style={{ height: "80px", width: "80px", objectFit: "contain" }}
        />
      ) : (
        <Img />
      )}
      <div style={{ padding: "0 20px" }}>
        {imagesName[id].fileName ? (
          <div style={{ marginTop: "30px" }}>
            {imagesName[id].fileName.slice(0, 12) +
              "..." +
              imagesName[id].fileName.slice(imagesName[id].fileName.length - 4)}
          </div>
        ) : (
          <label className={s.link}>
            <div className={s.logoTitle}>{t("images")}</div>
            {imagesName[id].loader !== t("loading") && (
              <div className={s.link} style={{ textDecoration: "underline" }}>
                {t("upload")}
              </div>
            )}
            <div className={s.keywordsDescription} style={{ margin: "0 0 0 30px" }}>
              {t("format")}
            </div>
            <input
              className={s.file}
              type="file"
              onChange={onSelectFile}
              accept=".png, .jpg, .jpeg"
              id={'inpt'}
            />
          </label>
        )}
        {images[id] && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeState(4);
            }}
          >
            <Trash style={{ position: "absolute", top: "10px", right: "20px" }} />
          </div>
        )}
        <div
          className={s.loader}
          style={imagesName[id].loader === t("loading") ? { color: "red" } : { color: "green" }}
        >
          {imagesName[id].loader}
        </div>
      </div>
      <AddAndCropLogo
        visible={visibleCropLogo}
        setVisible={setVisibleCropLogo}
        saveCropLogo={handleFileSelect}
        img={img}
        setImg={setImg}
        inputId={'inpt'}
      />
    </div>
  );
};

export default AddPhoto;
