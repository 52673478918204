import { useState } from "react";
import { useTranslation } from "react-i18next";
import { backendUrl } from "../../api/api";
/* eslint-disable */

const EllipsisText = ({
  len,
  children = "",
  isDownloadLink = false,
  linkClass = "",
  isText = true
}) => {
  const { t } = useTranslation("FS18-PaymentApplications");
  const [ellipsis, setEllipsis] = useState(() => children.length > len);

  return (
    <p style={{ wordBreak: "break-word" }}>
      {ellipsis && (
        <>
          {!isText ? (
            isDownloadLink ? (
              <a
                href={`${backendUrl}/files/${children}`}
                className={linkClass}
                target="_blank"
              >{`${children.slice(0, len)}${!isDownloadLink ? "..." : ""} `}</a>
            ) : (
              <a href={children} className={linkClass} target="_blank">{`${children.slice(
                0,
                len
              )}... `}</a>
            )
          ) : (
            `${children.slice(0, len)}... `
          )}
          <span
            style={{ color: "#1890ff", fontSize: 12, cursor: "pointer" }}
            onClick={() => {
              setEllipsis(!ellipsis);
            }}
          >
            {t("expand")}
          </span>
        </>
      )}
      {!ellipsis &&
        (!isText ? (
          isDownloadLink ? (
            <a href={`${backendUrl}/files/${children}`} className={linkClass}>{`${children} `}</a>
          ) : (
            <a href={children} className={linkClass} target="_blank">{`${children} `}</a>
          )
        ) : (
          `${children}`
        ))}
    </p>
  );
};

export default EllipsisText;
