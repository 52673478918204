import * as React from "react";

const SearchIcon = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8ZM0 6a6 6 0 1 1 10.89 3.477l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6.001 6.001 0 0 1 0 6Z"
      fill="#B0AEB4"
    />
  </svg>
);

export default SearchIcon;
