import { createSlice } from "@reduxjs/toolkit";
import somalilandIcon from "Assets/image/countries/somaliland.png";

let initialState = {
  countries: {
    XS: somalilandIcon
  }
};

const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {}
});

export default countrySlice.reducer;
