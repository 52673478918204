export const changeCookieModalText = (text)=>{
    if(text === "Отклонить все"){
        return "Отклонить все необязательные "
    }
    if(text === "Reject All"){
        return "Reject All Non-Essential"
    }
    if(text === "رفض الكل"){
        return "ارفض كل ما هو غير ضروري"
    }
    if(text === "Отхвърли всички"){
        return "Отхвърли всички несъществени"
    }
    if(text === "Rebutja-ho tot"){
        return "Rebutja tot allò no essencial"
    }
    if(text === "Odmítnout vše"){
        return "Odmítnout vše nepodstatné"
    }if(text === "Afvis alle"){
        return "Afvis alle ikke-væsentlige"
    }
    if(text === "Alles ablehnen"){
        return "Unwesentliches ablehnen"
    }
    if(text === "Unwesentliches ablehnen"){
        return "Unwesentliches ablehnen"
    }
    if(text === "Απόρριψη όλων"){
        return "Απόρριψη όλων των μη ουσιωδών"
    }
    if(text === "Reject All"){
        return "Reject All Non-Essential"
    }
    if(text === "Rechazar todo"){
        return "Rechazar todas no esenciales"
    }
    if(text === "رد همه"){
        return "رد همه غیر ضروری "
    }
    if(text === "Hylkää kaikki"){
        return "Hylkää kaikki ei-välttämättömät"
    }
    if(text === "Tout rejeter"){
        return "Rejeter tous non essentiels"
    }
    if(text === "דחה הכל"){
        return "דחה לא חיוני "
    }
    if(text === "सभी को अस्वीकार करें"){
        return "गैर-जरूरी को अस्वीकार करें"
    }
    if(text === "Odbaci sve"){
        return "Odbaci sve nebitno"
    }
    if(text === "Összes elutasítása"){
        return "Összes lényegtelen elutasitása"
    }
    if(text === "Tolak Semua"){
        return "Tolak Semua Non-Esensial"
    }
    if(text === "Hafna öllum"){
        return "Hafna öllum ónauðsynlegum"
    }
    if(text === "Rifiuta tutto"){
        return "Rifiuta tutto ciò che non è essenziale"
    }
    if(text === "すべて拒否"){
        return "すべて拒否 不可欠でない"
    }
    if(text === "Барлығын қабылдамау"){
        return "Барлық міндетті емес"
    }
    if(text === "모두 거부"){
        return "모든 비필수 거부"
    }
    if(text === "Noraidīt visu"){
        return "Noraidīt nebūtisku"
    }
    if(text === "Atmesti viską"){
        return "Atmesti viską, kas nėra būtina"
    }
    if(text === "Tolak Semua"){
        return "Tolak Semua Yang Tidak Penting"
    }
    if(text === "Avvis alle"){
        return "Avvis alle ikke-essensielle"
    }
    if(text === "Alles afwijzen"){
        return "Niet-essentieel afwijzen"
    }
    if(text === "Odrzuć wszystkie"){
        return "Odrzuć wszystkie nieistotne"
    }
    if(text === "Rejeitar tudo"){
        return "Rejeitar Não Essencial"
    }
    if(text === "Respinge toate"){
        return "Respinge toate neesențiale"
    }
    if(text === "Odmietnuť všetko"){
        return "Odmietnuť všetky voliteľné"
    }
    if(text === "Odbaci sve"){
        return "Odbaci sve nebitno"
    }
    if(text === "Avvisa alla"){
        return "Avvisa alla icke väsentliga"
    }
    if(text === "ปฏิเสธทั้งหมด"){
        return "ปฏิเสธสิ่งที่ไม่จำเป็นทั้งหมด "
    }
    if(text === "Tümünü Reddet"){
        return "Tümünü Reddet Gereksiz"
    }
    if(text === "Відхилити всі"){
        return "Відхилити всі необов'язкові"
    }
    if(text === "Từ chối tất cả"){
        return "Từ chối tất cả không cần thiết"
    }
    if(text === "全部拒绝" || text === "全部拒絕"){
        return "全部拒絕非本質的"
    }
    if(text === "拒絕所有"){
        return "全部拒絕非本質的"
    }
    if(text === "拒絕所有"){
        return "全部拒絕非本質的"
    }

    return text
}