import { createSlice } from "@reduxjs/toolkit";
import { matchmakingAPI } from "../api/api";

const initialState = {
  matchmakingRequestsCount: [],
  allRequests: []
};

const matchMakingSlice = createSlice({
  name: "matchmaking",
  initialState,
  reducers: {
    setAllMatchmakingCount(state, action) {
      state.matchmakingRequestsCount = action.payload;
    },
    setAllMatchMakingRequests(state, action) {
      state.allRequests = action.payload;
    }
  }
});

export const { setAllMatchmakingCount } = matchMakingSlice.actions;
export const getAllMatchMakingCount = () => (dispatch) => {
  matchmakingAPI.getMatchMakingCount().then((res) => {
    dispatch(setAllMatchmakingCount(res.data));
  });
};

export const { setAllMatchMakingRequests } = matchMakingSlice.actions;
export const getAllMatchMakingRequests = () => (dispatch) => {
  matchmakingAPI.getAllMatchMakingRequests().then((res) => {
    dispatch(setAllMatchMakingRequests(res.data));
  });
};
export default matchMakingSlice.reducer;
