import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Badge, Button, Layout, Menu } from "antd";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { LoginIcon } from "Assets/svg/LoginIcon";
import MenuLink from "Components/MenuLink/MenuLink";
import { adminLogout } from "Store/authSlice";
import { ADMIN_ROUTES } from "./routes";
import s from "./Sider.module.less";
import { getNotifications } from "../../store/notificationsSlice";

const { Sider: AntdSider } = Layout;

const SUB_TO_MENU_KEY = {
  [ADMIN_ROUTES.meetingsList]: "meetings",
  [ADMIN_ROUTES.meetingsListModeration]: "meetings",

  [ADMIN_ROUTES.exporterIncomingRequestMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.emailConfirmationMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.passwordRecoveryTemplate]: "mail-templates",
  [ADMIN_ROUTES.meetingInvitationMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.paymentConfirmationMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.buyerAndSellerFeedbackMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.rejectedChangesNotificationMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.meetingInvitationModerMailTemplate]: "mail-templates",
  [ADMIN_ROUTES.meetingInvitationFromSellerTemplate]: "mail-templates",
  [ADMIN_ROUTES.meetingInvitationFromSellerModerTemplate]: "mail-templates",
  [ADMIN_ROUTES.meetingNotificationTemplate]: "mail-templates",

  [ADMIN_ROUTES.requestsList]: "requests",
  [ADMIN_ROUTES.mainRequests]: "requests",
  [ADMIN_ROUTES.translationList]: "requests",
  [ADMIN_ROUTES.matchMakingRequests]: "requests",
  [ADMIN_ROUTES.searchRequestsWithoutMatch]: "requests"
};

const AdminSider = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation(["Sider", "Header"]);
  const location = useMemo(
    () => window.location.pathname.split("/").pop(),
    [window.location.pathname]
  );
  const [openKeys, setOpenKeys] = useState([SUB_TO_MENU_KEY[location]]);
  const {
    unprocessedAdminMeetings,
    unprocessedAdminModeratedMeetings,
    unreadAdminComplaints,
    unreadAdminTechIssues,
    unprocessedModerationChanges,
    unprocessedInquiries
  } = useSelector((state) => state.notifications);

  useEffect(() => {
    if (!openKeys.includes(SUB_TO_MENU_KEY[location])) {
      setOpenKeys([SUB_TO_MENU_KEY[location]]);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getNotifications());
    const intervalId = setInterval(() => {
      dispatch(getNotifications());
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const logout = () => {
    dispatch(adminLogout());
  };

  const items = useMemo(
    () => [
      user.roleId <= 8 && {
        label: (
          <>
            {t("meetings")}
            {!!(unprocessedAdminModeratedMeetings + unprocessedAdminMeetings) && (
              <Badge
                count={unprocessedAdminModeratedMeetings + unprocessedAdminMeetings}
                style={{
                  marginLeft: 5,
                  backgroundColor: "#EA5B0C"
                }}
              />
            )}
          </>
        ),
        key: "meetings",
        children: [
          { title: "meetingsList", minRole: 7 },
          { title: "meetingsListModeration", minRole: 8 }
        ].map((item, i) => {
          return (
            user.roleId <= item.minRole && {
              label: (
                <MenuLink
                  to={ADMIN_ROUTES[item.title]}
                  label={t(item.title)}
                  count={i == 1 ? unprocessedAdminModeratedMeetings : unprocessedAdminMeetings}
                />
              ),
              key: ADMIN_ROUTES[item.title]
            }
          );
        })
      },

      user.roleId <= 7 && {
        label: t("requests"),
        key: "requests",
        children: [
          "requestsList",
          "searchRequestsWithoutMatch",
          "mainRequests",
          "translationList",
          "matchMakingRequests"
        ].map((item) => ({
          label: <MenuLink to={ADMIN_ROUTES[item]} label={t(item)} />,
          key: ADMIN_ROUTES[item]
        }))
      },

      user.roleId === 1 && {
        label: <MenuLink to={ADMIN_ROUTES.employeesList} label={t("employeesList")} isTop />,
        key: ADMIN_ROUTES.employeesList
      },

      user.roleId <= 7 && {
        label: (
          <MenuLink
            to={ADMIN_ROUTES.inquiries}
            label={t("inquiries")}
            isTop
            count={unprocessedInquiries}
          />
        ),
        key: ADMIN_ROUTES.inquiries
      },

      [1, 4, 5].some((accessId) => accessId === user.roleId) && {
        label: <MenuLink to={ADMIN_ROUTES.promocodes} label={t("promocodes")} isTop />,
        key: ADMIN_ROUTES.promocodes
      },

      user.roleId <= 1 && {
        label: (
          <MenuLink
            to={ADMIN_ROUTES["questionsAndSuggestions"]}
            label={t("QuestionsAndSuggestions")}
            isTop
          />
        ),
        key: ADMIN_ROUTES["questionsAndSuggestions"]
      },

      user.roleId <= 7 && {
        label: <MenuLink to={ADMIN_ROUTES["usersList"]} label={t("usersList")} isTop />,
        key: ADMIN_ROUTES["usersList"]
      },

      user.roleId <= 7 && {
        label: <MenuLink to={ADMIN_ROUTES["addUser"]} label={t("addUser")} isTop />,
        key: ADMIN_ROUTES["addUser"]
      },

      user.roleId <= 7 && {
        label: (
          <MenuLink
            to={ADMIN_ROUTES.moderationChanges}
            label={t("moderationChanges")}
            isTop
            count={unprocessedModerationChanges}
          />
        ),
        key: ADMIN_ROUTES.moderationChanges
      },

      user.roleId <= 1 && {
        label: <MenuLink to={ADMIN_ROUTES["tariffs"]} label={t("tariffs")} isTop />,
        key: ADMIN_ROUTES["tariffs"]
      },

      user.roleId <= 7 && {
        label: (
          <MenuLink to={ADMIN_ROUTES.subscribedAccounts} label={t("subscribedAccounts")} isTop />
        ),
        key: ADMIN_ROUTES.subscribedAccounts
      },

      [1, 4, 2, 6, 7].some((accessId) => accessId === user.roleId) && {
        label: <MenuLink to={ADMIN_ROUTES.promotedGoods} label={t("promotedGoods")} isTop />,
        key: ADMIN_ROUTES.promotedGoods
      },

      [1, 6, 7].some((accessId) => accessId === user.roleId) && {
        label: (
          <MenuLink
            to={ADMIN_ROUTES.complaints}
            label={t("complaints")}
            isTop
            count={unreadAdminComplaints}
          />
        ),
        key: ADMIN_ROUTES.complaints
      },

      [1, 6, 7].some((accessId) => accessId === user.roleId) && {
        label: (
          <MenuLink
            to={ADMIN_ROUTES.technicalProblems}
            label={t("technical_problems")}
            count={unreadAdminTechIssues}
            isTop
          />
        ),
        key: ADMIN_ROUTES.technicalProblems
      },

      user.roleId === 1 && {
        label: t("templates"),
        key: "mail-templates",
        children: [
          "exporterIncomingRequestMailTemplate",
          "emailConfirmationMailTemplate",
          "meetingInvitationMailTemplate",
          "meetingInvitationModerMailTemplate",
          "meetingInvitationFromSellerTemplate",
          "meetingInvitationFromSellerModerTemplate",
          "passwordRecoveryTemplate",
          "paymentConfirmationMailTemplate",
          "buyerAndSellerFeedbackMailTemplate",
          "rejectedChangesNotificationMailTemplate",
          "meetingNotificationTemplate"
        ].map((item) => ({
          label: <MenuLink to={ADMIN_ROUTES[item]} label={t(item)} />,
          key: ADMIN_ROUTES[item]
        }))
      }
    ],
    [
      t,
      user.roleId,
      unprocessedAdminMeetings,
      unprocessedAdminModeratedMeetings,
      unreadAdminTechIssues,
      unreadAdminComplaints,
      unprocessedModerationChanges
    ]
  );

  return (
    <AntdSider width={304} className={s.sider}>
      <Menu
        className={s.menu}
        onOpenChange={(openKeys) => {
          setOpenKeys([openKeys[1]]);
        }}
        onClick={({ keyPath }) => {
          setOpenKeys([keyPath[1]]);
        }}
        selectedKeys={[location]}
        openKeys={openKeys}
        expandIcon={<DropdownIcon />}
        mode="inline"
        items={items}
      />

      <div className={s.itemWrapper}>
        <div className={s.item}>
          {user.firstName}&nbsp;{user.lastName}
        </div>
        <div className={s.item}>{user.email}</div>
        <div className={s.item}>
          {t("lastLoginDate")}:
          <br />
          {new Date(user && user.lastLoginDate).toLocaleString().slice(0, 10)}
        </div>
        <div className={s.item}>
          {t("support")}:
          <br />
          <a href="mailto:support@b2b-one.com" style={{ color: "#1890ff" }}>
            support@b2b-one.com
          </a>
        </div>
      </div>

      <Button
        className="logoutBtn"
        style={{ margin: 25 }}
        icon={<LoginIcon />}
        type="link"
        onClick={logout}
      >
        <span className="ml-15">{t("logout", { ns: "Header" })}</span>
      </Button>
    </AntdSider>
  );
};

export default AdminSider;
