import { Col, Row } from "antd";

import { Link } from "react-router-dom";
import s from "./footer.module.less";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation(["Footer", "Header"]);
  return (
    <footer className={s.footer}>
      <Row justify="center">
        <Col className={s.wrap} span={22}>
          <Row align="space-between" className="mb-18">
            <Col>
              <Link className={s.link} to="/main-catalog">
                {t("toCatalogue", { ns: "Header" })}
              </Link>
            </Col>
            <Col>
              <Link className={s.link} to="/how-to-buy">
                {t("howToBuy", { ns: "Header" })}
              </Link>
            </Col>
            <Col>
              <Link className={s.link} to="/how-to-sell">
                {t("howToSell", { ns: "Header" })}
              </Link>
            </Col>
            <Col>
              <Link className={s.link} to="/subscription-management">
                {t("priceList", { ns: "Header" })}
              </Link>
            </Col>
            <Col>
              <Link className={s.link} to="/match-making">
                {t("matchMaking", { ns: "Header" })}
              </Link>
            </Col>
            <Col>
              <Link className={s.link} to="/contact-us">
                {t("contactUs", { ns: "Header" })}
              </Link>
            </Col>
          </Row>
          <p className="mb-18" style={{ fontSize: 15 }}>
            {t("p1")}
          </p>
          <div>
            <Link className={`${s.bottom_link}`} to="/terms-and-conditions" target="_blank">
              {t("agreement")}
            </Link>
            <Link className={`${s.bottom_link} ml-18`} to="/privacy_policy" target="_blank">
              {t("policy")}
            </Link>
            <Link className={`${s.bottom_link} ml-18`} to="/cookies_policy" target="_blank">
              {t("cookies_policy")}
            </Link>
          </div>
        </Col>
      </Row>
    </footer>
  );
};
