import { BUYER_ROUTES, ROUTES, SELLER_ROUTES } from "./routes";
import { Badge, Button, Layout, Menu, Tooltip } from "antd";
import { me, setUserType, userLogout } from "Store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { LoginIcon } from "Assets/svg/LoginIcon";
import MenuLink from "Components/MenuLink/MenuLink";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getNotifications } from "Store/notificationsSlice";
import { profileAPI } from "Api/api";
import s from "./Sider.module.less";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Sider: AntdSider } = Layout;

const SUB_TO_MENU_KEY = {
  [ROUTES.leaveRequestBuyer]: "requests",
  [ROUTES.outgoingRequestsBuyer]: "requests",

  [ROUTES.incomingRequestsMeetingSeller]: "meetings",
  [ROUTES.incomingRequestsMeetingModerSeller]: "meetings",
  [ROUTES.outgoingRequestsMeetingSeller]: "meetings",
  [ROUTES.outgoingRequestsMeetingModerSeller]: "meetings",
  [ROUTES.incomingRequestsMeetingBuyer]: "meetings",
  [ROUTES.incomingRequestsMeetingModerBuyer]: "meetings",
  [ROUTES.outgoingRequestsMeetingBuyer]: "meetings",
  [ROUTES.outgoingRequestsMeetingModerBuyer]: "meetings",

  [ROUTES.reportTechIssues]: "tech-issues",
  [ROUTES.allTechIssues]: "tech-issues",

  [ROUTES.makeComplaint]: "complaints",
  [ROUTES.allComplaints]: "complaints",

  [ROUTES.addProduct]: "seller-tools",
  [ROUTES.addService]: "seller-tools",
  [ROUTES.editProductOrService]: "seller-tools",
  [ROUTES.productAndServicePromotion]: "seller-tools"
};

const USER_TYPES = {
  0: "BOTH",
  1: "BUYER",
  2: "SELLER",
  4: "DEACTIVATED"
};

const Sider = () => {
  const { user, userType } = useSelector((state) => state.auth);
  const {
    unprocessedOrdersSeller,
    unprocessedIncomingMeetingsSeller,
    unprocessedIncomingModeratedMeetingsSeller,
    unprocessedIncomingMeetingsBuyer,
    unprocessedIncomingModeratedMeetingsBuyer,
    unreadMessagesTotal,
    allBuyer,
    allSeller,
    productsServices,
    company
  } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const { t } = useTranslation(["Sider", "Header"]);
  const location = useMemo(
    () => window.location.pathname.split("/").pop(),
    [window.location.pathname]
  );
  const [openKeys, setOpenKeys] = useState([SUB_TO_MENU_KEY[location]]);
  const [switchedTo, setSwitchedTo] = useState(null);
  const connectedProfileType = useMemo(() => USER_TYPES[user.company.type], [user.company.type]);
  const navigate = useNavigate();

  // // console.log(location);
  // // console.log(connectedProfileType, switchedTo);

  // console.log(allSeller)

  useEffect(() => {
    if (!userType) {
      if (["BOTH", "SELLER", "DEACTIVATED"].some((type) => type === connectedProfileType)) {
        dispatch(setUserType("seller"));
      }
      if (connectedProfileType === "BUYER") {
        dispatch(setUserType("buyer"));
      }
    } else {
      setSwitchedTo(userType.toUpperCase());
    }
  }, [userType]);

  useEffect(() => {
    if (Object.values(SELLER_ROUTES).includes(location)) {
      switchToSeller();
    }
    if (Object.values(BUYER_ROUTES).includes(location)) {
      switchToBuyer();
    }
    if (!openKeys.includes(SUB_TO_MENU_KEY[location])) {
      setOpenKeys([SUB_TO_MENU_KEY[location]]);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getNotifications());
    const intervalId = setInterval(() => {
      dispatch(getNotifications());
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const switchToSeller = () => {
    setSwitchedTo("SELLER");
    dispatch(setUserType("seller"));
  };

  const switchToBuyer = () => {
    setSwitchedTo("BUYER");
    dispatch(setUserType("buyer"));
  };

  const connectProfileHandler = (type) => {
    profileAPI
      .enableProfile(type)
      .then(() => {
        dispatch(me());
      })
      .catch((e) => {
        // console.log(e.response);
      });
  };

  const logout = () => {
    dispatch(userLogout());
  };

  const REQUESTS = useMemo(
    () =>
      switchedTo === "SELLER"
        ? {
          label: (
            <MenuLink
              isTop
              to={ROUTES.incomingRequestsSeller}
              label={t("incomingRequestsSeller")}
              count={unprocessedOrdersSeller}
              tooltip={t("requestsTooltip")}
            />
          ),
          key: ROUTES.incomingRequestsSeller
        }
        : {
          label: (
            <>
              {t("requests")}
              <Tooltip placement="bottomLeft" title={t("requestsTooltipBuyer")}>
                <QuestionCircleOutlined
                  style={{
                    position: "relative",
                    cursor: "help",
                    alignSelf: "end",
                    marginBottom: 7,
                    marginLeft: "5px",
                    color: "#474747"
                  }}
                />
              </Tooltip>
            </>
          ),
          key: "requests",
          children: [
            {
              label: <MenuLink to={ROUTES.leaveRequestBuyer} label={t("leaveRequestBuyer")} />,
              key: ROUTES.leaveRequestBuyer
            },
            {
              label: (
                <MenuLink to={ROUTES.outgoingRequestsBuyer} label={t("outgoingRequestsBuyer")} />
              ),
              key: ROUTES.outgoingRequestsBuyer
            }
          ]
        },
    [t, switchedTo, unprocessedOrdersSeller]
  );

  const SELLER_MEETINGS = useMemo(
    () => [
      {
        label: (
          <MenuLink
            to={ROUTES.incomingRequestsMeetingSeller}
            label={t("incomingRequestsMeetingSeller")}
            count={unprocessedIncomingMeetingsSeller}
            tooltip={t("buyersMeetingRequests")}
          />
        ),
        key: ROUTES.incomingRequestsMeetingSeller
      },
      {
        label: (
          <MenuLink
            to={ROUTES.incomingRequestsMeetingModerSeller}
            label={t("incomingRequestsMeetingModerSeller")}
            count={unprocessedIncomingModeratedMeetingsSeller}
            tooltip={t("buyersModerMeetingRequests")}
          />
        ),
        key: ROUTES.incomingRequestsMeetingModerSeller
      },
      {
        label: (
          <MenuLink
            to={ROUTES.outgoingRequestsMeetingSeller}
            label={t("outgoingRequestsMeetingSeller")}
            tooltip={t("outgoingMeetingRequests")}
          />
        ),
        key: ROUTES.outgoingRequestsMeetingSeller
      },
      {
        label: (
          <MenuLink
            to={ROUTES.outgoingRequestsMeetingModerSeller}
            label={t("outgoingRequestsMeetingModerSeller")}
            tooltip={t("outgoingRequestsMeetingModerSellerTooltip")}
          />
        ),
        key: ROUTES.outgoingRequestsMeetingModerSeller
      }
    ],
    [t, unprocessedIncomingMeetingsSeller, unprocessedIncomingModeratedMeetingsSeller]
  );

  const BUYER_MEETINGS = useMemo(
    () => [
      {
        label: (
          <MenuLink
            to={ROUTES.outgoingRequestsMeetingBuyer}
            label={t("outgoingRequestsMeetingBuyer")}
            tooltip={t("outgoingRequestsMeetingBuyerTooltip")}
          />
        ),
        key: ROUTES.outgoingRequestsMeetingBuyer
      },
      {
        label: (
          <MenuLink
            to={ROUTES.outgoingRequestsMeetingModerBuyer}
            label={t("outgoingRequestsMeetingModerBuyer")}
            tooltip={t("outgoingRequestsMeetingModerBuyerTooltip")}
          />
        ),
        key: ROUTES.outgoingRequestsMeetingModerBuyer
      },
      {
        label: (
          <MenuLink
            to={ROUTES.incomingRequestsMeetingBuyer}
            label={t("incomingRequestsMeetingBuyer")}
            count={unprocessedIncomingMeetingsBuyer}
            tooltip={t("incomingRequestsMeetingBuyerTooltip")}
          />
        ),
        key: ROUTES.incomingRequestsMeetingBuyer
      },
      {
        label: (
          <MenuLink
            to={ROUTES.incomingRequestsMeetingModerBuyer}
            label={t("incomingRequestsMeetingModerBuyer")}
            count={unprocessedIncomingModeratedMeetingsBuyer}
            tooltip={t("incomingRequestsMeetingModerBuyerTooltip")}
          />
        ),
        key: ROUTES.incomingRequestsMeetingModerBuyer
      }
    ],
    [t, unprocessedIncomingMeetingsBuyer, unprocessedIncomingModeratedMeetingsBuyer]
  );

  const items = useMemo(
    !user?.onModeration
      ? () => [
        ...["userProfile", "companyProfile"].map((item) => ({
          label: (
            <MenuLink
              count={item === "companyProfile" && company}
              to={ROUTES[item]}
              label={t(item)}
              isTop
            />
          ),
          key: ROUTES[item]
        })),
        REQUESTS,

        {
          label: (
            <>
              {t("meetings")}
              <Badge
                count={
                  switchedTo === "SELLER"
                    ? unprocessedIncomingMeetingsSeller +
                    unprocessedIncomingModeratedMeetingsSeller || 0
                    : unprocessedIncomingMeetingsBuyer +
                    unprocessedIncomingModeratedMeetingsBuyer || 0
                }
                style={{ marginLeft: 5, backgroundColor: "#EA5B0C" }}
              />
              <Tooltip
                placement="bottomLeft"
                title={
                  switchedTo === "SELLER" ? t("meetingsTooltip") : t("meetingsWithBuyerTooltip")
                }
              >
                <QuestionCircleOutlined
                  style={{
                    position: "relative",
                    cursor: "help",
                    alignSelf: "end",
                    marginBottom: 7,
                    marginLeft: "5px",
                    color: "#474747"
                  }}
                />
              </Tooltip>
            </>
          ),
          key: "meetings",
          children: switchedTo === "SELLER" ? SELLER_MEETINGS : BUYER_MEETINGS
        },

        ...["paymentApplications"].map((item) => ({
          label: <MenuLink to={ROUTES[item]} label={t(item)} isTop />,
          key: ROUTES[item]
        })),
        switchedTo === "SELLER" && {
          label: (
            <>
              {t("sellerTools")}
              <Badge
                count={productsServices}
                style={{ marginLeft: 5, backgroundColor: "#EA5B0C" }}
              />
            </>
          ),
          key: "seller-tools",
          children: ["addProduct", "addService", "editProductOrService"]
            .map((item) => ({
              label: (
                <MenuLink
                  count={item === "editProductOrService" && productsServices}
                  to={ROUTES[item]}
                  label={t(item)}
                />
              ),
              key: ROUTES[item]
            }))
            .concat([
              {
                label: (
                  <MenuLink
                    to={ROUTES.productAndServicePromotion}
                    label={t("productAndServicePromotion")}
                    tooltip={t("productAndServicePromotionTooltip")}
                  />
                ),
                key: ROUTES.productAndServicePromotion
              }
            ])
        },
        {
          label: (
            <>
              <MenuLink
                to={ROUTES.dialogs}
                state={{ showNew: false }}
                label={t("dialogs")}
                isTop
                tooltip={t("dialogsTooltip")}
              />
              <Badge
                count={unreadMessagesTotal}
                style={{ marginLeft: 5, backgroundColor: "#EA5B0C" }}
                onClick={() => {
                  navigate("dialogs", { state: { showNew: true } });
                }}
              />
            </>
          ),
          key: ROUTES.dialogs
        },
        {
          label: (
            <>
              {t("techIssues")}
              <Tooltip placement="bottomLeft" title={t("technicalProblemsTooltip")}>
                <QuestionCircleOutlined
                  style={{
                    position: "relative",
                    cursor: "help",
                    alignSelf: "end",
                    marginBottom: 7,
                    marginLeft: "5px",
                    color: "#474747"
                  }}
                />
              </Tooltip>
            </>
          ),
          key: "tech-issues",
          children: ["reportTechIssues", "allTechIssues"].map((item) => ({
            label: <MenuLink to={ROUTES[item]} label={t(item)} />,
            key: ROUTES[item]
          }))
        },
        {
          label: (
            <>
              {t("complaints")}
              <Tooltip placement="bottomLeft" title={t("complaintsTooltip")}>
                <QuestionCircleOutlined
                  style={{
                    position: "relative",
                    cursor: "help",
                    alignSelf: "end",
                    marginBottom: 7,
                    marginLeft: "5px",
                    color: "#474747"
                  }}
                />
              </Tooltip>
            </>
          ),
          key: "complaints",
          children: ["makeComplaint", "allComplaints"].map((item) => ({
            label: <MenuLink to={ROUTES[item]} label={t(item)} />,
            key: ROUTES[item]
          }))
        },

        ...["settings"].map((item) => ({
          label: <MenuLink to={ROUTES[item]} label={t(item)} isTop />,
          key: ROUTES[item]
        }))
      ]
      : () => [
        ...["userProfile", "companyProfile"].map((item) => ({
          label: <MenuLink to={ROUTES[item]} label={t(item)} isTop />,
          key: ROUTES[item]
        })),
        switchedTo === "SELLER" && {
          label: t("sellerTools"),
          key: "seller-tools",
          children: ["addProduct", "addService", "editProductOrService"].map((item) => ({
            label: <MenuLink to={ROUTES[item]} label={t(item)} />,
            key: ROUTES[item]
          }))
        },
        ...["settings"].map((item) => ({
          label: <MenuLink to={ROUTES[item]} label={t(item)} isTop />,
          key: ROUTES[item]
        }))
      ],
    [
      t,
      switchedTo,
      unprocessedOrdersSeller,
      productsServices,
      company,
      unprocessedIncomingMeetingsSeller,
      unprocessedIncomingModeratedMeetingsSeller,
      unprocessedIncomingMeetingsBuyer,
      unprocessedIncomingModeratedMeetingsBuyer,
      unreadMessagesTotal
    ]
  );

  return (
    <AntdSider width={304} className={s.sider}>
      <div className={s.userType}>
        <Button className={switchedTo === "BUYER" ? s.activeBtn : s.btn} onClick={switchToBuyer}>
          {t("buyer")}
          <Badge
            count={allBuyer}
            style={{ marginLeft: 5, backgroundColor: "#EA5B0C", boxShadow: "none" }}
          />
        </Button>

        <Button className={switchedTo === "SELLER" ? s.activeBtn : s.btn} onClick={switchToSeller}>
          {t("seller")}
          <Badge
            count={allSeller}
            style={{ marginLeft: 5, backgroundColor: "#EA5B0C", boxShadow: "none" }}
          />
        </Button>
      </div>

      {switchedTo === "BUYER" &&
        (connectedProfileType === "SELLER" || connectedProfileType === "DEACTIVATED") ? (
        <div className={s.wrapperConnect}>
          <div className={s.connect}>
            {t("connectProfileBuyer1")}
            <div
              onClick={() => {
                connectProfileHandler(1);
              }}
              className={s.connectLink}
            >
              {t("connectProfileBuyer2")}
            </div>
          </div>
        </div>
      ) : switchedTo === "SELLER" &&
        (connectedProfileType === "BUYER" || connectedProfileType === "DEACTIVATED") ? (
        <div className={s.wrapperConnect}>
          <div className={s.connect}>
            {t("connectProfileSeller1")}
            <div
              onClick={() => {
                // connectProfileHandler(2);
                navigate("/continue-registration");
              }}
              className={s.connectLink}
            >
              {t("connectProfileSeller2")}
            </div>
          </div>
        </div>
      ) : (
        <Menu
          className={s.menu}
          onOpenChange={(openKeys) => {
            setOpenKeys([openKeys[1]]);
          }}
          onClick={({ keyPath }) => {
            setOpenKeys([keyPath[1]]);
          }}
          selectedKeys={[location]}
          openKeys={openKeys}
          expandIcon={<DropdownIcon />}
          mode="inline"
          items={items}
        />
      )}

      <div className={s.itemWrapper}>
        <div className={s.item}>
          {user.firstName}&nbsp;{user.lastName}
        </div>
        <div className={s.item}>{user.email}</div>
        <div className={s.item}>
          {t("lastLoginDate")}:
          <br />
          {new Date(user && user.lastLoginDate).toLocaleString().slice(0, 10)}
        </div>
        <div className={s.item}>
          {t("support")}:
          <br />
          <a href="mailto:support@b2b-one.com" style={{ color: "#1890ff" }}>
            support@b2b-one.com
          </a>
        </div>
      </div>

      <Button
        className="logoutBtn"
        style={{ margin: 25 }}
        icon={<LoginIcon />}
        type="link"
        onClick={logout}
      >
        <span className="ml-15">{t("logout", { ns: "Header" })}</span>
      </Button>
    </AntdSider>
  );
};

export default Sider;
