import { employeesAPI, profileAPI } from "Api/api";

import { createSlice } from "@reduxjs/toolkit";

const normalizeData = (data) =>
  data.map(
    ({
      id,
      firstName,
      lastName,
      status,
      company,
      unprocessedOrdersCount,
      processedOrdersCount,
      answeredOrdersCount,
      finishedMeetingsCount,
      scheduledMeetingsCount,
      canceledMeetingsCount,
      finishedModeratedMeetingsCount,
      scheduledModeratedMeetingsCount,
      canceledModeratedMeetingsCount,
      createdAt,
      updatedAt
    }) => {
      const apiRequests = {};
      if (company?.type === 0) apiRequests.buyersIds = apiRequests.companiesIds = id;
      if (company?.type === 1) apiRequests.buyersIds = id;
      if (company?.type === 2) apiRequests.companiesIds = id;

      return {
        key: id,
        type: company?.type ?? "",
        fullName: `${firstName ?? ""} ${lastName ?? ""}`.trim(),
        company: company?.companyName ?? "",
        companyInfo: {
          country: company?.country?.localizedName ?? "",
          businessTypes: company?.businessTypes?.map(({ name }) => name),
          annualTurnover: company?.annualTurnover?.name ?? "",
          numberEmployees: company?.numberEmployees?.name ?? ""
        },
        tariff: company?.tariff?.id ?? "",
        verification: {
          verifiedBy: company?.adminVerifier?.email,
          verifiedAt: company?.verifiedAt,
          isVerified: company.isVerified,
        },
        value: "",
        status: status === 1,
        meetings: {
          finished: finishedMeetingsCount ?? 0,
          scheduled: scheduledMeetingsCount ?? 0,
          canceled: canceledMeetingsCount ?? 0
        },
        meetingsModer: {
          finished: finishedModeratedMeetingsCount ?? 0,
          scheduled: scheduledModeratedMeetingsCount ?? 0,
          canceled: canceledModeratedMeetingsCount ?? 0
        },
        requests: {
          answered: answeredOrdersCount ?? 0,
          processed: processedOrdersCount ?? 0,
          unprocessed: unprocessedOrdersCount ?? 0
        },
        registeredAt: createdAt ?? "",
        updatedAt: updatedAt ?? "",
        manager: {
          fullName: `${company?.manager?.lastName ?? ""} ${company?.manager?.firstName ?? ""
            }`.trim(),
          userId: id
        },
        apiRequests: apiRequests
      };
    }
  );

const normalizeStatistics = (data) =>
  data.reduce((arr, item) => {
    if (item.period === "All") arr[0] = { ...item, period: "totalUsers" };
    if (item.period === "Month") arr[1] = { ...item, period: "lastMonth" };
    if (item.period === "Day") arr[2] = { ...item, period: "lastDay" };
    return arr;
  }, []);

const normalizeManagers = (data) =>
  data.items.filter((item) => item.roleId === 2 || item.roleId === 3);

const initialState = {
  data: [],
  total: 10,
  managers: [],
  managersCount: 0,
  statistics: []
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = normalizeData(action.payload.items);
      state.total = action.payload.total;
    },
    setManagers(state, action) {
      state.managers = normalizeManagers(action.payload);
    },
    setManagersCount(state, action) {
      state.managersCount = action.payload;
    },
    setStatistics(state, action) {
      state.statistics = normalizeStatistics(action.payload);
    }
  }
});

export const { setData, setManagers, setManagersCount, setStatistics } = usersListSlice.actions;

export default usersListSlice.reducer;

export const getUsers = (params) => (dispatch) => {
  profileAPI
    .getAllUsers(params)
    .then((res) => {
      // console.log("DATA_B", res.data);
      dispatch(setData(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const getUsersCount = () => (dispatch) => {
  profileAPI
    .getUsersCount()
    .then((res) => {
      dispatch(setStatistics(res.data));
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

export const getManagers =
  ({ page, limit }) =>
    (dispatch) => {
      employeesAPI
        .getEmployeesTable({ rolesIds: "2,3", page, limit })
        .then((res) => {
          // console.log("MAN_B", res.data);
          dispatch(setManagers(res.data));
          dispatch(setManagersCount(res.data.total));
        })
        .catch((e) => {
          // console.log(e.response);
        });
    };
