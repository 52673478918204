/* eslint-disable react/prop-types */
import { Button, Col, Form, Input, Modal, Row, Select, Tooltip } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DropdownIcon from "../DropdownIcon/DropdownIcon";
import s from "../../pages/FA34-EmployeesList/EmployeesList.module.less";
import { employeesAPI } from "../../api/api";
import { getAllEmployees, getAllEmployeesTable } from "../../store/employeesListSlice";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";

const initialState = {
  role: false
};

export const AddUserModal = ({
  setModalMessage,
  setModalSuccessIsVisible,
  modalIsVisible,
  setModalIsVisible
}) => {
  const { t } = useTranslation(["FA34-EmployeesList", "Roles", "FS0-Home"]);
  const roles = useSelector((state) => state.resources.roles);
  const [form] = Form.useForm();

  const [openState, setOpenState] = useState(initialState);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [emailModalMessage, setEmailModalMessage] = useState("");
  const dispatch = useDispatch();

  const roless = [...roles.filter((role) => role.id !== 1)];

  const [role, setRole] = useState("");

  const onFinish = () => {
    let phone = phoneNumber.replace(/[()-]/g, "");
    setFirstName(firstName.trim());
    setLastName(lastName.trim());

    employeesAPI
      .addUser(role, firstName, lastName, email, password, phone)
      .then((res) => {
        if (res.status === 200) {
          form.resetFields();
          setModalIsVisible(false);
          setModalSuccessIsVisible(true);
          setModalMessage(t("modal.addUserSuccess"));
          dispatch(getAllEmployeesTable());
          dispatch(getAllEmployees());
        }
      })
      .catch((error) => {
        if (error.response.data.message === "VALIDATION_ERROR") {
          form.resetFields(["email"]);
          setIsEmail(true);
          setEmailModalMessage(t("email.errors.required", { ns: "FS0-Home" }));
        } else if (error.response.data.message === "EMAIL_ALREADY_EXISTS") {
          form.resetFields(["email"]);
          setIsEmail(true);
          setEmailModalMessage(t("modal.EMAIL_ALREADY_EXISTS"));
        }
      });
  };

  const { OnlyLatinLettersTextInput } = useOnlyLatinLetterInputHandlerAndModal();

  return (
    <>
      {isEmail && (
        <Modal
          visible={isEmail}
          onOk={() => setIsEmail(false)}
          onCancel={() => setIsEmail(false)}
          footer={[
            <Button key="ok" className="btn btn_v2" onClick={() => setIsEmail(false)}>
              OK
            </Button>
          ]}
        >
          <p style={{ marginTop: "20px" }}>{emailModalMessage}</p>
        </Modal>
      )}
      <div className={s.adduserModal}>
        <Modal
          visible={modalIsVisible}
          footer={[<div key={2} style={{ marginBottom: "20px" }}></div>]}
          onCancel={() => setModalIsVisible(false)}
          className={s.modal}
        >
          <h3 style={{ fontSize: "20px", fontWeight: 600, marginBottom: "15px" }}>
            {t("addUser.title")}
          </h3>
          <Form
            labelAlign="left"
            form={form}
            onFinish={onFinish}
            name="addUser"
            initialValues={{}}
            autoComplete="off"
            requiredMark={false}
            validateTrigger="onSubmit"
          >
            <Row gutter={[32]}>
              <Col span={12}>
                <Form.Item
                  style={{ display: "flex", flexDirection: "column" }}
                  name="role"
                  label={
                    <div className={`${s.label} ${s.req}`}>{t("addUser.label.role.title")}</div>
                  }
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 17 }}
                  rules={[
                    {
                      required: true,
                      message: t("addUser.label.role.required")
                    }
                  ]}
                >
                  <Select
                    style={{ width: "129%" }}
                    className={s.roles_selector}
                    onDropdownVisibleChange={(state) => {
                      setOpenState({ ...initialState, role: state });
                      form.setFields([{ name: "role", errors: [] }]);
                    }}
                    onChange={(val) => {
                      setRole(val);
                    }}
                    showArrow
                    open={openState.role}
                    placeholder={t("addUser.label.role.title")}
                    size="default"
                    suffixIcon={
                      <DropdownIcon
                        onClick={() => {
                          setOpenState((prevState) => ({
                            ...prevState,
                            role: !prevState.role
                          }));
                        }}
                      />
                    }
                  >
                    {roless.length &&
                      roless.map((role) => (
                        <Select.Option style={{ width: "100%" }} key={role.id} value={role.id}>
                          <Tooltip title={t(role.name, { ns: "Roles" })}>
                            {" "}
                            {t(role.name, { ns: "Roles" })}
                          </Tooltip>
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "129%"
                  }}
                  label={
                    <span className={`${s.label} ${s.req}`}>
                      {t("form.formInputs.firstName", { ns: "FS0-Home" })}
                    </span>
                  }
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: t("form.validation.firstNameRequired", { ns: "FS0-Home" })
                    }
                  ]}
                >
                  <Input
                    style={{ backgroundColor: "#F5F7F8", width: "71%" }}
                    size="middle"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      form.setFields([{ name: "firstName", errors: [] }]);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "129%"
                  }}
                  label={
                    <span className={`${s.label} ${s.req}`}>
                      {t("form.formInputs.lastName", { ns: "FS0-Home" })}
                    </span>
                  }
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: t("form.validation.lastNameRequired", { ns: "FS0-Home" })
                    }
                  ]}
                >
                  <MaskedInput
                    mask={/[a-zA-Zа-яА-Я]+$/}
                    style={{ backgroundColor: "#F5F7F8", width: "71%" }}
                    size="middle"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      form.setFields([{ name: "lastName", errors: [] }]);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  style={{ display: "flex", flexDirection: "column", width: "149%" }}
                  label={
                    <span className={`${s.label} ${s.req}`}>
                      {t("form.formInputs.phoneNumber", { ns: "FS0-Home" })}
                    </span>
                  }
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: t("form.validation.phoneNumberRequired", { ns: "FS0-Home" })
                    },
                    {
                      validator: (_, value) => {
                        if (!value || value.indexOf("_") !== -1) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <MaskedInput
                    maxLength={20}
                    mask={/^([0-9+])+$/}
                    style={{ height: "32px", backgroundColor: "#F5F7F8" }}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      form.setFields([{ name: "phone", errors: [] }]);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "149%"
                  }}
                  label={
                    <span className={`${s.label} ${s.req}`}>
                      {t("form.formInputs.email", { ns: "FS0-Home" })}
                    </span>
                  }
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("email.errors.required", { ns: "FS0-Home" })
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject(t("email.errors.required", { ns: "FS0-Home" }));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <MaskedInput
                    mask={/[^а-яА-Я]/gi}
                    type="email"
                    style={{ backgroundColor: "#F5F7F8" }}
                    size="middle"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      form.setFields([{ name: "email", errors: [] }]);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    width: "149%"
                  }}
                  label={
                    <span className={`${s.label} ${s.req}`}>
                      {t("addUser.label.password.title")}
                    </span>
                  }
                  className={s.passwordInput}
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    form.setFields([{ name: "password", errors: [] }]);
                  }}
                  rules={[
                    {
                      required: true,
                      message: t("addUser.label.password.required")
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length === 0) {
                          return Promise.reject("addUser.label.password.required");
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}
                >
                  <Input.Password style={{ backgroundColor: "#F5F7F8" }} size="middle" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle>
              <Button
                className="btn btn_v2"
                type="default"
                htmlType="submit"
                size="large"
                style={{ padding: "14px 40px", float: "left" }}
              >
                {t("btn2")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};
