import axios from "axios";
import { backendUrl } from "./api";

const instance = axios.create({
  baseURL: `${backendUrl}/meeting/`
});

const GET = {
  getAllMeetingsBuyer: (onlineMeetingModerator, initiator) => (config) =>
    instance.get(
      `all-meetings-buyer?onlineMeetingModerator=${onlineMeetingModerator}&initiator=${initiator}`,
      config
    ),
  getAllMeetingsSeller: (onlineMeetingModerator, initiator) => (config) =>
    instance.get(
      `all-meetings-seller?onlineMeetingModerator=${onlineMeetingModerator}&initiator=${initiator}`,
      config
    ),
  searchMeetingsSeller: (name, onlineMeetingModerator, initiator) => (value) =>
    instance.get(`search?${name}=${value}`, {
      params: { onlineMeetingModerator, initiator, isSeller: true }
    }),
  searchMeetingsBuyer: (name, onlineMeetingModerator, initiator) => (value) =>
    instance.get(`search?${name}=${value}`, {
      params: { onlineMeetingModerator, initiator, isSeller: false }
    })
};

export const meetingApi = { ...GET };
