import { Button, Form, Modal } from "antd";
import {
  setFile,
  setFileName,
  setFilesLoader,
  setLogo,
  setPhoto,
  setVideo,
  setVideoLoader
} from "../../store/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import AdditionalStuff from "Components/AddUser/AdditionalStuff";
import CompanyInfo from "Components/AddUser/CompanyInfo";
import CompanyInfoDetails from "Components/AddUser/CompanyInfoDetails";
import ContactInfo from "Components/AddUser/ContactInfo";
import MainMarkets from "Components/AddUser/MainMarkets";
import SocialLinks from "Components/AddUser/SocialLinks";
import UserInfo from "Components/AddUser/UserInfo";
import { getCountries } from "Store/resourcesSlice";
import { profileAPI } from "Api/api";
import s from "./AddUser.module.less";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddUser = () => {
  const { t, i18n } = useTranslation(["FA2.1-AddUser", "Countries", "utils"]);
  const { setTitle } = useOutletContext();
  const { logo, video, photo, files } = useSelector((state) => state.addUser);
  const { countries } = useSelector((state) => state.resources);
  const [modal, setModal] = useState({ visible: false, title: "" });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [hasLogoError, setHasLogoError] = useState(false);
  const [mainMarkets, setMainMarkets] = useState([]);
  const [companyPhotos, setCompanyPhotos] = useState([]);

  useEffect(() => {
    setTitle(t("title"));
  }, [t, setTitle]);

  useEffect(() => {
    if (!countries.length) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (logo) setHasLogoError(false);
  }, [logo]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const openModal = (title) => {
    setModal({ visible: true, title });
  };

  const closeModal = () => {
    setModal({ visible: false, title: "" });
  };

  const countriesOption = useMemo(
    () =>
      countries
        .map(({ id, name }) => ({ value: id, label: t(name, { ns: "Countries" }) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t, countries]
  );

  const onFinish = ({ user, company }) => {
    if (user.spareEmail === "") {
      delete user.spareEmail;
    }
    if (logo) {
      const companyData = {
        ...company,
        logo,
        files,
        video,
        photo,
        mainMarkets
      };
      companyData.images = companyPhotos;
      if (!files[0].filename && !files[0].description) {
        delete companyData.files;
      }
      companyData.keywords = company.keywords ? company.keywords.split(",") : [];
      profileAPI
        .createUser({
          user,
          company: companyData
        })
        .then((res) => {
          openModal(t("success"));
          form.resetFields();
          dispatch(setLogo(""));
          setMainMarkets([]);
          setCompanyPhotos([]);
          dispatch(setPhoto(""));
          dispatch(setVideo(""));
          dispatch(setFilesLoader(""));
          dispatch(setFileName(""));
          dispatch(setVideoLoader(""));
          dispatch(setFile([{ filename: "", description: "" }]));
        })
        .catch((err) => {
          let title = t("error");
          if (err.response?.data?.message === "USER_ALREADY_EXISTS") {
            title = t("userExist");
          } else if (err.response?.data?.message === "AUTH_REGISTER_MAIL_IS_USED") {
            title = t("emailExist", { ns: "utils" });
          }
          openModal(title);
        });
    } else {
      setHasLogoError(true);
    }
  };

  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  return (
    <section className={s.section}>
      {OnlyLatinLettersModal}
      <div className={s.scrollable}>
        <Form
          initialValues={{ "user.addImage": "" }}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center"
          }}
          className={s.form}
          autoComplete="off"
          labelAlign="left"
          labelWrap
          colon={false}
          requiredMark={false}
          onFinish={onFinish}
          onFinishFailed={({ values }) => {
            console.log(values);
            setHasLogoError(!logo);
          }}
          validateTrigger="onChange"
          form={form}
          style={{ width: 1130 }}
        >
          <UserInfo form={form} />
          <CompanyInfo hasError={hasLogoError} logo={logo} />
          <ContactInfo options={countriesOption} form={form} />
          <AdditionalStuff
            filesArray={companyPhotos}
            setFilesArray={setCompanyPhotos}
            modal={modal.title}
            form={form}
          />
          <SocialLinks />
          <CompanyInfoDetails />
          <MainMarkets
            mainMarkets={mainMarkets}
            setMainMarkets={setMainMarkets}
            options={countriesOption}
          />
          <Form.Item>
            <Button className={s.submit} type="default" htmlType="submit" size="large">
              {t("save")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        {...modal}
        destroyOnClose
        centered
        className={s.modal}
        width="max-content"
        maskClosable={false}
        onCancel={closeModal}
        footer={[
          <Button key={1} className={s.controlBtn} onClick={closeModal}>
            {t("back")}
          </Button>
        ]}
      />
    </section>
  );
};

export default AddUser;
