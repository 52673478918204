import { createSlice } from "@reduxjs/toolkit";

const statuses = {
  1: "Не подтверждена-NOT_CONFIRMED",
  2: "Подтверждена-CONFIRMED",
  3: "Отменена-CANCELED",
  4: "Проведена-COMPLETED"
};

const normalizeData = (data) =>
  data.map(
    ({ id, language, countryIdFk, dateFrom, createdAt, status, buyerIdMeetingFk, buyerId, zoomLink, order, languageId, unreadMessagesCount }) => {
      const date = new Date(dateFrom);
      date.setHours(date.getHours() + 2);

      return {
        key: id,
        unreadMessagesCount,
        product: order?.item ?? "",
        company: buyerIdMeetingFk?.companyName ?? "",
        fullName: `${buyerIdMeetingFk?.user?.firstName ?? ""
          } ${buyerIdMeetingFk?.user?.lastName ?? ""}`.trim(),
        country: buyerIdMeetingFk?.country?.localizedName ?? "",
        workMail: buyerIdMeetingFk?.user?.email ?? "",
        phoneNumber: buyerIdMeetingFk?.phone ?? "",
        productAmount: order?.itemAmount ?? "",
        message: order?.message ?? "",
        language: language ?? "",
        languageId: languageId ?? "",
        requestTime: createdAt ?? "",
        meetingTime: dateFrom ?? "",
        meetingDuration: date.toISOString(),
        link: zoomLink ?? "",
        toDialog: buyerId ?? "",
        status,
        buyerId,
        countryId: buyerIdMeetingFk?.country?.name ?? "",
      };
    }
  );

const initialState = {
  data: [],
  total: 10
};

const outgoingRequestsMeetingSellerSlice = createSlice({
  name: "outgoingRequestsMeetingSeller",
  initialState,
  reducers: {
    setData(state, action) {
      state.total = action.payload.total;
      state.data = normalizeData(action.payload.items);
    }
  }
});

export const { setData } = outgoingRequestsMeetingSellerSlice.actions;

export default outgoingRequestsMeetingSellerSlice.reducer;
