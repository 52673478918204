import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  photo: "",
  logo: "",
  files: [{ filename: "", description: "" }],
  filesLoader: "",
  fileName: "",
  video: "",
  videoLoader: ""
};

const addUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    setPhoto(state, action) {
      state.photo = action.payload;
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setFile(state, action) {
      state.files = action.payload;
    },
    setVideo(state, action) {
      state.video = action.payload;
    },
    setFilesLoader(state, action) {
      state.filesLoader = action.payload;
    },
    setFileName(state, action) {
      state.fileName = action.payload;
    },
    setVideoLoader(state, action) {
      state.videoLoader = action.payload;
    }
  }
});

export const { setPhoto, setLogo, setFile, setVideo, setFilesLoader, setFileName, setVideoLoader } =
  addUserSlice.actions;

export default addUserSlice.reducer;
