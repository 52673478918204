import React, {useEffect, useMemo, useState} from 'react';
import s from "./PreviewProfile.module.less";
import style from "../FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import CompanyHat from "../FK3-сompanyProfile/CompanyHat/CompanyHat";
import { useSelector } from "react-redux";
import Nav from "../FK3-сompanyProfile/Nav/Nav";
import PreviewAbout from "./PreviewAbout";
import {Button, Form} from "antd";
import {useTranslation} from "react-i18next";
import PreviewNav from './PreviewNav/PreviewNav';

const PreviewProfile = ({setPreview, onFinish, form, videoLink}) => {

  const prof = useSelector((state) => state.changeCompanyProfile);
  const { t } = useTranslation("FS8-changeCompanyProfile");



  return (
    <div className={s.wrapper}>
      <div style={{margin: '0 -32px'}}>
        <CompanyHat profile={prof} />
      </div>
      <PreviewNav prof={prof} videoLink={videoLink}/>
      <div
        className={style.btnWrapper}
        style={{
          margin: "40px 0 10px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end"
        }}
      >
        <Form.Item noStyle>
          <Button
            className="btn btn_v8"
            type="default"
            htmlType="submit"
            size="large"
            style={{ padding: "26px 54px" }}
            onClick={() => setPreview(false)}
          >
            {t("btn3")}
          </Button>
        </Form.Item>

        <Form.Item noStyle>
          <Button
            state={{modal: 'moderation'}}
            className="btn btn_v2"
            size="large"
            onClick={() => {
              setPreview(false)
              onFinish(true, t("modal.error1"), "done", form.getFieldValue());
            }}
            style={{
              padding: "26px 54px",
              fontWeight: "400",
              fontSize: "14px",
              marginLeft: "10px"
            }}
          >
            <div className={style.btn1}>{t("btn4")}</div>
          </Button>
        </Form.Item>

      </div>
    </div>
  );
};

export default PreviewProfile;
