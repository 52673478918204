import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export const dateFormat = "DD.MM.YYYY";

export const workTime = (date, tz) => {
  if (typeof tz === "string") {
    const newDate = new Date(date);
    newDate.setMinutes(
      +newDate.getMinutes() +
        +tz.split("UTC")[1].split(":")[0] * 60 +
        Number(+tz.split(":")[1]) +
        +newDate.getTimezoneOffset()
    );
    return date && dayjs(newDate);
  }
  return date && dayjs(date).tz("Asia/Dubai");
};

export const showWorkTime = (date, tz) => date && workTime(date, tz).format("DD.MM.YYYY\nHH:mm");

export const showTimeWithoutHours = (date) => date && workTime(date).format("DD.MM.YYYY");

export const timeInUTC = (date) => dayjs(date).utc(); //do not touch!

export const formatUTCTime = (date, format) => timeInUTC(date).format(format);

export const checkIsSameOrBefore = (date, dateTo) =>
  timeInUTC(date).isSameOrBefore(timeInUTC(dateTo), "day");

export const checkIsSameOrAfter = (date, dateFrom) =>
  timeInUTC(date).isSameOrAfter(timeInUTC(dateFrom), "day");

export const checkIsBetween = (date, dateFrom, dateTo) =>
  timeInUTC(date).isBetween(timeInUTC(dateFrom), timeInUTC(dateTo), "day", "[]");

export const calcDiffInDays = (dateFrom, dateTo) =>
  timeInUTC(dateTo).diff(timeInUTC(dateFrom), "day");

export const getDiffInMinites = (meetingTime, currentDate) => {
  const date1 = dayjs(meetingTime).tz("Europe/London");
  const diff = date1.diff(currentDate, "m");
  return diff;
};
export const getCurrentDate = () => {
  return dayjs().tz("Europe/London");
};
