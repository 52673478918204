import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const TooltipShowcase = () => {
  const {t} = useTranslation('FS8-changeCompanyProfile');

  return (
    <>
      <div style={{ whiteSpace: "pre-line" }}>
        <p>
          {t("tooltip1")}
          <NavLink
            target={"_blank"}
            style={{ color: "rgb(234, 91, 12)" }}
            to={"/account/add-product"}
          >
            {t("tooltipLink1")}
          </NavLink>
        </p>
        {/* <p>
          {t("tooltip2")}
          <NavLink
            target={"_blank"}
            style={{ color: "rgb(234, 91, 12)" }}
            to={"/account/add-service"}
          >
            {t("tooltipLink2")}
          </NavLink>{" "}
          {t("tooltipLink3")}
        </p> */}
      </div>
        <div style={{ whiteSpace: "pre-line", marginTop: '10px' }}>
        <p>
          {t("tooltip2")}
          <NavLink
            target={"_blank"}
            style={{ color: "rgb(234, 91, 12)" }}
            to={"/account/add-service"}
          >
            {t("tooltipLink2")}
          </NavLink>{" "}
          {t("tooltipLink3")}
        </p>
        {/* <span>
          {t("tooltip3")}
          <NavLink
            target={"_blank"}
            style={{ color: "rgb(234, 91, 12)" }}
            to={"/account/edit-product-or-service"}
          >
            {t("tooltipLink4")}
          </NavLink>
        </span>
        <span>
          {t("tooltip4")}
        </span> */}
      </div>
    </>
  );
};

export default TooltipShowcase;
