import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  getAllEmployees,
  getAllEmployeesTable,
  searchEmployee
} from "../../store/employeesListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AddUserModal } from "../../components/FA34-Employees-list/addUserModal";
import { FA34EMployeesListTable } from "../../components/FA34-Employees-list/FA34EmployeesListTable";
import Pagination from "../../components/Pagination/Pagination";
import { backendUrl } from "../../api/api";
import { getAllRoles } from "../../store/resourcesSlice";
import s from "./EmployeesList.module.less";
import { getDateFrom } from "../../helpers/timeFormat";
import PageSizePicker from "../../components/PageSizePicker/PageSizePicker";

export const EmployeesList = () => {
  const dispatch = useDispatch();
  const { setTitle } = useOutletContext();
  const { t } = useTranslation(["FA34-EmployeesList", "Roles", "FS0-Home", "utils"]);
  const { allEmployees } = useSelector((state) => state.employees);
  const tableData = useSelector((state) => state.employees.allEmployeesTable);
  const { lang } = useSelector((state) => state.auth);
  const [totalPages, setTotalPages] = useState(1);
  const [fullName, setFullName] = useState("");

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [qParams, setQParams] = useState({
    page: 1,
    limit: 10,
    dateStartFrom: null,
    dateEndTo: null,
    sortColumn: null,
    sortDirection: null,
    rolesIds: null,
    isBlocked: null,
    dateFrom: null,
    dateTo: null
  });
  const [modalSuccessIsVisible, setModalSuccessIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    setTitle(t("title"));
  }, [t, setTitle]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAllRoles());
  }, []);

  useEffect(() => {
    dispatch(getAllEmployeesTable(qParams));
  }, [qParams]);

  const changePageSize = (e) => {
    setQParams((prev) => ({ ...prev, limit: e }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(searchEmployee(fullName));
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [fullName]);

  useEffect(() => {
    setTotalPages(tableData.total);
  }, [tableData, lang]);

  const handleAddUserModal = () => {
    setModalIsVisible(true);
  };

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") setModalSuccessIsVisible(false);
    });
  }, [document]);
  const handleRoleId = (id, period) => {
    if (period === "Month") {
      const dateFrom = getDateFrom("Month", true);
      setQParams((prev) => ({
        ...prev,
        dateFrom: dateFrom,
        dateTo: null,
        dateEndTo: null,
        sortColumn: null,
        sortDirection: null,
        rolesIds: null,
        dateStartFrom: null,
        isBlocked: null
      }));
    } else if (period === "lastDay") {
      const today = getDateFrom("Today");
      const yesterday = getDateFrom("Day", true);
      setQParams((prev) => ({
        ...prev,
        dateFrom: yesterday,
        dateTo: today,
        dateEndTo: null,
        sortColumn: null,
        sortDirection: null,
        rolesIds: null,
        dateStartFrom: null,
        isBlocked: null
      }));
    } else if (id === "total") {
      setQParams((prev) => ({
        ...prev,
        dateFrom: null,
        dateTo: null,
        dateEndTo: null,
        sortColumn: null,
        sortDirection: null,
        rolesIds: null,
        dateStartFrom: null,
        isBlocked: null
      }));
    } else if (id === "deleted") {
      setQParams((prev) => ({
        ...prev,
        dateFrom: null,
        dateTo: null,
        dateEndTo: null,
        sortColumn: null,
        sortDirection: null,
        rolesIds: null,
        dateStartFrom: null,
        isBlocked: true
      }));
    } else {
      setQParams((prev) => ({
        ...prev,
        rolesIds: id,
        isBlocked: null,
        page: 1,
        dateFrom: null,
        dateTo: null
      }));
    }
  };

  const employeesCountRender = useMemo(
    () =>
      allEmployees?.map((employee, index) => {
        const style = employee.name === "thisMonth" || employee.name === "lastDay";
        if (!style)
          return (
            <p className={s.count} key={`${employee.amount}_${index}`}>
              {t(`employeesCount.${employee.name}`)}{" "}
              <span className={s.amount} onClick={() => handleRoleId(employee.roleId)}>
                {employee.amount}
              </span>
            </p>
          );
      }),
    [allEmployees, lang, t]
  );
  return (
    <>
      {modalSuccessIsVisible ? (
        <Modal
          visible={modalSuccessIsVisible}
          onOk={() => setModalSuccessIsVisible(false)}
          onCancel={() => setModalSuccessIsVisible(false)}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalSuccessIsVisible(false);
              }}
            >
              {t("modal.buttonOk", { ns: "FA34-EmployeesList" })}
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      ) : null}
      {modalIsVisible ? (
        <AddUserModal
          setModalSuccessIsVisible={setModalSuccessIsVisible}
          setModalMessage={setModalMessage}
          modalIsVisible={modalIsVisible}
          setModalIsVisible={setModalIsVisible}
        />
      ) : null}

      <section className="regular_block">
        <div className={s.buttons}>
          <a
            href={`${backendUrl}/exports/admins`}
            style={{ display: "inline-block", marginRight: "auto" }}
          >
            <button className={`btn btn_v3 excel_btn`}>{t("downloadExel", { ns: "utils" })}</button>
          </a>
          <Button className={s.addUser} onClick={handleAddUserModal}>
            {t("buttons.addUser")}
          </Button>
        </div>
        <Row gutter={[34]} style={{ marginBottom: "55px", marginTop: "55px" }}>
          <Col span={14}>{employeesCountRender}</Col>
          <Col>
            <p className={s.count}>
              {t("employeesCount.thisMonth")}{" "}
              <span className={s.amount} onClick={() => handleRoleId(0, "Month")}>
                {allEmployees?.find((item) => item.name === "thisMonth")?.amount}
              </span>
            </p>
            <p className={s.count}>
              {t("employeesCount.lastDay")}{" "}
              <span className={s.amount} onClick={() => handleRoleId(0, "lastDay")}>
                {allEmployees?.find((item) => item.name === "lastDay")?.amount}
              </span>
            </p>
          </Col>
        </Row>

        <Row gutter={[32]} style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
          <Col span={14}>
            <PageSizePicker setPageSize={changePageSize} employee />
          </Col>
          <Col>
            <div style={{ display: "flex", gap: "67px", alignItems: "center" }}>
              <p style={{ fontWeight: 500, fontSize: "16px" }}>{t("search")}</p>
              <Input
                allowClear
                onChange={(e) => setFullName(e.target.value)}
                style={{ width: "290px", height: "40px", backgroundColor: "#F5F7F8" }}
              />
            </div>
          </Col>
        </Row>

        <div style={{ overflowX: "auto" }}>
          <FA34EMployeesListTable
            pageSize={qParams.limit}
            tableData={tableData?.items}
            current={qParams.page}
            setQParams={setQParams}
          />
        </div>
        <br />
        <Pagination
          pageSize={qParams.limit}
          current={qParams.page}
          setCurrent={(num) => {
            setQParams((prev) => ({ ...prev, page: num }));
          }}
          total={totalPages}
          style={{ margin: "24px auto 0" }}
        />
      </section>
    </>
  );
};
