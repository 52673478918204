import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import s from "./MenuLink.module.less";
import {Trans} from 'react-i18next';

const SEPARATOR = " ";

const MenuLink = ({ count, label, to, state, isTop = false, tooltip = "", barge }) => {
  let lastWord,
    text = label;

  if (count) {
    text = label.split(SEPARATOR);
    lastWord = text.pop();
    if (text.length) text.push("");
    text = text.join(SEPARATOR);
  }

  return (
    <NavLink
      to={to}
      state={state}
      className={({ isActive }) => (isTop ? s.active : isActive ? s.active : s.link)}
    >
      <Trans>{text}</Trans>
      {count > 0 && (
        <span className={s.noWrap}>
          {lastWord}
          <Badge
            count={count}
            style={{
              marginLeft: 5,
              backgroundColor: "#EA5B0C"
            }}
          />
        </span>
      )}
      {tooltip && (
        <Tooltip placement="bottomLeft" title={tooltip} overlayStyle={{whiteSpace: 'pre-line', maxWidth: '500px'}} >
          <QuestionCircleOutlined
            style={{
              position: "relative",
              cursor: "help",
              alignSelf: "end",
              marginBottom: 7,
              marginLeft: "5px",
              color: "#474747"
            }}
          />
        </Tooltip>
      )}
    </NavLink>
  );
};

MenuLink.propTypes = {
  count: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
  isTop: PropTypes.bool,
  tooltip: PropTypes.string
};

export default memo(MenuLink);
