import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  filters: {
    platformServiceType: null
  }
};

const adminInquiries = createSlice({
  name: "adminInquiries",
  initialState,
  reducers: {
    appendFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state, action) => {
      state.filters = { ...initialState.filters, ...action.payload };
    }
  }
});

export const { appendFilters, resetFilters } = adminInquiries.actions;

export default adminInquiries.reducer;
