import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import {NavLink, useOutletContext} from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {backendUrl, domain, mediaUrl, profileAPI} from '../../api/api';
import DropdownIcon from '../../components/DropdownIcon/DropdownIcon';
import {
  fetchAnnualTurnoverTypes,
  fetchCountries,
  fetchMyCompany,
  fetchNumberEmployeesTypes,
  fetchPercentageTurnoverTypes, fetchStatusModeration,
  setAddress2,
  setAddress3,
  setAnnualTurnover,
  setBusinessCompanyName,
  setBusinessTypes,
  setCity,
  setCompanyName,
  setCountry,
  setDescription,
  setFaceBook,
  setFile,
  setFileName,
  setHouseNumber,
  setImages,
  setImagesName,
  setIndex,
  setInitialCustomProfile,
  setInitialLogoSet,
  setInitialProfile,
  setInstagram,
  setKeywords,
  setLinkedIn,
  setLogo,
  setLogoLoader,
  setMainMarkets,
  setMembershipAssociations,
  setNumberEmployees,
  setPercentageExportsTurnover,
  setPercentageImportsTurnover,
  setPhone,
  setPhone2,
  setRegion,
  setRegistrationNumber, setSavedVideo, setShowcase,
  setStreet,
  setTwitter,
  setVideo,
  setVideoLoader, setVideoYT,
  setWebsite,
  setYearFoundation,
  setYoutube,
  trashLogo,
} from '../../store/changeCompanyProfileSlice';
import {useDispatch, useSelector} from 'react-redux';

import AddFiles from './AddFiles';
import AddPhoto from './AddPhoto';
import Img from '../../assets/svg/Img';
import PlugItem from '../FS9-showcaseSelection/PlugItem';
import ShowcaseSelection from '../FS9-showcaseSelection/ShowcaseSelection';
import TextArea from 'antd/es/input/TextArea';
import Trash from '../../assets/svg/trash';
import axios from 'axios';
import {fetchBusinessType} from '../../store/resourcesSlice';
import plusSvg from '../../assets/svg/plus.svg';
import s from './ChangeCompanyProfile.module.less';
import useOnlyLatinLetterInputHandlerAndModal from '../../hooks/useOnlyLatinLetterInputHandlerAndModal';
import {useTranslation} from 'react-i18next';
import PreviewProfile from '../FS15-previewProfile/PreviewProfile';
import {QuestionCircleOutlined} from '@ant-design/icons';
import TooltipShowcase from './TooltipShowcase';
import {TooltipWithLink} from '../../components/TooltipWithLink/TooltipWithLink';
import Verified from '../../assets/svg/Verified';
import NotVerified from '../../assets/svg/NotVerified';
import {customKeyWordsValidator} from '../../helpers/validation-rules';
import ShowModal from '../FS9-showcaseSelection/ShowModal';
import AddAndCropLogo from '../../components/AddAndCropLogo/AddAndCropLogo';
import { notificationsFeedAPI } from '../../api/api';

import {tariffDescriptionJSONtoHTML} from '../../helpers/tariffDescriptionJSONtoHTML';
import Professional from '../../assets/svg/Professional';
import PremiumMember from '../../assets/svg/PremiumMember';
import MainMarkets from '../../components/MainMarkets/MainMarkets';
import Loader from '../../components/Loader/Loader';
import {MaskedInput} from "antd-mask-input";

const ChangeCompanyProfile = () => {
  const {businessType} = useSelector((state) => state.resources);
  const [preview, setPreview] = useState(false);
  const {user, lang} = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [preview])

  let {files, images, showcase, savedVideo, videoYT} = useSelector(
    (state) => state.changeCompanyProfile.customProfile,
  );

  let {logoSet} = useSelector((state) => state.changeCompanyProfile);

  const {annualTurnoverTypes, numberEmployeesTypes, percentageTurnoverTypes, countries} =
    useSelector((state) => state.changeCompanyProfile.resources);
  let {
    companyName,
    businessCompanyName,
    website,
    description,
    street,
    houseNumber,
    address2,
    address3,
    phone,
    phone2,
    country,
    region,
    city,
    index,
    facebook,
    youtube,
    twitter,
    linkedin,
    yearFoundation,
    numberEmployees,
    percentageImportsTurnover,
    percentageExportsTurnover,
    annualTurnover,
    mainMarkets,
    membershipAssociations,
    videoLoader,
    video,
    businessTypes,
    registrationNumber,
    companyPhotos,
    companyFiles,
    products,
    services,
    instagram,
    logo,
  } = useSelector((state) => state.changeCompanyProfile.profile);

  const {profile, loader} = useSelector((state) => state.changeCompanyProfile);

  const businessTypesNormal = useMemo(() => {
    if (businessTypes[0]?.id) {
      return businessTypes.map((m) => m.id);
    } else {
      return businessTypes;
    }
  }, [businessTypes]);

  const {OnlyLatinLettersTextInput, OnlyLatinLettersModal} =
    useOnlyLatinLetterInputHandlerAndModal();

  const dispatch = useDispatch();
  const {Option} = Select;
  const {setTitle} = useOutletContext();
  const {t} = useTranslation([
    'FS8-changeCompanyProfile',
    'Countries',
    'FS5-Registration',
    'FK3-сompanyProfile',
    'FS10-AddNewProduct',
    'utils',
    'tariffDescription',
  ]);
  const [form] = Form.useForm();
  const [videoType, setVideoType] = useState('uploadVideo');
  const [visible, setVisible] = useState(false);
  const initialState = {
    countries: false,
    numberEmployees: false,
    percentageTurnoverTypes: false,
    percentageImportsTurnover: false,
    percentageExportsTurnover: false,
    annualTurnover: false,
  };

  const [openState, setOpenState] = useState(initialState);

  const status = useCallback((stat) =>  {
    if (stat === 'MODERATION') return <div className={`${s.status} ${s.status__red}`}>{t("status.1")}</div>
    else if (stat ==='EDIT') return <div className={`${s.status} ${s.status__red}`}>{t("status.2")}</div>
    else if (stat ==='ACCEPT') return <div className={`${s.status} ${s.status__green}`}>{t("status.3")}</div>
    else if (stat ==='REJECT') return <div className={`${s.status} ${s.status__red}`}>{t("status.4")}</div>
  }, [profile])

  const optionsWithDisabled = businessType.map((m) => ({label: t(m.name), value: m?.id}));
  let market = useMemo(() => {
    if (mainMarkets !== null && mainMarkets[0]?.id) {
      return profile.mainMarkets.map((m) => m.id);
    } else if (mainMarkets) {
      return mainMarkets;
    } else {
      return [];
    }
  }, [profile.mainMarkets]);

  const logoLoaderStyle = useMemo(() => {
    if (logoSet.loader === t('loading')) {
      return {color: 'red'}
    } else if (logoSet.loader === t('videoError')) {
      return {color: 'red', maxWidth: '120px', top: 10, right: 5}
    }  else {
      return {color: 'green'}
    }
  }, [logoSet.loader])

  //блокировка автойсева при загрузке страницы
  const [autosaveDisabled, setAutosaveDisabled] = useState(true)
  useEffect(() => {
    if (!loader) {
      const id = setTimeout(() => {
        setAutosaveDisabled(false)
      }, 1000)
      return () =>  clearInterval(id)
    }
  }, [loader])

  // автосохранение при изменении любого поля
  const autoSaveTimeoutId = useRef(0);
  const autoSave = (field = {}) => {
    if (!loader && !autosaveDisabled) {
      clearTimeout(autoSaveTimeoutId.current);
      autoSaveTimeoutId.current = setTimeout(() => {
        const values = form.getFieldsValue();
        onFinish(false, null, null, Object.assign(values, field));
      }, 1000);
    }
  };
  useEffect(() => {
    if (!loader) autoSave();
    return () => {
      clearTimeout(autoSaveTimeoutId.current);
    };
  }, [video, images, logo, files, market, savedVideo, videoYT]);

  useEffect(() => {
    const companyProfileId = profile.id && [profile.id]
      if(companyProfileId) {
        notificationsFeedAPI.markNotificationsAsReadNew([profile.id], 'company');
      }
  }, [profile.id])


  useEffect(() => {
    if (video) {
      if (video.includes('youtube') || video.includes('youtu.be')) {
        setVideoType('youTubeLink');
        dispatch(setVideoYT(video))
      } else {
        setVideoType('uploadVideo');
        dispatch(setSavedVideo(video))
      }
    }
  }, [video]);

  const countriesArr = useMemo(
    () =>
      countries
        .map((f) => ({
          ...f,
          name: t(f.name, {ns: 'Countries'}),
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [t, countries],
  );

  const [error, setError] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const plug = [0, 1, 2, 3];

  const [visibleCropLogo, setVisibleCropLogo] = useState(false);
  const [img, setImg] = useState({});

  const handleLogoSelect = async (file) => {
    form.setFields([{name: 'logo', errors: []}]);
    dispatch(setLogoLoader({loader: t('loading')}));
    const formData = new FormData();
    formData.append('file', file);
    formData.append('originalname', file?.name);
    try {
      const res = await axios({
        method: 'post',
        url: `${backendUrl}/upload/image`,
        data: formData,
      });
      if (res.status === 200) {
        dispatch(setLogo(res.data.filename));
        dispatch(setLogoLoader({fileName: file?.name, loader: t('loaded')}));
        autoSave({logo: res.data.filename});
      }
    } catch (error) {
      dispatch(setLogo(''));
      dispatch(setLogoLoader({fileName: file?.name, loader: t('videoError')}));
    }
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].size < 50000000) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImg({base64: reader.result?.toString() || '', name: e.target.files[0].name}),
      );
      reader.readAsDataURL(e.target.files[0]);
      setVisibleCropLogo(true);
    } else if (e.target.files[0].size > 50000000) {
      document.getElementById('fileInput').value = '';
      setModalMessage(t('logoModalError'));
    }
  };

  const inptRef = useRef(null);

  const handleVideoSelect = async (e) => {
    const file = e.target.files[0];
    if (!savedVideo) {
      dispatch(setSavedVideo(e.target.files[0].name));
      dispatch(setVideoLoader(t('loading')));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('originalname', file?.name);
      try {
        const res = await axios({
          method: 'post',
          url: `${domain}api/upload/video`,
          data: formData,
          headers: {'Content-Type': 'multipart/form-data'},
        });
        if (res.status === 200) {
          dispatch(setSavedVideo(res.data.filename));
          dispatch(setVideoLoader(t('loaded')));
        }
      } catch (error) {
        console.log(error)
        dispatch(setSavedVideo(''));
        dispatch(setVideoLoader(t('videoError')));
      }
    }
  };

  useEffect(() => {
    let arr = [];
    if (images[0] === '' && companyPhotos?.length) {
      dispatch(setImages(companyPhotos.map((m) => m.photo)));
      companyPhotos.forEach((f) => arr.push({fileName: f.photo, loader: t('loaded')}));
      dispatch(setImagesName(arr));
    }
  }, [dispatch, companyPhotos]);

  useEffect(() => {
    let arr1 = [];
    let arr2 = [];
    if (files[0]?.filename === '' && companyFiles?.length) {
      companyFiles.forEach((f) =>
        arr1.push({filename: f.filename, description: f.description, id: f.id}),
      );
      companyFiles.forEach((f) => arr2.push({name: f.filename, loader: t('loaded')}));
      if (profile.id) {
        dispatch(setFile(arr1));
        dispatch(setFileName(arr2));
      }
    }
  }, [dispatch, companyFiles]);

  const companyKeyWordsNormalize = (type) => {
    const value = profile.companyKeywords;
    if (type === 'onFinish') {
      if (value === undefined || value === null) {
        return [];
      } else if (typeof value === 'string') {
        return value.split(', ');
      } else if (typeof value === 'object') {
        return value.map((m) => m.keyword.trim());
      } else {
        return value;
      }
    } else if (type === 'formData') {
      if (value === undefined || value === null) {
        return '';
      } else if (typeof value === 'object') {
        return value.map((m) => m.keyword.trim()).join(', ');
      } else {
        return value;
      }
    }
  };

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    let cheek = services.length ? showcase.find((f) => f.id !== services[0].id) : [];
    if (!showcase.length && products?.length) {
      arr = products.filter((f) => f.visible && !f.onModeration && f).map((m) => ({entityId: m?.id, entityType: 'product'}));
    }
    if (services.length && !cheek) {
      arr2 = services.filter((f) => f.visible && !f.onModeration && f).map((m) => ({entityId: m?.id, entityType: 'service'}));
    }
    if (profile.id) {
      dispatch(setShowcase([...arr, ...arr2]));
    }
  }, [dispatch, products, services]);

  const selectedVideo = () => {
    if ((videoType === 'youTubeLink' && videoYT) || (videoType === 'uploadVideo' && !savedVideo && videoYT)) {
      return videoYT
    } else if ((videoType === 'uploadVideo' && savedVideo) || (videoType === 'youTubeLink' && savedVideo && !videoYT)) {
      return savedVideo
    }
    else return ''
  }

  const onFinish = async (sendModeration, errorText, doneText, value = {}) => {
    let file = files[0].filename !== '' ? files.filter((f) => f.filename !== '' && f) : [];
    let image = images[0] !== '' ? images.filter((f) => f !== '' && f) : [];
    for (let prop in value) {
      if (value[prop] === null &&
        prop !== 'numberEmployees' &&
        prop !== 'percentageImportsTurnover' &&
        prop !== 'percentageExportsTurnover' &&
        prop !== 'annualTurnover' &&
        prop !== 'yearFoundation') {
        value[prop] = '';
      }
    }
    if (value.companyKeywords.length) value.keywords = value.companyKeywords.split(',')
    else if (!value.companyKeywords.length) value.keywords = []
    delete value.companyKeywords
    const body = {
      ...value,
      sendModeration: sendModeration,
      countryId: country?.id ? country.id : country,
      video: selectedVideo(),
      logo: logo,
      files: file,
      images: image,
    };
    profileAPI
      .updateProfileCompany(Object.assign(body, {logo: logo}))
      .then((res) => {
        setModalMessage(doneText);
        dispatch(fetchStatusModeration(false))
      })
      .catch((e) => {
        setModalMessage(errorText);
      });
  };
  const select1 = (value) => {
    dispatch(setAnnualTurnover(value));
    autoSave()
  };
  const select2 = (value) => {
    dispatch(setNumberEmployees(value));
    autoSave()
  };
  const select3 = (value) => {
    dispatch(setPercentageImportsTurnover(value));
    autoSave()
  };
  const select4 = (value) => {
    dispatch(setPercentageExportsTurnover(value));
    autoSave()
  };

  const select6 = (value) => {
    dispatch(setCountry(value));
  };
  useEffect(() => {
    if (error) {
      setError(false);
    }
    if (logo) {
      setLogoError(false);
    }
  }, [businessTypesNormal, logo]);
  useEffect(() => {
    setTitle(t('title'));
  }, [t, setTitle]);

  useEffect(() => {
    dispatch(fetchAnnualTurnoverTypes());
    dispatch(fetchNumberEmployeesTypes());
    dispatch(fetchPercentageTurnoverTypes());
    dispatch(fetchCountries());
    dispatch(fetchBusinessType());
    dispatch(fetchMyCompany(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setInitialProfile());
      dispatch(setInitialLogoSet());
      dispatch(setInitialCustomProfile());
    };
  }, []);

  const formData = {
    annualTurnover:
      typeof profile.annualTurnover === 'object' && profile.annualTurnover !== null
        ? profile.annualTurnover.id
        : profile.annualTurnover,
    businessCompanyName: profile.businessCompanyName,
    city: profile.city,
    companyName: profile.companyName,
    country:
      profile.country !== undefined && profile.country.id ? profile.country.id : profile.country,
    description: profile.description,
    faceBook: profile.facebook,
    houseNumber: profile.houseNumber,
    index: profile.index,
    linkedIn: profile.linkedin,
    instagram: profile.instagram,
    logo: profile.logo,
    membershipAssociations: profile.membershipAssociations,
    numberEmployees:
      typeof profile.numberEmployees === 'object' && profile.numberEmployees !== null
        ? profile.numberEmployees.id
        : profile.numberEmployees,
    percentageExportsTurnover:
      typeof profile.percentageExportsTurnover === 'object' &&
      profile.percentageExportsTurnover !== null
        ? profile.percentageExportsTurnover.id
        : profile.percentageExportsTurnover,
    percentageImportsTurnover:
      typeof profile.percentageImportsTurnover === 'object' &&
      profile.percentageImportsTurnover !== null
        ? profile.percentageImportsTurnover.id
        : profile.percentageImportsTurnover,
    phone: profile.phone,
    phone2: profile.phone2,
    region: profile.region,
    registrationNumber: profile.registrationNumber,
    street: profile.street,
    twitter: profile.twitter,
    // video: profile.video,
    youTubeLink: videoYT,
    uploadVideo: savedVideo,
    website: profile.website,
    yearFoundation: profile.yearFoundation,
    youtube: profile.youtube,
    companyKeywords: companyKeyWordsNormalize('formData'),
    mainMarkets: market,
    address2: profile.address2,
    address3: profile.address3,
    videoType: videoType,
    businessTypes: businessTypesNormal,
  };

  useEffect(() => {
    let descArr = {};
    for (const [key, value] of Object.entries(files)) {
      if (value.description !== '') {
        descArr['descriptionFile' + key] = value.description;
      }
    }
    form.setFieldsValue(descArr);
  }, [files]);
  useEffect(() => {
    form.resetFields([`descriptionFile${files.length - 1}`]);
  }, [files.length]);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [form, market, videoType, profile]);
  //из deps убрал profile, надо глянуть последствия
  //вернул, при отправке на модерацию пропадают поля keywords

  const inputHandler = (e, num) => {
    let reg = /[^\d+().-]/gi;
    if (num === 1) {
      dispatch(setPhone(e.target.value.replace(reg, '')));
      form.setFieldsValue({phone: e.target.value.replace(reg, '')});
      form.setFields([{name: 'phone', errors: []}]);
    } else if (num === 2) {
      dispatch(setPhone2(e.target.value.replace(reg, '')));
      form.setFieldsValue({phone2: e.target.value.replace(reg, '')});
      form.setFields([{name: 'phone2', errors: []}]);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        setModalMessage(false);
      }
    });
  }, [document]);

  const validateInput = (e) => {
    let pattern = /[^a-z\s\d-@,.?!/:]/gi;
    if (e.match(pattern)) {
      return e.replace(pattern, '');
    } else {
      return e;
    }
  };
  if (loader) {
    return <Loader/>;
  } else if (preview) {
    return <PreviewProfile setPreview={setPreview} onFinish={onFinish} form={form} videoLink={selectedVideo()}/>;
  } else {
    return (
      <div className={`${s.wrapper} regular_block`}>
        {OnlyLatinLettersModal}
        {modalMessage ? (
          <Modal
            visible={modalMessage}
            onOk={() => setModalMessage('')}
            onCancel={() => setModalMessage('')}
            footer={[
              <Button
                key="ok"
                className="btn btn_v2"
                onClick={() => {
                  setModalMessage('');
                }}
              >
                OK
              </Button>,
            ]}
          >
            {modalMessage === 'done' ? (
              <div>
                <h2 style={{textAlign: 'center'}}>{t('modalTitle')}</h2>
                <p>{t('modalText')}</p>
              </div>
            ) : (
                <div>
                  <p>{modalMessage}</p>
                  {modalMessage === t("modal.done") && <p style={{marginTop: '10px'}}>{t("modal.done2")}</p>}
                </div>
            )}
          </Modal>
        ) : null}
        <ShowModal
          visible={visible}
          // eslint-disable-next-line react/no-children-prop
          children={
            <ShowcaseSelection setVisible={setVisible} visible={visible} t={t} plug={plug}/>
          }
        />
        <div className={s.title}>{t('subTitle1')}</div>
        {visibleCropLogo && <AddAndCropLogo
          visible={visibleCropLogo}
          setVisible={setVisibleCropLogo}
          saveCropLogo={handleLogoSelect}
          img={img}
          setImg={setImg}
        />}
        <Form
          className={s.changeForm}
          style={{width: '100%'}}
          colon={false}
          name="changeProfile"
          labelCol={{span: 9}}
          wrapperCol={{span: 24}}
          autoComplete="off"
          labelAlign="left"
          labelWrap
          requiredMark={false}
          validateTrigger="onSubmit"
          form={form}
          onFieldsChange={(q) => {
            if (!q[0].name.includes('logo')) {
              return autoSave();
            }
          }}
        >
          <Row style={{justifyContent: 'space-between'}}>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <Form.Item
                name="logo"
                rules={[
                  {
                    required: true,
                    message: t('logoError'),
                  },
                ]}
              >
                <div
                  className={s.imgWrapper}
                  style={logoError ? {border: '1px solid #ff4d4f'} : {}}
                >
                  {logo ? (
                    <img src={`${mediaUrl}min-${logo}`} style={{height: '80px', width: '80px', objectFit: 'contain'}}/>
                  ) : (
                    <Img/>
                  )}
                  <div style={{padding: '0 20px'}}>
                    {logo && logoSet.loader !== t('loading') ? (
                      <div style={{marginTop: '30px'}}>
                        {logoSet.fileName
                          ? (logoSet.fileName.length > 20? logoSet.fileName.slice(0, 12) + '...' + logoSet.fileName.slice(logoSet.fileName.length - 4) : logoSet.fileName)
                          : logo.slice(0, 12) + '...' + logo.slice(logo.length - 4)}
                      </div>
                    ) : (
                      <label className={s.link}>
                        <div className={s.logoTitle}>{t('logoTitle')}*</div>
                        {logoSet.loader !== t('loading') && (
                          <div className={s.link} style={{textDecoration: 'underline'}}>
                            {t('upload')}
                          </div>
                        )}
                        <div className={s.keywordsDescription} style={{margin: '0 0 0 30px'}}>
                          {t('format')}
                        </div>
                        <input
                          className={s.file}
                          type="file"
                          onChange={onSelectFile}
                          accept=".png, .jpg, .jpeg"
                          id="fileInput"
                        />
                      </label>
                    )}
                    {logo && (
                      <div
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          autoSave({logo: ''});
                          dispatch(setLogo(''));
                          dispatch(trashLogo(''));
                          dispatch(setLogoLoader({fileName: '', loader: ''}));
                        }}
                      >
                        <Trash style={{position: 'absolute', top: '10px', right: '20px'}}/>
                      </div>
                    )}
                    <div
                      className={s.loader}
                      style={logoLoaderStyle}
                    >
                      {logoSet.loader}
                    </div>
                  </div>
                </div>
              </Form.Item>
              <div
                className={s.keywordsDescription}
                style={{marginTop: '10px', whiteSpace: 'pre-line'}}
              >
                {t('logoFormats')}
              </div>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('companyName')}</div>}
                name="companyName"
                onChange={(e) => {
                  dispatch(setCompanyName(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'companyName',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
                rules={[{required: true, message: t('companyNameError')}]}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('subCompanyName')}</div>}
                name="businessCompanyName"
                initialValue={businessCompanyName}
                onChange={(e) => {
                  dispatch(setBusinessCompanyName(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'businessCompanyName',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: t('businessCompanyNameError'),
                  },
                ]}
              >
                <Input size="large"/>
              </Form.Item>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('registrationNumber')}</div>}
                name="registrationNumber"
                initialValue={registrationNumber}
                onChange={(e) => {
                  dispatch(setRegistrationNumber(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'registrationNumber', form, true, null, null, false, autoSave);
                }}
              >
                <Input size="large"/>
              </Form.Item>
              {profile && (
                <div className={s.txt}>
                  {(() => {
                    const type = profile && profile?.tariffId;
                    if (type === 2) {
                      return (
                        <Tooltip
                          overlayStyle={{maxWidth: '700px'}}
                          placement="bottomLeft"
                          title={tariffDescriptionJSONtoHTML('professional', t)}
                        >
                          <Professional style={{marginRight: '10px'}}/>
                          {profile.tariff.name[lang]}
                        </Tooltip>
                      );
                    } else if (type === 3) {
                      return (
                        <Tooltip
                          overlayStyle={{maxWidth: '700px'}}
                          placement="bottomLeft"
                          title={tariffDescriptionJSONtoHTML('professionalPlus', t)}
                        >
                          <Professional style={{marginRight: '10px'}}/>
                          {profile.tariff.name[lang]}
                        </Tooltip>
                      );
                    } else if (type === 4) {
                      return (
                        <Tooltip
                          overlayStyle={{maxWidth: '700px'}}
                          placement="bottomLeft"
                          title={tariffDescriptionJSONtoHTML('premium', t)}
                        >
                          <PremiumMember style={{marginRight: '10px'}}/>
                          {profile.tariff.name[lang]}
                        </Tooltip>
                      );
                    } else if (type === 1) {
                      return <>{profile.tariff.name[lang]}</>;
                    }
                  })()}
                </div>
              )}

              {(() => {
                if (profile && profile.isVerified) {
                  return (
                    <div className={s.txt}>
                      <Verified style={{marginRight: '10px'}}/>
                      <Tooltip
                        overlayStyle={{maxWidth: '960px'}}
                        placement="bottomLeft"
                        title={<TooltipWithLink isVerified={true}/>}
                      >
                        {t('isVerified', {ns: 'FK3-сompanyProfile'})}
                      </Tooltip>
                    </div>
                  );
                } else if (profile && !profile.isVerified && profile.isVerified !== null) {
                  return (
                    <div className={s.txt}>
                      <NotVerified style={{marginRight: '10px'}}/>
                      <Tooltip
                        overlayStyle={{maxWidth: '960px'}}
                        placement="bottomLeft"
                        title={<TooltipWithLink isVerified={false}/>}
                      >
                        {t('verifiedStatus')}{' '}
                        <NavLink
                          style={{color: 'rgb(234, 91, 12)'}}
                          target={'_blank'}
                          to={'/subscription-management'}
                        >
                          {t('verifiedStatusLink')}
                        </NavLink>
                      </Tooltip>
                    </div>
                  );
                }
              })()}
            </Col>
            <Col md={{span: 10}} xl={{span: 12}} xxl={{span: 12}}>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('website')}</div>}
                name="website"
                initialValue={website}
                onChange={(e) => {
                  dispatch(setWebsite(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'website',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
                rules={[
                  {required: true, message: t('websiteFillingError')},
                  {
                    validator: (_, value) => {
                      const httpPattern = /^https?:\/\//;
                      const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
                      if (!value || !httpPattern.test(value)) {
                        return Promise.reject(t("websiteError"))
                      }
                      if (!value || !urlPattern.test(value)) {
                        return Promise.reject(t("webSiteUnCorrected"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                className={s.textArea}
                label={<div className={`${s.label} ${s.req}`}>{t('companyDescription')}</div>}
                name="description"
                initialValue={description}
                onChange={(e) => {
                  dispatch(setDescription(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'description',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: t('descriptionError'),
                  },
                ]}
              >
                <TextArea showCount maxLength={1000} autoSize={true} row={8} size="large"/>
              </Form.Item>

              <Form.Item
                className={s.companyKeywords}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
                label={<div className={`${s.label} ${s.req}`}>{t('keywords')}</div>}
                name="companyKeywords"
                onChange={(e) => {
                  dispatch(setKeywords(validateInput(e.target.value)));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'companyKeywords',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  customKeyWordsValidator(form, t),
                ]}
              >
                <TextArea size="large" autoSize showCount maxLength={150}/>
              </Form.Item>
              <div className={s.keywordsDescription} style={{maxWidth: '85%'}}>
                {t('keywordsDescription')}
              </div>
            </Col>
          </Row>

          <Row style={{justifyContent: 'space-between', marginTop: '50px'}}>
            {user?.company?.type !== 1 && (
              <Col md={{span: 10}} xl={{span: 10}} xxl={{span: 11}}>
                <div className={s.title}>
                  {t('subTitle2')}
                  <Tooltip
                    overlayStyle={{maxWidth: '400px'}}
                    placement="bottomLeft"
                    title={<TooltipShowcase/>}
                  >
                    <QuestionCircleOutlined
                      width={10}
                      style={{
                        position: 'relative',
                        cursor: 'help',
                        marginLeft: '5px',
                        color: '#474747',
                        fontSize: '18px',
                      }}
                    />
                  </Tooltip>
                </div>
                <div className={s.selectWrapper}>
                  {plug.map((m) => (
                    <PlugItem companyId={profile.id} key={m} id={m} setVisible={setVisible}/>
                  ))}
                </div>
              </Col>
            )}
            <Col md={{span: 10}} xl={{span: 12}} xxl={{span: 12}}>
              <div className={s.title} style={{marginBottom: '10px'}}>
                {t('subTitle4')}
              </div>
              <div className={s.keywordsDescription}>{t('socialDescription')}</div>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('facebook')}</div>}
                name="faceBook"
                rules={[{type: 'url', message: t('error', {field: t('facebook')})}]}
                initialValue={facebook}
                onChange={(e) => {
                  dispatch(setFaceBook(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'faceBook',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('youtube')}</div>}
                name="youtube"
                rules={[{type: 'url', message: t('error', {field: t('youtube')})}]}
                initialValue={youtube}
                onChange={(e) => {
                  dispatch(setYoutube(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'youtube',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('twitter')}</div>}
                name="twitter"
                rules={[{type: 'url', message: t('error', {field: t('twitter')})}]}
                initialValue={twitter}
                onChange={(e) => {
                  dispatch(setTwitter(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'twitter',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('linkedIn')}</div>}
                name="linkedIn"
                rules={[{type: 'url', message: t('error', {field: t('linkedIn')})}]}
                initialValue={linkedin}
                onChange={(e) => {
                  dispatch(setLinkedIn(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'linkedIn',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('instagram')}</div>}
                name="instagram"
                rules={[{type: 'url', message: t('error', {field: t('instagram')})}]}
                initialValue={instagram}
                onChange={(e) => {
                  dispatch(setInstagram(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'instagram',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>
            </Col>
          </Row>
          <div className={s.title} style={{marginTop: '50px'}}>
            {t('subTitle3')}
          </div>
          <Row style={{justifyContent: 'space-between'}}>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('street')}</div>}
                name="street"
                initialValue={street}
                onChange={(e) => {
                  dispatch(setStreet(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'street', form, true, null, null, true, autoSave);
                }}
                rules={[{required: true, message: t('streetError')}]}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('houseNumber')}</div>}
                name="houseNumber"
                initialValue={houseNumber}
                onChange={(e) => {
                  dispatch(setHouseNumber(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'houseNumber',
                    form,
                    true,
                    null,
                    null,
                    true,
                  );
                }}
                rules={[{required: true, message: t('houseNumberError')}]}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('address2')}</div>}
                name="address2"
                initialValue={address2}
                onChange={(e) => {
                  dispatch(setAddress2(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'address2',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('address3')}</div>}
                name="address3"
                initialValue={address3}
                onChange={(e) => {
                  dispatch(setAddress3(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(
                    e.target.value,
                    'address3',
                    form,
                    true,
                    null,
                    null,
                    true,
                      autoSave
                  );
                }}
              >
                <Input size="large"/>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('phoneNumber1')}</div>}
                initialValue={phone}
                name="phone"
                rules={[
                  {required: true, message: ''},
                  {
                    message: t('fields.phoneNumber.errors.required', {ns: 'FS5-Registration'}),
                    validator: (_, value) => {
                      if (!value || value.indexOf('_') !== -1) {
                        return Promise.reject(
                          t('fields.phoneNumber.errors.required', {ns: 'FS5-Registration'}),
                        );
                      } else if (value.length < 10) {
                        return Promise.reject(
                          t('fields.phoneNumber.errors.required', {ns: 'FS5-Registration'}),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  onChange={(e) => inputHandler(e, 1)}
                  value={phone}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('phoneNumber2')}</div>}
                name="phone2"
                onChange={(e) => inputHandler(e, 2)}
                initialValue={phone2}
                rules={[
                  {required: false, message: ''},
                  {
                    validator: (_, value) => {
                      if (value === null || value.length === 0) {
                        return Promise.resolve();
                      }
                      if (value.length < 10) {
                        return Promise.reject(
                          t('fields.phoneNumber.errors.required', {ns: 'FS5-Registration'}),
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input size="large" maxLength={20}/>
              </Form.Item>
              <Form.Item
                name="country"
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('country')}</div>}
                rules={[{required: true, message: t('countryError')}]}
              >
                <Select
                  style={{maxWidth: '600px', fontSize: '16px'}}
                  onChange={select6}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onDropdownVisibleChange={(state) => {
                    setOpenState({...initialState, countries: state});
                    form.setFields([{name: 'country', errors: []}]);
                  }}
                  open={openState.countries}
                  suffixIcon={
                    <DropdownIcon onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        countries: !prevState.countries,
                      }));
                    }}/>
                  }
                  filterSort={(optionA, optionB) => {
                    return optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase());
                  }}
                >
                  {countriesArr.map((m) => {
                    return (
                      <Option key={m.id} value={m.id}>
                        {m.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('region')}</div>}
                name="region"
                initialValue={region}
                onChange={(e) => {
                  dispatch(setRegion(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'region', form, true, null, null, false, autoSave);
                }}
              >
                <Input size="large"/>
              </Form.Item>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label} ${s.req}`}>{t('city')}</div>}
                name="city"
                initialValue={city}
                onChange={(e) => {
                  dispatch(setCity(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'city', form, true, null, null, true, autoSave);
                }}
                rules={[{required: true, message: t('cityError')}]}
              >
                <Input size="large"/>
              </Form.Item>
              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('index')}</div>}
                name="index"
                initialValue={index}
                onChange={(e) => {
                  dispatch(setIndex(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'index', form, true, null, null, false, autoSave);
                }}
              >
                <Input type="number" size="large"/>
              </Form.Item>
            </Col>
          </Row>
          <div className={s.title} style={{marginTop: '50px'}}>
            {t('subTitle5')}
          </div>
          <Row style={{justifyContent: 'space-between'}}>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <div className="border-grey">
                <h5>{t('videoTitle')}</h5>
                <p className="fw-300 mb-12">{t('videoDescription')}</p>
                <Radio.Group
                  value={videoType}
                  onChange={(e) => {
                    setVideoType(validateInput(e.target.value.trim()));
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={'youTubeLink'}>
                      {t('videoLink')}
                    </Radio>
                    <Radio value={'uploadVideo'}>
                      {t('videoFile')}
                    </Radio>
                  </Space>
                </Radio.Group>

                {videoType === 'youTubeLink' && (
                  <Form.Item
                    style={{width: '305px'}}
                    className="mt-12"
                    name="youTubeLink"
                    onChange={(e) => {
                      dispatch(setVideoYT(validateInput(e.target.value.trim())));
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        'video',
                        form,
                        true,
                        null,
                        null,
                        true,
                          autoSave
                      );
                    }}
                    rules={[
                      {type: 'url', message: t('error', {field: t('Yotube')})},
                      {
                        validator: (_, value) => {
                          if (value && (value.includes('youtube') || value.includes('youtu.be'))) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(t('error', {field: t('Yotube')}));
                          }
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder={t('linkYoutube')}/>
                  </Form.Item>
                )}

                {videoType === 'uploadVideo' && (
                  <div>
                    <div style={{display: 'flex'}}>
                      <label
                        htmlFor="video"
                        className="mt-12 btn btn_v5"
                        style={{width: 'max-content', cursor: 'pointer', minHeight: '64px'}}
                      >
                        {!savedVideo && <img src={plusSvg} className="mr-20"/>}
                        {savedVideo
                          ? savedVideo.slice(0, 12) + '...' + savedVideo.slice(savedVideo.length - 4)
                          : t('videoAdd')}
                      </label>
                      <div className={s.trash}>
                        {savedVideo && (
                          <Trash
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              dispatch(setSavedVideo(''));
                              dispatch(setVideoLoader(''));
                              inptRef.current.value = '';
                            }}
                          />
                        )}
                      </div>
                    </div>
                      <input
                          accept=".mp4"
                          id="video"
                          type="file"
                          className="d-none"
                          ref={inptRef}
                          onChange={handleVideoSelect}
                      />
                    <div
                      style={
                        videoLoader === t('loading') || videoLoader === t('videoError')
                          ? {color: 'red'}
                          : {color: 'green'}
                      }
                    >
                      {videoLoader}
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <h5 style={{marginBottom: '-20px'}}>{t('images')}</h5>
              <>
                {images.map((item, index) => (
                  <AddPhoto key={index} id={index} t={t} item={item} setModalMessage={setModalMessage}/>
                ))}
              </>
              <div
                style={{
                  fontSize: '11px',
                  fontWeight: '300',
                  color: '#474747',
                  marginTop: '-22px',
                }}
              >
                {t('recommendedSize')}
              </div>
              <div style={{fontSize: '11px', fontWeight: '300', color: '#474747'}}>
                {t('tipImages')}
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: '20px'}}>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <div className="border-grey">
                <h5>{t('additionalFiles')}</h5>
                <p className="fw-300 mb-12">{t('additionalDescription')}</p>
                <>
                  {files.map((item, index) => (
                    <AddFiles
                      key={index}
                      id={index}
                      t={t}
                      item={item}
                      validateInput={validateInput}
                      OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
                      form={form}
                      OnlyLatinLettersSuccessCallback={autoSave}
                    />
                  ))}
                </>
              </div>
            </Col>
          </Row>
          <div className={s.title} style={{marginTop: '50px'}}>
            {t('subTitle6')}
          </div>
          <div className={s.theTypeOfBusiness}>{t('theTypeOfBusiness')}*</div>
          <div className={s.keywordsDescription}>{t('typeDescription')}</div>
          <Row style={{display: 'flex'}}>
            <Form.Item
              name="businessTypes"
              rules={[
                {required: true, message: ''},
                {
                  validator: (_, value) => {
                    if (value === null || value.length === 0) {
                      return Promise.reject(t('typeBusinessError'));
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              onChange={() => form.setFields([{name: 'businessTypes', errors: []}])}
            >
              <Checkbox.Group
                className={s.group}
                options={optionsWithDisabled}
                value={businessTypesNormal}
                onChange={(e) => {
                  dispatch(setBusinessTypes(e));
                  autoSave()
                }}
              />
            </Form.Item>
          </Row>
          <Row style={{marginTop: '32px', justifyContent: 'space-between'}}>
            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <Form.Item
                name="annualTurnover"
                label={<div className={`${s.label}`}>{t('annualTurnover')}</div>}
                initialValue={annualTurnover}
              >
                <Select
                  open={openState.annualTurnover}
                  suffixIcon={
                    <DropdownIcon onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        annualTurnover: !prevState.annualTurnover,
                      }));
                    }}/>
                  }
                  onDropdownVisibleChange={(state) => {
                    setOpenState({...initialState, annualTurnover: state});
                  }}
                  placeholder={t('placeholder')}
                  onChange={select1}
                  allowClear>
                  {annualTurnoverTypes.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="numberEmployees"
                label={<div className={`${s.label}`}>{t('theNumberOfEmployees')}</div>}
                initialValue={numberEmployees}
              >
                <Select
                  open={openState.numberEmployees}
                  suffixIcon={
                    <DropdownIcon onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        numberEmployees: !prevState.numberEmployees,
                      }));
                    }}/>
                  }
                  onDropdownVisibleChange={(state) => {
                    setOpenState({...initialState, numberEmployees: state});
                  }}
                  placeholder={t('placeholder')}
                  onChange={select2}
                  allowClear>
                  {numberEmployeesTypes.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('yearOfFoundation')}</div>}
                name="yearFoundation"
                onChange={(e) => {
                    dispatch(setYearFoundation(validateInput(e.target.value.trim())));
                    OnlyLatinLettersTextInput(
                        e.target.value,
                        'yearFoundation',
                        form,
                        true,
                        null,
                        null,
                        true,
                        autoSave
                    );
                }}
                initialValue={yearFoundation}
                rules={[
                  {required: false, message: ''},
                  {
                    validator: (_, value) => {
                      if (value && value <= 0) {
                        return Promise.reject();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <MaskedInput
                    mask={Number}
                    maskOptions={{ min: 0, max: 2050 }}
                    size="large"
                />
              </Form.Item>
            </Col>

            <Col md={{span: 10}} xl={{span: 11}} xxl={{span: 11}}>
              <Form.Item
                name="percentageImportsTurnover"
                label={<div className={`${s.label}`}>{t('import')}</div>}
                initialValue={percentageImportsTurnover}
              >
                <Select
                  onDropdownVisibleChange={(state) => {
                    setOpenState({...initialState, percentageImportsTurnover: state});
                  }}
                  open={openState.percentageImportsTurnover}
                  suffixIcon={
                    <DropdownIcon onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        percentageImportsTurnover: !prevState.percentageImportsTurnover,
                      }));
                    }}/>
                  }
                  placeholder={t('placeholder')}
                  onChange={select3}
                  allowClear>
                  {percentageTurnoverTypes.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="percentageExportsTurnover"
                label={<div className={`${s.label}`}>{t('export')}</div>}
                initialValue={percentageExportsTurnover}
              >
                <Select
                  onDropdownVisibleChange={(state) => {
                    setOpenState({...initialState, percentageExportsTurnover: state});
                  }}
                  open={openState.percentageExportsTurnover}
                  suffixIcon={
                    <DropdownIcon onClick={() => {
                      setOpenState((prevState) => ({
                        ...initialState,
                        percentageExportsTurnover: !prevState.percentageExportsTurnover,
                      }));
                    }}/>
                  }
                  placeholder={t('placeholder')}
                  onChange={select4}
                  allowClear>
                  {percentageTurnoverTypes.map((m) => (
                    <Option key={m.id} value={m.id}>
                      {m.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                label={<div className={`${s.label}`}>{t('membership')}</div>}
                name="membershipAssociations"
                onChange={(e) => {
                  dispatch(setMembershipAssociations(validateInput(e.target.value.trim())));
                  OnlyLatinLettersTextInput(e.target.value, 'membershipAssociations', form, true, null, null, false, autoSave);
                }}
                initialValue={membershipAssociations}
              >
                <TextArea showCount maxLength={1000} autoSize={true} size="large"/>
              </Form.Item>
            </Col>
          </Row>
          <div className={s.title} style={{marginTop: '50px', marginBottom: '0'}}>
            {t('subTitle7')}
          </div>
          <div className={s.keywordsDescription}>{t('market')}</div>
          <MainMarkets countriesArr={countriesArr} mainMarkets={mainMarkets} market={market} t={t}
                       setValue={(e) => dispatch(setMainMarkets(e))}/>

          <div
            className={s.btnWrapper}
            style={{
              margin: '40px 0 10px 0',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Form.Item noStyle>
              <Button
                className={`btn btn_v2 ${s.btnWidth}`}
                type="default"
                size="large"
                style={{padding: '26px 54px'}}
                onClick={() => onFinish(false, t('modal.error1'), t('modal.done'), form.getFieldsValue())}
              >
                {t('btn2')}
              </Button>
            </Form.Item>

            <Form.Item noStyle>
              <Button
                className={`btn btn_v8 ${s.btnWidth}`}
                size="large"
                style={{
                  padding: '26px 54px',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      if (!logo) {
                        setLogoError(true);
                        form.setFields([{name: 'logo', errors: [t('logoError')]}]);
                        form.scrollToField('logo', {block: 'center'});
                      } else {
                        setPreview(true);
                      }
                    })
                    .catch((rej) => {
                      form.scrollToField(rej.errorFields[0].name.toString(), {block: 'center'});
                      if (!logo) {
                        setLogoError(true);
                        form.setFields([{name: 'logo', errors: [t('logoError')]}]);
                        form.scrollToField('logo', {block: 'center'});
                      }
                    });
                }}
              >
                <div className={s.btn1}>{t('btn1')}</div>
              </Button>
            </Form.Item>
          </div>
          {profile.moderationStatus && status(profile.moderationStatus)}
        </Form>
      </div>
    );
  }
};

export default ChangeCompanyProfile;
