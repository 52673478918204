import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import s from "./AddUser.module.less";

const inputStyles = { width: 290, backgroundColor: "#F5F7F8" };

const SocialLinks = () => {
  const { t } = useTranslation(["FA2.1-AddUser", "FS8-changeCompanyProfile"]);

  return (
    <section>
      <h2 className={s.title}>{t("socialLinks")}</h2>
      <div className={s.wrapper}>
        <div>
          <Form.Item
            name={["company", "linkedin"]}
            label={<span className={s.label_4}>{t("linkedin")}</span>}
            rules={[
              {
                type: "url",
                message: t("error", {
                  ns: "FS8-changeCompanyProfile",
                  field: t("linkedin", { ns: "FS8-changeCompanyProfile" })
                })
              }
            ]}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["user", "wechat"]}
            label={<span className={s.label_4}>{t("wechat")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["user", "whatsapp"]}
            label={<span className={s.label_4}>{t("whatsapp")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>
          <Form.Item
            name={["company", "instagram"]}
            label={
              <span className={s.label_4}>
                {t("instagram", { ns: "FS8-changeCompanyProfile" })}
              </span>
            }
            rules={[
              {
                type: "url",
                message: t("error", {
                  ns: "FS8-changeCompanyProfile",
                  field: t("instagram", { ns: "FS8-changeCompanyProfile" })
                })
              }
            ]}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={["company", "facebook"]}
            label={<span className={s.label_4}>{t("facebook")}</span>}
            rules={[
              {
                type: "url",
                message: t("error", {
                  ns: "FS8-changeCompanyProfile",
                  field: t("facebook", { ns: "FS8-changeCompanyProfile" })
                })
              }
            ]}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "youtube"]}
            label={<span className={s.label_4}>{t("youtube")}</span>}
            rules={[
              {
                type: "url",
                message: t("error", {
                  ns: "FS8-changeCompanyProfile",
                  field: t("youtube", { ns: "FS8-changeCompanyProfile" })
                })
              }
            ]}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "twitter"]}
            label={<span className={s.label_4}>{t("twitter")}</span>}
            rules={[
              {
                type: "url",
                message: t("error", {
                  ns: "FS8-changeCompanyProfile",
                  field: t("twitter", { ns: "FS8-changeCompanyProfile" })
                })
              }
            ]}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>
        </div>
      </div>
    </section>
  );
};

export default SocialLinks;
