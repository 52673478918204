import PropTypes from "prop-types";

const Trash = ({ onClick, style }) => {
  return (
    <svg
      className="trash"
      onClick={onClick}
      style={{ ...style, cursor: "pointer" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33594 9.16667V14.1667M11.6693 9.16667V14.1667M3.33594 5.83333H16.6693M15.8359 5.83333L15.1134 15.9517C15.0835 16.3722 14.8954 16.7657 14.5869 17.053C14.2784 17.3403 13.8725 17.5 13.4509 17.5H6.55427C6.13272 17.5 5.72683 17.3403 5.41834 17.053C5.10986 16.7657 4.9217 16.3722 4.89177 15.9517L4.16927 5.83333H15.8359ZM12.5026 5.83333V3.33333C12.5026 3.11232 12.4148 2.90036 12.2585 2.74408C12.1022 2.5878 11.8903 2.5 11.6693 2.5H8.33594C8.11492 2.5 7.90296 2.5878 7.74668 2.74408C7.5904 2.90036 7.5026 3.11232 7.5026 3.33333V5.83333H12.5026Z"
        stroke="#F32D2D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Trash.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object
};
export default Trash;
