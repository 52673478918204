import * as React from "react";

const Verified = (props) => (
  <svg width={16} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.568 6.434a.625.625 0 0 1 0 .885l-3.75 3.75a.625.625 0 0 1-.885 0L5.058 9.194a.626.626 0 1 1 .885-.885l1.433 1.433 3.307-3.308a.624.624 0 0 1 .885 0Z"
      fill="#249F5D"
    />
    <path
      d="M.5 2.5A2.5 2.5 0 0 1 3 0h10a2.5 2.5 0 0 1 2.5 2.5v16.875a.625.625 0 0 1-.971.52L8 16.376l-6.529 3.519a.625.625 0 0 1-.971-.52V2.5ZM3 1.25A1.25 1.25 0 0 0 1.75 2.5v15.707l5.904-3.102a.625.625 0 0 1 .692 0l5.904 3.102V2.5A1.25 1.25 0 0 0 13 1.25H3Z"
      fill="#249F5D"
    />
  </svg>
);

export default Verified;
