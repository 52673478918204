import * as React from "react"

const Alert = (props) => (
  <svg
    width={26}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.002 9v2.666m0 5.334h.013m-9.25 5.333h18.474c2.053 0 3.336-2.223 2.31-4l-9.238-16c-1.027-1.777-3.592-1.777-4.618 0l-9.238 16c-1.026 1.777.256 4 2.31 4Z"
      stroke="#D62D30"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Alert
