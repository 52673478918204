import { Button, Input } from "antd";
import Edit2 from "Assets/svg/Edit2";
import Save from "Assets/svg/Save";
import Trash2 from "Assets/svg/trash2";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./Note.module.less";

export const Note = ({
  date,
  id,
  text,
  handleNoteDelete,
  handleNoteEdit,
  handleNoteMode,
  editMode
}) => {
  const [newNote, setNewNote] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.note}>
        <p className={styles.date}>{date}</p>
        {editMode === id ? (
          <Input.TextArea
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleNoteEdit(id, newNote ? newNote : false)}
            autoFocus
            defaultValue={text}
            autoSize
          />
        ) : (
          <p className={styles.text}>{text}</p>
        )}
      </div>
      <div className={styles.buttons}>
        {editMode === id ? (
          <Button
            onClick={() => handleNoteEdit(id, newNote)}
            className={`${styles.btn} ${styles.saveBtn}`}
          >
            <Save />
          </Button>
        ) : (
          <Button onClick={() => handleNoteMode(id)} className={styles.btn}>
            <Edit2 />
          </Button>
        )}
        <Button onClick={() => handleNoteDelete(id)} className={styles.btn}>
          <Trash2 />
        </Button>
      </div>
    </div>
  );
};

Note.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
  handleNoteDelete: PropTypes.func,
  handleNoteEdit: PropTypes.func,
  handleNoteMode: PropTypes.func,
  editMode: PropTypes.number,
  id: PropTypes.number
};
