import * as React from "react";

const LinkedIn = (props) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={32} height={32} rx={16} fill="#1976D2" />
    <path
      d="M22.398 16.597v4.413h-2.582v-4.144c0-1.022-.377-1.722-1.292-1.722-.7 0-1.13.485-1.291.915a2.007 2.007 0 0 0-.108.646v4.305h-2.583s.054-6.996 0-7.695h2.583v1.076c.323-.538.968-1.292 2.314-1.292 1.668 0 2.96 1.13 2.96 3.498ZM11.851 9.602c-.86 0-1.453.591-1.453 1.345 0 .753.539 1.345 1.4 1.345.914 0 1.453-.592 1.453-1.345.053-.807-.485-1.345-1.4-1.345ZM10.56 21.01h2.583v-7.695H10.56v7.695Z"
      fill="#fff"
    />
  </svg>
);

export default LinkedIn;
