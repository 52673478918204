/* eslint-disable react/prop-types */
import React from "react";
import { NavLink } from "react-router-dom";
import s from "./MainCatalog.module.less";
import {  mediaUrl } from "../../api/api";
const TopCompanyItem = ({ id, city, name, logo }) => {
  return (
    <NavLink to={`/company-profile/${id}`} className={s.link} style={{margin: 0, maxWidth: '236px'}}>
      <div className={s.img}>
        <img src={`${mediaUrl}${logo}`} className={s.img} alt={logo} />
      </div>
      <div className={s.wrapperTitle}>
        <div className={s.name}>{name}</div>
        <div className={s.city}>{city}</div>
      </div>
    </NavLink>
  );
};

export default TopCompanyItem;
