import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { domain, mediaUrl, productsAPI, serviceAPI } from "../../../../api/api";
import { useDispatch, useSelector } from "react-redux";

import CheckboxSVG from "../../../../assets/svg/CheckboxSVG";
import { Tooltip } from "antd";
import s from "./ProductItem.module.less";
import { setShowcase } from "../../../../store/changeCompanyProfileSlice";
import style from "../../OfferForm/OfferForm.module.less";
import {useTranslation} from 'react-i18next';

const ProductItem = ({
  title,
  price,
  wrapStyle,
  img,
  currency,
  imgStyle,
  item = { incoterms: [{ name: "" }] },
  check,
  descStyle,
  navLink,
  plug,
  customMode = { enabled: false },
  product,
  plugService,
  length = 8,
  visible = null
}) => {
  const { showcase } = useSelector((state) => state.changeCompanyProfile.customProfile);
  const { t } = useTranslation(["FK3-сompanyProfile"]);
  const [checked, setCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (check && showcase) {
      const lengthCase = showcase.find((f) => Number(f.entityId) === Number(item.id))
      if (lengthCase)setCheck(true)
      else setCheck(false)
    }
  }, [showcase])

  const addShowCase = () => {
    if (!checked) {
      if (showcase.length < 4) {
        setCheck(true);
        if (product) {
          dispatch(setShowcase([...showcase, { entityId: item.id, entityType: "product" }]));
        } else {
          dispatch(setShowcase([...showcase, { entityId: item.id, entityType: "service" }]));
        }
      }
    } else if (checked) {
      setCheck(false);
      const arr = showcase.filter((f) => f.entityId !== item.id && f);
      dispatch(setShowcase(arr));
    }
  };

  const linkHandler = async () => {
    let companyId;
    let id;
    if (item.productId) {
      await productsAPI
        .getProductId(item.productId)
        .then((res) => {
          companyId = res.data.companyId;
          id = res.data.id;
        })
        .catch((e) => {
          // console.log(e.response)
        });
      await navigate(`/company-profile/${companyId}/product/${id}`);
    } else {
      await serviceAPI
        .getServiceId(item.serviceId)
        .then((res) => {
          companyId = res.data.companyId;
          id = res.data.id;
        })
        .catch((e) => {
          // console.log(e.response)
        });
      if (companyId && id) await navigate(`/company-profile/${companyId}/service/${id}`);
    }
  };

  const incotermsMap = item?.incoterms
    ? item.incoterms.map((m) => <span key={m.id}>{m.name} </span>)
    : [];

  const option = () => {
    //all item with navlink
    if (navLink) {
      return (
        <NavLink to={navLink} className={s.wrapper} style={wrapStyle}>
          <div className={s.img} style={imgStyle}>
            <img src={`${mediaUrl}${img}`} className={s.img} alt={"product image"} />
          </div>
          <div className={s.flex} style={descStyle}>
            {title && (
              <div className={s.title} style={length > 8 ? { maxWidth: "100%" } : {}}>
                {title}
              </div>
            )}
            <div>
              {price && price / 100} {currency}{" "}
              {item?.incoterms && item.incoterms.length ? item.incoterms[0].name : null}
              {item?.incoterms && item.incoterms.length > 1 && (
                <Tooltip placement="topLeft" title={incotermsMap} arrowPointAtCenter>
                  ...
                </Tooltip>
              )}
            </div>
          </div>
          {visible !== null && <div className={s.visible} style={visible? {background: '#249F5D'} : {background: '#D62D30'}}>{visible? t("visible") : t("invisible")}</div>}
        </NavLink>
      );
    } else if (customMode.enabled) {
      return (
        <div
          className={`${customMode.productItemLink ? s.wrapperAndHov : s.wrapper} ${
            customMode?.promoId === item?.id ? s.chosen : ""
          }`}
          style={wrapStyle}
          onClick={() => {
            if (customMode.productItemLink) {
              linkHandler();
            } else {
              customMode?.setPromoItemName(item.productName ? item.productName : item.serviceName);
              customMode?.setPromoItemLink(
                `/company-profile/${item.companyId}/${item.productName ? "product" : "service"}/${
                  item.id
                }`
              );
              if (customMode?.promoId === item.id) {
                customMode.setPromoId("");
              } else {
                customMode.setPromoId(item.id);
              }
              if (customMode.setCompetitorItem) {
                customMode.setCompetitorItem(item);
              }
            }
          }}
        >
          <div className={s.img} style={imgStyle}>
            <img src={`${mediaUrl}${img}`} className={s.img} alt={"product image"} />
          </div>

          <div className={s.flex} style={descStyle}>
            <div className={s.title} style={length > 8 ? { maxWidth: "100%" } : {}}>
              {customMode.setPromoId && (
                <a
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  className="orange"
                  href={`/company-profile/${item.companyId}/${
                    item.productName ? "product" : "service"
                  }/${item.id}`}
                  rel="noreferrer"
                >
                  {title}
                </a>
              )}
              {!customMode.setPromoId && title}
            </div>
            <div>
              {price && price / 100} {currency}{" "}
              {item?.incoterms && item.incoterms.length ? item.incoterms[0].name : null}
              {item?.incoterms && item.incoterms.length > 1 && (
                <Tooltip placement="topLeft" title={incotermsMap} arrowPointAtCenter>
                  ...
                </Tooltip>
              )}
            </div>
          </div>
          {/*{customMode.setPromoId && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      display: "flex",*/}
          {/*      justifyContent: "center",*/}
          {/*      alignItems: "center",*/}
          {/*      marginTop: 10*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div className={style.checkboxWrapper} style={{ marginTop: "5px", marginBottom: 0 }}>*/}
          {/*      <div*/}
          {/*        className={`${style.check} ${customMode?.promoId === item?.id ? style.box : ""}`}*/}
          {/*        onClick={() => {*/}
          {/*          customMode?.setPromoItemName(*/}
          {/*            item.productName ? item.productName : item.serviceName*/}
          {/*          );*/}
          {/*          customMode?.setPromoItemLink(*/}
          {/*            `/company-profile/${item.companyId}/${*/}
          {/*              item.productName ? "product" : "service"*/}
          {/*            }/${item.id}`*/}
          {/*          );*/}
          {/*          if (customMode?.promoId === item.id) {*/}
          {/*            customMode.setPromoId("");*/}
          {/*          } else {*/}
          {/*            customMode.setPromoId(item.id);*/}
          {/*          }*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {customMode?.promoId === item?.id && <CheckboxSVG />}*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      );
    }
    //fs9
    else if (check) {
      return (
        <div className={checked? `${s.wrapper} ${s.chosen}` : s.wrapper} style={wrapStyle} onClick={addShowCase}>
          <div className={s.img} style={imgStyle}>
            <img
              src={`${mediaUrl}${img}`}
              className={s.img}
              style={{ width: "180px", height: "180px" }}
              alt={"product image"}
            />
          </div>
          <div className={s.flex} style={descStyle}>
            <div className={s.title} style={length > 8 ? { maxWidth: "100%" } : {}}>
              {title}
            </div>
            <div>
              {price && price / 100} {currency}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className={style.checkboxWrapper} style={{ marginTop: "5px", marginBottom: 0 }}>
              {checked ? (
                <div className={`${style.check} ${style.box}`}>
                  <CheckboxSVG />
                </div>
              ) : (
                <div className={style.check} />
              )}
            </div>
          </div>
        </div>
      );
    }
    //fs8 small item
    else if (plug) {
      return (
        <div className={s.wrapper} style={wrapStyle}>
          <div className={s.img} style={imgStyle}>
            <img
              src={`${mediaUrl}${img}`}
              className={s.img}
              style={imgStyle}
              alt={"product image"}
            />
          </div>
          <div className={s.flex} style={descStyle}>
            <div className={s.title} style={{ maxWidth: "100%" }}>
              {title.slice(0, 15)}
              {title.length > 8 ? (
                <Tooltip placement="topLeft" title={title} arrowPointAtCenter>
                  ...
                </Tooltip>
              ) : null}
            </div>
            <div>
              {price && price / 100} {currency && currency}{" "}
              {item?.incoterms.length ? item.incoterms[0].name : null}
              {item?.incoterms && item.incoterms.length > 1 && (
                <Tooltip placement="topLeft" title={incotermsMap} arrowPointAtCenter>
                  ...
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      );
    } else if (plugService) {
      return (
        <div className={s.wrapper} style={wrapStyle}>
          <div className={s.img} style={imgStyle}>
            <img
              src={`${mediaUrl}${img}`}
              className={s.img}
              style={imgStyle}
              alt={"product image"}
            />
          </div>
          <div className={s.flex} style={descStyle}>
            <div className={s.title} style={{ maxWidth: "100%" }}>
              {title.slice(0, 15)}
              {title.length > 8 ? (
                <Tooltip placement="topLeft" title={title} arrowPointAtCenter>
                  ...
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`${customMode.productItemLink ? s.wrapperAndHov : s.wrapper} ${
            customMode?.promoId === item?.id ? s.chosen : ""
          }`}
          style={wrapStyle}
        >
          <div className={s.img} style={imgStyle}>
            <img src={`${mediaUrl}${img}`} className={s.img} alt={"product image"} />
          </div>

          <div className={s.flex} style={descStyle}>
            <div className={s.title} style={length > 8 ? { maxWidth: "100%" } : {}}>
              {!customMode.setPromoId && title}
            </div>
            <div>
              {price && price / 100} {currency}{" "}
              {item?.incoterms && item.incoterms.length ? item.incoterms[0].name : null}
              {item?.incoterms && item.incoterms.length > 1 && (
                <Tooltip placement="topLeft" title={incotermsMap} arrowPointAtCenter>
                  ...
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return <>{option()}</>;
};

export default ProductItem;
