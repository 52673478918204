import Pin from "Assets/image/Pin.png";
import PropTypes from "prop-types";
import styles from "./NoteInput.module.less";

export const NoteInput = ({ t, noteText, handleNoteChange, handleSubmitNote }) => {
  return (
    <div className={styles.addNoteWrap} style={{ marginBottom: 8 }}>
      <div className={styles.addNoteInput}>
        <img width={80} height={80} src={Pin} />
        <input
          onChange={(e) => handleNoteChange(e.target.value)}
          value={noteText}
          placeholder={t("notesPlaceholder")}
          type="text"
          className={styles.noteInput}
        />
      </div>
      <button onClick={() => handleSubmitNote()} className={`${styles.addNoteButton} btn btn_v3`}>
        {t("saveNote")}
      </button>
    </div>
  );
};

NoteInput.propTypes = {
  t: PropTypes.func,
  noteText: PropTypes.string,
  handleNoteChange: PropTypes.func,
  handleSubmitNote: PropTypes.func
};
