import * as React from "react";

const Close = (props) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m1 1 12 12M1 13 13 1 1 13Z"
      stroke="#222"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Close;
