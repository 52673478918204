import { useSelector } from "react-redux";
import { Outlet, Navigate, useOutletContext } from "react-router-dom";
import Loader from "../Loader/Loader";
import PropTypes from "prop-types";

const PrivateRoutes = ({ isAllowed, redirectPath = "/" }) => {
  const context = useOutletContext();

  const { authLoading } = useSelector((state) => state.auth);
  if (authLoading) return <Loader />;
  if (!isAllowed) return <Navigate to={redirectPath} />;
  return <Outlet context={context} />;
};
PrivateRoutes.propTypes = {
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string
};
export default PrivateRoutes;
