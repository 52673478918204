import { locales } from "../api/api";
import { setIsLoading } from "../store/authSlice";

export const tabsChange = (key, template, dispatch, getter) => {
  if (!template[locales[key]]) {
    dispatch(getter(locales[key]));
  }
};

export const updateTemplate = (
  lang,
  template,
  setModalMessage,
  setModalIsVisible,
  updater,
  t,
  dispatch
) => {
  dispatch(setIsLoading(true));
  updater(lang, template[lang].template, template[lang].subject)
    .then(() => {
      setModalMessage(t("responses.success"));
    })
    .catch(() => {
      setModalMessage(t("responses.error"));
    })
    .finally(() => {
      setModalIsVisible(true);
      dispatch(setIsLoading(false));
    });
};
