import * as React from "react";

const Plug = (props) => (
  <svg width={104} height={104} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M52 35.333V52m0 0v16.667M52 52h16.667M52 52H35.333M102 52a49.995 49.995 0 0 1-30.866 46.194 49.995 49.995 0 0 1-54.49-10.839A50 50 0 1 1 102 52Z"
      stroke="#E8EDF0"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Plug;
