/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import { Select } from "Components/Search";
import s from "./AddUser.module.less";
import { useState, useRef } from "react";

const inputStyles = { width: 290, backgroundColor: "#F5F7F8" };

const ContactInfo = ({ options, form }) => {
  const { t } = useTranslation("FA2.1-AddUser");
  const [phone, setPhone] = useState();
  const [phone2, setPhone2] = useState();
  const refContainer = useRef(null)

  // const inputHandler = (e, num) => {
  //   let reg = /[^\d+().-]/gi;
  //   if (num === 1) {
  //     setPhone(e.target.value.replace(reg, ""));
  //     form.setFieldsValue({ phone: e.target.value.replace(reg, "") });
  //   } else if (num === 2) {
  //     setPhone2(e.target.value.replace(reg, ""));
  //     form.setFieldsValue({ phone2: e.target.value.replace(reg, "") });
  //   }
  // };

  return (
    <section ref={refContainer}>
      <h2 className={s.title}>{t("contactInfo")}</h2>
      <div className={s.wrapper}>
        <div>
          <Form.Item
            name={["company", "street"]}
            rules={[{ required: true, message: t("errors.street") }]}
            label={<span className={s.label_3}>{t("street")}*</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "houseNumber"]}
            rules={[{ required: true, message: t("errors.houseNumber") }]}
            label={<span className={s.label_3}>{t("house")}*</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "address2"]}
            label={<span className={s.label_3}>{t("address2")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "address3"]}
            label={<span className={s.label_3}>{t("address3")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "phone"]}
            rules={[
              { required: true, message: t("errors.phoneNumber") },
              { min: 11, message: t("errors.phoneNumberMinLength") }
            ]}
            label={<span className={s.label_3}>{t("phone1")}*</span>}
          >
            <MaskedInput
              maxLength={20}
              mask={Number}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={inputStyles}
              size="large"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={["company", "phone2"]}
            label={<span className={s.label_3}>{t("phone2")}</span>}
            rules={[{ min: 11, message: t("errors.phoneNumberMinLength") }]}
          >
            <MaskedInput
              maxLength={20}
              mask={Number}
              value={phone2}
              onChange={(e) => setPhone2(e.target.value)}
              style={inputStyles}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name={["company", "countryId"]}
            rules={[{ required: true, message: t("errors.country") }]}
            label={<span className={s.label_3}>{t("country")}*</span>}
          >
            <Select
              options={options}
              size="large"
              placeholder=""
              getPopupContainer={() => refContainer.current}
              style={{ width: 290, fontSize: 14 }}
            />
          </Form.Item>

          <Form.Item
            name={["company", "region"]}
            label={<span className={s.label_3}>{t("region")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "city"]}
            rules={[{ required: true, message: t("errors.city") }]}
            label={<span className={s.label_3}>{t("city")}*</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["company", "index"]}
            label={<span className={s.label_3}>{t("postCode")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
