import { Calendar, Coins, People, Planer } from "../../../assets/svg/KeyIndicators";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { backendUrl, mediaUrl } from "../../../api/api";

import Alert from "../../../assets/svg/Alert";
import BusinessTypeItem from "./BusinessTypeItem/BusinessTypeItem";
import KeyIndicators from "./KeyIndicators/KeyIndicators";
import Map from "../Map/Map";
import { Modal } from "antd";
import ProductItem from "./ProductItem/ProductItem";
import YouTube from "react-youtube";
import axios from "axios";
import s from "./About.module.less";
import style from "../../FK2.1-productPage/ProductPage.module.less";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const HelperYoutubeSize = (e) => {
  const { height } = e.target.getSize();
  e.target.setSize(height, height / 16 * 9)
}

const About = () => {
  const { t } = useTranslation(["FK3-сompanyProfile", "FS8-changeCompanyProfile"]);
  const { companyId } = useSelector((state) => state.company);
  const [setCurrencyAll] = useState([]);
  const [noneTitle, setNoneTitle] = useState(false);
  const { user, isAdmin } = useSelector((state) => state.auth);

  const [openSlider, setOpenSlider] = useState(false);
  const [sliderPhoto, setSliderPhoto] = useState(null);
  const [sliderArr, setSliderArr] = useState([]);
  const photoHandler = (num) => {
    if (num === 1) {
      if (sliderPhoto > 0) {
        setSliderPhoto(sliderPhoto - 1);
      } else {
        setSliderPhoto(sliderArr.length - 1);
      }
    } else if (num === 2) {
      if (sliderPhoto === sliderArr.length - 1) {
        setSliderPhoto(0);
      } else {
        setSliderPhoto(sliderPhoto + 1);
      }
    }
  };

  const keyIndicator = [
    {
      svg: <People />,
      title: t("KeyIndicators.description1"),
      value: companyId.numberEmployees && companyId.numberEmployees.name
    },
    {
      svg: <Coins />,
      title: t("KeyIndicators.description2"),
      value: companyId.annualTurnover && companyId.annualTurnover.name
    },
    {
      svg: <Planer />,
      title: t("KeyIndicators.description3"),
      value: companyId.percentageExportsTurnover && companyId.percentageExportsTurnover.name
    },
    {
      svg: <Calendar />,
      title: t("KeyIndicators.description4"),
      value: companyId.yearFoundation && companyId.yearFoundation
    },
    {
      svg: <Planer />,
      title: t("KeyIndicators.description5"),
      value: companyId.percentageImportsTurnover && companyId.percentageImportsTurnover.name
    },
    {
      svg: <People />,
      title: t("membership1", {ns: "FS8-changeCompanyProfile"}),
      value: companyId.membershipAssociations && companyId.membershipAssociations
    },
  ];
  const indicatorArr = useMemo(() => keyIndicator.filter(f => f.value !== null).filter(f => f.value !== '').length, [keyIndicator])
  let productArr = useMemo(() => [...companyId.services, ...companyId.products], [companyId]);

  useEffect(() => {
    axios.get(`${backendUrl}/resources/payment-methods`).then((res) => setCurrencyAll(res.data));
  }, []);

  useEffect(() => {
    setSliderArr(companyId.companyPhotos.map((f) => f.photo));
  }, [companyId]);

  useEffect(() => {
    if (keyIndicator.filter((item) => item.value === null).length === 6) {
      setNoneTitle(true);
    }
  }, [keyIndicator]);

  return (
    <div>
      <Modal
        className={style.modal}
        visible={openSlider}
        onOk={() => setOpenSlider(false)}
        onCancel={() => setOpenSlider(false)}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "30px", color: "#fff" }} />}
      >
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={style.prev}
          onClick={() => photoHandler(1)}
        >
          <LeftOutlined className={style.arrow} />
        </div>
        <img className={style.imgModal} src={`${mediaUrl}${sliderArr[sliderPhoto]}`} alt="" />
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={style.next}
          onClick={() => photoHandler(2)}
        >
          <RightOutlined className={style.arrow} />
        </div>
      </Modal>
      <div className={s.section}>
        <div className={s.title}>{t("about")}</div>
        <div className={s.text}>{companyId.description}</div>
        <div className={s.text} style={{ display: "flex" }}>
          <div
            className={companyId.companyKeywords.length <= 0 ? s.title_none : ""}
            style={{ textDecoration: "underline" }}
          >
            {t("keyWords")}
          </div>
          {companyId.companyKeywords &&
            companyId.companyKeywords.map((item, index) => (
              <div key={index} className={s.keyWord}>
                {item.keyword.replace(/[\s,%]/g, " ")}
              </div>
            ))}
        </div>
      </div>

      <div className={productArr.length <= 0 ? s.section_noMargin : s.section}>
        <div className={s.title}>{t("productsAndService")}</div>
        <div className={s.productItem}>
          {productArr.map((item, index) => {
            if (item.productName) {
              return (
                <ProductItem
                  navLink={`/company-profile/${item.companyId}/product/${item.id}`}
                  key={index}
                  title={item.productName}
                  price={item.unitPrice}
                  img={item.image}
                  currency={item.currency.name}
                  item={item}
                />
              );
            } else {
              return (
                <ProductItem
                  navLink={`/company-profile/${item.companyId}/service/${item.id}`}
                  key={index}
                  title={item.serviceName}
                  img={item.image}
                  length={25}
                />
              );
            }
          })}
        </div>
      </div>

      <div
        className={companyId?.companyBusinessTypes?.length <= 0 ? s.section_noMargin : s.section}
      >
        <div className={s.title}>{t("businessType.title")}</div>
        <div className={s.productItem}>
          {companyId.companyBusinessTypes?.map((item, index) => (
            <BusinessTypeItem key={index} id={item.id} title={item.name} t={t} />
          ))}
        </div>
      </div>

      <div className={noneTitle ? s.section_noMargin : s.section}>
        <div className={s.title}>{t("KeyIndicators.title")}</div>
        <div
          className={s.indicator}
          style={indicatorArr >= 5? {justifyContent: 'center'} : {}}
        >
          {keyIndicator.map((item, index) => (
            <KeyIndicators key={index} title={item.title} svg={item.svg} value={item.value} />
          ))}
        </div>
      </div>

      <div className={s.section}>
        <div className={s.title}>{t("tradingCompanies")}</div>
        <Map companyId={companyId} bool={true} />
      </div>

      <div className={s.section}>
        {companyId.video ? (
          <div>
            <div className={s.title}>{t("additionalMaterials")}</div>
            <div className={s.text} style={{ fontSize: "18px" }}>
              {t("video")}
            </div>
            {(() => {
              let regExp =
                /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
              let match = companyId.video.match(regExp);
              let videoId = match && match[7]?.length == 11 ? match[7] : false;

              if (companyId.video && companyId.video.indexOf("youtube") > 0) {
                return <YouTube videoId={videoId} onReady={HelperYoutubeSize}/>;
              } else if (companyId.video && companyId.video.indexOf("youtu.be") > 0) {
                return <YouTube videoId={videoId} onReady={HelperYoutubeSize}/>;
              } else {
                return (
                  <video
                    src={`${mediaUrl}${companyId.video}`}
                    title="video"
                    controls="controls"
                    style={{
                      width: "max-content",
                      height: "max-content",
                      maxHeight: "260px",
                      maxWidth: "390px"
                    }}
                  />
                );
              }
            })()}
          </div>
        ) : null}

        {companyId.companyPhotos?.length ? (
          <div className={s.text} style={{ fontSize: "18px", marginTop: "32px" }}>
            {t("image")}
          </div>
        ) : null}
        <div
          className={s.imgWrapper}
          style={companyId.companyPhotos?.length === 4 ? { justifyContent: "space-between" } : {}}
        >
          {companyId.companyPhotos?.map(
            (item, index) =>
              item.photo && (
                <img
                  onClick={() => {
                    setOpenSlider(true);
                    setSliderPhoto(sliderArr.indexOf(item.photo));
                  }}
                  style={{
                    width: "300px",
                    height: "180px",
                    marginBottom: "30px",
                    objectFit: "contain",
                    cursor: "pointer",
                    marginRight: companyId.companyPhotos?.length === 4 ? "0" : "30px"
                  }}
                  key={index}
                  src={`${mediaUrl}${item.photo}`}
                  alt="img"
                />
              )
          )}
        </div>
        {companyId.companyFiles?.length ? (
          <div className={s.text} style={{ fontSize: "18px", marginTop: "32px" }}>
            {t("additionalFiles")}
          </div>
        ) : null}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {companyId.companyFiles?.map((item, index) =>
            item.filename ? (
              <div><a href={`${mediaUrl}${item.filename.split('|')[0]}`} key={index} className={s.link}>
                {index + 1}. <span style={{ textDecoration: "underline" }}>{item.filename.split('|')[1]}</span>
              </a> <span style={{fontSize: '16px'}}> - {item.description}</span></div>
            ) : null
          )}
        </div>
      </div>
      <div className={`${s.jcsb} ${s.flex} ${s.alignItemsCenter}`} style={{ marginBottom: "82px" }}>
        <div className={`${s.alignItemsCenter} ${s.flex}`}>
          {/*<Checkbox*/}
          {/*  style={{ borderColor: "red" }}*/}
          {/*  checked={check}*/}
          {/*  onChange={() => setCheck(!check)}*/}
          {/*/>*/}
          {/*<div style={{ marginLeft: "18px", fontWeight: "400" }}>{t("auctions.tracked")}</div>*/}
        </div>
        <NavLink
          to={!isAdmin && user? "/account/make-complaint" : '/login'}
          state={!isAdmin && user?{ companyLink: window.location.href } : {moveOn: "/account/make-complaint", stateFromLink: { companyLink: window.location.href } }}
          className={`${s.flex} ${s.alignItemsCenter}`}
          style={{ fontWeight: "400", color: "#D62D30", marginRight: "140px" }}
        >
          <Alert style={{ marginRight: "22px" }} />
          {t("auctions.complain")}
        </NavLink>
      </div>
    </div>
  );
};

export default About;
