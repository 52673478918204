import { Spin } from "antd";
import PropTypes from "prop-types";

const Loader = ({ style = null }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={
        style || {
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "1001",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }
      }
    >
      <Spin />
    </div>
  );
};
Loader.propTypes = { style: PropTypes.object || null };
export default Loader;
