import { Button, Col, Modal, Row, Select, Space, Table, Tabs, Tooltip } from "antd";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import {
  getProductById,
  getProducts,
  initialCurrentProduct,
  setCurrentProduct
} from "../../store/productsSlice";
import {
  getServiceById,
  getServices,
  initialCurrentService,
  setCurrentService
} from "../../store/servicesSlice";
import { productsAPI, serviceAPI } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AddNewProduct } from "../FS10-AddNewProduct/AddNewProduct";
import AddNewService from "../FS11-AddNewService/AddNewService";
import DropdownIcon from "../../components/DropdownIcon/DropdownIcon";
import Edit from "../../assets/svg/Edit";
import Loader from "../../components/Loader/Loader";
import PropTypes from "prop-types";
import Trash from "../../assets/svg/trash";
import { requestAmount } from "../../helpers/tableHelpers";
import s from "../../components/IncomingRequestsSeller/IncomingRequestsSellerTable.module.less";
import { setIsLoading } from "../../store/authSlice";
import { showTimeWithoutHours } from "../../helpers/timeDate";
import { timeFormatter2 } from "../../helpers/timeFormat";
import useProductServiceInfo from "../../hooks/useProductServiceInfo";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CheckboxV1 from "../../components/Checkbox_v1/CheckboxV1";
import { notificationsFeedAPI } from "../../api/api";

const EditProductOrService = ({
  companyId,
  showDetails = true,
  openAddProductAdmin,
  admin = false,
  openAddServiceAdmin
}) => {
  const { products } = useSelector((state) => state.products);
  const { services } = useSelector((state) => state.services);
  const { isLoading } = useSelector((state) => state.auth);
  const user = companyId ? companyId : useSelector((state) => state.auth);
  const { getInfo, InfoVisualisation } = useProductServiceInfo(admin, companyId);
  const [deleteItemId, setDeleteItemId] = useState();
  const [itemName, setItemName] = useState();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const {state} = location

  const { TabPane } = Tabs;
  const { t } = useTranslation([
    "FS12-EditProductOrService",
    "ProductCategories",
    "ServiceCategories",
    "Pagination",
    "utils"
    // "FS10-AddNewProduct"
  ]);
  const { setTitle } = useOutletContext();
  useEffect(() => {
    return () => {
      setEditMode(null);
      dispatch(setCurrentProduct(initialCurrentProduct));
      dispatch(setCurrentService(initialCurrentService));
    };
  }, [dispatch]);

  // edit-product-or-service
  const [openState, setOpenState] = useState({
    productsRequestAmount: false,
    servicesRequestAmount: false
  });
  const [productsPagination, setProductsPagination] = useState({
    total: products.total,
    current: 1,
    pageSize: 10
  });

  const [servicesPagination, setServicesPagination] = useState({
    total: services.total,
    current: 1,
    pageSize: 10
  });

  const [type, setType] = useState('product')

  const productsIsAtr = products.items.length && products.items.filter((obj) => obj.act).map((obj) => obj.id)
  const servicesIsAtc = services.items.length && services.items.filter((obj) => obj.act).map((obj) => obj.id)

  console.log(services)
  console.log(servicesIsAtc)


  // useEffect(() => {
  //   if (loggedIn) {
  //     dispatch(
  //       getProducts(
  //         productsPagination.pageSize,
  //         productsPagination.current,
  //         null,
  //         null,
  //         null,
  //         user.user.company.id,
  //         true
  //       )
  //     );
  //     dispatch(
  //       getServices(
  //         servicesPagination.pageSize,
  //         servicesPagination.current,
  //         null,
  //         null,
  //         null,
  //         user.user.company.id,
  //         true
  //       )
  //     );
  //   }
  // }, [loggedIn]);
  const [editMode, setEditMode] = useState(null); // product | service | null

  const [productsData, setProductsData] = useState();
  const [servicesData, setServicesData] = useState();

  const [productsDirection, setProductsDirection] = useState();
  const [productsColumn, setProductsColumn] = useState();

  const [servicesDirection, setServicesDirection] = useState();
  const [servicesColumn, setServicesColumn] = useState();

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") return <a>{t("prev", { ns: "Pagination" })}</a>;
    else if (type === "next") return <a>{t("next", { ns: "Pagination" })}</a>;
    else return originalElement;
  };
  useEffect(() => {
    if (editMode === null) {
      {
        setTitle(t("title"));
      }
    }
  }, [t, setTitle, editMode]);

  const handleTableChange2 = (
    newPagination,
    filters,
    sorter,
    setPagination,
    setDirection,
    setColummn
  ) => {
    setPagination({ ...newPagination });

    if (sorter) {
      if (sorter.order === "ascend") setDirection("ASC");
      else if (sorter.order === "descend") setDirection("DESC");
      else setDirection();
      setColummn(sorter.field);
    }
  };

  useEffect(() => {
    // console.log(productsPagination)
    // console.log(productsDirection)
    // console.log(productsColumn)
    // console.log('-----------------------------------')
      dispatch(
          getProducts(
              productsPagination.pageSize,
              productsPagination.current,
              null,
              productsColumn,
              productsDirection,
              admin ? companyId : user.user.company.id,
              true
          )
      );

  }, [productsPagination, productsDirection, productsColumn]);

  useEffect(() => {
      dispatch(
          getServices(
              servicesPagination.pageSize,
              servicesPagination.current,
              null,
              servicesColumn,
              servicesDirection,
              admin ? companyId : user.user.company.id,
              true
          )
      );

  }, [servicesPagination, servicesDirection, servicesColumn]);

  useEffect(() => {
    const data = products.items.map((p) => {
      const source = p?.moderations ? p.moderations : p;
      return {
        key: p.id,
        productName: source.productName,
        category: source.subcategories
          .map((s) => t(`productCategories.${s.category?.type}.${s.type}`, { ns: "ProductCategories" }))
          .join(" | "),
        createdAt: showTimeWithoutHours(p.createdAt),
        visible: p.visible,
        moderation: p.moderationStatus
      };
    });
    setProductsData(data);

    if (products.total !== productsPagination.total) {
      setProductsPagination((prev) => ({
        ...prev,
        total: products.total
      }));
    }
  }, [products, t]);

  useEffect(() => {
    const data = services.items.map((p) => {
      const source = p?.moderations ? p.moderations : p;
      return {
        key: p.id,
        serviceName: source.serviceName,
        category: source.subcategories
          .map((s) => t(`${s.category?.type}.${s.type}`, { ns: "ServiceCategories" }))
          .join(" | "),
        createdAt: showTimeWithoutHours(p.createdAt),
        visible: p.visible,
        moderation: p.moderationStatus
      };
    });
    setServicesData(data);

    if (services.total !== servicesPagination.total) {
      setServicesPagination((prev) => ({
        ...prev,
        total: services.total
      }));
    }
  }, [services, t]);
  const [productOrService, setProductOrService] = useState(
    location?.state?.tab === "service" ? 2 : 1
  );

  const tabsChange = (key) => {
    setProductOrService(Number(key));
  };

  console.log(type)

  console.log(productOrService)

  useEffect(() => {
    if(state?.key) {
      setProductOrService(2)
    }
    if(productOrService === 1) {
      setType('product')
    } else {
      setType('service')
    }
  }, [productOrService, state?.key])

  const handleItemDelete = (id) => {
    setModalIsVisible(false);
    if (productOrService === 1) {
      dispatch(setIsLoading(true));
      productsAPI
        .deleteProduct(id)
        .then(() => {
          let page;
          if (
            (productsPagination.total - 1) % productsPagination.pageSize === 0 &&
            productsPagination.current !== 1
          ) {
            page = productsPagination.current - 1;
          } else {
            page = productsPagination.current;
          }
          setProductsPagination((prev) => ({
            ...prev,
            current: page
          }));
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setModalMessage(t("deleteError"));
          } else {
            setModalMessage(t("errorOccurred"));
          }
          setModalIsVisible(true);
          setError(true);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
          getInfo();
        });
    } else {
      dispatch(setIsLoading(true));
      serviceAPI
        .deleteService(id)
        .then(() => {
          let page;
          if (
            (servicesPagination.total - 1) % servicesPagination.pageSize === 0 &&
            servicesPagination.current !== 1
          ) {
            page = servicesPagination.current - 1;
          } else {
            page = servicesPagination.current;
          }
          setServicesPagination((prev) => ({
            ...prev,
            current: page
          }));
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setModalMessage(t("deleteError"));
          } else {
            setModalMessage(t("errorOccurred"));
          }
          setModalIsVisible(true);
          setError(true);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
          getInfo();
        });
    }
  };


  const columns = [
    {
      title: productOrService === 1 ? t("product") : t("service"),
      dataIndex: productOrService === 1 ? "productName" : "serviceName",
      key: productOrService === 1 ? "productName" : "serviceName",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"]
    },
    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
      width: 200
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend"]
    },
    {
      title: <>{t("status")}</>,
      dataIndex: "moderationStatusId",
      key: "moderationStatusId",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      width: 225,
      render: (_, { moderation }) => {
        let text;
        let cssClass;
        if (moderation?.name === "ACCEPTED") {
          text = t("moderated");
          cssClass = "label_v2 label_v2_green";
        } else if (moderation?.name === "PROCESSED") {
          text = t("onModeration");
          cssClass = "label_v2 label_v2_red";
        } else if (moderation?.name === "EDITED") {
          text = t("editing");
          cssClass = "label_v2 label_v2_orange";
        } else if (moderation?.name === "REJECTED") {
          text = t("rejected");
          cssClass = "label_v2 label_v2_red";
        }

        return <div className={cssClass}>{text}</div>;
      }
    },
    {
      title: (
        <>
          {t("visible")}{" "}
          <Tooltip title={t("visibilityDescriptions")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "visible",
      key: "visible",
      width: 200,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (_, { visible, moderation, key }) => {
        let cssClass;
        let text;
        if (visible && moderation?.name === "ACCEPTED") {
          cssClass = "label_v2 label_v2_green";
          text = t("isVisible");
        } else {
          cssClass = "label_v2 label_v2_red";
          text = t("invisible");
        }
        const changeVisibility = () => {
          productOrService === 1
            ? productsAPI
                .changeProductVisibility(key, !visible)
                .then(() => setProductsPagination((prev) => ({ ...prev })))
            : serviceAPI
                .changeServiceVisibility(key, !visible)
                .then(() => setServicesPagination((prev) => ({ ...prev })));
          if (moderation?.name !== "ACCEPTED" && !visible) {
            setError(true);
            setItemName("");
            setModalMessage(t("visibleAfterModeration"));
            setModalIsVisible(true);
          }
        };
        return (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <span className={cssClass}>{text}</span>
            <CheckboxV1
              style={{ marginLeft: "auto" }}
              visible={!!visible}
              onClick={changeVisibility}
            />
            <div className="divider" style={{ marginLeft: "12px", width: 2.5 }}></div>
          </div>
        );
      }
    },
    {
      dataIndex: "actions",
      key: "actions",
      width: 150,

      render: (_, { key }) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            {/*<Link*/}
            {/*  style={{ width: "max-content" }}*/}
            {/*  className="underline mr-35"*/}
            {/*  to={`/account/create-auction/${key}`}*/}
            {/*>*/}
            {/*  {t("createAuction", { ns: "FS12-EditProductOrService" })}*/}
            {/*</Link>*/}
            <div className="actions_block">
              <Tooltip title={t('edit')}>
                <span style={{display: 'flex', alignItems: 'center'}}>
                  <Edit
                    onClick={() => {
                      if (productOrService === 1) setEditMode("product");
                      else setEditMode("service");
                      setEditId(key);
                    }}
                  />
                </span>
              </Tooltip>
              <div className="divider"></div>
              <Tooltip title={t('delete')}>
                <span style={{display: 'flex', alignItems: 'center'}}>
                  <Trash
                  onClick={() => {
                    setDeleteItemId(key);
                    if (productOrService === 1) {
                      setItemName(productsData.find((p) => p.key === key).productName);
                      setModalMessage(t("deleteProduct"));
                    } else {
                      setItemName(servicesData.find((s) => s.key === key).serviceName);
                      setModalMessage(t("deleteService"));
                    }
                    setModalIsVisible(true);
                  }}
                />
                </span>
              </Tooltip>
            </div>
          </div>
        );
      }
    }
  ];

  // END edit-product-or-service

  // edit-mode

  const [editId, setEditId] = useState();

  useEffect(() => {
    if (editMode === "product") {
      dispatch(getProductById(editId, true, true));
    } else if (editMode === "service") {
      dispatch(getServiceById(editId, true, true));
      // console.log("edit service ");
    } else {
      dispatch(
        getProducts(
          productsPagination.pageSize,
          productsPagination.current,
          null,
          productsColumn,
          productsDirection,
          admin ? companyId : user.user.company.id,
          true
        )
      );
      dispatch(
        getServices(
          servicesPagination.pageSize,
          servicesPagination.current,
          null,
          servicesColumn,
          servicesDirection,
          admin ? companyId : user.user.company.id,
          true
        )
      );
      dispatch(setCurrentProduct(initialCurrentProduct));
      dispatch(setCurrentService(initialCurrentService));
      window.scrollTo(0, 0);
    }
  }, [editMode]);

  useEffect(() => {
    if(type === 'product' && productsIsAtr.length){
      notificationsFeedAPI.markNotificationsAsReadNew(productsIsAtr, type);
    }
    if(type === 'service' && servicesIsAtc.length) {
      notificationsFeedAPI.markNotificationsAsReadNew(servicesIsAtc, type)
    }
  }, [type, servicesPagination.current, productsPagination.current, servicesIsAtc.length, productsIsAtr.length])

  return (
    <>
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onCancel={() => setModalIsVisible(false)}
          footer={
            !error
              ? [
                  <Button
                    key="ok"
                    className="btn btn_v12"
                    onClick={() => {
                      handleItemDelete(deleteItemId);
                    }}
                  >
                    {t("buttons.yes")}
                  </Button>,
                  <Button
                    key="cancel"
                    className="btn btn_v6"
                    onClick={() => setModalIsVisible(false)}
                  >
                    {t("buttons.no")}
                  </Button>
                ]
              : [
                  <Button
                    key="cancel"
                    className="btn btn_v2"
                    onClick={() => {
                      setModalIsVisible(false);
                      setError(false);
                    }}
                  >
                    ОK
                  </Button>
                ]
          }
        >
          <p style={{ fontWeight: 400 }}>{modalMessage}</p>
          <p style={{ fontWeight: 400 }}>{itemName}</p>
        </Modal>
      ) : null}

      <Row>
        <Col
          sm={{ span: 24 }}
          xxl={{ span: 24 }}
          style={admin ? { minWidth: "100%" } : {}}
          className={`regular_block ${editMode ? "d-none" : ""}`}
        >
          {showDetails && <Row>{InfoVisualisation()}</Row>}
          <Tabs
            defaultActiveKey={state?.key || productOrService.toString()}
            onChange={tabsChange}
            tabBarExtraContent={
              <Link
                onClick={() => {
                  admin && productOrService === 1 ? openAddProductAdmin() : openAddServiceAdmin();
                }}
                className="btn btn_v2"
                to={
                  admin
                    ? ""
                    : productOrService === 1
                    ? "/account/add-product"
                    : "/account/add-service"
                }
              >
                {productOrService === 1 ? t("buttons.addProduct") : t("buttons.addService")}
              </Link>
            }
          >
            {isLoading && <Loader />}
            <TabPane tab={`${t("products")} (${products?.total || 0})`} key="1">
              <Space className="mt-15" align="center" size={12}>
                <span className={s.requestAmountLabel}>{t("show")}</span>
                <Select
                  className={s.requestAmount}
                  size="large"
                  suffixIcon={
                    <DropdownIcon
                      onClick={() => {
                        setOpenState((prevState) => ({
                          ...prevState,
                          productsRequestAmount: !prevState.productsRequestAmount
                        }));
                      }}
                    />
                  }
                  open={openState.productsRequestAmount}
                  onDropdownVisibleChange={(state) => {
                    setOpenState((prevState) => ({ ...prevState, productsRequestAmount: state }));
                  }}
                  onChange={(pageSize) => {
                    let current;
                    if (
                      (productsPagination.total % pageSize === 0 ||
                        pageSize > productsPagination.total) &&
                      productsPagination.current !== 1
                    ) {
                      current = productsPagination.current - 1;
                    } else {
                      current = productsPagination.current;
                    }
                    handleTableChange2(
                      {
                        ...productsPagination,
                        pageSize,
                        current
                      },
                      null,
                      null,
                      setProductsPagination,
                      setProductsDirection,
                      setProductsColumn
                    );
                  }}
                  style={{
                    width: 73
                  }}
                  optionLabelProp={"label"}
                  options={requestAmount}
                  defaultValue={10}
                />
                <span className={s.requestAmountLabel}>{t("showProducts")}</span>
              </Space>
              <Table
                style={{
                  marginTop: 50
                }}
                columns={columns}
                pagination={{
                  ...productsPagination,
                  itemRender: itemRender,
                  hideOnSinglePage: true,
                  position: ["bottomCenter"],
                  showSizeChanger: false
                }}
                dataSource={productsData}
                onChange={(newPagination, filters, sorter) => {
                  handleTableChange2(
                    newPagination,
                    filters,
                    sorter,
                    setProductsPagination,
                    setProductsDirection,
                    setProductsColumn
                  );
                }}
              />
            </TabPane>
            <TabPane onClick={() => console.log('test services')} tab={`${t("services")} (${services?.total || 0})`} key="2">
              <Space className="mt-15" align="center" size={12}>
                <span className={s.requestAmountLabel}>{t("show")}</span>
                <Select
                  className={s.requestAmount}
                  size="large"
                  suffixIcon={
                    <DropdownIcon
                      onClick={() => {
                        setOpenState((prevState) => ({
                          ...prevState,
                          servicesRequestAmount: !prevState.servicesRequestAmount
                        }));
                      }}
                    />
                  }
                  open={openState.servicesRequestAmount}
                  onDropdownVisibleChange={(state) => {
                    setOpenState((prevState) => ({ ...prevState, servicesRequestAmount: state }));
                  }}
                  onChange={(pageSize) => {
                    let current;
                    if (
                      (servicesPagination.total % pageSize === 0 ||
                        pageSize > servicesPagination.total) &&
                      servicesPagination.current !== 1
                    ) {
                      current = servicesPagination.current - 1;
                    } else {
                      current = servicesPagination.current;
                    }
                    handleTableChange2(
                      {
                        ...servicesPagination,
                        pageSize,
                        current
                      },
                      null,
                      null,
                      setServicesPagination,
                      setServicesDirection,
                      setServicesColumn
                    );
                  }}
                  style={{
                    width: 73
                  }}
                  optionLabelProp={"label"}
                  options={requestAmount}
                  defaultValue={10}
                />
                <span className={s.requestAmountLabel}>{t("showServices")}</span>
              </Space>
              <Table
                style={{
                  marginTop: 50
                }}
                columns={columns}
                pagination={{
                  ...servicesPagination,
                  itemRender: itemRender,
                  hideOnSinglePage: true,
                  position: ["bottomCenter"],
                  showSizeChanger: false
                }}
                dataSource={servicesData}
                onChange={(newPagination, filters, sorter) => {
                  handleTableChange2(
                    newPagination,
                    filters,
                    sorter,
                    setServicesPagination,
                    setServicesDirection,
                    setServicesColumn
                  );
                }}
              />
            </TabPane>
          </Tabs>
        </Col>
        {editMode && (
          <>
            {editMode === "product" ? (
              <AddNewProduct
                admin={admin}
                customCompanyId={companyId}
                editMode={editMode}
                setEditMode={setEditMode}
                editId={editId}
              />
            ) : (
              <AddNewService
                admin={admin}
                customCompanyId={companyId}
                editMode={editMode}
                setEditMode={setEditMode}
                editId={editId}
              />
            )}
          </>
        )}
      </Row>
    </>
  );
};
EditProductOrService.propTypes = {
  companyId: PropTypes.number,
  showDetails: PropTypes.bool
};
export default EditProductOrService;
