import * as React from "react";

const Professional = (props) => (
  <svg width={14} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.065 10.41 7 8.943l1.925 1.467-.734-2.375L10.124 6.5H7.757L6.999 4.16 6.24 6.5H3.874l1.925 1.534-.734 2.375Zm8.6-3.075A6.665 6.665 0 0 0 7 .668 6.665 6.665 0 0 0 .332 7.335c0 1.691.633 3.225 1.667 4.4v6.433l5-1.667 5 1.667v-6.433a6.634 6.634 0 0 0 1.666-4.4ZM7 2.335c2.758 0 5 2.241 5 5 0 2.758-2.242 5-5 5-2.759 0-5-2.242-5-5 0-2.759 2.241-5 5-5Zm0 12.5-3.334.85V13.1c.984.567 2.117.9 3.334.9 1.216 0 2.35-.333 3.333-.9v2.584l-3.333-.85Z"
      fill="#EA5B0C"
    />
  </svg>
);

export default Professional;
