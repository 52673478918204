import { Outlet, useLocation } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";

import AdminSider from "../../components/Sider/AdminSider";
import { Footer } from "Components/footer/Footer";
import { Header } from "../../components/header/Header";
import { Layout } from "antd";
import Loader from "../../components/Loader/Loader";
import s from "../Account/Account.module.less";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import { useTranslation } from "react-i18next";

const { Content } = Layout;
const AdminPanel = () => {
  const { t } = useTranslation(["Header", "AdminPanel"]);
  const [title, setTitle] = useState(t("title", { ns: "AdminPanel" }));
  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/admin-panel") {
      setTitle(t("title", { ns: "AdminPanel" }));
    }
  }, [t]);
  return (
    <>
      <Header h1={title} withBottomPanel={false} />
      <Layout className={s.content} style={{ flexDirection: "row" }}>
        <AdminSider />
        <Suspense fallback={<Loader style={{ width: "100%", height: "auto" }} />}>
          <Content style={{ padding: "32px", position: "relative", zIndex: 1 }}>
            <Outlet context={{ setTitle }} />
          </Content>
        </Suspense>
      </Layout>
      <Footer />
    </>
  );
};

export default AdminPanel;
