/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useOnlyLatinLetterInputHandlerAndModal from "Hooks/useOnlyLatinLetterInputHandlerAndModal";
import SearchIcon from "../../../assets/svg/SearchIcon";
import s from "./Search.module.less";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

const Search = ({ defaultValue, small, setCurrentPage, setFilters, initialFilters }) => {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation("FK4-SearchProducts");
  const { serv_type } = useParams();
  const { searchingProductType } = useSelector((state) => state.catalog);
  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();
  const changeHandler = (e) => {
    setInputValue(e.currentTarget.value);
    OnlyLatinLettersTextInput(e.currentTarget.value, "item", form, true)
  };

  const submitHandler = (e) => {
    // console.log("=========================",inputValue);
    navigate(`/searching/type${searchingProductType === NaN ? serv_type : searchingProductType}/page1/${inputValue || ""}`, { replace: true });
    setFilters(initialFilters)
    // setCurrentPage(1);
  };

  return (
    <div
      className={s.wrapper}
      style={small ? { padding: "0", flexDirection: "column", width: "338px" } : {}}
    >
      {OnlyLatinLettersModal}
      <div
        className={s.txt}
        style={small ? { fontSize: "14px", minWidth: "250px", marginBottom: "18px" } : {}}
      >
        {t("searchLabel")}
      </div>
      <Form
        form={form}
        className={s.inputWrapper}
        labelAlign="left"
        colon={false}
        requiredMark={false}
        onFinish={submitHandler}
      >
        <Form.Item
          name="item"
          className={s.input_block}
        >
          <input
            placeholder={t("searchPlaceholder")}
            className={s.input}
            style={small ? { marginLeft: "0" } : {}}
            defaultValue={inputValue}
            onChange={changeHandler}
          />
        </Form.Item>
        <SearchIcon
          style={{ position: "absolute", right: "40px", top: "20px", cursor: "pointer" }}
          className={s.submit_btn}
          onClick={submitHandler}
        />
      </Form>
    </div>
  );
};

export default Search;
