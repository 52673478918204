import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";

const Agreement = () => {
  const { t } = useTranslation("FS0-Home");
  useEffect(() => {
    setDocumentTitle(t("form.formInputs.links.first"));
  }, [t]);
  return (
    <>
      <Header h1={t("form.formInputs.links.first")} />
      <Row justify="center">
        <Col xl={{ span: 16 }}>
          <div className="regular_block">{t("form.formInputs.links.first")}</div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Agreement;
