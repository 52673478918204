import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EllipsisText from "Components/EllipsisText/EllipsisText";
import { getAllTechIssues } from "Store/techIssueSlice";
import s from "./AllTechIssueTable.module.less";
import FilterTable from "Components/FilterTable/FilterTable";
import { makeColumnItem } from "../../helpers/tableHelpers";
import { workTime } from "../../helpers/timeDate";

export const AllTechIssuesTable = () => {
  const { t } = useTranslation([
    "FS40-all-tech-issues",
    "Statuses",
    "techProblemTopics",
    "Pagination",
    "utils"
  ]);
  const { allTechIssue } = useSelector((state) => state.techIssues);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  useMemo(() => {
    dispatch(getAllTechIssues(10, currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    setTotalPages(allTechIssue.total);
  }, [allTechIssue.total]);

  const filterTableData = useMemo(() =>
    allTechIssue.items?.length
      ? [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...allTechIssue?.items?.map((item, i) => ({ ...item, key: `${item.id}_${i}` }))
        ]
      : [][allTechIssue]
  );

  const columns = [
    {
      ...makeColumnItem(t, "date-application", 200),
      dataIndex: "createdAt",
      render(_, { createdAt }) {
        return <span>{workTime(createdAt, user?.tz?.timeZone).format("DD.MM.YYYY")}</span>;
      }
    },
    {
      ...makeColumnItem(t, "time-application", 200),
      dataIndex: "createdAt",
      render(_, { createdAt }) {
        return <span>{workTime(createdAt, user?.tz?.timeZone).format("HH:mm")}</span>;
      }
    },
    {
      ...makeColumnItem(t, "problem-ID", 200),
      dataIndex: "code",
      render(_, { code }) {
        return <p style={{ width: "150px" }}>{code}</p>;
      }
    },
    {
      ...makeColumnItem(t, "whatProblemWith", 200),
      dataIndex: "topicTechnicalProblem",
      render(_, { topicTechnicalProblem }) {
        return <p>{t(topicTechnicalProblem?.name, { ns: "techProblemTopics" })}</p>;
      }
    },
    {
      ...makeColumnItem(t, "problem-thing", 200),
      dataIndex: "message",
      render(_, { message }) {
        return (
          <EllipsisText len={35} style={{ fontSize: "12.6px", width: "250px" }}>
            {message || ""}
          </EllipsisText>
        );
      }
    },
    {
      ...makeColumnItem(t, "problem-status", 200),
      dataIndex: "status",
      render(_, { status }) {
        return (
          <div
            className={
              status.name === "PROCESSED"
                ? s.processed
                : status.name === "NOT_PROCESSED"
                ? s.not_processed
                : status.name === "VIEWED"
                ? s.viewed
                : ""
            }
          >
            <p>{t(status.name, { ns: "Statuses" })}</p>
          </div>
        );
      }
    },
    {
      ...makeColumnItem(t, "admin-answer", 200),
      dataIndex: "answer",
      render(_, { answer }) {
        return (
          <EllipsisText len={35} style={{ fontSize: "12.6px", width: "250px" }}>
            {answer || ""}
          </EllipsisText>
        );
      }
    }
  ];

  return (
    <>
      <section className={s.wrapper}>
        <FilterTable
          columns={columns}
          data={filterTableData}
          current={currentPage}
          pageSize={10}
          total={totalPages}
          onChange={setCurrentPage}
        />
      </section>
    </>
  );
};
