const SendMessageBtn = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.2664 8.08992L33.7211 34.2415C33.3024 36.0869 32.2117 36.5462 30.6616 35.6774L22.2118 29.4509L18.1351 33.3726C17.6836 33.8241 17.3069 34.2008 16.4368 34.2008L17.0444 25.596L32.7039 11.446C33.3851 10.8396 32.5556 10.5023 31.646 11.11L12.2866 23.3005L3.95225 20.6912C2.13969 20.1255 2.10687 18.8787 4.33025 18.0085L36.9288 5.44917C38.4382 4.88348 39.7586 5.78517 39.2664 8.09123V8.08992Z"
        fill="#EA5B0C"
      />
    </svg>
  );
};

export default SendMessageBtn;
